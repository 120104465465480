





































































































































































































































































































import axios from "axios";
import SortIndicator from "../components/SortIndicator.vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import {notifier} from "../models/common";
import PortalStatus from "../components/PortalStatus.vue";
import LaddaButton from "../components/LaddaButton.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";

declare const htmlCheck: Function;
declare const htmlParse: Function;

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    PortalStatus,
    ConfirmRemoveItemModal,
    LaddaButton,
    SortIndicator
  }
})
export default class DistyAvailability extends TSXComponent<void> {
  selectedID: (number | undefined)[] = [];
  excludedIDs: (number | undefined)[] = [];
  currentRole = sessionStorage.getItem("userRole");
  ISSEARCH = 0;
  loading = false;
  selectedAll = false;
  confirmFileRemove = false;
  removeLoading = false;
  pageNumber = sessionStorage.pageDetails && JSON.parse(sessionStorage.pageDetails)["distyAvailability"] ? JSON.parse(sessionStorage.pageDetails)["distyAvailability"] : 1;
  items: any[] = [];
  details: any = {};
  sort = {
    field : null,
    direction : {
      product_sku: 1,
      product_name: 1,
      techdata: 1,
      synnex: 1,
      dh: 1,
      totalUsers: 1,
      totalAccounts: 1,
      status: 1
    }
  }
  directionField = "";
  filters: any = {
    product_sku: "",
    product_name: "",
    email: "",
    accountID: "",
    customer: "",
    domain: "",
    selectedStatus: []
  }
  searchFilters: any = {
    product_sku: "",
    product_name: ""
  }
  distyIDs = {
    "TechData": 2,
    "Synnex": 3,
    "DH": 4
  };
  saving = false;
  skus = "";
  skuRefreshLoadingIndex = -1;

  async created() {
    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
		try {
      var reqData = {
        controller      : "Custom",
        FunctionName    : "DistributorAvailibility",
        subsystem       : "VAR360",
        maxRows         : (sessionStorage.getItem('maxRows') != '' ? sessionStorage.getItem('maxRows') : getMaxRows()),
        PageNumber      : this.pageNumber,
        productName     : this.filters.product_name,
        productSku     : this.filters.product_sku,
      }
      if(this.sort.field) {
        reqData["order"] = this.sort.field;
        reqData["direction"] = this.sort.direction[this.directionField];
      }
			const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

			if (response.data.ERROR) {
				throw new Error(response.data.ERROR);
			}
			if (response.data.STATUS !== 1) {
				throw new Error(response.data.STATUSMESSAGE);
			}

      if(response.data.STATUS == 1) {
        this.details = response.data;
        this.items = response.data.SKULIST;
      }
      if (this.selectedAll === true) {
        this.selectAll(true);
      }
		}catch (err) {
			// console.log(err.message);
		}finally {
			this.loading = false;
		}
  }

  //go to search page
  gotoSearch = function(this: any, searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for" + this.$route.path.toLowerCase()
    });
  };

  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    if(sessionStorage.pageDetails != undefined && JSON.parse(sessionStorage.pageDetails)["distyAvailability"] ) {
      var oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["distyAvailability"] = this.pageNumber;
      sessionStorage.setItem('pageDetails',JSON.stringify(oldData))
    }

    if (this.pageNumber <= this.details.TOTALPAGES && this.pageNumber >= 1) {
      await this.fetchData();
    }
  }

  async sortBy(field, directionField) {
    this.sort.field = field;
    this.sort.direction[directionField] = (this.sort.direction[directionField] == 1 ? 2 : 1);
    this.directionField = directionField;

    //reset
    for (const dirField in this.sort.direction) {
      if (dirField === directionField) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  searchBy(field) {
    if(this.searchFilters[field] == "") {
      return;
    }
    this.filters[field] = this.searchFilters[field];
    this.pageNumber = 1;
    this.fetchData();
  }

  resetBy(field) {
    if(this.searchFilters[field] == "") {
      return;
    }
    this.pageNumber = 1;
    this.filters[field] = this.searchFilters[field] = "";
    this.fetchData();
  }

  // updateValue(field) {
  //   if(this.filters[field] == "") {
  //     this.pageNumber = 1;
  //     this.fetchData();
  //   }
  // }

  // checkAllStatus() {
  //   this.pageNumber = 1;
  //   this.filters.selectedStatus = [1, 0];
  //   this.fetchData();
  // }
  // searchByStatus() {
  //   this.pageNumber = 1;
  //   this.fetchData();
  // }
  // resetByStatus() {
  //   this.pageNumber = 1;
  //   this.filters.selectedStatus = [];
  //   this.fetchData();
  // }

  async saveSKUs() {
    var htmlCount = 0;
    var regex = /[^a-zA-Z0-9#-,]/gi;
    if(this.skus == "") {
      return;
    }
    if ($("#sku").val() != "") {
      if (htmlCheck($("#sku").val())) {
        this.skus = htmlParse($("#sku").val());
        htmlCount++;
      }
    }
    if (htmlCount != 0) {
      notifier.alert("HTML content found. This content has been removed!");
    }
    this.skus = this.skus.replace(regex, "");
    var skuArr = this.skus.split(',');
    var longestSku = skuArr.reduce(
      function (a, b) {
          return a.length > b.length ? a : b;
      }
    );
    if (longestSku.length > 25) {
      var longestSkuError = longestSku;
      $("#sku").addClass("longestSkuColor");
      $(".errorMsg").text("The length of an SKU should be 25 or less:" + longestSkuError);
      return;
    } else {
      $("#sku").removeClass("longestSkuColor");
      $(".errorMsg").text("");
    }

    try {
      this.saving = true;
      var reqData = {
        controller      : "Custom",
        FunctionName    : "AddSKU",
        subsystem       : "VAR360",
        skus            : this.skus
      }
			const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

			if (response.data.ERROR) {
				throw new Error(response.data.ERROR);
			}
			if (response.data.STATUS == 0) {
				throw new Error(response.data.STATUSMESSAGE);
			}

      if(response.data.STATUS == 1) {
        notifier.success(response.data.STATUSMESSAGE);
        // reload list
        await this.fetchData();
        this.skus = "";
      }
      if(response.data.STATUS == 2)
        notifier.alert(response.data.STATUSMESSAGE);
		}catch (err) {
			// console.log(err.message);
		}finally {
			this.saving = false;
		}
  }

  async refreshSKU(item: any, sourceID, index) {
    try {
      this.skuRefreshLoadingIndex = index;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller      : "Helpers",
        FunctionName    : "GetSKUData",
        SKU             : item.SKU,
        sourceId        : sourceID
      });

			if (response.data.ERROR) {
				throw new Error(response.data.ERROR);
			}
			if (response.data.STATUS !== 1) {
				throw new Error(response.data.STATUSMESSAGE);
			}

      if(response.data.STATUS == 1) {
        await this.fetchData();
      }
		}catch (err) {
			// console.log(err.message);
		}finally {
			this.skuRefreshLoadingIndex = -1;
		}
  }
  getSelectedTotal(total) {
    var ret = 0;
    if (this.selectedAll) {
      ret = total - this.excludedIDs.length;
    } else {
      ret = this.selectedID.length;
    }

    return ret;
  }
  checkExcludedIDs(items, key) {
    if (this.selectedAll) {
      for (var val of items) {
        if (!this.selectedID.includes(val[key])) {
          if (!this.excludedIDs.includes(val[key])) {
            this.excludedIDs.push(val[key]);
          }
        } else if (this.excludedIDs.includes(val[key])) {
          this.$delete(
            this.excludedIDs,
            this.excludedIDs.findIndex(id => id == val[key])
          );
        }
      }

      if (this.getSelectedTotal(this.details.TOTAL) == 0) {
        this.selectedAll = false;
        this.excludedIDs = [];
      }
    }
  }
  selectAll(selectedAll = true) {
    this.selectedAll = selectedAll;
    this.selectedID = [];
    if (this.selectedAll == true) {
      for (var val of this.items) {
        if (!this.excludedIDs.includes(val.SKU)) {
          this.selectedID.push(val.SKU);
        }
      }
    } else {
      //uncheck
      this.excludedIDs = [];
    }
  }
  async deleteProducts() {
    this.removeLoading = true;
    try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller      : "Custom",
          FunctionName    : "Delete",
          subsystem       : "VAR360",
          deleteProduct: "true",
          selectedIDs: this.selectedID.toString()
        });
        if (response.data.STATUS) {
          notifier.success("Product deleted successfully");
          await this.fetchData();
          this.confirmFileRemove = false;
          this.selectedID = [];
        }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.removeLoading = false;
    }
  }
}
