























































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import { notifier,roundCents, wait, downloadFileUrl } from "../models/common";
import CustomerAutoSuggestInput from "../components/CustomerAutoSuggestInput.vue";
import VarsourceAutoSuggestInput from "../components/VarsourceAutoSuggestInput.vue";
import SKUAutoSuggestInput from "../components/SKUAutoSuggestInput.vue";
import ProductNameAutoSuggestInput from "../components/ProductNameAutoSuggestInput.vue";
import PriceWarnTooltip from "../components/PriceWarnTooltip.vue";
import Simplified from "../components/createSimplified.vue";
import Direct from "../components/createDirect.vue";
import Indirect from "../components/createIndirect.vue";
import Extended from "../components/createExtended.vue";
import LaddaButton from "../components/LaddaButton.vue";
import { ErrorBag } from "vee-validate";
import directives from "../helpers/directives";
import GlobalTagSelect from "../components/GlobalTagSelect.vue";
import ConfirmSwitchCustomer from "../components/ConfirmRemoveItemModal.vue";
import ConfirmSwitchTaxRate from "../components/ConfirmRemoveItemModal.vue";
import ConfirmModalBox from "../components/ConfirmRemoveItemModal.vue";
import MessageBox from "../components/MessageBox.vue";
import ConfirmCustomTax from "../components/ConfirmRemoveItemModal.vue";
import DropdownControl from "../components/DropdownControl.vue";
import CustomerDepartmentTooltip from "../components/CustomerDepartmentTooltip.vue";
import PageTitle from '../components/pageTitle.vue';
import SubQuoteEndUserCustomData from "@/components/Quote/SubQuoteEndUserCustomData.vue";
import { dollarFormat, getRandomNumber } from "@/helpers/ApiHelper";
import { ApiHelper } from "@/helpers/all";
import SubOrderQuoteEndUser from "@/components/SubOrderQuoteEndUser.vue";
import opportunityAutoSuggestInput from "@/components/opportunityAutoSuggestInput.vue";
import CustomerAddressTooltip from "@/components/CustomerAddressTooltip.vue";

declare const $: any;
declare const getImageRouteData: any;
declare const dataURL: string;
declare const getRouteData: Function;
declare const htmlCheck: Function;
declare const htmlParse: Function;

// const findParent = (indexName: string, itemNames: Record<string, any>) => {
//   const idx = parseInt(indexName.split("_")[1]);
//   // find parent
//   for (let i = idx - 1; i >= 1; i--) {
//     const each = itemNames[`productName_${i}`];

//     if (parseInt(each.included) === 0) {
//       return each;
//     }
//     if (!each) {
//       continue;
//     }
//   }

//   return null;
// };

const findParent = (index: string, itemNames: any) => {
  const currentItem = itemNames[index];
  if (!currentItem || !(currentItem.included || 0)) return null;

  // find parent for an included item
  let parent: any = null;
  const keys = Object.keys(itemNames);
  for (let i = 0; i <= keys.length - 1; i++) {
    const item = itemNames[keys[i]];
    if (!(item.included || 0)) {
      parent = item;
    }
    if (index == keys[i]) {
      break;
    }
  }

  return parent;
};

const findOtherIncludedItems = (
  indexName: string,
  itemNames: Record<string, any>
) => {
  const idx = parseInt(indexName.split("_")[1]);
  const items: any[] = [];
  // find parent
  for (let i = idx - 1; i >= 1; i--) {
    const each = itemNames[`productName_${i}`];
    if (!each) {
      continue;
    }
    if (parseInt(each.included) === 0) {
      break;
    }

    items.push(each);
  }

  return items;
};

const findImmediateIncludedItems = (
  indexName: string,
  itemNames: Record<string, any>
) => {
  const idx = parseInt(indexName.split("_")[1]);
  var hasIncluded = false;
  if (Object.keys(itemNames).length != idx) {
    if (parseInt(itemNames[`productName_${idx + 1}`].included)) {
      if (itemNames[`productName_${idx + 1}`].quantity) {
        hasIncluded = true;
      } else {
        // console.log('itemlength',itemNames.length)
      }
    }
    if (
      parseInt(itemNames[`productName_${idx}`].included) &&
      !itemNames[`productName_${idx}`].quantity
    ) {
      for (let i = idx + 1; i <= Object.keys(itemNames).length; i++) {
        const each = itemNames[`productName_${i}`];
        if (parseInt(each.included) === 1 && parseInt(each.quantity)) {
          const parent = findParent(indexName, itemNames);
          if (parent) {
            parent.priceWarn = true;
          }
        }
        if (parseInt(each.included) === 0) {
          break;
        }
      }
    }
    if (!parseInt(itemNames[`productName_${idx}`].included)) {
      // const parent = findParent(indexName, itemNames);
    }
  }

  return hasIncluded;
};
@Component({
  inheritAttrs: false,
  components: {
    CustomerAutoSuggestInput,
    VarsourceAutoSuggestInput,
    PriceWarnTooltip,
    Simplified,
    Direct,
    Indirect,
    Extended,
    LaddaButton,
    ConfirmSwitchCustomer,
    ConfirmSwitchTaxRate,
    GlobalTagSelect,
    ConfirmModalBox,
    MessageBox,
    ConfirmCustomTax,
    DropdownControl,
    CustomerDepartmentTooltip,
    PageTitle,
    SubQuoteEndUserCustomData,
    SubOrderQuoteEndUser,
    opportunityAutoSuggestInput,
    CustomerAddressTooltip
  },
  directives
})
export default class QuoteCreate extends TSXComponent<void> {
  $decimals: any;
  $decimalsView: any;
  // directives
  // components: {
  //   CustomerAutoSuggestInput,
  //   VarsourceAutoSuggestInput,
  //   PriceWarnTooltip,
  //   Simplified,
  //   Direct,
  //   Indirect,
  //   Extended,
  //   LaddaButton
  // SKUAutoSuggestInput,
  // ProductNameAutoSuggestInput,
  // }
  // data() {
  length = 0;

  // errors = [];
  // errors:any = [];

  // }
  $refs!: {
    quoteView:Extended | Indirect | Direct | Simplified
  }
  // return {
  currentComponent = "";
  title= "New Quote";
  zero = 0;
  customer = "";
  source = "";
  sourceID = 0;
  prevsource:any = [];
  aID = 0;
  // cusTaxApplied = false;
  // currCustTax = 0;
  // allowEst = false;
  // prevCustTax = 0;
  itemNames: {
    name?: string;
    price?: any;
    customerPrice?: any;
    sku?: string;
    quantity?: number;
    included?: number;
    total?: any;
    taxRate?: any;
    margin?: any;
    tax?: any;
    priceWarn?: boolean;
    noTax?: boolean;
    shipping?: number;
    totalProducts?: number;
    configID?: string;
    DistyPrice?: number;
    DistyAvailability?: number;
    DistyPrice_Format?: string;
    DistyAvailability_Format?: string;
    Disty?: string;
    orgPrice?: any;
    orgCustomerPrice?: any;
    disable?: boolean;
    isContractFee?: boolean;
    isFee?: boolean;
  } = {};

  itemName: {
    [key: string]: {
      name?: string;
      price?: any;
      customerPrice?: any;
      sku?: string;
      quantity?: number;
      included?: number;
      total?: any;
      taxRate?: any;
      margin?: any;
      marginRate?: any;
      tax?: any;
      priceWarn?: boolean;
      noTax?: boolean;
      shipping?: number;
      totalProducts?: number;
      configID?: string;
      DistyPrice?: number;
      DistyAvailability?: number;
      DistyPrice_Format?: string;
      DistyAvailability_Format?: string;
      Disty?: string;
      orgPrice?: any;
      orgCustomerPrice?: any;
      disable?: boolean;
      isContractFee?: boolean;
      includedparent : number;
      baseProductId : number;
      includeItemCount : number;
      parentLiId?: number;
      quoteLiId?: number;
      isFee?: boolean;
      ItemPLID?: number; 
      ItemPLName?: string; 
    };
  } = {};
  items: {
    [key: string]: {
      name?: string;
      price?: any;
      customerPrice?: any;
      sku?: string;
      quantity?: number;
      included?: number;
      total?: any;
      taxRate?: any;
      margin?: any;
      tax?: any;
      priceWarn?: boolean;
      noTax?: boolean;
      shipping?: number;
      totalProducts?: number;
      configID?: string;
      DistyPrice?: number;
      DistyAvailability?: number;
      DistyPrice_Format?: string;
      DistyAvailability_Format?: string;
      Disty?: string;
      orgPrice?: any;
      orgCustomerPrice?: any;
      disable?: boolean;
      isContractFee?: boolean;
      includedparent : number;
      baseProductId : number;
      includeItemCount : number;
      isFee?: boolean;
    };
  }[] = [];
  details: object[] = [];
  arrowCounter = 0;
  profit: any = "$0.00";
  totalShipping: any = "$0.00";
  qShip: any = 0.0;
  totalTax: any = "$0.00";
  qTax: any = 0.0;
  taxVal: any = 0;
  estPercent = 0;
  grandTotal: any = "$0.00";
  itemTotal: any = "$0.00";
  totalVarCost: any = "$0.00";
  quoteDate = "";
  quoteName = "";
  errorMsg = "";
  uploading: any = false;
  saving: any = false;
  taxLoading: any = false;
  taxRateOn = 0;
  placeholderValue = "";
  autoName = 1;
  autoCost = 0;
  qOpen = 0;
  loading: any = false;
  accountID = "";
  USECUSTOMTAX = 0;
  // globalTag = "";
  // customID = 0;
  globalTags: any = [];
  BDnumber = "";
  ContractName = "";
  Created = "";
  Expires = "";
  QuoteNumber = "";
  ContractNumber = "";
  globalMargin = 0;
  globalMarginBK = 0;
  confirmedGlobalMargin = false;
  marginInput = 'rate';//default
  marginView = "margin_per";//default
  MarginViewTooltipVisible = false;
  confirmSwitchCustomerModalVisible = false;
  confirmedSwitchCustomer = false;
  confirmSwitchTaxRateVisible = false;
  // cusTaxApplied = false;
  hpDirectTaxRate = 0;
  hpDirectTaxRateTmp = 0;
  indirectTaxRate = 0;
  indirectTaxRateTmp = 0;
  // prevCustTax = 0;
  // currCustTax = 0;
  switchCustomerMsg1 = "";
  switchCustomerMsg2 = "";
  customer_bk: any = {
    customer: "",
    aID: 0,
    accountID: ""
  };
  newTaxRate = 0;
  currentTaxRate = 0;
  duplicateLoading: any = false;
  defaultESTPercent = 0;
  distyMarginRate = [];
  confirmedSourceRateVisible = false;
  ConfirmCustomTax = false;
  allowEditQuote = false;
  sourceRateMessage = "";
  selectedIndexForTax = "";
  messageBoxModal = false;
  DistyTotal: any = {};
  // default techdata disty when using dynamic source
  defaultDisty = 2;
  distyIDs = [4];
  qQuoteID = "";
  sourceName = "";
  percent = 0;
  HPEdefaultESTPercent = 0;
  expandDecimal = false;
  expandDecimalPrecision = 2;
  addNewType = "";
  customerDepartment: any = [];
  selectedDepartment: number[] = [];
  departmentVisible = false;
  departmentItems = [
    {
      NAME: "",
      ID: 0
    }
  ];
  duplicateDeptName = false;
  selectedDepartmentName = "";
  orderContractNumber: any = [];
  orderContractsID: number[] = [];
  selectedOrderContracts = "";
  selectedContracts: any = [];
  onetimeDepartment: any = {};
  includedItem = false;
  includedPrice = false;
  quoteBigDealNumber = "";
  quoteRevisionNumber = "";
  businessLineOptions: any = [];
  selectedBusinessLine: number[] = [];
  fullCustomData: any = [];
  customDataVisible = false;
  isDuplicate = false;
  cfIds: any = {};
  configCategory: any = {};
  feeVisibleIndex = "";
  splitBySubQuoteId: any = [{
    subQuoteId: 0
  }];
  selectedSubQuote: any = {};
  euCustomDataVisible = false;
  uploadedFileName = "";
  euDataVisible = false;
  opportunityName: string = "";
  opportunityID: number = 0;
  opportunityPrevID: number = 0;
  // shipping/billing address
  customerAddresses: any = [];
  selectedAddress: number[] = [];
  highlightedAddress = 0;
  customerAddressVisible = false;
  customerAddressesBilling: any = [];
  selectedBillingAddress: number[] = [];
  highlightedBillingAddress = 0;
  billingAddressVisible = false;
  onetimeAddress: any = {};
  onetimeAddressBilling: any = {};
  count:number = 0;
  qShip_BK: number = 0;
  qTax_BK: number = 0;
  currentComponent_BK: string = "";
  showIncludeVarCost: boolean = false;
  includeVarCost: boolean = true;
  checkAllTax: boolean = false;

  @Watch("customer")
  async customerChanged(val) {
    if(val == null) {
      this.customer = "";
      this.aID = 0;
      this.accountID = "";
      this.customer_bk = {
        customer: this.customer,
        aID: this.aID,
        accountID: this.accountID
      }
      this.customerDepartment = [];
      this.selectedDepartment = [];
      this.opportunityName = "";
      this.opportunityID = 0;
    }
  }

  @Watch("opportunityName")
  async opportunityChanged(val) {
    if(val == null) {
      this.opportunityName = "";
      this.opportunityID = 0;
    }
  }

  async created() {
    if (typeof this.$route.query.aName === "string") {
      this.customer = this.$route.query.aName.length ? this.$route.query.aName : "";
    }
    if (
      typeof this.$route.query.aId !== "undefined"
      && typeof this.$route.query.aId !== "number"
    ) {
      this.aID = this.$route.query.aId.length ? this.$route.query.aId : 0;
    }
    // init for DistyTotal
    for(const i in this.distyIDs) {
      const distyID = this.distyIDs[i];
      this.DistyTotal[`${distyID}`] = {
        "TotalPrice": 0,
        "StockQty": 0,
        "TotalQty": 0
      };
    }
    let standardUUID = this.$route.params.createquote || '';
    let fromStandardList = parseInt(this.$route.query.fromStandardList) || 0;
    let standardUUIDs = this.$route.query.standardIds || "";

    if (standardUUID.length) {
      var responseCreateQuotes = await ApiHelper.callApi(
        'post',
        {
          controller: "Quotes",
          FunctionName: "StandardView",
          ObjID: standardUUID,
          selectedLiID: this.$route.query.selectedLiID
        }
      );
    }
    if (fromStandardList && standardUUIDs.length) {
      this.loading = true;
      var responseCreateQuotes = await ApiHelper.callApi("post", {
        controller: "Quotes",
        FunctionName: "PrepareStandardData",
        createFor: "Quote",
        UUIDs: standardUUIDs
      });
    }
    if (
      typeof this.$route.params.qID == "undefined" ||
      parseInt(this.$route.params.qID) == 0
    ) {
      this.length = 3;
      if (standardUUID.length || (fromStandardList && standardUUIDs.length)){
        this.count = responseCreateQuotes.ITEMS.length
      }else{
        this.count = 3
      }
      for (let i = 1; i <= this.count; i++) {
        this.itemName["productName_" + i] = {
          name: "",
          sku: "",
          price: 0.0,
          customerPrice: 0.0,
          margin: 0.0,
          marginRate: 0,
          total: 0.0,
          quantity: 0,
          tax: 0.0,
          taxRate: 0,
          shipping: 0.0,
          included: 0,
          priceWarn: false,
          totalProducts: 3,
          noTax: false,
          configID: "",
          DistyPrice: 0,
          DistyAvailability: 0,
          DistyPrice_Format: "",
          DistyAvailability_Format: "",
          Disty: "",
          orgPrice: 0.0,
          orgCustomerPrice: 0.0,
          disable: false,
          isContractFee: false,
          includedparent : 0,
          baseProductId : 0,
          includeItemCount : 0,
          parentLiId: 0,
          quoteLiId: 0,
          isFee: false,
          ItemPLID: 0,
          ItemPLName: ''
        };
      }
      this.itemNames = this.itemName;
    }
    if(standardUUID.length || (fromStandardList && standardUUIDs.length)) {
      if (responseCreateQuotes.STATUS) {
        if (responseCreateQuotes.DETAILS) {
          this.quoteName = responseCreateQuotes.DETAILS.STANDARDNAME;
          this.title = "Standard quotes #" + responseCreateQuotes.DETAILS.STANDARDNAME;
        } else {
          this.title = "Create Standard Quote";
        }
        this.qQuoteID = responseCreateQuotes.SOURCEQUOTEIDS;
        let index = 0;
        let grandTotal = 0;
        let profitTotal = 0;
        let varCost = 0;
        for (let i in this.itemNames) {
          if (responseCreateQuotes.ITEMS.length > index) {
            this.loading = false;
            let tmpMargin = responseCreateQuotes.ITEMS[index].SPRODPRICE - responseCreateQuotes.ITEMS[index].SPRICEREG;
            this.itemNames[i].sku = responseCreateQuotes.ITEMS[index].SPRODSKU;
            this.itemNames[i].name = responseCreateQuotes.ITEMS[index].SPRODDESC;
            this.itemNames[i].customerPrice = responseCreateQuotes.ITEMS[index].SPRODPRICE;
            this.itemNames[i].price = responseCreateQuotes.ITEMS[index].SPRICEREG;
            this.itemNames[i].margin = tmpMargin;
            this.itemNames[i].marginRate = tmpMargin / (responseCreateQuotes.ITEMS[index].SPRODPRICE ? responseCreateQuotes.ITEMS[index].SPRODPRICE : 1) * 100;
            this.itemNames[i].quantity = responseCreateQuotes.ITEMS[index].SPRODQUANTITY;
            this.itemNames[i].total = responseCreateQuotes.ITEMS[index].SPRICEACT;
            this.itemNames[i].ItemCategory = responseCreateQuotes.ITEMS[index].CATEGORYID;
            this.itemNames[i].ItemCategoryName = responseCreateQuotes.ITEMS[index].CATEGORYNAME;
            this.itemNames[i].qcategory = responseCreateQuotes.ITEMS[index].CATEGORYTYPE;
            this.itemNames[i].savedAsConfig = responseCreateQuotes.ITEMS[index].ISCONFIG || 0;
            this.itemNames[i].ISCONFIG = responseCreateQuotes.ITEMS[index].ISCONFIG || 0;
            this.itemNames[i].baseProductId = responseCreateQuotes.ITEMS[index].STANDARDLIID || 0;
            this.itemNames[i].includedparent = responseCreateQuotes.ITEMS[index].PARENTLIID || 0;
            this.itemNames[i].included = responseCreateQuotes.ITEMS[index].SINCLUDED || 0;
            this.itemNames[i].ItemPLID = responseCreateQuotes.ITEMS[index].SPRODUCTLINE || 0;
            this.itemNames[i].ItemPLName = responseCreateQuotes.ITEMS[index].PRODUCTLINE || '';
            grandTotal = grandTotal + responseCreateQuotes.ITEMS[index].SPRICEACT;
            profitTotal = profitTotal + (tmpMargin * responseCreateQuotes.ITEMS[index].SPRODQUANTITY);
            varCost = varCost + (responseCreateQuotes.ITEMS[index].SPRICEREG * responseCreateQuotes.ITEMS[index].SPRODQUANTITY);
            index = index + 1
          }
        }
        this.grandTotal = grandTotal;
        this.itemTotal = grandTotal;
        this.profit = profitTotal;
        this.totalVarCost = varCost;
        this.addRow();
        this.$nextTick().then(() => {
          this.checkExpandedItems("upload");
        });
      } else {
        notifier.alert("Unable to create Quotes.");
        this.$router.push({
          name: 'QuoteStandards'
        });
      }
    }
    if (
     this.$route.params.qID &&
      parseInt(this.$route.params.qID) != 0
    ) {
      this.title =
        "Edit Quote #<span id='quoteID'>" +
        this.$route.params.qID +
        "</span>";
      this.editQuote(this.$route.params.qID, this);
    } else {
      // Comment out per amanda instruction on tie view to source 
      // this.sourceID = 1;
      // this.source = "HP Direct";
      this.currentComponent = "Simplified";
    }

    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "VARSources",
      FunctionName: "GlobalParams",
      subsystem: "VAR360",
      action: "list"
    });
    this.defaultESTPercent = response.data.ESTPercent || 0;
    this.distyMarginRate = response.data.distributor || [];
    this.HPEdefaultESTPercent = response.data.HPEESTPercent || 0;
    this.businessLineOptions = (response.data.businessLineOptions || []).map(item => ({
      ID: item.ID,
      TEXT: item.BUSINESSLINENAME,
      data: item
    }));
    this.configCategory = response.data.configCategory || {};
    this.cfIds = response.data.cfIds || {};

    //get global tags/custom data
    try {
      const ignoreFieldIds = [26];
      if((this.cfIds.HPQuoteNoCFieldId || 0) > 0) {
        ignoreFieldIds.push(this.cfIds.HPQuoteNoCFieldId);
      }
      if((this.cfIds.BigDealNoCFieldId || 0) > 0) {
        ignoreFieldIds.push(this.cfIds.BigDealNoCFieldId);
      }
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller				: "Helpers",
        FunctionName			: "CustomData",
        DataType          : "2",
        Id                : (typeof this.$route.params.qID != "undefined" ? this.$route.params.qID : 0),
        contractDropdown  : 26,
        ignoreFieldIds: ignoreFieldIds.join(",")
      });

      if(response.data.STATUS == 1) {
        this.fullCustomData = [];
        for(var val of response.data.CUSTOMDEFINITION) {
          let tmpValue: any = (response.data.CUSTOMDATA || []).filter(tmp => tmp.CUSTOMFIELDID == val.CUSTOMFIELDID);
          this.fullCustomData.push({
            AID: val.AID,
            CUSTOMFIELDID: val.CUSTOMFIELDID,
            CUSTOMFIELDNAME: val.CUSTOMFIELDNAME,
            CUSTOMVALUE: tmpValue.length ? tmpValue[0].CUSTOMVALUE : "",
            CUSTOMID: tmpValue.length ? tmpValue[0].CUSTOMID : 0,
            CUSTOMDTYPE: val.CUSTOMDTYPE, 
            OPTIONS: val.OPTIONS || []
          });
        }

        this.globalTags = response.data.globalTags.filter(item => item.FORQUOTES);
        this.orderContractNumber = response.data.customContractNumber;
        this.selectedContracts = response.data.selectedContractData || [];
        if(typeof this.$route.params.qID != "undefined") {
          this.selectedGlobalTags = response.data.selectedGlobalTags.map((val: any) => ({
            CUSTOMFIELDOPTIONID: parseInt(val.CUSTOMFIELDOPTIONID),
            CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME,
            FORQUOTES: val.FORQUOTES
          })).filter(item => item.FORQUOTES);
          this.selectedGlobalTagsID = this.selectedGlobalTags.map((val: any) => val.CUSTOMFIELDOPTIONID);
        }

        if(this.selectedContracts.length) {
          this.selectedOrderContracts = this.selectedContracts[0].CUSTOMFIELDOPTIONNAME;
          this.orderContractsID = this.selectedContracts.map(
            (val: any) => val.CUSTOMFIELDOPTIONID
          );
        }
      }
    }catch (err) {
      console.log(err);
    }
  }

  listPageRedirection(){
    this.$router.push({ name: "Quotes" });
  }
  swapComponent(view) {
    var i = "";
    if(view == "Direct") {
      //from other views, goto "Direct", set price per = cost per
      for(i in this.itemNames) {
        if(this.currentComponent != "Direct") {
          this.itemNames[i].customerPrice_bk = this.itemNames[i].customerPrice;
          this.itemNames[i].marginRate_bk = this.itemNames[i].marginRate;
        }
        this.itemNames[i].customerPrice = this.itemNames[i].isContractFee || this.itemNames[i].isFee ? this.itemNames[i].customerPrice : this.itemNames[i].price;
      }
      // this.getTotals();
    }else if(this.currentComponent == "Direct" && view != "Direct") {
      //from "Direct" view, goto other views, restore backuped values
      // this.currentComponent = view;
      for(i in this.itemNames) {
        if(typeof this.itemNames[i].customerPrice_bk != "undefined") {
          this.itemNames[i].customerPrice = this.itemNames[i].customerPrice_bk;

          // case source = hp direct, and switch from view Direct -> Indirect, need update margin %
          // if(this.sourceID == 1 && view == "Indirect") {
          //   this.marginCalc1(i);
          // }
        }
        if(this.sourceID == 1 && typeof this.itemNames[i].marginRate_bk != "undefined" && !this.itemNames[i].isContractFee && !this.itemNames[i].isFee) {
          this.itemNames[i].marginRate = this.itemNames[i].marginRate_bk;
          this.itemNames[i].customerPrice = this.getCustomerPriceByMarinRate(this.itemNames[i]);
          this.reCalculateMargin(this.itemNames[i], "amount");
        }
      }
    }

    this.productCatTTVisibleIndex = "";
    this.currentComponent = view;
    this.getTotals();

    // defaul cases
    if(view == "Indirect" && this.sourceID == 0) {
      // select "dynamic" as default source
      this.updateSource({
        "varsource": {
          VARSOURCE_ID: 10,
          VARSOURCE_NAME: "Dynamic"
        }
      });
    }
    if(view == "Direct" && this.sourceID == 0) {
      // select "HP direct" as default source
      this.updateSource({
        "varsource": {
          VARSOURCE_ID: 1,
          VARSOURCE_NAME: "HP Direct"
        }
      });
    }
  }
  autoNameSwitch(val) {
    this.autoName = val;
  }
  autoCostSwitch(val) {
    this.autoCost = val;

    if(val == 1) {//enable auto price
      this.checkDistyError();
    }else {
      this.clearDistyError();
    }
  }
  idxOf(index: string): number {
    return Object.keys(this.itemNames).indexOf(index);
  }
  getTotals(opt:{
    overrideTaxAmount?:boolean,
    comeFrom?:string
  } = {}) {
    var comInstance = this;
    var totalTax = 0;
    this.itemTotal = 0;
    //this.totalTax = 0;
    this.totalShipping = parseFloat(this.totalShipping.replace("$", ""));
    //this.profit = 0;
    //this.estPercent = 0;
    var totalCost = 0;
    var profit = 0;
    let totalTaxable = 0;

    $.each(comInstance.itemNames, function(key, val) {
      // var ind = i.replace("productName_", "");
      if (val) {
        // var included = val.included || 0;
        var price = val.price || 0;
        price = price != 0 ? parseFloat(price).toFixed(comInstance.expandDecimalPrecision) : 0.0;
        var customerPrice = val.customerPrice || 0;
        if (customerPrice != "") {
          customerPrice =
            customerPrice != 0 ? parseFloat(customerPrice).toFixed(comInstance.expandDecimalPrecision) : price;
        } else {
          customerPrice = 0;
        }

        var quantity = val.quantity;
        quantity = quantity > 0 ? parseInt(quantity) : 0;
        val.quantity = quantity;
        // Comment out condition to update margin when est != 0
        // if (comInstance.estPercent == 0) {
          //just canculate margin in case no using EST%
        if(!val.isContractFee && !val.isFee) {
          val.margin = (
            parseFloat(customerPrice) - parseFloat(price)
          ).toFixed(comInstance.$decimals);
        }
        // }
        //val.taxRate = parseFloat(val.taxRate).toFixed(this.$decimals);
        var taxRate = val.taxRate;
        val.total = parseFloat(parseFloat(customerPrice).toFixed(comInstance.expandDecimalPrecision)) * quantity;

        // recalculate margin/customer price in case indirect quote based on margin rate
        // if(comInstance.isIndirect()) {
          //both: direct & indirect type
          // if(val.price != 0 && comInstance.currentComponent != "Direct") {
          //   comInstance.reCalculateMargin(val, "amount");//calculate margin based on margin rate
          //   if(isNaN(val.margin)) {
          //     val.margin = 0.00;
          //   }
          //   customerPrice = parseFloat((val.margin + val.price).toFixed(comInstance.$decimals));
          //   val.customerPrice = customerPrice;
          //   val.total = customerPrice * quantity;
          // }
        // }

        //tax is re-calculated based on tax rate here, and re-assign based on option overrideTaxAmount
        // var tax = (customerPrice * quantity * taxRate) / 100;
        // tax = tax > 0 ? parseFloat(tax.toFixed(comInstance.$decimalsView)) : 0.00;
        // if (opt && typeof opt.overrideTaxAmount == "undefined" || opt.overrideTaxAmount == true) {
        //   comInstance.itemNames["productName_" + ind].tax = tax;
        // }

        //just add to total profit if line item not included
        // if (!included) {
          //comInstance.profit = parseFloat( comInstance.profit  ) + parseFloat((customerPrice * quantity) - (price * quantity)) ;
          profit += (customerPrice - price) * quantity;
        // }

        // total var cost
        totalCost += price * quantity;

        //comInstance.totalShipping = parseFloat(comInstance.totalShipping) + parseFloat(shipping);

        //just add line item tax to totalTax in case noTax, and not included
        // var noTax =
        //   typeof comInstance.itemNames["productName_" + ind].noTax !=
        //     undefined &&
        //   comInstance.itemNames["productName_" + ind].noTax == true
        //     ? true
        //     : false;
        // if (noTax == false && !included) {
        //   //comInstance.totalTax = parseFloat(comInstance.totalTax) + parseFloat(comInstance.itemNames['productName_' + ind].tax);
        //   totalTax = totalTax + parseFloat(comInstance.itemNames["productName_" + ind].tax);
        // }

        //just add line item total to itemTotal in case not included
        // if (!included) {
        //comInstance.itemTotal = parseFloat(comInstance.itemTotal) + parseFloat(val.total.toFixed(comInstance.expandDecimalPrecision));
        if (!val.included) {
          comInstance.itemTotal = parseFloat(comInstance.itemTotal) + comInstance.getBaseTotal(val, key).total;
        }else if (!(val.included && val.ISCONFIG)) {
          // grouped item
          comInstance.itemTotal = parseFloat(comInstance.itemTotal) + parseFloat(val.total.toFixed(comInstance.expandDecimalPrecision));
        }

        /* if(taxRate != 0 && !(val.included && val.ISCONFIG)) {
          // totalTaxable += val.total;
          if (!val.included) {
            totalTaxable += comInstance.getBaseTotal(val, key).total;
          } else {
            // grouped item
            totalTaxable += val.total;
          }

          // include configs of this base product
          // const configItems = comInstance.getIncludedItems(val, i).filter(t => t.ISCONFIG || 0);
          // for(const t of configItems) {
          //   totalTaxable += t.total || 0;
          // }
        } */
        if (taxRate != 0) {
          totalTaxable += val.total;
        }
      }
    });

    //we no change tax if user use a custom tax, and do change something for price, customer price, or quantity
    //if(this.usedCustomTax() && typeof opt.comeFrom != "undefined" && $.inArray(opt.comeFrom, ["cost_per", "price_per", "qty"]) != -1) {
    if (this.usedCustomTax()) {
      this.totalTax = parseFloat(comInstance.totalTax.replace(/[$|,\s]/g, ""));
    } else {
      //other cases
      // this.totalTax = totalTax;

      // calculate total tax based on a general tax rate of this customer
      // this.totalTax = parseFloat(((totalTaxable * this.currentTaxRate) / 100).toFixed(2));
      this.totalTax = parseFloat(
        roundCents((totalTaxable * this.currentTaxRate) / 100).toFixed(2)
      );
    }

    this.grandTotal = this.itemTotal + this.totalShipping + this.totalTax;
    this.qShip = parseFloat(this.totalShipping).toFixed(this.$decimalsView);
    this.qTax = parseFloat(this.totalTax).toFixed(this.$decimalsView);

    //if user inputted an EST % before, we canculate profit based on subTotal*EST%
    if (!this.isIndirect() && this.estPercent > 0) {
      this.profit = (this.itemTotal * this.estPercent) / 100;
    } else {
      this.profit = profit;
    }

    if (totalCost >= 0) {
      this.totalVarCost = totalCost;
    }
    // this.profit =
    //   "$" +
    //   parseFloat(this.profit)
    //     .toFixed(this.$decimalsView)
    //     .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
    //     .toString();
    this.totalShipping =
      "$" +
      parseFloat(this.totalShipping)
        .toFixed(this.$decimalsView)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
        .toString();
    this.totalTax =
      "$" +
      parseFloat(this.totalTax)
        .toFixed(this.$decimalsView)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
        .toString();
    // this.itemTotal =
    //   "$" +
    //   parseFloat(this.itemTotal)
    //     .toFixed(this.$decimalsView)
    //     .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
    //     .toString();
    // this.grandTotal =
    //   "$" +
    //   parseFloat(this.grandTotal)
    //     .toFixed(this.$decimalsView)
    //     .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
    //     .toString();
    this.updateDistyTotal();
  }
  addRowWithVal(val) {
    this.length = this.length + 1;
    this.itemNames["productName_" + this.length] = {
      sku: val.sku,
      name: val.name,
      price: val.price,
      customerPrice: val.customerPrice ? val.customerPrice : 0.0,
      margin: val.margin,
      marginRate: (typeof val.marginRate != "undefined" ? val.marginRate : 0),
      total: val.total,
      quantity: val.quantity,
      quantityBk: val.quantityBk || val.quantity,
      tax: val.tax,
      taxRate: val.taxRate,
      shipping: val.shipping,
      included: val.included,
      ISCONFIG: val.ISCONFIG || 0,
      priceWarn: false,
      totalProducts: val.totalProducts,
      noTax: typeof val.noTax != "undefined" && val.noTax ? 1 : 0,
      configID: typeof val.configID != "undefined" ? val.configID : "",
      lineNotes: typeof val.lineNotes != "undefined" ? val.lineNotes : "",
      DistyPrice: typeof val.DistyPrice != "undefined" ? val.DistyPrice : 0,
      DistyAvailability:
        typeof val.DistyAvailability != "undefined" ? val.DistyAvailability : 0,
      DistyPrice_Format:
        typeof val.DistyPrice_Format != "undefined"
          ? val.DistyPrice_Format
          : "",
      DistyAvailability_Format:
        typeof val.DistyAvailability_Format != "undefined"
          ? val.DistyAvailability_Format
          : "",
      Disty: typeof val.Disty != "undefined" ? val.Disty : "",
      ItemCategory: typeof val.ItemCategory != "undefined" ? val.ItemCategory : 0,
      ItemCategoryName: typeof val.ItemCategoryName != "undefined" ? val.ItemCategoryName : "",
      dynamicInfo: val.dynamicInfo || {},
      contractPrice: typeof val.contractPrice != undefined ? val.contractPrice : undefined,
      contractPriceDate: typeof val.contractPriceDate != undefined ? val.contractPriceDate : undefined,
      orgPrice: val.orgPrice,
      orgCustomerPrice: val.orgCustomerPrice,
      disable: val.disable,
      isContractFee: val.isContractFee,
      includedparent : val.includedparent,
      baseProductId : val.baseProductId,
      includeItemCount : val.includeItemCount,
      parentLiId: val.parentLiId || 0,
      quoteLiId: val.quoteLiId || 0,
      isFee: val.isFee,
      subQuoteId: val.subQuoteId || 0,
      CONFIGCOUNT: val.CONFIGCOUNT || 0,
      savedAsConfig: val.ISCONFIG || 0,
      ItemPLID: val.ItemPLID || 0,
      ItemPLName: val.ItemPLName || '',
      buildHeader: val.buildHeader || ''
    };
    this.$forceUpdate();
  }
  addRow(subId = 0) {
    this.length = this.length + 1;
    var item = {
      sku: "",
      name: "",
      price: 0.0,
      customerPrice: 0.0,
      margin: 0.0,
      marginRate: this.getConfirmedGlobalMargin(true),
      total: 0.0,
      quantity: 0,
      tax: 0,
      taxRate: this.USECUSTOMTAX ? 0 : this.currentTaxRate,
      shipping: 0.0,
      included: 0,
      priceWarn: false,
      totalProducts: 0.0,
      configID: "",
      orgPrice: 0.0,
      orgCustomerPrice: 0.0,
      disable: false,
      isContractFee: false,
      includedparent: 0,
      baseProductId: 0,
      includeItemCount: 0,
      parentLiId: 0,
      quoteLiId: 0,
      isFee: false,
      subQuoteId: subId
    };
    const newIndex = `productName_${this.getMaxIndex() + 1}`;
    if (this.itemNames[newIndex] != undefined) {
      const existed = this.itemNames[newIndex];
      item = {
        sku: existed.sku,
        name: existed.name,
        price: existed.price,
        customerPrice: this.itemNames[newIndex].customerPrice,
        margin: existed.margin,
        marginRate: this.getConfirmedGlobalMargin(true),
        total: existed.total,
        quantity: existed.quantity,
        tax: existed.tax,
        taxRate: existed.taxRate,
        shipping: existed.shipping,
        included: existed.included,
        priceWarn: existed.priceWarn,
        totalProducts: this.itemNames[newIndex].totalProducts,
        configID: "",
        orgPrice: existed.price,
        orgCustomerPrice: existed.customerPrice,
        disable: false,
        isContractFee: existed.isContractFee,
        includedparent: existed.includedparent,
        baseProductId: existed.baseProductId,
        includeItemCount: existed.includeItemCount,
        parentLiId: existed.parentLiId || 0,
        quoteLiId: existed.quoteLiId || 0,
        isFee: existed.isFee,
        subQuoteId: existed.subQuoteId || 0
      };
    }
    this.$set(this.itemNames, newIndex, item);
    this.$forceUpdate();
    // if (upload != true) {
    //   setTimeout(() => {
    //     $("html, body").animate(
    //       { scrollTop: $("html, body").get(0).scrollHeight },
    //       200
    //     );
    //   }, 100);
    // }
  }

  sortItems() {
    const newItems: any = {};
    let newIndex = 1;
    for (const i in this.itemNames) {
      newItems["productName_" + newIndex] = this.itemNames[i];
      newIndex += 1;
    }
    this.itemNames = newItems;
    this.$forceUpdate();
  }

  addDelete(index, fromSku = false) {
    $(".list-item[data-index='" + index + "'] .deleteIcon")
      .removeClass("displayNone")
      .css("display", "inline-block");
    if (index.toString().indexOf("productName_") != -1) {
      //get max index
      // var indArr: number[] = [];
      // var maxInd = 0;
      // $.each(this.itemNames, function(i, val) {
      //   indArr.push(parseInt(i.replace("productName_", "")));
      // });
      // if (indArr.length) {
      //   maxInd = Math.max.apply(Math, indArr);
      // }

      // if (
      //   parseInt(index.replace("productName_", "")) == maxInd ||
      //   parseInt(index.replace("productName_", "")) ==
      //     Object.keys(this.itemNames).length
      // ) {
      //   this.addRow();
      // }

      // auto add new row if input at the end of the items
      if(this.isEndItem(index)) {
        this.addRow();
      }
    }

    var productItems = this.itemNames[index];

    //For contract fee
    if(productItems.sku.toLowerCase() == "contractfee") {
      productItems.isContractFee = true;
      productItems.customerPrice = 0;
      productItems.marginRate = 0;
      productItems.margin = 0;
      productItems.quantity = 1;
      productItems.taxRate = 0;
      productItems.included = 0;
      productItems.total = 0;
    } else {
      productItems.isContractFee = false;
    }
    //Check for fee
    this.feeVisibleIndex = productItems.sku.toLowerCase() == "fee" && fromSku ? index : "";

    // try {
    //   if (index == "productName_" + this.length) {
    //     this.length = this.length + 1;
    //     this.itemNames["productName_" + this.length] = {
    //       name: "",
    //       sku: "",
    //       price: 0.0,
    //       customerPrice: 0.0,
    //       margin: 0.0,
    //       total: 0.0,
    //       quantity: 0,
    //       tax: 0.0,
    //       taxRate: 0,
    //       shipping: 0.0,
    //       included: 0,
    //       priceWarn: false,
    //       totalProducts: 3,
    //       noTax: false,
    //       configID: "",
    //       DistyPrice: 0,
    //       DistyAvailability: 0,
    //       DistyPrice_Format: "",
    //       DistyAvailability_Format: "",
    //       Disty: "",
    //       orgPrice: 0.0,
    //       orgCustomerPrice: 0.0,
    //       disable: false,
    //       isContractFee: false,
    //       includedparent : 0,
    //       baseProductId : 0,
    //       includeItemCount : 0,
    //       isFee: false
    //     };
    //     this.$forceUpdate();
    //   }
    // } catch (error) {
    //   // console.log("err", error);
    // }
  }

  itemIsFee(item, isFee, index) {
    item.isFee = isFee;
    if(isFee) {
      item.customerPrice = 0;
      item.marginRate = 0;
      item.margin = 0;
      item.quantity = 1;
      item.taxRate = 0;
      item.included = 0;
      item.total = 0;
    }
    $(`#name_${index}`).focus();
  }

  closeConfirmItemIsFee(item, index) {
    this.feeVisibleIndex = "";
    this.SKULookup(index, item.sku, item);
  }

  checkItemIsFee(val) {
    return (val.QPRODSKU.toLowerCase() == "fee" && !val.QCUSTOMERPRICE && !val.QINCLUDED);
  }

  updateFunction(index, id, value, cost, sku, included) {
    $("#quantity_" + index).focus();
    this.arrowCounter = 0;
    this.itemNames[index].name = value;
    this.itemNames[index].price = parseFloat(cost).toFixed(this.$decimals);
    this.itemNames[index].customerPrice = parseFloat(cost).toFixed(this.$decimals);
    this.itemNames[index].sku = sku;
    this.itemNames[index].included = included;
    this.itemNames[index].quantity = 0;
    this.itemNames[index].total = 0.00;
    this.getTotals();
    this.$forceUpdate();
  }
  async updateCustomer(data) {
    if (this.aID != data.account.AID) {
      this.opportunityName = "";
      this.opportunityID = 0;
    }
    this.customer = data.account.ANAME;
    this.aID = data.account.AID;
    this.accountID = data.account.ACCOUNTID;
    // this.currCustTax = data.account.TAXRATE;
    // var $this = this;
    // if (data.account.TAXRATE > 0 || this.cusTaxApplied) {
    //   $.each(this.itemNames, function(i, val) {
    //     if( $this.prevCustTax == val.taxRate){
    //       val.taxRate = data.account.TAXRATE;
    //     }
    //     $this.taxRate(i)
    //   });
    //   this.cusTaxApplied = true;
    // }
    // this.prevCustTax = data.account.TAXRATE;

    // var currentTaxRate = this.getTaxRateBySource();
    this.newTaxRate = 0;
    if(this.isIndirect() && data.account.INDIRECTTAXRATE > 0) {//indirect
      this.newTaxRate = data.account.INDIRECTTAXRATE;
    }else if(!this.isIndirect() && data.account.HPDIRECTTAXRATE > 0) {//direct
      this.newTaxRate = data.account.HPDIRECTTAXRATE;
    }
    if(this.currentTaxRate != this.newTaxRate && !this.isEmptyLineItems()) {
      this.switchCustomerMsg1 = "You are about to switch to customer <strong>" + data.account.ANAME + "</strong>. Please confirm this change."
      this.hpDirectTaxRateTmp = data.account.HPDIRECTTAXRATE;
      this.indirectTaxRateTmp = data.account.INDIRECTTAXRATE;
      this.confirmedSwitchCustomer = false;
      this.confirmSwitchCustomerModalVisible = true;
    }else {
      //backup current selected customer
      this.customer_bk = {
        customer: this.customer,
        aID: this.aID,
        accountID: this.accountID
      };

      if(this.currentTaxRate != this.newTaxRate) {//need to confirm switch tax rate in this case
        this.hpDirectTaxRateTmp = data.account.HPDIRECTTAXRATE;
        this.indirectTaxRateTmp = data.account.INDIRECTTAXRATE;
        this.confirmSwitchCustomer();
      }else {
        this.hpDirectTaxRate = data.account.HPDIRECTTAXRATE;
        this.indirectTaxRate = data.account.INDIRECTTAXRATE;
      }
    }
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Accounts",
      FunctionName: "defaultAddress",
      // defaultAddress: false,
      aID: this.aID
    });
    if(response.data.STATUS == 1) {
      if (response.data.INVOICEFLAG) {
        this.messageBoxModal = true;
      }

      // fill shipping address
      const defaultShippingAddr = (response.data.DEFAULTADDRESS || []).find(tmp => tmp.ACCOUNTSADDRESS_TYPE == 1);
      if(defaultShippingAddr) {
        // if had a default shipping address
        this.getAddressText(defaultShippingAddr)
        this.selectedAddress = [defaultShippingAddr.ACCOUNTSADDRESS_ID];
        this.highlightedAddress = defaultShippingAddr.ACCOUNTSADDRESS_ID;
      }

      // fill billing address
      const defaultBillingAddr = (response.data.DEFAULTADDRESS || []).find(tmp => tmp.ACCOUNTSADDRESS_TYPE == 2);
      if(defaultBillingAddr) {
        // if had a default billing address
        this.getAddressText(defaultBillingAddr)
        this.selectedBillingAddress = [defaultBillingAddr.ACCOUNTSADDRESS_ID];
        this.highlightedBillingAddress = defaultBillingAddr.ACCOUNTSADDRESS_ID;
      }
    }

    this.onetimeDepartment = {};
    if(this.aID > 0) {
      await this.getCustomerAddresses(this.aID, true);
      //Get customer department details
      await this.getCustomerDepartment(this.aID);
    }

    // To list the selected address on the top
    if(this.customerAddresses.length) {
      this.sortingAddresses(this.customerAddresses, this.selectedAddress);
    }
    if(this.customerAddressesBilling.length) {
      this.sortingAddresses(this.customerAddressesBilling, this.selectedBillingAddress);
    }

    // this.hpDirectTaxRate = data.account.HPDIRECTTAXRATE;
    // this.indirectTaxRate = data.account.INDIRECTTAXRATE;
    // this.applyTaxRateBySource();
  }
  async updateSource(data) {
    if (this.sourceID) {
      this.prevsource = data.allOption.find((item) => item.VARSOURCE_ID === this.sourceID)
    } else {
      this.prevsource.VARSOURCE_ID = 0;
      this.prevsource.VARSOURCE_NAME = "";
    }
    this.sourceID = data.varsource.VARSOURCE_ID;
    this.source = data.varsource.VARSOURCE_NAME;
    // if(!!this.customer == true) {
    //   this.currentTaxRate = this.getTaxRateBySource();
    //   this.applyTaxRateBySource();
    // }

    if((this.sourceID == 1) || (this.sourceID == 12)){
      this.swapComponent('Direct')
    }else{
      this.swapComponent('Indirect')
    }

    this.clearDistyError();
    if(this.notHPSource()) {//get disty info
      var search: string[] = [];
      for(var i in this.itemNames) {
        if(this.itemNames[i].sku != "" && !search.includes(this.itemNames[i].sku)) {
          search.push(this.itemNames[i].sku);
        }
      }

      if(search.length) {
        this.loading = true;
        try {
          const response = await axios.post(dataURL + "?ReturnType=JSON", {
            controller				: "Quotes",
            FunctionName			: "quoteLineItemList",
            search  					: search,
            source            : this.sourceID,
            contractNumberValue: this.selectedOrderContracts,
            contractNumberId : this.orderContractsID.join()
          });

          if (response.data.ERROR) {
            throw new Error(response.data.ERROR);
          }
          if (response.data.STATUS !== 1) {
            throw new Error(response.data.STATUSMESSAGE);
          }
          
          if(response.data.STATUS == 1 && typeof response.data.LINEITEMS != "undefined") {
            //update disty info
            for(let j in this.itemNames) {
              let productInfo = response.data.LINEITEMS.filter(val => val.PRODUCTSKU == this.itemNames[j].sku);
              if(productInfo.length) {
                productInfo = productInfo[0];
                // case dynamic source
                // if(this.isDynamic()) {
                  this.itemNames[j].dynamicInfo = productInfo;
                // }

                if(typeof productInfo.Disty != "undefined") {
                  this.itemNames[j].Disty = productInfo.Disty;

                  if(typeof productInfo.DistyPrice != "undefined") {
                    this.itemNames[j].DistyPrice = productInfo.DistyPrice;
                    this.itemNames[j].DistyPrice_Format = productInfo.DistyPrice_Format;
                  }
                  if(typeof productInfo.DistyAvailability != "undefined") {
                    this.itemNames[j].DistyAvailability = productInfo.DistyAvailability;
                    this.itemNames[j].DistyAvailability_Format = productInfo.DistyAvailability_Format;
                  }
                }

                if(this.autoCost == 1 || this.autoName == 1) {
                  if(this.autoCost == 1) {
                    // this.itemNames[j].price = productInfo.PRODUCTPRICE;
                    // this.itemNames[j].customerPrice = productInfo.PRODUCTPRICE;
                    if(productInfo.PRODUCTPRICE != 0) {
                      this.itemNames[j].price = productInfo.PRODUCTPRICE;

                      // recalculate customer price in case had a margin rate on this line
                      if(this.isIndirect() && this.confirmedGlobalMargin && this.itemNames[j].marginRate != 0) {
                        // this.itemNames[j].margin = parseFloat((this.itemNames[j].price * this.itemNames[j].marginRate / 100).toFixed(this.$decimalsView));
                        // this.itemNames[j].customerPrice = parseFloat((this.itemNames[j].margin + this.itemNames[j].price).toFixed(this.$decimalsView));
                        this.itemNames[j].customerPrice = this.getCustomerPriceByMarinRate(this.itemNames[j]);
                        this.itemNames[j].customerPrice = parseFloat(this.itemNames[j].customerPrice.toFixed(this.$decimalsView));
                        this.reCalculateMargin(this.itemNames[j], "amount", this.$decimalsView);
                      }

                      //force event when changing price per
                      this.marginCalc1(j);
                    }
                  }
                  if(this.autoName == 1 && productInfo.PRODUCTNAME != "") {
                    this.itemNames[j].name = productInfo.PRODUCTNAME;
                  }
                  this.itemNames[j]["contractPrice"] = productInfo.ContractPrice;
                  this.itemNames[j]["contractPriceDate"] = productInfo.ContractPriceDate;
                  // this.marginCalc1(j);
                }
              }
            }
            this.$refs.quoteView.$forceUpdate();
            this.checkDistyError();
            this.updateDistyTotal();
          }
        }catch (err) {
          // console.log(err.message);
        }finally {
          this.loading = false;
        }
      }
    }
    // if(this.autoCost == 1) {
    //   this.checkDistyError();
    // }

    // apply default EST%/ margin% for new order
    // if(this.$route.name == "QuoteCreate") {
      // hp direct
      if((this.sourceID == 1 && this.defaultESTPercent != 0) || (this.sourceID == 12 && this.HPEdefaultESTPercent != 0)) {
        // this.applyDefaultESTPercent(this.defaultESTPercent);
        if(this.sourceID == 12) {
          this.percent = this.HPEdefaultESTPercent;
        }
        else {
          this.percent = this.defaultESTPercent;
        }
        this.sourceRateMessage = "You are about to switch to <strong>" + this.source + " (EST = " + this.percent + "%)</strong>. Please confirm this change."
        this.confirmedSourceRateVisible = true;
      }
      // other disty
      if(this.isIndirect()) {
        this.confirmApplySourceRate(); 
        /* const defaultDistyMarginRate: any = this.distyMarginRate.find((tmp: any) => tmp.VARSOURCE_ID == this.sourceID);
        if(defaultDistyMarginRate && defaultDistyMarginRate.value != 0) {
          // this.applyDefaultDistyMarginRate(defaultDistyMarginRate.value);
          this.sourceRateMessage = "You are about to switch to <strong>" + this.source + " (Margin = " + defaultDistyMarginRate.value + "%)</strong>. Please confirm this change."
          this.confirmedSourceRateVisible = true;
        } */
      }
    // }

    if(this.confirmedSourceRateVisible == false) {
      this.showConfirmSwitchTaxRateBySource();
    }

    if(this.isDynamic()) {
      this.autoName = 1;
      this.autoCost = 1;
      $(".switch.auto input[type=checkbox].autoName, .switch.auto input[type=checkbox].autoCost").prop("checked", true);
    }
  }

  confirmApplySourceRate() {
    // if(this.$route.name == "QuoteCreate") {
      // hp direct
      if((this.sourceID == 1 && this.defaultESTPercent != 0) || (this.sourceID == 12 && this.HPEdefaultESTPercent != 0)) {
        this.applyDefaultESTPercent(this.percent);
      }
      // other disty
      // if(this.isIndirect()) {
      //   const defaultDistyMarginRate: any = this.distyMarginRate.find((tmp: any) => tmp.VARSOURCE_ID == this.sourceID);
      //   if(defaultDistyMarginRate && defaultDistyMarginRate.value != 0) {
      //     this.applyDefaultDistyMarginRate(defaultDistyMarginRate.value);
      //   }
      // }

      this.confirmedSourceRateVisible = false;
      this.sourceRateMessage = "";
    // }
  }

  applyDefaultESTPercent(defaultPercent) {
    // check which case should apply default rate?
    var canApply = true;
    if(canApply) {
      this.estPercent = defaultPercent;
      // for(let i in this.itemNames) {
      //   this.itemNames[i].customerPrice = this.itemNames[i].price;
      //   this.itemNames[i].margin = 0;
      //   this.itemNames[i].marginRate = 0;
      // }
      this.getTotals();
    }
  }

  applyDefaultDistyMarginRate(defaultPercent) {
    // check which case should apply default rate?
    var canApply = true;
    if(canApply) {
      this.globalMargin = defaultPercent;
      this.confirmedGlobalMargin = false;
      this.toggleConfirmGlobalMargin();
    }
  }

  onArrowDown(data) {
    if (this.arrowCounter < data.length) {
      this.arrowCounter = this.arrowCounter + 1;
    }
  }
  onArrowUp() {
    if (this.arrowCounter > 0) {
      this.arrowCounter = this.arrowCounter - 1;
    }
  }
  onEnter(data,index = 0) {
    this.customer = data[this.arrowCounter].ANAME;
    this.arrowCounter = 0;
    var element = document.getElementById("autoSuggestCustomer");
    $("#quantity_" + index).focus();
    element!.classList.add("hide");
  }
  onEnterItem(data, index) {
    this.itemNames[index].name = data[this.arrowCounter].ANAME;
    this.itemNames[index].price = 100;
    this.arrowCounter = 0;
    var element = document.getElementById("autoSuggest_" + index);
    element!.classList.add("hide");
  }
  isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  totalCal(index) {
    if(!isNaN(this.itemNames[index].quantity)){
      this.itemNames[index].quantity = Math.ceil(this.itemNames[index].quantity);
    }
    if(this.itemNames[index].quantity) {
      this.itemNames[index].quantityBk = this.itemNames[index].quantity;
    }
    if (
      this.itemNames[index].quantity &&
      (this.itemNames[index].quantity < 0 ||
        !$.isNumeric(this.itemNames[index].quantity))
    ) {
      this.itemNames[index].quantity = 0;
    }

    var quantity = this.itemNames[index].quantity || 0;

    if (this.itemNames[index].included !== 1) {
      const options: any[] = [];
      const currentItem = this.itemNames[index];
      const keys = Object.keys(this.itemNames);
      const idx = keys.findIndex(k => k === index);
      for (let i = idx + 1; i < keys.length; i++) {
        const key = keys[i];
        const item = this.itemNames[key];
        if (item.included === 1) {
          options.push({ key, item });
        }
      }

      for (const { key, item } of options) {
        // item.quantity = quantity;
        this.totalCal(key);
      }
    }

    var customerPrice = this.itemNames[index].customerPrice;
    var price = this.itemNames[index].price;
    var taxRate = this.itemNames[index].taxRate;
    this.itemNames[index].total = parseFloat((customerPrice * quantity).toFixed(this.$decimals));
    // this.itemNames[index].total = parseFloat(
    //   (quantity * customerPrice).toString()
    // )
    //   .toFixed(this.$decimals)
    //   .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
    //   .toString();

    if (this.estPercent == 0) {
      //just canculate margin in case no using EST%
      this.itemNames[index].margin = parseFloat(
        (quantity * customerPrice - quantity * price).toString()
      ).toFixed(this.$decimals); //.replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString();
    }
    // this.itemNames[index].tax = parseFloat(customerPrice * (taxRate/100)).toFixed(this.$decimals);//.toString();

    this.getTotals({ comeFrom: "qty" });
    this.reCalculateGlobalMargin();
  }
  totalShipTax(index, type) {
    var total = isNaN(this.itemNames[index].total)?this.itemNames[index].total.replace(/[$|,\s]/g, ""):this.itemNames[index].total;
    if (type == "tax") {
      if (parseFloat(total) === 0) {
        this.itemNames[index].taxRate = 0.0;
      } else if (
        this.itemNames[index].tax != undefined &&
        this.itemNames[index].tax != "" &&
        this.itemNames[index].tax >= 0
      ) {
        this.itemNames[index].taxRate =
          (this.itemNames[index].tax / total) * 100;

        //just allow 4 decimals for taxRate
        var tmpTaxRate = this.itemNames[index].taxRate.toString();
        if (
          tmpTaxRate.indexOf(".") != -1 &&
          tmpTaxRate.split(".")[1].length > 4
        ) {
          this.itemNames[index].taxRate = parseFloat(
            this.itemNames[index].taxRate.toFixed(4)
          );
        }
      } else {
        this.itemNames[index].taxRate = 0.0;
      }

      this.itemNames[index].taxRate_bk = this.itemNames[index].taxRate;//backup inputted tax rate
      this.USECUSTOMTAX = 0; //user changed something for tax amount
      this.getTotals({ overrideTaxAmount: false }); //no override tax amount in this case when do calculation
      $("li.tax")
        .find("input")
        .val(this.totalTax.replace(/[$|,\s]/g, ""));

      this.$refs.quoteView.$forceUpdate();
    }
  }
  taxRate(index) {
    if (this.itemNames[index].taxRate > 100) {//maximum tax rate = 100%
      this.itemNames[index].taxRate = 100;
    }
    // Convert Negative to positive values
    if (
      this.itemNames[index].taxRate < 0 ||
      isNaN(this.itemNames[index].taxRate)
    ) {
      // this.itemNames[index].taxRate = 0;
      if(isNaN(this.itemNames[index].taxRate)){
        this.itemNames[index].taxRate = this.itemNames[index].taxRate.replace(/-/g, "");
      }
      else{
        this.itemNames[index].taxRate = this.itemNames[index].taxRate * -1;
      }
    }
    this.itemNames[index].taxRate_bk = this.itemNames[index].taxRate;//backup inputted tax rate
    this.USECUSTOMTAX = 0; //user changed something for tax rate
    this.getTotals();
  }
  estPercentInput() {
    var estPercent = parseFloat($("li.estPercent input").val());
    if (estPercent > 100) {
      this.estPercent = 100;
    }
    this.globalMarginBK = 0;
    this.confirmedGlobalMargin = false;
  }
  htmlCheck(InputVal) {
    if (InputVal.length > htmlParse(InputVal).length) {
      return true;
    } else {
      return false;
    }
  }
  checkHTML() {
    var result: any = "";
    var htmlCount = 0;
    var self = this;
    $(".validateHtml").each(function(this: Window) {
      if ($(this).val() != "") {
        if (self.htmlCheck($(this).val())) {
          self.quoteName = htmlParse($(this).val());
          htmlCount++;
        }
      }
    });
    if (htmlCount != 0) {
      notifier.alert("HTML content found. This content has been removed!");
      result = false;
    } else {
      result = true;
    }
    return result;
  }
  async validateBeforeSubmit(e, updateQuote = false, duplicateQuote = false) {
    $("input.initial.error.number").removeClass("initial");
    this.$validator.validateAll().then(async result => {
      var resultLineItem = true;
      try {   
        var emptyRow = 0;
        for (var j = 0; j < Object.keys(this.itemNames).length; j++) {
          if (typeof this.itemNames[`productName_${j + 1}`] != "undefined") {
            if (
              !this.itemNames[`productName_${j + 1}`].customerPrice &&
              !this.itemNames[`productName_${j + 1}`].name &&
              !this.itemNames[`productName_${j + 1}`].price &&
              !this.itemNames[`productName_${j + 1}`].quantity &&
              !this.itemNames[`productName_${j + 1}`].sku
            ) {
              emptyRow = j + 1;
            }
            for (var i = 0; i < this.$validator.errors.items.length; i++) {
              if (
                this.$validator.errors.items[i].field == `sku_productName_${emptyRow}` ||
                this.$validator.errors.items[i].field == `name_productName_${emptyRow}` ||
                this.$validator.errors.items[i].field == `cost_productName_${emptyRow}` ||
                this.$validator.errors.items[i].field ==
                  `cust_cost_productName_${emptyRow}` ||
                this.$validator.errors.items[i].field == `quantity_productName_${emptyRow}`
              ) {
                this.$validator.errors.items.splice(i, 1);
                continue;
              }
            }
            for (var k = 0; k < this.$validator.errors.items.length; k++) {
              if(this.$validator.errors.items.length && this.$validator.errors.items[k]){
                if (
                  this.itemNames[`productName_${j + 1}`].customerPrice > 0 ||
                  this.itemNames[`productName_${j + 1}`].price > 0
                ) {
                  if (
                    this.$validator.errors.items[k] &&
                    this.$validator.errors.items[k].field != undefined &&
                    this.$validator.errors.items[k].field == `cost_productName_${j + 1}`
                  ) {
                    this.$validator.errors.items.splice(k, 1);
                  }
                  if (
                    this.$validator.errors.items[k] &&
                    this.$validator.errors.items[k].field != undefined &&
                    this.$validator.errors.items[k].field == `cust_cost_productName_${j + 1}`
                  ) {
                    this.$validator.errors.items.splice(k, 1);
                  }
                }
              }
              // if(this.errors.items[i].field != undefined && this.errors.items[i].field == `cost_${this.index}` ){
              // this.errors.items.splice(i, 1);
            }
          }
        }
        if (!this.$validator.errors.items.length) {
          result = true;
        }
      } catch (error) {
        result = false;
      }

      // if (this.aID == "" && this.customer != "") {
      //   $("#customer")
      //     .parent()
      //     .find(".errorMsg")
      //     .html("Please select a customer from suggested list.");
      //   result = false;
      // }
      const [quoteStatus] = await Promise.all([
        this.$refs.quoteView.childValidation()
      ]);
      var keyCount = 0;
      var errorMessages: string[] = [];

      if (Object.keys(this.itemNames).length >= 1) {
          var lastIndex = parseInt(
              Object.keys(this.itemNames)[
                  Object.keys(this.itemNames).length - 1
              ].split("_")[1]
          );
          for (var j1 = 0; j1 < lastIndex; j1++) {
              if (typeof this.itemNames[`productName_${j1 + 1}`] != "undefined") {
                  var item = this.itemNames[`productName_${j1 + 1}`];
                  if (
                      item.name != "" &&
                      item.quantity &&
                      item.sku != ""
                  ) {
                      keyCount = keyCount + 1;
                  }
              }
          }
      }

      if (keyCount == 0 || !quoteStatus) {
          result = false;
          resultLineItem = false;
          errorMessages.push("No valid line items or quote status is false.");
      }

      // Validate individual fields
      if (keyCount == 0) {
          if (parseInt($("#quantity_productName_1").val()) < 1) {
              result = false;
              resultLineItem = false;
              errorMessages.push("Quantity for productName_1 is less than 1.");
          }
          if ($("#shipping_productName_1").val() == "") {
              result = false;
              resultLineItem = false;
              errorMessages.push("Shipping for productName_1 is empty.");
          }
          if ($("#tax_rate_productName_1").val() == "") {
              result = false;
              resultLineItem = false;
              errorMessages.push("Tax rate for productName_1 is empty.");
          }
          if ($("#name_productName_1").val() == "") {
              result = false;
              resultLineItem = false;
              errorMessages.push("Name for productName_1 is empty.");
          }
          if ($("#sku_productName_1").val() == "") {
              result = false;
              resultLineItem = false;
              errorMessages.push("SKU for productName_1 is empty.");
          }
          if ($("#tax_productName_1").val() == "") {
              result = false;
              resultLineItem = false;
              errorMessages.push("Tax for productName_1 is empty.");
          }
      }

      // Display error messages
      if (errorMessages.length > 0) {
          var errorMessageContainer = $("<div class='error-message'></div>");
          errorMessages.forEach(function(message) {
              errorMessageContainer.append("<p>Error: " + message + "</p>");
          });
          $(".completeOrderPassword").after(errorMessageContainer);
          errorMessageContainer.show();
      }
      if (!resultLineItem) {
        $("#errorMsgLine").html(
          "Let's enter enough info about SKU, Product, Cost and Quantity, ..."
        );
      } else {
        $("#errorMsgLine").html("");
      }
      if (!this.checkHTML()) {
        result = false;
      }
      //qTax, qShip is custom input
      const qTax = $("#qTax").val()
        ? parseFloat($("#qTax").val()).toFixed(this.$decimalsView)
        : 0.0;
      const qShip = $("#qShip").val()
        ? parseFloat($("#qShip").val()).toFixed(this.$decimalsView)
        : 0.0;
      this.qTax = qTax;
      this.qShip = qShip;

      //remove html for global tag
      // if(this.htmlCheck(this.globalTag)) {
      //   this.globalTag = htmlParse(this.globalTag);
      // }

      //Do not allow saving with 0 quantity
      var hasZeroQty = false;
      var items = this.itemNames;
      for(let i in items) {
        if(items[i].sku != "" && (items[i].price != 0 || items[i].customerPrice != 0) && items[i].quantity == 0 && !items[i].included) {
          hasZeroQty = true;
          result = false;
        }
        if((items[i].isContractFee || items[i].isFee) && !items[i].price) {
          result = false;
        }
      }
      if(hasZeroQty) {
        $("#errorMsgLine").html("Please enter a quantity to save");
      }
      if (this.USECUSTOMTAX == 1 && result) {
        this.ConfirmCustomTax = true;
        result = false;
        this.isDuplicate = duplicateQuote;
      }

      if (result) {
        e.preventDefault();
        if(duplicateQuote) {
          await this.duplicateQuote(this.$route.params.qID);
        } else if(updateQuote) {
          await this.quoteUpdate(this.$route.params.qID);
        } else {
          this.saving = true;
          await this.createQuote(this);
          this.saving = false;
        }
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }

  async updateCustomTax() {
    this.taxLoading = true;
    try {
      if(this.isDuplicate) {
        await this.duplicateQuote(this.$route.params.qID);
      } else if (this.$route.params.qID && parseInt(this.$route.params.qID) != 0) {
        this.allowEditQuote = true;
        await this.quoteUpdate(this.$route.params.qID);
      }
      else {
        await this.createQuote(this);
        this.taxLoading = false;
        this.ConfirmCustomTax = false;
      }
    } catch (err) {
      this.taxLoading = "error";
    }
  }

  createQuote(value) {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var saveData = {};
    const aID = value.aID ? value.aID.toString() : typeof value.customer !== "number" ? this.aID : value.customer;
    saveData["Controller"] = "Quotes";
    saveData["FunctionName"] = "Add";
    saveData["qlines"] = value.length.toString();
    saveData["quoteID"] = "[Auto Generate]";
    saveData["aID"] = aID;
    saveData["accountID"] = value.accountID;
    saveData["qDateTime"] = date;
    saveData["qDescription"] = value.quoteName;
    saveData["qQuoteID"] = value.qQuoteID;
    saveData["totalPrice"] = value.grandTotal;
    saveData["qTax"] = value.qTax;
    saveData["qShip"] = value.qShip;
    saveData["source"] = value.sourceID;
    saveData["EstPercent"] = this.isIndirect() ? 0 : value.estPercent;
    saveData["currentComponent"] = value.currentComponent;
    saveData["selectedGlobalTagsID"] = value.selectedGlobalTagsID.join(",");
    //params for quoteParams
    saveData["BDnumber"] = value.BDnumber;
    saveData["ContractName"] = value.ContractName;
    saveData["Created"] = value.Created;
    saveData["Expires"] = value.Expires;
    saveData["QuoteNumber"] = value.QuoteNumber;
    saveData["ContractNumber"] = value.ContractNumber;
    saveData["globalMargin"] = this.isIndirect() ? value.globalMargin : 0;
    saveData["quoteBigDealNumber"] = value.quoteBigDealNumber;
    saveData["quoteRevisionNumber"] = value.quoteRevisionNumber;
    saveData["opportunityID"] = value.opportunityID;
    //(value.confirmedGlobalMargin ? value.globalMargin: 0);
    saveData["customData"] = JSON.stringify(value.fullCustomData);
    saveData["taxRate"] = this.currentTaxRate;
    saveData["usedCustomTax"] = !!this.USECUSTOMTAX;
    saveData["customSourceName"] = value.sourceName;
    saveData["expandDecimal"] = this.expandDecimal;
    saveData["FileName"] = this.uploadedFileName;
    saveData["includeVarCost"] = this.includeVarCost;
    // Department Info
    const departmentDetails = {
      ID: this.selectedDepartment.length ? this.selectedDepartment.join() : 0,
      NAME: this.selectedDepartmentName
    }
    saveData["departmentDetails"] = departmentDetails;
    let DefaultDeptID = (this.selectedDepartment.length ? this.selectedDepartment.join() : 0);
    if (DefaultDeptID == 0) {
      // if use a one time dept for this order
      saveData["onetimeDepartment"] = this.onetimeDepartment;
    }

    //Contract Number Info
    saveData["selectedContractNumberValue"] = value.selectedOrderContracts;
    saveData["contractNumberFieldId"] = value.orderContractNumber[0].CUSTOMFIELDID;

    // Include price roll up
    saveData["includedItem"] = this.includedItem;
    saveData["includedPrice"] = this.includedPrice;

    // business line
    saveData["businessLineId"] = this.selectedBusinessLine[0] || 0;

    // shipping/billing address
    saveData["DefaultShippingID"] = this.selectedAddress.length ? this.selectedAddress.join(",") : 0;
    saveData["DefaultBillingID"] = this.selectedBillingAddress.length ? this.selectedBillingAddress.join(",") : 0;
    if(this.selectedAddress.length && saveData["DefaultShippingID"] == 0) {
      // if use a one time shipping address
      saveData["onetimeAddress"] = this.onetimeAddress;
    }
    if (this.selectedBillingAddress.length && saveData["DefaultBillingID"] == 0) {
      // if use a one time billing address
      saveData["onetimeAddressBilling"] = this.onetimeAddressBilling;
    }

    const sourceIDs: any = [];
    let totalContractFee = 0;
    let itemOrder = 0;
    $.each(value.itemNames, function(i, val) {
      var ind = i.replace("productName_", "");
      saveData["ItemDescription" + ind] =
        value.itemNames["productName_" + ind].name;
      saveData["ItemNumber" + ind] = value.itemNames["productName_" + ind].sku;
      saveData["ItemQuantity" + ind] =
        value.itemNames["productName_" + ind].quantity > 0
          ? value.itemNames["productName_" + ind].quantity.toString()
          : "";
      saveData["RegPrice" + ind] =
        value.itemNames["productName_" + ind].price != 0
          ? value.itemNames["productName_" + ind].price.toFixed(value.expandDecimalPrecision).toString()
          : "";
      saveData["ItemTotal" + ind] =
        value.itemNames["productName_" + ind].total > 0
          ? value.itemNames["productName_" + ind].total.toString()
          : "";
      saveData["ItemCustomerPrice" + ind] =
        value.itemNames["productName_" + ind].customerPrice != 0
          ? value.itemNames["productName_" + ind].customerPrice.toFixed(value.expandDecimalPrecision).toString()
          : "";
      saveData["ItemMargin" + ind] = value.itemNames["productName_" + ind].margin.toString();
      saveData["ItemMarginRate" + ind] = (typeof val.marginRate != "undefined" ? val.marginRate : 0);
      saveData["ItemShipping" + ind] =
        value.itemNames["productName_" + ind].shipping > 0
          ? value.itemNames["productName_" + ind].shipping.toString()
          : "";
      saveData["ItemTaxRate" + ind] =
        value.itemNames["productName_" + ind].taxRate > 0
          ? value.itemNames["productName_" + ind].taxRate.toString()
          : "";
      saveData["ItemTax" + ind] =
        value.itemNames["productName_" + ind].tax > 0
          ? value.itemNames["productName_" + ind].tax.toString()
          : "";
      saveData["ItemNoTax" + ind] = value.itemNames["productName_" + ind].noTax
        ? 1
        : 0;
      saveData["ItemIncluded" + ind] = value.itemNames["productName_" + ind]
        .included
        ? 1
        : 0;
      saveData["ItemConfigID" + ind] =
        typeof value.itemNames["productName_" + ind].configID != "undefined"
          ? value.itemNames["productName_" + ind].configID
          : "";
      saveData["ItemCategory" + ind] = typeof value.itemNames["productName_" + ind].ItemCategory != "undefined" ? value.itemNames["productName_" + ind].ItemCategory : 0;
      saveData["ItemPLID" + ind] = typeof value.itemNames["productName_" + ind].ItemPLID != "undefined" ? value.itemNames["productName_" + ind].ItemPLID : 0;
      saveData["productLine" + ind] = value.itemNames["productName_" + ind].ItemPLName || "";
      saveData["buildHeader" + ind] = typeof value.itemNames["productName_" + ind].buildHeader != "undefined" ? value.itemNames["productName_" + ind].buildHeader : "";
      saveData["sourceID" + ind] = val.dynamicInfo ?  (val.dynamicInfo.selectedDistyID || 0) : 0;

      if(saveData[`sourceID${ind}`] > 0) {
        sourceIDs.push(saveData[`sourceID${ind}`]);
      }

      saveData[`ItemIsConfig${ind}`] = val.ISCONFIG || 0;

      // specify item order
      itemOrder++;
      saveData[`ItemOrder${ind}`] = itemOrder;

      // calculate total contract fee
      if(value.itemNames["productName_" + ind].isContractFee || false) {
        totalContractFee += value.itemNames["productName_" + ind].price;
      }
    });

    saveData["totalContractFee"] = totalContractFee;

    // auto update source to a distributor in case dynamic
    if(saveData["source"] == 10 && sourceIDs.length) {
      const usedMultiSource = sourceIDs.find((s: any) => s != sourceIDs[0]);
      if(!usedMultiSource) {
        saveData["source"] = sourceIDs[0];
      }
    }

    var self = this;
    var saveRecord = getRouteData(saveData);
    return saveRecord
      .then(function(response, statusText, jqXHR) {
        try {
          if (response.data.STATUS && response.data.STATUSMESSAGE) {
            self.items = response.data.QUOTES || [];
            self.details = response.data || {};
            self.loading = false;

            self.$router.push({ name: "Quotes" });
          }
        } catch (e) {
          //handle error
        }
      })
      .catch(function(error) {
        // console.log(error);
      });
  }

  showQuoteFileUpload() {
    $("#quoteFileUpload").click();
  }
  //edit(type,allowhide = 0) {
  edit(type) {
    if (type == 'shipping') {
      this.showIncludeVarCost = true;
    }
    if ($("li." + type).find("input").hasClass("displayNone")) {
      //if(!allowhide){
        $("li." + type)
          .find("strong")
          .addClass("displayNone");
        $("li." + type)
          .find("input")
          .removeClass("displayNone");

        //auto select
        $("li." + type).find("input").focus().select();

      //}
      // if(type == "estPercent"){
      //   var validItems = 0;
      //   var priceCnt = 0;
      //   var totalMargin = 0;
      //   $.each(
      //     this.itemNames,
      //     function(i, val) {
      //       totalMargin += parseInt(val.margin);
      //       if(val.customerPrice > 0 && val.price > 0){
      //         priceCnt += 1;
      //       }
      //       if((val.customerPrice > 0 || val.price > 0) && val.name != "" &&  val.sku != "" &&  val.quantity != 0 )
      //         validItems += 1;
      //     }
      //   );
      //   if(priceCnt == validItems && validItems && priceCnt && totalMargin){
      //     this.allowEst = true;
      //     this.estPercent = 0;
      //   }else{
      //     this.allowEst = false;
      //   }
      // }
    } else {
      $("li." + type)
        .find("input")
        .addClass("displayNone");
      $("li." + type)
        .find("strong")
        .removeClass("displayNone");

      //recalculate in case re-click edit icon to hide textbox tax/ship
      if (type == "tax" || type == "shipping" || type == "estPercent") {
        this.updateTotalTaxShip(event, type, true);
      }
    }
  }
  closeInput(type) {
    if (type == "shipping") {
      this.showIncludeVarCost = false;
    }
    $("li." + type)
      .find("input")
      .addClass("displayNone");
    $("li." + type)
      .find("strong")
      .removeClass("displayNone");

    if (type == "tax" || type == "shipping" || type == "estPercent") {
      this.updateTotalTaxShip(event, type, true);
    }

    if(type == "globalMargin") {
      // auto confirm global margin
      this.confirmedGlobalMargin = (this.globalMargin != this.globalMarginBK ? true : this.confirmedGlobalMargin);
      this.globalMarginChanged(null, true);
    }
  }
  updateTotalTaxShip(event, type, forceUpdate = false) {
    var comInstance = this;
    if (event.key == "Enter" || event.key == "Tab" || forceUpdate) {
      var tax = $("li.tax input").val() != "" ? $("li.tax input").val() : 0;
      var shipping =
        $("li.shipping input[type='text']").val() != "" ? $("li.shipping input[type='text']").val() : 0;
      var estPercent =
        $("li.estPercent input").val() != ""
          ? $("li.estPercent input").val()
          : 0;

      //detect if user wants to use a custom tax
      // if (
      //   parseFloat(tax.toString().replace(/[$|,\s]/g, "")) !=
      //   parseFloat(this.totalTax.replace(/[$|,\s]/g, ""))
      // ) {
      //   this.USECUSTOMTAX = 1;

      //   // set tax rate = 0 if user want to use a custom tax
      //   if(this.USECUSTOMTAX) {
      //     this.currentTaxRate = 0;
      //   }
      // }

      if (parseFloat(estPercent) > 0) {
        //if user inputted an EST %
        this.profit =
          (parseFloat(this.itemTotal.toString().replace(/[^0-9.]/g, "")) *
            parseFloat(estPercent)) /
          100;
      } else {
        var profit = 0;
        $.each(this.itemNames, function(i, val) {
          // if (!val.included || comInstance.isMultiEndUsers) {
          //   profit += val.margin * val.quantity;
          // }
          profit += val.margin * val.quantity;
        });
        this.profit = profit;
      }
      // this.profit =
      //   "$" +
      //   parseFloat(this.profit)
      //     .toFixed(this.$decimalsView)
      //     .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
      //     .toString();
      this.grandTotal =
        parseFloat(this.itemTotal.toString().replace(/[^0-9.]/g, "")) +
        parseFloat(tax) +
        parseFloat(shipping);
      // this.grandTotal =
      //   "$" +
      //   parseFloat(this.grandTotal)
      //     .toFixed(this.$decimalsView)
      //     .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
      //     .toString();
      this.totalTax =
        "$" +
        parseFloat(tax)
          .toFixed(this.$decimalsView)
          .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
          .toString();
      this.totalShipping =
        "$" +
        parseFloat(shipping)
          .toFixed(this.$decimalsView)
          .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
          .toString();

      $("li." + type)
        .find("input")
        .addClass("displayNone");
      $("li." + type)
        .find("strong")
        .removeClass("displayNone");

      //case: user press TAB from custom tax box, go to custom shipping box so that user can input something there
      if (event.key == "Tab" && type == "tax") {
        $("li.shipping")
          .find("strong")
          .addClass("displayNone");
        $("li.shipping")
          .find("input")
          .removeClass("displayNone");
        setTimeout(() => {
          $("li.shipping")
            .find("input")
            .focus()
            .select();
        }, 100);
      }

      //case: user press TAB from EST % box, go to custom tax box so that user can input something there
      if (event.key == "Tab" && type == "estPercent") {
        $("li.tax")
          .find("strong")
          .addClass("displayNone");
        $("li.tax")
          .find("input")
          .removeClass("displayNone");
        setTimeout(() => {
          $("li.tax")
            .find("input")
            .focus()
            .select();
        }, 100);
      }

      /**
       * case: press TAB or Enter on bottom textbox EST%
       * set margin to 0 on line items for some cases
       */
      if (type == "estPercent") {
        if (parseFloat(estPercent) > 0) {
          //if user inputted an EST %
          $.each(this.itemNames, function(i, val) {
            //save current margin/cost per/price per
            var currentMargin = val.customerPrice - val.price;
            if (currentMargin != 0) {
              //cost per and price per are different
              val.prevMargin = currentMargin;
              val.prevPrice = val.price;
              val.prevCustomerPrice = val.customerPrice;
            }

            //set cost per = price per
            //val.price = val.customerPrice;
            // Comment out to fix the price update when change Est%
            // val.customerPrice = val.price;
            comInstance.marginCalc1(i);

            //reset margin
            val.margin = 0;
          });
        } else {
          //restore backup daata
          $.each(this.itemNames, function(i, val) {
            if (val.price == val.customerPrice) {
              //just restore in case cost per = price per
              //restore for previous saving
              val.margin =
                typeof val.prevMargin != "undefined" && val.prevMargin != 0
                  ? val.prevMargin
                  : val.margin;
              val.price =
                typeof val.prevPrice != "undefined" && val.prevPrice != 0
                  ? val.prevPrice
                  : val.price;
              val.customerPrice =
                typeof val.prevCustomerPrice != "undefined" &&
                val.prevCustomerPrice != 0
                  ? val.prevCustomerPrice
                  : val.customerPrice;
              comInstance.marginCalc1(i);

              //reset backup data
              val.prevMargin = 0;
              val.prevPrice = 0;
              val.prevCustomerPrice = 0;
            }
          });

          //this.getTotals();
        }
      }
    }
  }

  //run when user inputs a custom tax
  updateUseCustomTaxFlag() {
    this.USECUSTOMTAX = 0;
    if (this.qTax != 0) {
      this.USECUSTOMTAX = 1;
    }

    // set tax rate = 0 if user want to use a custom tax
    if(this.USECUSTOMTAX) {
      this.currentTaxRate = 0;
    }
  }
  checkAllTaxRate() {
    this.checkAllTax = !this.checkAllTax;
    for (const key in this.itemNames) {
      if (!this.itemNames[key].ISCONFIG || 0) {
        if(this.currentTaxRate != 0) {
          this.itemTaxable(key, this.checkAllTax);
        }
        else {
          this.checkAllTax = false;
          const quoteView: any = this.$refs.quoteView;
          quoteView.taxRateVisible = true;
        }
      }
    }
  }

  checkForUnselected() {
    let unSelectedforTax = {};
    for(let key in this.itemNames) {
      if (this.itemNames[key].taxRate == 0) {
        unSelectedforTax[key] = this.itemNames[key];
      }
    }
    if (Object.keys(unSelectedforTax).length == 0) {
      this.checkAllTax = true;
    }
  }

  itemTaxable(index, checkAll = false) {
    const item = this.itemNames[index];
    // no allow click on this item when using custom tax
    // if(this.USECUSTOMTAX) {
    //   return;
    // }

    // disable tax on fee
    // (item.included && item.ISCONFIG)
    if(this.isFee(item)) {
      return;
    }

    // reopen tax rate box if current tax rate = 0 before
    if(((item.taxRate == 0 && this.currentTaxRate == 0) || this.USECUSTOMTAX)) {
      const quoteView: any = this.$refs.quoteView;
      quoteView.taxRateVisible = true;
      this.selectedIndexForTax = index;
      return;
    }

    if(this.USECUSTOMTAX) {
      return;
    }

    const checkbox = "#taxable_" + index;
    if ($(checkbox).hasClass("checkbox_checked") && !checkAll) {
      //unchecked
      item.taxRate = 0;
      this.checkAllTax = false;
    } else if ($(checkbox).hasClass("checkbox_unchecked")) {
      //checked
      item.taxRate = this.currentTaxRate;
    }
    // config items have same taxRate as parent
    const configItems = this.getIncludedItems(item, index).filter(t => t.ISCONFIG || 0);
    for(const t of configItems) {
      t.taxRate = item.taxRate;
    }

    this.checkForUnselected();
    this.USECUSTOMTAX = 0;
    this.$refs.quoteView.$forceUpdate();
    this.getTotals();
  }

  calcWithChangeCostPrice(index) {
    const item = this.itemNames[index];
    const currentMargin = item.margin;
    var price = item.price;
    var customerPrice = item.customerPrice;
    item.priceWarn = false;
    item.orgPrice = item.price;

    if(this.isIndirect() && this.confirmedGlobalMargin && this.currentComponent != "Direct" && !item.isContractFee && !item.isFee) {// confirmed a global margin
      // calculate customer price first
      item.customerPrice = this.getCustomerPriceByMarinRate(item);
      this.reCalculateMargin(item, "amount");
      // item.customerPrice = parseFloat((price + item.margin).toFixed(this.$decimalsView));
      this.getTotals({ comeFrom: "cost_per" });
    } else if(!item.isContractFee && !item.isFee) {
      item.margin = parseFloat((customerPrice - price).toFixed(this.$decimals));
      this.reCalculateMargin(item, "rate");
      this.getTotals({ comeFrom: "cost_per" });
      this.reCalculateGlobalMargin(); //re-calculate for global margin
    } else {
      item.margin = 0;
      item.marginRate = 0;
      this.getTotals({ comeFrom: "cost_per" });
      this.reCalculateGlobalMargin();
    }

    // if(item.included){
    //   const parent = findParent(index, this.itemNames);
    //   if(parent) {
    //     const parentIndex = this.findParentIndex(index, this.itemNames);
    //     var includedPrice = 0;
    //     for (let i = parentIndex + 1; i < Object.entries(this.itemNames).length; i++) {
    //       const lineItem = this.itemNames[`productName_${i}`];
    //       if (!lineItem) {
    //         continue;
    //       }
    //       if (lineItem.included){
    //         includedPrice += lineItem.price;
    //       } else {
    //         break;
    //       }
    //     }
    //     parent.price = includedPrice;
    //     parent.margin = parseFloat((parent.customerPrice - parent.price).toFixed(this.$decimals));
    //     this.reCalculateMargin(parent, "rate");
    //   }
    // }
    item.orgCustomerPrice = item.customerPrice;
    this.$refs.quoteView.$forceUpdate();
    this.checkItemChangedValue(index, "margin", parseFloat(currentMargin).toFixed(2) , parseFloat(item.margin).toFixed(2));
    this.checkItemChangedValue(index, "price", customerPrice, item.customerPrice);

  }
  updateCustomerPrice(index) {
    this.calcWithChangeCostPrice(index);
    this.itemNames[index].customerPrice = this.itemNames[index].price;
    this.marginCalc1(index);
  }
  marginCalc1(index) {
    const item = this.itemNames[index];
    const currentMargin = item.margin;
    if (item.customerPrice == 0) {
      item.customerPrice = item.customerPrice * -1;
    }
    var customerPrice = item.customerPrice;
    var quantity = item.quantity || 0;
    var price = item.price;
    item.orgCustomerPrice = item.customerPrice;

    if (typeof customerPrice != "undefined" && customerPrice != 0) {
      customerPrice = customerPrice != 0 ? customerPrice : 0;
      quantity = quantity >= 0 ? quantity : 0;
      price = price != 0 ? price : 0;
      item.margin = parseFloat((customerPrice - price).toFixed(this.$decimals));
      item.total = parseFloat((customerPrice * quantity).toFixed(this.$decimals));
      // item.total = parseFloat(
      //   (customerPrice * quantity).toString()
      // )
      //   .toFixed(this.$decimals)
      //   .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
      //   .toString();
    } else {
      if (customerPrice != "") {
        item.customerPrice = 0;
        item.margin = 0;
      }
    }

    // if(item.included){
    //   const parent = findParent(index, this.itemNames);
    //   if(parent) {
    //     const parentIndex = this.findParentIndex(index, this.itemNames);
    //     var includedCustomerPrice = 0;
    //     for (let i = parentIndex + 1; i < Object.entries(this.itemNames).length; i++) {
    //       const lineItem = this.itemNames[`productName_${i}`];
    //       if (!lineItem) {
    //         continue;
    //       }
    //       if (lineItem.included){
    //         includedCustomerPrice += lineItem.customerPrice;
    //       } else {
    //         break;
    //       }
    //     }
    //     parent.customerPrice = includedCustomerPrice;
    //     parent.margin = parseFloat((parent.customerPrice - parent.price).toFixed(this.$decimals));
    //     this.reCalculateMargin(parent, "rate");
    //   }
    // }

    item.priceWarn = false;
    //this.edit('estPercent',1);
    this.reCalculateMargin(this.itemNames[index], "rate");
    this.getTotals({ comeFrom: "price_per" });
    this.reCalculateGlobalMargin(); //re-calculate for global margin
    this.$refs.quoteView.$forceUpdate();
    this.checkItemChangedValue(index, "margin", currentMargin, item.margin);
  }
  marginCalc2(index) {
    const currentCustomerPrice = this.itemNames[index].customerPrice;
    if (this.itemNames[index].margin) {
      if (this.itemNames[index].margin < 0) {
        if (
          Math.abs(this.itemNames[index].margin) >= this.itemNames[index].price
        ) {
          this.itemNames[index].margin = -Math.abs(this.itemNames[index].price);
        }
      }
      this.itemNames[index].customerPrice =
        this.itemNames[index].margin + this.itemNames[index].price;

      // this.itemNames[index].total = this.itemNames[index].customerPrice;
      // updated for tax calculate when update margin
      // this.itemNames[index].total = roundCents(
      //   this.itemNames[index].customerPrice * this.itemNames[index].quantity
      // );
      this.itemNames[index].total = parseFloat((this.itemNames[index].customerPrice * this.itemNames[index].quantity).toFixed(this.$decimals));
      this.reCalculateMargin(this.itemNames[index], "rate");
    } else {
      this.itemNames[index].margin = 0;
      this.itemNames[index].marginRate = 0;
      this.itemNames[index].customerPrice = this.itemNames[index].price;
    }
    //this.taxRate(index);
    //this.edit('estPercent',1);
    this.itemNames[index].orgCustomerPrice = this.itemNames[index].customerPrice;
    // this.setIncludedItemPrice(false);
    this.getTotals({ comeFrom: "price_per" });
    this.reCalculateGlobalMargin(); //re-calculate for global margin
    this.$refs.quoteView.$forceUpdate();
    this.checkItemChangedValue(index, "price", currentCustomerPrice, this.itemNames[index].customerPrice);
  }

  reCalculateGlobalMargin() {
    if(this.isIndirect()) {//this.confirmedGlobalMargin === true
      var totalCustomerPrice = 0;
      // var totalCostPer = 0;
      var totalMargin = 0;
      for(var i in this.itemNames) {
        let item = this.itemNames[i];
        if(!isNaN(item.customerPrice) && !isNaN(item.quantity)) {
          totalCustomerPrice += parseFloat((item.customerPrice * item.quantity).toFixed(this.$decimals));
        }
        // if(!isNaN(item.price) && !isNaN(item.quantity) && !item.included) {
        //   totalCostPer += parseFloat((item.price * item.quantity).toFixed(this.$decimals));
        // }
        if(!isNaN(item.margin) && !isNaN(item.quantity)) {
          totalMargin += parseFloat((item.margin * item.quantity).toFixed(2));
          // let tmpMargin = ((item.customerPrice * item.marginRate) / 100) * item.quantity;
          // let tmpMargin = ((item.price * item.marginRate) / 100) * item.quantity;
          // if(isNaN(tmpMargin)) {
          //   tmpMargin = 0;
          // }
          // totalMargin += parseFloat(tmpMargin.toFixed(this.$decimals));
        }
      }

      if(totalCustomerPrice != 0) {
        //totalMargin = parseFloat(totalMargin.toFixed(this.$decimals));
        // this.globalMargin = parseFloat((totalMargin / totalCustomerPrice * 100).toFixed(this.$decimalsView));
        this.globalMargin = parseFloat((totalMargin / totalCustomerPrice * 100).toFixed(this.$decimalsView));
      }else {
        this.globalMargin = 0;
      }
      this.globalMarginBK = this.globalMargin;
    }
  }

  async editQuote(quote, container) {
    var comInstance = this;
    comInstance.loading = true;

    var dataObj = {
      controller: "Quotes",
      FunctionName: "View",
      Content: "Detailed",
      ObjID: quote,
      inEditPage: true
    };
    // var accObj = {
    //   controller: "Accounts",
    //   FunctionName: "List"
    // };
    if (quote != 0) {
      // var quoteData = getRouteData(dataObj);
      // quoteData.then(function(response, statusText, jqXHR) {
      const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
      try {
        $("input#quoteName").val(response.data.QUOTENAME);
        var retData = response.data.QUOTEITEMS;
        /*$("#list-content-container").empty();*/

        //set some value for comInstance
        var quoteDetails = response.data.QUOTEDETAILS;
        const quoteParams = JSON.parse(quoteDetails.QUOTEPARAMS || "{}");
        comInstance.sourceName = typeof quoteParams.customSourceName != "undefined" ? quoteParams.customSourceName : "";
        comInstance.expandDecimal = typeof quoteParams.expandDecimal != "undefined" ? quoteParams.expandDecimal : false;
        comInstance.quoteBigDealNumber = typeof quoteParams.quoteBigDealNumber != "undefined" ? quoteParams.quoteBigDealNumber : '';
        comInstance.quoteRevisionNumber = typeof quoteParams.quoteRevisionNumber != "undefined" ? quoteParams.quoteRevisionNumber : '';
        comInstance.opportunityID = typeof quoteDetails.OPPORTUNITYID != "undefined" && quoteDetails.OPPORTUNITYID ? quoteDetails.OPPORTUNITYID : 0;
        comInstance.opportunityPrevID = typeof quoteDetails.OPPORTUNITYID != "undefined" && quoteDetails.OPPORTUNITYID ? quoteDetails.OPPORTUNITYID : 0;
        comInstance.opportunityName = typeof quoteDetails.OPPORTUNITYNAME != "undefined" && quoteDetails.OPPORTUNITYNAME ? quoteDetails.OPPORTUNITYNAME : typeof quoteDetails.OPPORTUNITYID != "undefined" && quoteDetails.OPPORTUNITYID ? `MOP-${quoteDetails.OPPORTUNITYID}` : "";
        if (comInstance.expandDecimal) {
          comInstance.expandDecimalPrecision = 5;
        }
        comInstance.includedItem = quoteParams.includedItem || false;
        comInstance.includedPrice = typeof quoteParams.includedPrice != "undefined" ? quoteParams.includedPrice : false;
        comInstance.includeVarCost = typeof quoteParams.includeVarCost != "undefined" ? quoteParams.includeVarCost : true;
        comInstance.aID = quoteDetails.AID.toString();

        if(typeof quoteParams.DefaultShippingID != "undefined" && quoteParams.DefaultShippingID != "") {
          comInstance.selectedAddress = [parseInt(quoteParams.DefaultShippingID)];
        }
        if(typeof quoteParams.onetimeAddress != "undefined" && Object.keys(quoteParams.onetimeAddress).length) {
          // used one time address for this order
          comInstance.selectedAddress = [0];
          comInstance.onetimeAddress = Object.assign(comInstance.onetimeAddress, quoteParams.onetimeAddress);
        }
        if (typeof quoteParams.DefaultBillingID != "undefined" && quoteParams.DefaultBillingID != "") {
          comInstance.selectedBillingAddress = [parseInt(quoteParams.DefaultBillingID)];
        }
        if (typeof quoteParams.onetimeAddressBilling != "undefined" && Object.keys(quoteParams.onetimeAddressBilling).length) {
          // used one time address for this order
          comInstance.selectedBillingAddress = [0];
          comInstance.onetimeAddressBilling = Object.assign(comInstance.onetimeAddressBilling, quoteParams.onetimeAddressBilling);
        }

        //Fill customer department
        if (typeof quoteParams.departmentDetails != "undefined" && quoteParams.departmentDetails != "") {
          comInstance.selectedDepartment = [parseInt(quoteParams.departmentDetails.ID)];
          comInstance.selectedDepartmentName = quoteParams.departmentDetails.NAME;
        }
        if (typeof quoteParams.onetimeDepartment != "undefined" && Object.keys(quoteParams.onetimeDepartment).length) {
          // used one time dept for this order
          comInstance.selectedDepartment = [0];
          comInstance.onetimeDepartment = Object.assign(comInstance.onetimeDepartment, quoteParams.onetimeDepartment);
        }
        
        comInstance.accountID = quoteDetails.AACCOUNTID;
        comInstance.quoteName = response.data.QUOTENAME;
        comInstance.qQuoteID = quoteDetails.QQUOTEID;
        comInstance.customer = quoteDetails.AACCOUNTNAME;
        comInstance.customer_bk = {
          customer: quoteDetails.AACCOUNTNAME,
          aID: quoteDetails.AID,
          accountID: quoteDetails.AACCOUNTID
        };
        comInstance.grandTotal = response.data.GRANDTOTALFORMATTED;
        comInstance.totalShipping = response.data.TOTALSHIPPINGFORMATTED;
        comInstance.totalTax = response.data.TOTALTAXFORMATTED;
        comInstance.qShip = response.data.TOTALSHIPPING;
        comInstance.qTax = response.data.TOTALTAX;
        comInstance.qOpen = quoteDetails.QOPEN;
        comInstance.totalVarCost = quoteDetails.QTOTALPRICE;
        comInstance.USECUSTOMTAX = (response.data.USECUSTOMTAX || 0) == 1 ? 1 : 0;
        comInstance.source =
          typeof quoteDetails.VARSOURCE_NAME != "undefined"
            ? quoteDetails.VARSOURCE_NAME
            : "";
        comInstance.sourceID =
          typeof quoteDetails.SOURCE != "undefined" ? quoteDetails.SOURCE : 0;
        comInstance.estPercent = quoteDetails.ESTPERCENT
          ? quoteDetails.ESTPERCENT
          : 0;
        var profitTotal =
          typeof quoteDetails.PROFIT != "undefined" &&
          quoteDetails.PROFIT != ""
            ? quoteDetails.PROFIT
            : 0.0;
        if (comInstance.estPercent > 0) {
          profitTotal =
            (quoteDetails.CUSTOMERTOTALPRICE * comInstance.estPercent) / 100;
        }
        // comInstance.profit =
        //   "$" +
        //   parseFloat(profitTotal)
        //     .toFixed(comInstance.$decimalsView)
        //     .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        comInstance.profit = parseFloat(profitTotal).toFixed(comInstance.$decimalsView);

        comInstance.itemTotal = response.data.TOTALAMOUNTFORMATTED;
        if(quoteDetails.GLOBALMARGIN != "undefined" && quoteDetails.GLOBALMARGIN != 0) {
          comInstance.globalMargin = quoteDetails.GLOBALMARGIN;
          comInstance.globalMarginBK = quoteDetails.GLOBALMARGIN;

          // comInstance.confirmedGlobalMargin = true;
        }
        comInstance.hpDirectTaxRate = (typeof quoteDetails.HPDIRECTTAXRATE != "undefined" ? quoteDetails.HPDIRECTTAXRATE : 0);
        comInstance.indirectTaxRate = (typeof quoteDetails.INDIRECTTAXRATE != "undefined" ? quoteDetails.INDIRECTTAXRATE : 0);
        comInstance.currentTaxRate = comInstance.getTaxRateBySource();
        comInstance.selectedBusinessLine = quoteDetails.BUSINESSLINEID ? [quoteDetails.BUSINESSLINEID] : [];

        // override current tax rate if user used an override value before
        if(typeof quoteDetails.QTAXRATE != "undefined" && quoteDetails.QTAXRATE !== "") {
          comInstance.currentTaxRate = quoteDetails.QTAXRATE;
        }
        // clear taxrate if use a custom tax
        if(this.USECUSTOMTAX) {
          comInstance.currentTaxRate = 0;
        }

        //detect global tag
        // if(typeof response.data.CUSTOMDATA != "undefined" && response.data.CUSTOMDATA.length) {
        //   let savedGlobalTag = response.data.CUSTOMDATA.filter(val => val.CUSTOMFIELDID == 8);
        //   if(savedGlobalTag.length) {
        //     comInstance.globalTag = savedGlobalTag[0].CUSTOMVALUE;
        //     comInstance.customID = savedGlobalTag[0].CUSTOMID;
        //   }
        // }

        if (retData.length) {
          var totalProducts = retData.length;

          $.each(retData, function(i, val) {
            comInstance.addRowWithVal({
              sku: val.QPRODSKU,
              name: val.QPRODDESC.replace(/[$|,]/g, ""),
              price:
                typeof val.QINCLUDED != "undefined" && val.QINCLUDED
                  ? val.QPRICEREG_BK
                  : val.QPRICEREG,
              customerPrice:
                typeof val.QINCLUDED != "undefined" && val.QINCLUDED
                  ? val.QCUSTOMERPRICE_BK
                  : val.QCUSTOMERPRICE,
              margin: val.QMARGIN,
              marginRate: comInstance.editGetMarginRate(val),
              // total: parseFloat(
              //   val.CUSTOMERTOTALPRICE && val.CUSTOMERTOTALPRICE != 0
              //     ? val.CUSTOMERTOTALPRICE
              //     : val.TOTALPRICE
              // )
              //   .toFixed(comInstance.$decimals)
              //   .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
              total: parseFloat(val.CUSTOMERTOTALPRICE && val.CUSTOMERTOTALPRICE != "" ? val.CUSTOMERTOTALPRICE : 0),
              quantity:
                typeof val.QINCLUDED != "undefined" && val.QINCLUDED
                  ? val.QPRODQUANTITY_BK
                  : val.QPRODQUANTITY,
              //typeof val.QPRODQUANTITY != "number" ? 0 : val.QPRODQUANTITY,
              taxRate:
                typeof val.QTAXRATE != "undefined" && val.QTAXRATE != ""
                  ? val.QTAXRATE
                  : 0,
              tax:
                typeof val.QTAX != "undefined" && val.QTAX != ""
                  ? val.QTAX
                  : 0,
              shipping:
                typeof val.QSHIPPING != "undefined" && val.QSHIPPING != ""
                  ? val.QSHIPPING
                  : 0,
              //included: ((typeof val.QINCLUDED != "undefined" && val.QINCLUDED) || val.QPRICEREG == 0 ? 1 : 0),
              included:
                typeof val.QINCLUDED != "undefined" && val.QINCLUDED ? 1 : 0,
              ISCONFIG: val.ISCONFIG || 0,
              priceWarn: false,
              totalProducts: totalProducts,
              noTax: typeof val.NOTAX != "undefined" && val.NOTAX ? 1 : 0,
              configID: val.CONFIGID,
              lineNotes:
                typeof val.LINENOTES != "undefined" ? val.LINENOTES : "",
              DistyPrice:
                typeof val.DistyPrice != "undefined" ? val.DistyPrice : 0,
              DistyAvailability:
                typeof val.DistyAvailability != "undefined"
                  ? val.DistyAvailability
                  : 0,
              DistyPrice_Format:
                typeof val.DistyPrice_Format != "undefined"
                  ? val.DistyPrice_Format
                  : "",
              DistyAvailability_Format:
                typeof val.DistyAvailability_Format != "undefined"
                  ? val.DistyAvailability_Format
                  : "",
              Disty: typeof val.Disty != "undefined" ? val.Disty : "",
              orgPrice: typeof val.QINCLUDED != "undefined" && val.QINCLUDED ? val.QPRICEREG_BK : typeof val.ORGQPRICEREG != "undefined" ? val.ORGQPRICEREG : val.QPRICEREG,
              orgCustomerPrice: typeof val.QINCLUDED != "undefined" && val.QINCLUDED ? val.QCUSTOMERPRICE_BK : typeof val.ORGQCUSTOMERPRICE != "undefined" ? val.ORGQCUSTOMERPRICE : val.QCUSTOMERPRICE,
              // disable: (typeof val.PARENTDISABLE != "undefined" && val.PARENTDISABLE) ? true : false,
              disable: false,
              ItemCategory: typeof val.QCATEGORY != "undefined" ? val.QCATEGORY : 0,
              ItemCategoryName: typeof val.CATEGORYNAME != "undefined" ? val.CATEGORYNAME : "",
              dynamicInfo: val,
              contractPrice: typeof val["CONTRACTPRICE"] != undefined ? val["CONTRACTPRICE"] : undefined,
              contractPriceDate: typeof val["CONTRACTPRICEDATE"] != undefined ? val["CONTRACTPRICEDATE"] : undefined,
              isContractFee: typeof val.ISCONTRACTFEE != "undefined" && val.ISCONTRACTFEE ? true : false,
              includedparent : typeof val.INCLUDEDPARENT != "undefined" ? val.INCLUDEDPARENT : 0,
              baseProductId : typeof val.QUOTELIID != "undefined" ? val.QUOTELIID : 0,
              includeItemCount : typeof val.INCLUDEITEMCOUNT != "undefined" ? val.INCLUDEITEMCOUNT : 0,
              parentLiId: val.PARENTLIID || 0,
              quoteLiId: val.QUOTELIID || 0,
              isFee: comInstance.checkItemIsFee(val),
              subQuoteId: val.SUBQUOTEID || 0,
              CONFIGCOUNT: val.CONFIGCOUNT || 0,
              savedAsConfig: val.ISCONFIG || 0,
              ItemPLID: val.PRODUCTLINEID || 0,
              ItemPLName: val.PRODUCTLINENAME || '',
              buildHeader: val.BUILDHEADER || ''
            });
            // comInstance.totalCal("productName_" + comInstance.length);
            // profitTotal += parseFloat(val.QMARGIN) * (typeof val.QPRODQUANTITY != "number" ? 0 : val.QPRODQUANTITY);
          });

          comInstance.itemNames["productName_" + (totalProducts + 1)] = {
            name: "",
            sku: "",
            price: 0.0,
            customerPrice: 0.0,
            margin: 0.0,
            marginRate: comInstance.getConfirmedGlobalMargin(true),
            total: 0.0,
            quantity: 0,
            tax: 0.0,
            taxRate: comInstance.currentTaxRate,
            shipping: 0.0,
            included: 0,
            ISCONFIG: 0,
            priceWarn: false,
            totalProducts: totalProducts,
            noTax: 0,
            configID: "",
            lineNotes: "",
            DistyPrice: 0,
            DistyAvailability: 0,
            DistyPrice_Format: "",
            DistyAvailability_Format: "",
            Disty: "",
            ItemCategory: 0,
            ItemCategoryName: "",
            orgPrice: 0.0,
            orgCustomerPrice: 0.0,
            disable: false,
            isContractFee: false,
            includedparent : 0,
            baseProductId : 0,
            includeItemCount : 0,
            parentLiId: 0,
            quoteLiId: 0,
            isFee: false,
            ItemPLID: 0,
            ItemPLName: '',
            buildHeader: ''
          };
          comInstance.length = totalProducts + 1;
        } else {
          comInstance.length = 3;
          for (let i = 1; i <= 3; i++) {
            comInstance.itemNames["productName_" + i] = {
              name: "",
              price: 0.0, //"0.00",
              customerPrice: 0.0, //"0.00",
              margin: 0.0, //"0.00",
              marginRate: 0,
              total: 0.0, //"0.00"
              quantity: 0,
              tax: 0.0, //"0.00"
              taxRate: 0, //0
              shipping: 0.0, //"0.00"
              included: 0,
              priceWarn: false,
              totalProducts: "3",
              noTax: 0,
              configID: "",
              DistyPrice: 0,
              DistyAvailability: 0,
              DistyPrice_Format: "",
              DistyAvailability_Format: "",
              Disty: "",
              orgPrice: 0.0,
              orgCustomerPrice: 0.0,
              disable: false,
              isContractFee: false,
              includedparent : 0,
              baseProductId : 0,
              includeItemCount : 0,
              parentLiId: 0,
              quoteLiId: 0,
              isFee: false
            };
          }
        }
        this.checkForUnselected();

        // var tmpSavedItems = JSON.parse(JSON.stringify(comInstance.itemNames));
        comInstance.loading = false;
        if (response.data.QVIEW != "") {
          const view = response.data.QVIEW || '';
          comInstance.currentComponent = view == "Extended" ? "Simplified" : view;
        } else {
          comInstance.currentComponent = "Simplified";
        }
        comInstance.currentComponent_BK = comInstance.currentComponent;

        if(comInstance.isDynamic()) {
          comInstance.autoName = 1;
          comInstance.autoCost = 1;
          $(".switch.auto input[type=checkbox].autoName, .switch.auto input[type=checkbox].autoCost").prop("checked", true);
          comInstance.DistyTotal = (typeof response.data.DistyTotal != "undefined" ? response.data.DistyTotal : {});
        }
        comInstance.splitBySubQuoteId = response.data.splitBySubQuoteId || [];
      } catch (e) {
        //handle error
        comInstance.loading = false;
      }

      if(comInstance.aID > 0) {
        await comInstance.getCustomerAddresses(comInstance.aID);
        //Get customer department details
        await comInstance.getCustomerDepartment(comInstance.aID);
      }
      // To list the selected address on the top
      if(this.customerAddresses.length) {
        this.sortingAddresses(this.customerAddresses, this.selectedAddress);
      }
      if(this.customerAddressesBilling.length) {
        this.sortingAddresses(this.customerAddressesBilling, this.selectedBillingAddress);
      }

      // eslint-disable-next-line require-atomic-updates
      comInstance.qShip_BK = parseFloat(comInstance.qShip);
      // eslint-disable-next-line require-atomic-updates
      comInstance.qTax_BK = parseFloat(comInstance.qTax);
      // });
    } else {
      container.items = { QDESCRIPTION: "" };
    }
  }
  backToDetails() {
    var quoteID = $("#quoteID").text();
    this.$router.push({ name: "ViewQuote", params: { id: quoteID } });
  }
  handleQuoteFileUpload(e) {
    var comInstance = this;
    e.stopPropagation();
    e.preventDefault();

    var filePath = e.target.files[0].name;
    var allowedExtensions = /(\.html|\.pdf|\.csv|\.xls|\.xlsx)$/i;
    var isCSV = (filePath.indexOf(".csv") != -1 ? true : false);
    if (!allowedExtensions.exec(filePath)) {
      this.errorMsg =
        "Please upload file having extensions .html/.pdf/.csv/.xls/.xlsx only.";
      $("#errorMsgLine").html(this.errorMsg);
      return false;
    } else {
      //upload file, and get returned json
      // comInstance.loading = true;
      comInstance.uploading = true;
      var processedFileUpload = getImageRouteData({
        Controller: "Quotes",
        FunctionName: "Add",
        quoteFileName: filePath,
        quotefile: e.target.files[0],
        uploadedFileName: e.target.files[0].name,
        fileType:
          filePath.indexOf(".html") != -1
            ? "html"
            : filePath.indexOf(".csv") != -1
            ? "csv"
            : filePath.indexOf(".xls") != -1
            ? "xls"
            : "pdf",
        source:
          typeof comInstance.sourceID != "undefined" ? comInstance.sourceID : 1
      })
        .then(async function(response, statusText, jqXHR) {
          if (response.data.STATUS) {
            $("#errorMsgLine").html("");
            var retData = response.data.DATA;
            //reset
            //$("#list-content-container").html("");
            comInstance.length = 0;
            // comInstance.$forceUpdate();

            var profitTotal = 0.0;
            var qTax = 0.0;
            var qShip = 0.0;
            let totalCost = 0;
            let totalAllItem = 0.0;
            var usedCustomTax = (response.data.EstimatedTax ? true : false);
            comInstance.uploadedFileName = response.data.UPLOADEDFILENAME;
            let items = {};
            if(comInstance.$route.params.qID) {
              for (let i = 0; i < retData.length; i++) {
                let skuItem = retData[i];
                $.each(comInstance.itemNames, function(i, val) {
                  if(val.sku == skuItem.SKU) {
                    val.price = skuItem.NetPrice;
                    val.customerPrice = skuItem.Price;
                    val.margin = parseFloat((skuItem.Price - skuItem.NetPrice).toFixed(comInstance.$decimals));
                    val.marginRate = comInstance.editGetMarginRate({'QMARGIN': skuItem.Price - skuItem.NetPrice, 'QPRICEREG_BK': skuItem.Price}),
                    val.total = parseFloat((skuItem.Price * val.quantity).toFixed(comInstance.$decimals));
                    val.tax = ((skuItem.Price * val.taxRate) / 100) * val.quantity;
                  } else {
                    val.tax = ((val.customerPrice * val.taxRate) / 100) * val.quantity;
                  }
                });
              }
              $.each(comInstance.itemNames, function(i, val) {
                profitTotal += val.margin;
                totalCost += val.price * val.quantity;
                totalAllItem += val.total;
                qTax += val.tax;
              });
              comInstance.length = Object.keys(comInstance.itemNames).length;
              let tmpTax = comInstance.qTax_BK != qTax ? qTax : comInstance.qTax_BK;
              qTax = tmpTax;
              qShip = comInstance.qShip_BK;
            } else {
              $.each(retData, function(i, val) {
                var tmpName =
                  typeof val.Desc != "undefined" ? val.Desc : val.Name;
                var tmpPrice = val.Price;
                var tmpPrice_val = parseFloat(tmpPrice.toString().replace(/[$|,\s]/g, ""));
                var tmpQty_val = parseFloat(val.Qty.toString().replace(/[$|,\s]/g, ""));
                var tmpIncluded = val.isIncluded || 0;
                var tmpTotal = parseFloat((tmpPrice * val.Qty).toFixed(comInstance.$decimals));
                let tmpIsConfig = tmpIncluded && ((tmpPrice || 0) == 0 || (val.configID || "") != "" || (val.isConfig || 0)) ? 1 : 0;
                let tmpQty = (val.Qty.toString() != "" ? val.Qty.toString() : 0).replace(/[$|,\s]/g, "");
                let tmpCostPer = (typeof val.NetPrice != "undefined" ? parseFloat(val.NetPrice.toString().replace(/[$|,\s]/g, "")) : 0);
  
                if (!isNaN(tmpPrice_val) && !isNaN(tmpQty_val)) {
                  // let tmpCostPer = (tmpPrice != "" ? parseFloat(tmpPrice.toString().replace(/[$|,\s]/g, "")) : 0);
                  let tmpMargin = 0;
                  if(isCSV) {
                    // tmpCostPer = (typeof val.NetPrice != "undefined" ? val.NetPrice : 0);
                    tmpMargin = parseFloat((tmpPrice - tmpCostPer).toFixed(comInstance.$decimalsView));
                    profitTotal += tmpMargin * tmpQty_val;
  
                    // auto toggle config if cost = price = 0
                    if(tmpIncluded && (tmpCostPer == 0 && tmpPrice_val == 0)) {
                      tmpIsConfig = 1;
                    }

                    // template used "Is Config" column, just follow what returned
                    if(val.useConfigColumn || false) {
                      tmpIsConfig = val.isConfig || 0;
                    }
                  }
                  let tmpCustomerPrice = tmpPrice != "" ? parseFloat(tmpPrice.toString().replace(/[$|,\s]/g, "")) : 0;
                  let tmpInd = i + 1;
  
                  // override for config sku
                  if((val.configID || "").trim() != "" && val.configID.trim() == val.SKU.trim() && (val.isIncluded || 0) == 0) {
                    tmpCostPer = 0;
                    tmpCustomerPrice = 0;
                    tmpMargin = 0;
                    tmpIsConfig = 0;
                    tmpTotal = 0;
                  }else if((val.configID || "").trim() != "" && (val.isIncluded || 0)) {
                    // base quantity
                    const tmpBase = retData.find(item => (item.SKU || "").trim() == (val.configID || "").trim());
                    if(tmpBase) {
                      tmpQty = tmpBase.Qty || 0;
                      tmpTotal = parseFloat((tmpPrice * tmpQty).toFixed(comInstance.$decimals));
                    }
                  }
  
                  items["productName_" + tmpInd] = {
                    sku: val.SKU.toString().replace(/[^a-zA-Z0-9#-]/gi, ""),
                    name: tmpName.toString(),
                    price: tmpCostPer,
                    customerPrice: tmpCustomerPrice,
                    margin: tmpMargin,
                    marginRate: comInstance.editGetMarginRate({'QMARGIN': tmpMargin, 'QPRICEREG_BK': tmpCustomerPrice}),
                    // total: tmpTotal.toFixed(comInstance.$decimals).replace(/\d(?=(\d{3})+\.)/g, "$&,"),
                    total: tmpTotal,
                    quantity: tmpQty,
                    quantityBk: tmpQty,
                    taxRate:
                      typeof val.taxRate != "undefined" && val.taxRate != ""
                        ? val.taxRate
                        : 0,
                    tax:
                      typeof val.tax != "undefined" && val.tax != ""
                        ? val.tax
                        : 0,
                    shipping:
                      typeof val.shipping != "undefined" && val.shipping != ""
                        ? val.shipping
                        : 0,
                    included: tmpIncluded,
                    priceWarn: false,
                    totalProducts: retData.length,
                    configID:
                      typeof val.configID != "undefined" ? val.configID : "",
                    orgPrice: tmpCostPer,
                    orgCustomerPrice: tmpCustomerPrice,
                    disable: false,
                    isContractFee: false,
                    includedparent : 0,
                    baseProductId : 0,
                    includeItemCount : 0,
                    ItemCategory: tmpIncluded ? (comInstance.configCategory.CATEGORYID || 0) : 0,
                    ItemCategoryName: tmpIncluded ? (comInstance.configCategory.CATEGORYNAME || "") : "",
                    isFee: false,
                    ISCONFIG: tmpIsConfig,
                    ItemPLID: 0,
                    ItemPLName: '',
                    buildHeader: val.header || ""
                  };
                  // comInstance.totalCal("productName_" + comInstance.length);
                  var tax =
                    typeof val.tax != "undefined" && val.tax != "" ? val.tax : 0;
                  var shipping =
                    typeof val.shipping != "undefined" && val.shipping != ""
                      ? val.shipping
                      : 0;
  
                  // if (tmpIncluded != 1) {
                    totalAllItem =
                      totalAllItem + parseFloat((tmpPrice * val.Qty).toString());
                  // }
                  totalCost += parseFloat((tmpCostPer * tmpQty).toFixed(comInstance.$decimalsView));
  
                  //apply global tax for line item
                  if(items["productName_" + tmpInd].taxRate == 0 && !usedCustomTax) {
                    let tmpTaxRate = comInstance.getTaxRateBySource();
                    items["productName_" + tmpInd].taxRate = tmpTaxRate;
                    items["productName_" + tmpInd].tax = parseFloat(((tmpTotal * tmpTaxRate) / 100).toFixed(comInstance.$decimalsView));
                    tax = items["productName_" + tmpInd].tax;
                  }
                  qTax = qTax + parseFloat(tax);
                  qShip = qShip + parseFloat(shipping);
                }
              });
  
              //more an empty row at the end
              var indArr: any = [];
              var maxInd = 0;
              $.each(items, function(i, val) {
                indArr.push(parseInt(i.replace("productName_", "")));
              });
              if (indArr.length) {
                maxInd = Math.max.apply(Math, indArr);
              }
              items["productName_" + (maxInd + 1)] = {
                sku: "",
                name: "",
                price: 0,
                customerPrice: 0,
                margin: 0,
                marginRate: 0,
                total: 0.0,
                quantity: 0,
                taxRate: comInstance.getTaxRateBySource(),
                tax: 0,
                shipping: 0,
                included: 0,
                priceWarn: false,
                configID: "",
                orgPrice: 0.0,
                orgCustomerPrice: 0.0,
                disable: false,
                isContractFee: false,
                includedparent : 0,
                baseProductId : 0,
                includeItemCount : 0,
                ItemCategory: 0,
                ItemCategoryName: "",
                isFee: false,
                ItemPLID: 0,
                ItemPLName: '',
                buildHeader: ''
              };

              comInstance.itemNames = items;
              comInstance.length = Object.keys(items).length;
            }

            //fill values from returned header
            if(typeof response.data.HEADER != "undefined") {
              let header = response.data.HEADER;
              if(typeof header.QuoteName != "undefined") {
                comInstance.quoteName = header.QuoteName;
              }

              comInstance.BDnumber = (typeof header.BDnumber != "undefined" ? header.BDnumber : "");
              comInstance.ContractName = (typeof header.ContractName != "undefined" ? header.ContractName : "");
              comInstance.Created = (typeof header.Created != "undefined" ? header.Created : "");
              comInstance.Expires = (typeof header.Expires != "undefined" ? header.Expires : "");
              comInstance.QuoteNumber = (typeof header.QuoteNumber != "undefined" ? header.QuoteNumber : "");
              comInstance.ContractNumber = (typeof header.ContractNumber != "undefined" ? header.ContractNumber : "");
            }

            // comInstance.addRow(true);
            if (response.data.EstimatedTax) {
              qTax = parseFloat(response.data.EstimatedTax);
              comInstance.USECUSTOMTAX = 1; //uploaded a quote file using custom tax
            }

            // recalculate order margin
            comInstance.reCalculateGlobalMargin();

            comInstance.profit =
              "$" +
              parseFloat(profitTotal.toString())
                .toFixed(comInstance.$decimalsView)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,");

            // profit for direct quote
            if (comInstance.isDirect() && comInstance.estPercent) {
              comInstance.profit = dollarFormat(((totalAllItem * comInstance.estPercent) / 100));
            }

            comInstance.totalVarCost = parseFloat(totalCost.toFixed(comInstance.$decimalsView));
            comInstance.itemTotal =
              "$" +
              parseFloat(totalAllItem.toString())
                .toFixed(comInstance.$decimalsView)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,");
            comInstance.totalShipping =
              "$" +
              parseFloat(qShip.toString())
                .toFixed(comInstance.$decimalsView)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,");
            comInstance.qShip = qShip;
            comInstance.totalTax =
              "$" +
              parseFloat(qTax.toString())
                .toFixed(comInstance.$decimalsView)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,");
            comInstance.qTax = qTax;
            comInstance.grandTotal =
              "$" +
              parseFloat((totalAllItem + qShip + qTax).toString())
                .toFixed(comInstance.$decimalsView)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,");
            $("#quoteFileUpload").val("");

            //switch to 'extended' view
            comInstance.currentComponent = comInstance.$route.params.qID ? comInstance.currentComponent_BK : "Simplified";
            // comInstance.setIncludedItemPrice();

            // check category
            let skus: string[] = [];
            for(const i in items) {
              if(items[i].sku.trim() != "") {
                skus.push(items[i].sku.trim());
              }
            }
            skus = [...new Set(skus)];
            if(skus.length) {
              const responseSKU = await axios.post(dataURL + "?ReturnType=JSON", {
                controller: "Quotes",
                FunctionName: "quoteLineItemList",
                search: skus,
                source: comInstance.sourceID,
                checkCategory: true
              });
              if(responseSKU.data.STATUS == 1) {
                const lineItems = responseSKU.data.LINEITEMS || [];
                let index = -1;
                for(const i in comInstance.itemNames) {
                  index++;
                  const item = comInstance.itemNames[i];
                  // if(item.ISCONFIG || 0) continue;
                  const inList = lineItems.find(t => t.PRODUCTSKU == item.sku);
                  if(inList) {
                    item.ItemPLID = inList.PRODUCTLINEID || 0;
                    item.ItemPLName = inList.PRODUCTLINENAME || "";
                    if(item.ISCONFIG || 0) continue;

                    if((inList.CATEGORYNAME || "") == "Config" && index == 0) {
                      // do nothing, if detect sku is a config sku but the line is top of list
                    }else {
                      item.ItemCategory = inList.DEFAULTCATEGORYID || 0;
                      item.ItemCategoryName = inList.CATEGORYNAME || "";
                    }
                  }
                }
              }
            }
          } else if (response.data.ERROR) {
            $("#errorMsgLine").html(response.data.STATUSMESSAGE);
          } else {
            if (response.data.STATUS == 0) {
              var downloadLink =
                "<a href='https://var360.s3.amazonaws.com/spreadsheets/Indirect%20Template.csv' style='color:blue; cursor: pointer' target='_blank'>here</a>";
              var messageError = response.data.STATUSMESSAGE;
              messageError +=
                ". If don't have standard file, please download sample template " +
                downloadLink;
              $("#errorMsgLine").html(messageError);
              $("#errorMsgLine").attr("style", "position: relative;");
            } else {
              $("#errorMsgLine").html(response.data.STATUSMESSAGE);
            }
          }

          // eslint-disable-next-line require-atomic-updates
          comInstance.uploading = false;
          // await comInstance.$nextTick();
          // await wait(100);
          comInstance.$nextTick().then(() => {
            comInstance.checkExpandedItems("upload");
            comInstance.toggleSelectedContractNumber(+comInstance.orderContractsID.join(), comInstance.selectedOrderContracts , true);
          });
        })
        .catch(function(error) {
          $("#errorMsgLine").html(error);
          // comInstance.loading = false;
          // eslint-disable-next-line require-atomic-updates
          comInstance.uploading = "error";
        });
    }
  }
  quoteUpdate(quote) {
    $("input.initial.error.number").removeClass("initial");
    this.$validator.validateAll().then(async result => {
      var resultLineItem = true;
      //make sure an account is selected
      // if (this.aID == "" && this.customer != "") {
      //   $("#customer")
      //     .parent()
      //     .find(".errorMsg")
      //     .html("Please select a customer from suggested list.");
      //   result = false;
      // }
      var emptyRow = 0;
      var key = Object.keys(this.itemNames)[
        Object.keys(this.itemNames).length - 1
      ].split("_")[1];
      for (var j = 0; j < parseInt(key); j++) {
        if (this.itemNames[`productName_${j + 1}`] != undefined) {
          if (
            !this.itemNames[`productName_${j + 1}`].customerPrice &&
            !this.itemNames[`productName_${j + 1}`].name &&
            !this.itemNames[`productName_${j + 1}`].price &&
            !parseInt(this.itemNames[`productName_${j + 1}`].quantity) &&
            !this.itemNames[`productName_${j + 1}`].sku
          ) {
            emptyRow = j + 1;
          }
          for (var i = 0; i < this.$validator.errors.items.length; i++) {
            if (
              this.$validator.errors.items[i].field == `sku_productName_${emptyRow}` ||
              this.$validator.errors.items[i].field == `name_productName_${emptyRow}` ||
              this.$validator.errors.items[i].field == `cost_productName_${emptyRow}` ||
              this.$validator.errors.items[i].field ==
                `cust_cost_productName_${emptyRow}` ||
              this.$validator.errors.items[i].field == `quantity_productName_${emptyRow}`
            ) {
              this.$validator.errors.items.splice(i, 1);
              continue;
            }
          }
          for (var k = 0; k < this.$validator.errors.items.length; k++) {
            if (
              this.itemNames[`productName_${j + 1}`].customerPrice > 0 ||
              this.itemNames[`productName_${j + 1}`].price > 0
            ) {
              if (
                this.$validator.errors.items[k].field != undefined &&
                this.$validator.errors.items[k].field == `cost_productName_${j + 1}`
              ) {
                this.$validator.errors.items.splice(k, 1);
              }
              if (
                this.$validator.errors.items[k].field != undefined &&
                this.$validator.errors.items[k].field == `cust_cost_productName_${j + 1}`
              ) {
                this.$validator.errors.items.splice(k, 1);
              }
            }
            // if(this.errors.items[i].field != undefined && this.errors.items[i].field == `cost_${this.index}` ){
            // this.errors.items.splice(i, 1);
          }
        }
      }
      if (!this.$validator.errors.items.length) {
        result = true;
      }
      const [quoteStatus] = await Promise.all([
        this.$refs.quoteView.childValidation()
      ]);
      var keyCount = 0;
      if (Object.keys(this.itemNames).length >= 1) {
        var lastIndex = parseInt(
          Object.keys(this.itemNames)[
            Object.keys(this.itemNames).length - 1
          ].split("_")[1]
        );
        for (var j2 = 0; j2 < lastIndex; j2++) {
          if (typeof this.itemNames[`productName_${j2 + 1}`] != "undefined") {
            if (
              (this.itemNames[`productName_${j2 + 1}`].customerPrice ||
                this.itemNames[`productName_${j2 + 1}`].price) &&
              this.itemNames[`productName_${j2 + 1}`].name &&
              this.itemNames[`productName_${j2 + 1}`].quantity &&
              this.itemNames[`productName_${j2 + 1}`].sku
            ) {
              keyCount = keyCount + 1;
            }
          }
        }
      }
      if (keyCount == 0 || !quoteStatus) {
        result = false;
        resultLineItem = false;
      }
      // if(typeof this.sourceID == "undefined" || this.sourceID == "" || this.sourceID == 0) {
      //     $("#source").parent().find(".errorMsg").html("Please select a Source from suggested list.");
      //     result = false;
      // }
      //validate data input

      if (
        (parseFloat($("#cust_cost_productName_1").val()) < 1 &&
          $("#cost__productName_1").val() < 1) ||
        parseInt($("#quantity_productName_1").val()) < 1 ||
        $("#shipping_productName_1").val() == "" ||
        $("#tax_rate_productName_1").val() == "" ||
        $("#name_productName_1").val() == "" ||
        $("#sku_productName_1").val() == "" ||
        $("#tax_productName_1").val() == ""
      ) {
        result = false;
        resultLineItem = false;
      }
      if (!resultLineItem) {
        $("#errorMsgLine").html(
          "Let's enter enough info about SKU, Product, Cost and Quantity, ..."
        );
      } else {
        $("#errorMsgLine").html("");
      }
      if (!this.checkHTML()) {
        result = false;
      }

      //qTax, qShip is custom input
      const qTax = $("#qTax").val()
        ? parseFloat($("#qTax").val()).toFixed(this.$decimals)
        : 0.0;
      const qShip = $("#qShip").val()
        ? parseFloat($("#qShip").val()).toFixed(this.$decimals)
        : 0.0;
      this.qTax = qTax;
      this.qShip = qShip;

      //remove html for global tag
      // if(this.htmlCheck(this.globalTag)) {
      //   this.globalTag = htmlParse(this.globalTag);
      // }

      //Do not allow saving with 0 quantity
      var hasZeroQty = false;
      var items = this.itemNames;
      for(let i in items) {
        if(items[i].sku != "" && (items[i].price != 0 || items[i].customerPrice != 0) && items[i].quantity == 0 && !items[i].included) {
          hasZeroQty = true;
          result = false;
        }
      }
      if(hasZeroQty) {
        $("#errorMsgLine").html("Please enter a quantity to save");
      }

      // if(this.isIndirect()) {//recalculate
      //   this.getTotals();
      // }

      if (this.USECUSTOMTAX == 1 && result && !this.allowEditQuote) {
        this.ConfirmCustomTax = true;
        result = false;
      }

      if (result) {
        var comInstance = this;
        var today = new Date();
        var date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();

        var dataObj: any = {
          controller: "Quotes",
          FunctionName: "Add",
          update: true,
          quoteID: quote,
          qDateTime: date,
          aID: comInstance.aID,
          accountID: comInstance.accountID,
          qlines: comInstance.length.toString(),
          qDescription: comInstance.quoteName,
          totalPrice: comInstance.grandTotal,
          isRequestedQuote: comInstance.qOpen == 6 ? true : false,
          selectedGlobalTagsID: this.selectedGlobalTagsID.join(","),
          qQuoteID: comInstance.qQuoteID,
          quoteBigDealNumber: comInstance.quoteBigDealNumber,
          quoteRevisionNumber: comInstance.quoteRevisionNumber,
          opportunityID: comInstance.opportunityID,
          includeVarCost: comInstance.includeVarCost,
          opportunityPrevID: comInstance.opportunityPrevID
        };
        const sourceIDs: any = [];
        let totalContractFee = 0;
        let itemOrder = 0;
        $.each(comInstance.itemNames, function(i, val) {
          var ind = i.replace("productName_", "");
          if (
            typeof comInstance.itemNames["productName_" + ind] != "undefined"
          ) {
            dataObj[`ItemDescription${ind}`] =
              comInstance.itemNames[`productName_${ind}`].name;
            dataObj[`ItemNumber${ind}`] =
              comInstance.itemNames[`productName_${ind}`].sku;
            dataObj[`ItemQuantity${ind}`] =
              comInstance.itemNames[`productName_${ind}`].quantity > 0
                ? comInstance.itemNames[
                    `productName_${ind}`
                  ].quantity.toString()
                : "";
            dataObj[`RegPrice${ind}`] =
              comInstance.itemNames[`productName_${ind}`].price != 0
                ? parseFloat(comInstance.itemNames[`productName_${ind}`].price).toFixed(comInstance.expandDecimalPrecision)
                : "";
            dataObj[`ItemTotal${ind}`] =
              comInstance.itemNames[`productName_${ind}`].total > 0
                ? comInstance.itemNames[`productName_${ind}`].total.toString()
                : "";
            dataObj[`ItemCustomerPrice${ind}`] =
              comInstance.itemNames[`productName_${ind}`].customerPrice != 0
                ? parseFloat(comInstance.itemNames[`productName_${ind}`].customerPrice).toFixed(comInstance.expandDecimalPrecision)
                : "";
            dataObj[`ItemMargin${ind}`] = comInstance.itemNames[`productName_${ind}`].margin.toString();
            dataObj["ItemMarginRate" + ind] = (typeof val.marginRate != "undefined" ? val.marginRate : 0);
            dataObj[`ItemShipping${ind}`] =
              comInstance.itemNames[`productName_${ind}`].shipping > 0
                ? comInstance.itemNames[
                    `productName_${ind}`
                  ].shipping.toString()
                : "";
            dataObj[`ItemTaxRate${ind}`] =
              comInstance.itemNames[`productName_${ind}`].taxRate > 0
                ? comInstance.itemNames[`productName_${ind}`].taxRate.toString()
                : "";
            dataObj[`ItemTax${ind}`] =
              comInstance.itemNames[`productName_${ind}`].tax > 0
                ? comInstance.itemNames[`productName_${ind}`].tax.toString()
                : "";
            dataObj[`ItemIncluded${ind}`] = comInstance.itemNames[
              `productName_${ind}`
            ].included
              ? 1
              : 0;
            dataObj[`ItemNoTax${ind}`] = comInstance.itemNames[
              `productName_${ind}`
            ].noTax
              ? 1
              : 0;

            dataObj[`ItemConfigID${ind}`] =
              comInstance.itemNames[`productName_${ind}`].configID;
            dataObj[`Note${ind}`] =
              typeof val.lineNotes != "undefined" ? val.lineNotes : "";
            dataObj[`ItemCategory${ind}`] =
              typeof val.ItemCategory != "undefined" ? val.ItemCategory : 0;
            dataObj[`ItemPLID${ind}`] = typeof val.ItemPLID != "undefined" ? val.ItemPLID : 0;
            dataObj[`productLine${ind}`] = val.ItemPLName || "";
            dataObj[`buildHeader${ind}`] = typeof val.buildHeader != "undefined" ? val.buildHeader : "";
            dataObj[`ItemQuoteLiId${ind}`] = val.quoteLiId || 0;
            dataObj[`ItemParentLiId${ind}`] = val.parentLiId || 0;
            dataObj[`sourceID${ind}`] = val.dynamicInfo ? (val.dynamicInfo.selectedDistyID || 0) : 0;

            if(dataObj[`sourceID${ind}`] > 0) {
              sourceIDs.push(dataObj[`sourceID${ind}`]);
            }
            dataObj[`ItemIsConfig${ind}`] = val.ISCONFIG || 0;
            dataObj[`ItemSubQuoteId${ind}`] = val.subQuoteId || 0;

            // specify item order
            itemOrder++;
            dataObj[`ItemOrder${ind}`] = itemOrder;

            // calculate total contract fee
            if(val.isContractFee || false) {
              totalContractFee += val.price;
            }
          }
        });

        dataObj["totalContractFee"] = totalContractFee;

        // const qTax = $("#qTax").val()
        //   ? parseFloat($("#qTax").val()).toFixed(this.$decimals)
        //   : 0.0;
        // const qShip = $("#qShip").val()
        //   ? parseFloat($("#qShip").val()).toFixed(this.$decimals)
        //   : 0.0;
        //qTax, qShip is custom input
        dataObj["qTax"] = this.qTax;
        dataObj["qShip"] = this.qShip;
        dataObj["source"] = this.sourceID;
        dataObj["EstPercent"] = this.isIndirect() ? 0 : this.estPercent;
        dataObj["currentComponent"] = this.currentComponent;
        dataObj["globalMargin"] = this.isIndirect() ? this.globalMargin : 0;
        dataObj["customSourceName"] = this.sourceName;
        //(this.confirmedGlobalMargin ? this.globalMargin: 0);
        dataObj["customData"] = JSON.stringify(this.fullCustomData);
        dataObj["taxRate"] = this.currentTaxRate;
        dataObj["usedCustomTax"] = !!this.USECUSTOMTAX;
        dataObj["expandDecimal"] = this.expandDecimal;
        // Department Info
        const departmentDetails = {
          ID: this.selectedDepartment.length ? this.selectedDepartment.join() : 0,
          NAME: this.selectedDepartmentName
        }
        dataObj["departmentDetails"] = departmentDetails;
        let DefaultDeptID = (this.selectedDepartment.length ? this.selectedDepartment.join() : 0);
        if (DefaultDeptID == 0) {
          // if use a one time dept for this order
          dataObj["onetimeDepartment"] = this.onetimeDepartment;
        }
        //Contract Number Info
        dataObj["selectedContractNumberValue"] = this.selectedOrderContracts;
        dataObj["contractNumberFieldId"] = this.orderContractNumber[0].CUSTOMFIELDID;
        // Include price roll up
        dataObj["includedItem"] = this.includedItem;
        dataObj["includedPrice"] = this.includedPrice;
        // business line
        dataObj["businessLineId"] = this.selectedBusinessLine[0] || 0;

        // shipping/billing address
        dataObj["DefaultShippingID"] = this.selectedAddress.length ? this.selectedAddress.join(",") : 0;
        dataObj["DefaultBillingID"] = this.selectedBillingAddress.length ? this.selectedBillingAddress.join(",") : 0;
        if(this.selectedAddress.length && dataObj["DefaultShippingID"] == 0) {
          // if use a one time shipping address
          dataObj["onetimeAddress"] = this.onetimeAddress;
        }
        if (this.selectedBillingAddress.length && dataObj["DefaultBillingID"] == 0) {
          // if use a one time billing address
          dataObj["onetimeAddressBilling"] = this.onetimeAddressBilling;
        }

        // auto update source to a distributor in case dynamic
        if(dataObj["source"] == 10 && sourceIDs.length) {
          const usedMultiSource = sourceIDs.find((s: any) => s != sourceIDs[0]);
          if(!usedMultiSource) {
            dataObj["source"] = sourceIDs[0];
          }
        }

        $("#updateQuoteBtn").prop("disabled", true);

        if (!this.allowEditQuote) {
          comInstance.saving = true;
        }

        var processedFileUpload = getRouteData(dataObj)
          .then(function(response, statusText, jqXHR) {
            $("#updateQuoteBtn").prop("disabled", false);

            if (typeof response.data.ERROR != "undefined") {
              // console.log("quoteUpdate", response.data.ERROR);
            } else {
              if (response.data.STATUS) {
                comInstance.taxLoading = false;
                comInstance.ConfirmCustomTax = false;
                comInstance.allowEditQuote = false;
                comInstance.backToDetails();
              } 
            }

            comInstance.saving = false;
          })
          .catch(function(error) {
            comInstance.saving = "error";
          });
      }
    });
  }
  removeItem(index) {
    const removedIndex = parseInt(index.split("_")[1]);
    const item = this.itemNames[index];
    this.$delete(this.itemNames, index);
    if (this.length > 0) {
      //update length
      this.length -= 1;
    }
    if (this.length == 1) {
      $(".deleteIcon").css("display", "none");
    }

    // if remove a parent, remove config items inside too
    if(!item.included) {
      for(let key in this.itemNames) {
        let tmpIndex = parseInt(key.split("_")[1]);
        if(tmpIndex > removedIndex) {
          if(!this.itemNames[key].included) {
            break;
          }
          
          if(this.itemNames[key].ISCONFIG) {
            this.$delete(this.itemNames, key);
          }else {
            // exclude grouped item
            this.itemNames[key].included = 0;
          }
        }
      }
    }
    this.sortItems();

    if (item.included === 1) {
      const includedItems = findOtherIncludedItems(index, this.itemNames);
      if (includedItems.length === 0) {
        const parent = findParent(index, this.itemNames);
        if (parent) {
          parent.priceWarn = false;
        }
      }
    }
    
    // Set included price based on after removing line item
    // if(this.includedPrice) {
    //   this.setIncludedItemPrice(false);
    // }

    if(this.isMultiEndUsers) {
      if (item.subQuoteId || 0) {
        let cnt = 0;
        for (const i in this.itemNames) {
          if ((this.itemNames[i].subQuoteId || 0) == item.subQuoteId) {
            cnt++;
          }
        }
        if (cnt == 0) {
          // auto add a new line item for this subset
          this.addRow(item.subQuoteId);
        }
      }
    }

    this.getTotals();
    if(!this.confirmedGlobalMargin) {
      this.reCalculateGlobalMargin();
    }
  }

  includeItem(index) {
    const item = this.itemNames[index];
    // var lineItemInd = index;

    //Avoid including the contract fee
    if((typeof item.isContractFee != "undefined" && item.isContractFee) || 
        (typeof item.isFee != "undefined" && item.isFee)) {
      return;
    }

    const configCategoryId = this.configCategory.CATEGORYID || 0;
    // toggle included
    item.included = (item.included || 0) == 0 ? 1 : 0;

    // process to include/exclude to base
    if(!item.included) {
      // $("#included_" + index)
      //   .removeClass("checkbox_checked")
      //   .addClass("checkbox_unchecked");
      if(item.ItemCategory == configCategoryId || (item.ISCONFIG || 0)) {
        item.ISCONFIG = 0;
        item.savedAsConfig = 0;
        item.ItemCategory = 0;
        item.ItemCategoryName = "";
        item.ItemCategoryBk = 0;
        item.ItemCategoryNameBk = "";
      }
    }else {
      // check if item is config
      if(configCategoryId > 0 && (item.ItemCategory || 0) == configCategoryId) {
        item.ISCONFIG = 1;

        // if item is config, follow base taxRate
        const parent = findParent(index, this.itemNames);
        if(parent) {
          // item.quantity = item.quantityBk = parent.quantity || 0;
          item.taxRate = parent.taxRate || 0;
          item.total = (item.customerPrice || 0) * (item.quantity || 0);
        }
      }
    }

    // if ($("#included_" + index).hasClass("checkbox_checked")) {
    if(!item.included) {
      // uncheck item
      // $("#included_" + index)
      //   .closest(".list-item")
      //   .find("input")
      //   .each(function(this:Window,index, el) {
      //     //in case textbox is "tax amount" or "tax rate", just enable if noTax is no using
      //     if ($(el).hasClass("taxRate") || $(el).hasClass("taxAmount")) {
      //       if ($("#noTax_" + lineItemInd).hasClass("checkbox_unchecked")) {
      //         $(this)
      //           .removeClass("disabled")
      //           .prop("disabled", false);
      //       }
      //     } else if (!$(el).hasClass("price_margin")) {
      //       //margin textbox always disable
      //       $(this)
      //         .removeClass("disabled")
      //         .prop("disabled", false);
      //     }
      //   });

      // $("#included_" + index)
      //   .removeClass("checkbox_checked")
      //   .addClass("checkbox_unchecked");
      // item.included = 0;
      // item.quantity = item.quantityBk || 0;
      // if(!this.includedPrice) {
        item.price = item.orgPrice;
        item.customerPrice = item.orgCustomerPrice;
        item.disable = false;
        if(item.marginBk) {
          item.margin = item.marginBk;
        }
        if(item.marginRateBk) {
          item.marginRate = item.marginRateBk;
        }
      // }

      // if(item.ItemCategoryBk) {
      //   item.ItemCategory = item.ItemCategoryBk;
      //   item.ItemCategoryName = item.ItemCategoryNameBk || "";
      // }
      // if(item.ItemCategory == configCategoryId || (item.ISCONFIG || 0)) {
      //   item.ISCONFIG = 0;
      //   item.ItemCategory = 0;
      //   item.ItemCategoryName = "";
      //   item.ItemCategoryBk = 0;
      //   item.ItemCategoryNameBk = "";
      // }
    } else {
      // $("#included_" + index)
      //   .closest(".list-item")
      //   .find("input")
      //   .each(function(this:Window,index, el) {
      //     if (!$(this).hasClass("skuInput") && !$(this).hasClass("nameInput")) {
      //       $(this)
      //         .addClass("disabled")
      //         .prop("disabled", true);
      //     }
      //   });

      // $("#included_" + index)
      //   .removeClass("checkbox_unchecked")
      //   .addClass("checkbox_checked");

      // check this item
      item.quantityBk = item.quantity;
      item.marginBk = item.margin;
      item.marginRateBk = item.marginRate;
      // item.included = 1;
      // item.quantity = 0;
      // item.taxRate  = 0;

      // if(!this.includedPrice) {
      //   // disable line item if not Price Roll-up for base item
      //   item.price = 0;
      //   item.customerPrice = 0;
      //   item.quantity = 0;
      //   item.total = 0;
      //   item.disable = true;
      //   item.margin = 0;
      //   item.marginRate = 0;
      // }

      // set config category for this item
      // if((this.configCategory.CATEGORYID || 0) > 0) {
      //   item.ItemCategoryBk = item.ItemCategory;
      //   item.ItemCategoryNameBk = item.ItemCategoryName;
      //   item.ItemCategory = this.configCategory.CATEGORYID;
      //   item.ItemCategoryName = this.configCategory.CATEGORYNAME;
      // }

      // check if item is config
      // if(configCategoryId > 0 && (item.ItemCategory || 0) == configCategoryId) {
      //   item.ISCONFIG = 1;
      // }
    }

    // for (const lineItem in this.itemNames) {
    //   var lineItemFields = this.itemNames[lineItem];
    //   if(!lineItemFields.included) {
    //     if(this.includedItem) {
    //       this.includedItem = false;
    //     }
    //     continue;
    //   }
    //   this.includedItem = true;
    //   break;
    // }

    this.includedItem = false;
    for(const i in this.itemNames) {
      if(this.itemNames[i].included) {
        this.includedItem = true;
        break;
      }
    }

    if (item.included === 0) {
      const includedItems = findOtherIncludedItems(index, this.itemNames);
      const parent = findParent(index, this.itemNames);
      if (parent && includedItems.length === 0) {
        parent.priceWarn = false;
        parent.disable = false;
        if(!this.confirmedGlobalMargin) {
          parent.price = parent.orgPrice;
          parent.customerPrice = parent.orgCustomerPrice;
          parent.margin = parseFloat((parent.customerPrice - parent.price).toFixed(this.$decimals));
          this.reCalculateMargin(parent, "rate");
        }
      }
    }

    // Set included price based on switch
    // if(this.includedPrice) {
    //   this.setIncludedItemPrice(false);
    // }

    // if(!this.includedItem) {
    //   this.includedPrice = false;
    // }

    this.getTotals();
    if(!this.confirmedGlobalMargin) {
      this.reCalculateGlobalMargin();
    }
    this.$refs.quoteView.$forceUpdate();
    this.$forceUpdate();
  }

  noTax(index, checkbox) {
    if ($(checkbox).hasClass("checkbox_checked")) {
      //unchecked
      $(checkbox)
        .removeClass("checkbox_checked")
        .addClass("checkbox_unchecked");
      this.itemNames[index].noTax = false;

      //enalbe related taxRate/tax box
      if ($("#included_" + index).hasClass("checkbox_unchecked")) {
        $("#tax_rate_" + index + ", #tax_" + index)
          .prop("disabled", false)
          .removeClass("disabled");
      }
    } else if ($(checkbox).hasClass("checkbox_unchecked")) {
      //checked
      $(checkbox)
        .removeClass("checkbox_unchecked")
        .addClass("checkbox_checked");
      this.itemNames[index].noTax = true;

      //disable related taxRate/tax box
      $("#tax_rate_" + index + ", #tax_" + index)
        .prop("disabled", true)
        .addClass("disabled");
    }

    this.getTotals();
    this.$refs.quoteView.$forceUpdate();
  }
  taxSwitch() {
    if (!$(".tax.editable-new-quote").hasClass("currencyInput")) {
      $(".tax.editable-new-quote").addClass("currencyInput");
      $(
        ".tax.editable-new-quote .left, .tax.editable-new-quote .taxAmount"
      ).removeClass("displayNone");
      $(".tax.editable-new-quote .taxRate").addClass("displayNone");
      this.taxRateOn = 1;
    } else {
      $(".tax.editable-new-quote").removeClass("currencyInput");
      $(
        ".tax.editable-new-quote .left, .tax.editable-new-quote .taxAmount"
      ).addClass("displayNone");
      $(".tax.editable-new-quote .taxRate").removeClass("displayNone");
      this.taxRateOn = 0;
    }
    this.getTotals()
  }
  usedCustomTax() {
    //return from api, or determin by change textbox custom tax
    if (this.USECUSTOMTAX != 0) {
      return this.USECUSTOMTAX; //1 or 0
    }

    return 0;
  }
  async SKULookup(index, sku, item) {
    var $this = this;
    var htmlCount = 0;
    // var regex = /[^a-zA-Z0-9#?]/gi;
    // New update from amanda
    var regex = /[^a-zA-Z0-9#-]/gi;
    if (sku != "" && typeof sku != "undefined") {
      if ($(".name input[type=checkbox]").is(":checked")) {
        $(
          ".list-item[data-index=" + index + "] .new-quote-name-input .loader"
        ).removeClass("hidden");
      }
      if ($(".price input[type=checkbox]").is(":checked")) {
        $(
          ".list-item[data-index=" + index + "] .new-quote-cost-input .loader"
        ).removeClass("hidden");
      }
      if (this.htmlCheck(sku)) {
        item.sku = htmlParse(sku);
        htmlCount++;
      }
      if (htmlCount != 0) {
        notifier.alert("HTML content found. This content has been removed!");
      }
      item.sku = item.sku.replace(regex, "");
      if (regex.test(item.sku) == false) {
        this.$set(
          this,
          "sku_productName_" + index,
          item.sku.replace(regex, "")
        );
      }
      var dataObj = {
        controller: "Quotes",
        FunctionName: "quoteLineItemList",
        search: sku,
        source: this.sourceID,
        contractNumberValue: this.selectedOrderContracts,
        contractNumberId : this.orderContractsID.join()
      };

      if(this.autoName == 1 || this.autoCost == 1) {
        await getRouteData(dataObj).then(function(response, statusText, jqXHR) {
          if ("LINEITEMS" in response.data && response.data.LINEITEMS[0]) {
            var productInfo = response.data.LINEITEMS[0];
            // setTimeout(function() {
              // case dynamic source
              // if($this.isDynamic()) {
                item.dynamicInfo = productInfo;
              // }

              if ($(".name input[type=checkbox]").is(":checked")) {
                $(
                  ".list-item[data-index=" +
                    index +
                    "] .new-quote-name-input .loader"
                ).addClass("hidden");
                
                if(response.data.LINEITEMS[0].PRODUCTNAME != "") {
                  item.name = response.data.LINEITEMS[0].PRODUCTNAME;
                }

                if (item.name.length > 255) {
                  $("#errorName").text(
                    "Item name should not be more than 255 characters"
                  );
                } else {
                  $("#errorName").text("");
                }

                const categoryId = productInfo.DEFAULTCATEGORYID || 0;
                const categoryName = productInfo.CATEGORYNAME || "";
                const ISCONFIG = productInfo.ISCONFIG || 0;
                if (ISCONFIG && index != 'productName_1') {
                  item.ISCONFIG = ISCONFIG;
                  item.included = 1;
                }
                if(categoryId) {
                  if($this.idxOf(index) == 0 && categoryName == "Config") {
                    // do nothing, if detect sku is a config sku but the line is top of list
                  }else {
                    item.ItemCategory = categoryId;
                    item.ItemCategoryName = categoryName;
                    $this.$refs.quoteView.$forceUpdate();
                  }
                }else {
                  item.ItemCategory = 0;
                  item.ItemCategoryName = "";
                  $this.$refs.quoteView.$forceUpdate();
                  $this.productCatTTVisibleIndex = index; //show product category tooltip
                }
              }
              if (typeof productInfo.ContractPrice != "undefined") {
                item["contractPrice"] = productInfo.ContractPrice;
              }
              if (typeof productInfo.ContractPriceDate != "undefined") {
                item["contractPriceDate"] = productInfo.ContractPriceDate;
              }
              if (typeof productInfo.PRODUCTLINEID != "undefined") {
                item["ItemPLID"] = productInfo.PRODUCTLINEID;
              }
              if (typeof productInfo.PRODUCTLINENAME != "undefined") {
                item["ItemPLName"] = productInfo.PRODUCTLINENAME;
              }
              if ($(".price input[type=checkbox]").is(":checked")) {
                $(
                  ".list-item[data-index=" +
                    index +
                    "] .new-quote-cost-input .loader"
                ).addClass("hidden");
                if(typeof productInfo.Disty != "undefined") {
                  item.Disty = productInfo.Disty;

                  if(typeof productInfo.DistyPrice != "undefined") {
                    item.DistyPrice = productInfo.DistyPrice;
                    item.DistyPrice_Format = productInfo.DistyPrice_Format;
                  }
                  if(typeof productInfo.DistyAvailability != "undefined") {
                    item.DistyAvailability = productInfo.DistyAvailability;
                    item.DistyAvailability_Format = productInfo.DistyAvailability_Format;
                  }
                }
                $this.checkDistyError();

                let productPrice = productInfo.PRODUCTPRICE != 0 ? productInfo.PRODUCTPRICE : 0;
                if($this.isDynamic()) {
                  // get lowest disty price
                  productPrice = $this.getLowestDistyPrice(productInfo, item);
                }
                if(productPrice != 0) {
                  item.price = productPrice;

                  // recalculate customer price in case had a margin rate on this line
                  if($this.isIndirect() && $this.confirmedGlobalMargin && item.marginRate != 0) {
                    // item.margin = parseFloat((item.price * item.marginRate / 100).toFixed($this.$decimals));
                    // item.customerPrice = parseFloat((item.margin + item.price).toFixed($this.$decimals));
                    item.customerPrice = $this.getCustomerPriceByMarinRate(item);
                    $this.reCalculateMargin(item, "amount");
                  }

                  //force event when changing price per
                  // $this.marginCalc1(index);

                  // changed cost
                  $this.calcWithChangeCostPrice(index);
                }
              }

              $this.updateDistyTotal();
            // }, 300);
          } else {
            $(
              ".list-item[data-index=" +
                index +
                "] .new-quote-name-input .loader, .list-item[data-index=" +
                index +
                "] .new-quote-cost-input .loader"
            ).addClass("hidden");

            $this.productCatTTVisibleIndex = index; //show product category tooltip
          }
        })
        .catch(function(error) {
          // console.log(error);
        });
      }

      // if had a global margin, no suggest a history price
      if(!(this.isIndirect() && this.confirmedGlobalMargin) && this.aID > 0 && this.sourceID > 0) {
        // get history price this case
        try {
          const response = await axios.post(dataURL + "?ReturnType=JSON", {
            controller: "Quotes",
            FunctionName: "getHistoryPrice",
            sku: sku,
            source: this.sourceID,
            aID: this.aID
          });

          if(response.data.STATUS == 1) {
            var hasHistoryPrice = false;
            if(item.price == 0 && typeof response.data.avgCost != "undefined") {
              // eslint-disable-next-line require-atomic-updates
              item.price = parseFloat(response.data.avgCost.toFixed(this.$decimalsView));
              hasHistoryPrice = true;
            }
            if(item.marginRate == 0 && typeof response.data.avgMarginRate != "undefined" 
              && this.currentComponent != "Direct" && this.estPercent == 0) {

              // eslint-disable-next-line require-atomic-updates
              item.marginRate = parseFloat(response.data.avgMarginRate.toFixed(this.$decimals));
              hasHistoryPrice = true;
            }
            if(hasHistoryPrice) {
              this.marginRate(index);
              // eslint-disable-next-line require-atomic-updates
              item.customerPrice_bk = item.customerPrice;
              if(this.currentComponent == "Direct") {
                // eslint-disable-next-line require-atomic-updates
                item.customerPrice = item.price;
                this.marginCalc1(index);
              }
            }
          }
        }catch (err) {
          // console.log(err.message);
        }
      }
    }else {
      this.productCatTTVisibleIndex = ""; //hide product category tooltip
      item.ItemCategory = 0;
      item.ItemCategoryName = "";
    }

    // auto toggle config for "config" category
    if (
      this.idxOf(index) != 0 &&
      item.ItemCategoryName == "Config" &&
      !(item.ISCONFIG || 0)
    ) {
      this.productCatTTVisibleIndex = "";
      // eslint-disable-next-line require-atomic-updates
      item.included = 0;
      this.includeItem(index);
    }
  }

  removeHtml(index, name) {
    var htmlCount = 0;
    if (this.htmlCheck(name)) {
      this.itemNames[index].name = htmlParse(name);
      htmlCount++;
    }
    if (htmlCount != 0) {
      notifier.alert("HTML content found. This content has been removed!");
    }
  }
  notHPSource() {
    if (this.sourceID != 0 && this.sourceID != 1 && this.sourceID != 12) {
      return true;
    }
    return false;
  }
  selectingDisty(disty) {
    if(typeof disty == "undefined" || disty == "") {
      return false;
    }

    if (disty.toLowerCase() == this.source.toLowerCase()) {
      return true;
    }
    return false;
  }

  clearDistyError() {
    for(var i in this.itemNames) {
      this.itemNames[i].distyError = false;
    }
  }

  checkDistyError() {//check cost price with disty price
    for(var i in this.itemNames) {
      if(this.autoCost == 1 && this.notHPSource() && this.selectingDisty(this.itemNames[i].Disty) && typeof this.itemNames[i].DistyPrice != 'undefined' && this.itemNames[i].DistyPrice > 0) {
        if(this.itemNames[i].price == "" || this.itemNames[i].price == 0) {
          this.itemNames[i].price = this.itemNames[i].DistyPrice;
        }
      }
    }
  }

  selectedGlobalTagsID: number[] = [];
  selectedGlobalTags: object[] = [];
  toggleSelectedGlobalTags(selectedID: number, tagName) {
    const index = this.selectedGlobalTagsID.findIndex(id => id === selectedID);
    if (index === -1) {
      this.selectedGlobalTagsID.push(selectedID);
      this.selectedGlobalTags.push({
        CUSTOMFIELDOPTIONID: selectedID,
        CUSTOMFIELDOPTIONNAME: tagName
      });
      //this.saveGlobalTag();
      return;
    }

    this.selectedGlobalTagsID.splice(index, 1);
    this.$delete(this.selectedGlobalTags, index);
    //this.saveGlobalTag();
  }

  selectAllGlobalTags() {
    this.resetGlobalTags();
    this.selectedGlobalTagsID = this.globalTags.map((val: any) => val.CUSTOMFIELDOPTIONID);
    this.selectedGlobalTags = this.globalTags.map((val: any) => ({
      CUSTOMFIELDOPTIONID: val.CUSTOMFIELDOPTIONID,
      CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME
    }));
    //this.saveGlobalTag();
  }

  resetGlobalTags() {
    this.selectedGlobalTags = [];
    this.selectedGlobalTagsID = [];
    //this.saveGlobalTag();
  }

  updateGlobalTagList(type, index, tagID) {
    if (this.selectedGlobalTagsID && type == "delete") {
      this.$delete(this.selectedGlobalTagsID, index);
      this.$delete(this.selectedGlobalTags, index);
    }
    //this.saveGlobalTag();
  }

  getSKUCatTitle(item) {
    let ret = "";

    if((item.ISCONFIG || 0) || (item.ItemCategoryName || "") == "Config") {
      ret = "";
    }else if(item.ItemCategoryName || "") {
      ret = `Category: ${item.ItemCategoryName}`;
    }else {
      ret = "Category not available";
    }

    if (ret.length) {
      ret += ", ";
    }
    if (item.ItemPLName || "") {
      ret += `Product Line: ${item.ItemPLName.toUpperCase()}`;
    } else {
      ret += "Product Line not available";
    }

    return ret;
  }
  
  productCatTTVisibleIndex = "";
  async showProductCatTT(item, index, e) {
    if(item.sku == "") {
      this.productCatTTVisibleIndex = "";
      item.ItemCategory = 0;
      item.ItemCategoryName = "";
      return;
    }
    if(this.productCatTTVisibleIndex == index) {
      return;
    }

    if(e.type == "click" || (e.type == "keydown" && this.autoName === 0)) {
      this.productCatTTVisibleIndex = index;
    }
  }

  globalMarginChanged(e, forceUpdate = false) {
    if(e && e.key == "Enter") {
      e.preventDefault();
      $("li.globalMargin").find("input").addClass("displayNone");
      $("li.globalMargin").find("strong").removeClass("displayNone");
      // auto confirm global margin
      this.confirmedGlobalMargin = (this.globalMargin != this.globalMarginBK ? true : this.confirmedGlobalMargin);
    }
    //go to tax box if press tab key
    if (e && e.key == "Tab") {
      e.preventDefault();
      $("li.tax").find("strong").addClass("displayNone");
      $("li.tax").find("input").removeClass("displayNone");
      setTimeout(() => {
        $("li.tax").find("input").focus().select();
      }, 100);
    }

    if(this.globalMargin == this.globalMarginBK) {//no changed
      return;
    }
    if ((e && (e.key == "Enter" || e.key == "Tab")) || forceUpdate) {
      setTimeout(() => {
        if(this.isIndirect() && this.confirmedGlobalMargin === true) {
          for(var i in this.itemNames) {
            let item = this.itemNames[i];
            // if(item.price != 0) {
              item.marginRate = this.getMarkupFromMargin(this.globalMargin);
              // item.margin = parseFloat((item.price * item.marginRate / 100).toFixed(this.$decimals));
              // item.customerPrice = item.margin + item.price;
              item.customerPrice = this.getCustomerPriceByMarinRate(item);
              this.reCalculateMargin(item, "amount");
              item.total = parseFloat((item.customerPrice * item.quantity).toFixed(this.$decimals));
              // item.total = roundCents(item.customerPrice * item.quantity);
              //this.reCalculateMargin(item, "rate");
              item.tax = this.getLineItemTax(item);
            // }
          }
          this.$refs.quoteView.$forceUpdate();
          this.getTotals();
          this.globalMarginBK = this.globalMargin;
        }
      }, 200);
    }
  }

  globalMarginInput() {
    if(this.globalMargin > 100) {
      this.globalMargin = 100;
    }
  }

  toggleConfirmGlobalMargin(e = null) {
    this.confirmedGlobalMargin = !this.confirmedGlobalMargin;
    this.globalMarginChanged(e, true);
  }

  getConfirmedGlobalMargin(isLineItem = false) {
    if(this.isIndirect() && this.confirmedGlobalMargin) {
      if(isLineItem) {
        // return markup
        return this.getMarkupFromMargin(this.globalMargin);
      }else {
        // return order margin
        return this.globalMargin;
      }
    }

    return 0;
  }

  marginSwitch() {
    this.marginInput = (this.marginInput == "amount" ? "rate" : "amount");
  }

  marginViewChange(viewType) {
    this.marginView = viewType;
    this.MarginViewTooltipVisible = false;
    this.$refs.quoteView.$forceUpdate();    
  }
  
  //calculate margin/marginRate
  reCalculateMargin(item, inCase, decimal = this.$decimals) {
    if(inCase == "rate") {
      if(item.price != 0 && item.customerPrice == 0) {
        item.marginRate = -100;
      }else if(item.price == 0 && item.customerPrice == 0) {
        item.marginRate = 0;
      }else if(item.price == 0) {
        item.marginRate = item.customerPrice > 0 ? 100 : item.customerPrice < 0 ? -100 : 0;
      }else {//just allow 4 decimals for rate
        var tmpRate = item.margin / item.price * 100;
        item.marginRate = parseFloat(tmpRate.toFixed(decimal));
        // if (tmpRate.toString().indexOf(".") != -1 && tmpRate.toString().split(".")[1].length > 4) {
        //   item.marginRate = parseFloat(tmpRate.toFixed(4));
        // }
      }
    }else if(inCase == "amount") {
      if(item.marginRate != 0 && item.marginRate != "") {
        item.margin = parseFloat(((item.price * item.marginRate) / 100).toFixed(decimal));
      }else {
        item.margin = 0.00;
      }
    }
  }

  editGetMarginRate(item) {
    var tmpRate = (typeof item.MARGINRATE != "undefined" && item.MARGINRATE != "" ? item.MARGINRATE : 0);
    if(tmpRate == 0 && item.QMARGIN != 0 && item.QMARGIN != "" && item.QPRICEREG_BK != 0) {
      tmpRate = item.QMARGIN / item.QPRICEREG_BK * 100;
    }
    // if(tmpRate.toString().indexOf(".") != -1 && tmpRate.toString().split(".")[1].length > 4) {
    //   tmpRate = parseFloat(tmpRate.toFixed(4));
    // }
    tmpRate = parseFloat(tmpRate.toFixed(this.$decimals));

    return tmpRate;
  }

  marginRate(index) {
    var item = this.itemNames[index];
    if(!isNaN(item.marginRate)) {
      const currentCustomerPrice = item.customerPrice;
      if(item.marginRate < -100) {//no allow < -100%
        item.marginRate = -100.00;
      }
      item.customerPrice = this.getCustomerPriceByMarinRate(item);
      item.orgCustomerPrice = item.customerPrice;
      this.reCalculateMargin(item, "amount");
      // item.customerPrice = item.margin + item.price;
      // item.total = parseFloat((item.customerPrice * item.quantity).toFixed(this.$decimals));
      item.total = parseFloat(item.customerPrice.toFixed(this.expandDecimalPrecision)) * item.quantity;
      item.tax = this.getLineItemTax(this.itemNames[index]);
      // if(this.includedItem) {
      //   this.setIncludedItemPrice(false);
      // }
      this.getTotals();
      this.$refs.quoteView.$forceUpdate();
      this.reCalculateGlobalMargin(); //re-calculate for global margin
      this.checkItemChangedValue(index, "price", currentCustomerPrice, item.customerPrice);
    }
  }

  getLineItemTax(item) {
    return parseFloat((item.total * (item.taxRate / 100)).toFixed(this.$decimalsView));
  }

  //check if should enable/disable EST%
  disabledEST() {
    var totalMargin = 0;
    var doDisable = false;
    for(var i in this.itemNames) {
      let item = this.itemNames[i];
      // let tmpCost = (item.price != "" ? item.price : 0);
      // let tmpPricePer = (item.customerPrice != "" ? item.customerPrice : 0);
      let tmpMargin = (item.margin != "" ? item.margin : 0);
      if(item.included == 0 && tmpMargin != 0) {
        // totalMargin += parseFloat(item.margin != "" ? item.margin : 0);
        doDisable = true;
        break;
      }
    }

    if(doDisable) {
      //Force to use EST% DEFAULT from Global params
      // this.estPercent = 0;
      return true;
    }

    return false;
  }

  selectAllText(id) {
    setTimeout(() => {
      $("#" + id).select();  
    }, 100);
  }

  isIndirect() {
    // if((this.sourceID > 1 && (this.currentComponent == "Indirect" || this.currentComponent == "Extended")) || this.currentComponent == "Indirect") {
    // if(this.sourceID > 1 || this.currentComponent == "Indirect") {
    //   return true;
    // }
    if(this.sourceID == 1 || this.sourceID == 12) {
      return false;
    }

    return true;
  }

  isDirect() {
    if (this.sourceID == 1 || this.sourceID == 12) {
      return true;
    }

    return false;
  }

  applyTaxRateBySource() {
    var tmpTaxRate = this.getTaxRateBySource();
    this.updateQuoteTaxRate(tmpTaxRate, true);
    /*this.USECUSTOMTAX = 0;
    $.each(this.itemNames, function(i, val) {
      val.taxRate = (typeof val.taxRate_bk != "undefined" ? val.taxRate_bk : tmpTaxRate);
    });
    this.$refs.quoteView.$forceUpdate();
    this.getTotals();*/
  }
  
  confirmSwitchCustomer() {
    // hide confirm message 1
    this.confirmedSwitchCustomer = true;//set a confirmed flag
    this.hpDirectTaxRate = this.hpDirectTaxRateTmp;
    this.indirectTaxRate = this.indirectTaxRateTmp;
    this.customer_bk = {
      customer: this.customer,
      aID: this.aID,
      accountID: this.accountID
    };
    this.confirmSwitchCustomerModalVisible = false;

    // show confirm message 2
    this.switchCustomerMsg2 = "<strong>" + this.customer + "</strong> has a tax rate of " + this.newTaxRate + "%. Do you want to override the current tax of " + this.currentTaxRate + "%?"
    this.confirmSwitchTaxRateVisible = true;
  }

  closeSwitchCustomer() {
    this.confirmSwitchCustomerModalVisible = false;
    if(!this.confirmedSwitchCustomer) {//back to previous selected customer
      this.customer = this.customer_bk.customer;
      this.aID = this.customer_bk.aID;
      this.accountID = this.customer_bk.accountID;
    }
  }

  confirmSwitchTaxRate() {
    this.currentTaxRate = this.getTaxRateBySource();
    this.applyTaxRateBySource();
    this.confirmSwitchTaxRateVisible = false;
  }

  getTaxRateBySource() {
    var tmpTaxRate = 0;
    if(this.isIndirect() && this.indirectTaxRate > 0) {//indirect
      tmpTaxRate = this.indirectTaxRate;
    }else if(!this.isIndirect() && this.hpDirectTaxRate > 0) {//direct
      tmpTaxRate = this.hpDirectTaxRate;
    }

    return tmpTaxRate;
  }

  getRealMargin(item) {//calculate margin based on margin rate, and cost
    var tmpMargin = item.margin;
    // if(item.price != 0 && this.currentComponent != "Direct") {
    //   if(item.marginRate != 0 && item.marginRate != "") {
    //     tmpMargin = parseFloat(((item.price * item.marginRate) / 100).toFixed(this.$decimalsView));
    //   }else {
    //     tmpMargin = 0.00;
    //   }
    // }
    return tmpMargin;
  }

  isEmptyLineItems() {
    var ret = true;
    for(let i in this.itemNames) {
      let item = this.itemNames[i];
      if(item.sku != "" || item.name != "" 
        || (item.price != "" && item.price != 0) 
        || (item.customerPrice != "" && item.customerPrice != 0)
        || item.quantity > 0
        || (item.total != "" && item.total != 0)) {
        ret = false;
        break;
      }
    }
    return ret;
  }

  async duplicateQuote(quoteID){
    const comInstance = this;
    this.duplicateLoading = true;
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var saveDuplicateData = {};
    saveDuplicateData["Controller"] = "Quotes";
    saveDuplicateData["FunctionName"] = "duplicate";
    saveDuplicateData["qlines"] = this.length.toString();
    saveDuplicateData["quoteID"] = quoteID;
    saveDuplicateData["aID"] = this.aID ? this.aID.toString() : this.customer;
    saveDuplicateData["accountID"] = this.accountID;
    saveDuplicateData["qDateTime"] = date;
    saveDuplicateData["qDescription"] = this.quoteName;
    saveDuplicateData["qQuoteID"] = this.qQuoteID;
    saveDuplicateData["totalPrice"] = this.grandTotal;
    saveDuplicateData["qTax"] = this.qTax;
    saveDuplicateData["qShip"] = this.qShip;
    saveDuplicateData["source"] = this.sourceID;
    saveDuplicateData["EstPercent"] = this.estPercent;
    saveDuplicateData["currentComponent"] = this.currentComponent;
    saveDuplicateData["selectedGlobalTagsID"] = this.selectedGlobalTagsID.join(",");
    //params for quoteParams
    saveDuplicateData["BDnumber"] = this.BDnumber;
    saveDuplicateData["ContractName"] = this.ContractName;
    saveDuplicateData["Created"] = this.Created;
    saveDuplicateData["Expires"] = this.Expires;
    saveDuplicateData["QuoteNumber"] = this.QuoteNumber;
    saveDuplicateData["ContractNumber"] = this.ContractNumber;
    saveDuplicateData["globalMargin"] = this.globalMargin;
    saveDuplicateData["taxRate"] = this.currentTaxRate;
    saveDuplicateData["customSourceName"] = this.sourceName;
    saveDuplicateData["expandDecimal"] = this.expandDecimal;
    saveDuplicateData["quoteBigDealNumber"] = this.quoteBigDealNumber;
    saveDuplicateData["quoteRevisionNumber"] = this.quoteRevisionNumber;
    saveDuplicateData["opportunityID"] = this.opportunityID;
    saveDuplicateData["duplicateQuoteCreate"] = true;
    saveDuplicateData["includeVarCost"] = this.includeVarCost;
    // Department Info
    const departmentDetails = {
      ID: this.selectedDepartment.length ? this.selectedDepartment.join() : 0,
      NAME: this.selectedDepartmentName
    }
    saveDuplicateData["departmentDetails"] = departmentDetails;
    let DefaultDeptID = (this.selectedDepartment.length ? this.selectedDepartment.join() : 0);
    if (DefaultDeptID == 0) {
      // if use a one time dept for this order
      saveDuplicateData["onetimeDepartment"] = this.onetimeDepartment;
    }
    //Contract Number Info
    saveDuplicateData["selectedContractNumberValue"] = this.selectedOrderContracts;
    saveDuplicateData["contractNumberFieldId"] = this.orderContractNumber[0].CUSTOMFIELDID;
    // Include price roll up
    saveDuplicateData["includedItem"] = this.includedItem;
    saveDuplicateData["includedPrice"] = this.includedPrice;
    // business line
    saveDuplicateData["businessLineId"] = this.selectedBusinessLine[0] || 0;
    saveDuplicateData["customData"] = JSON.stringify(
      this.fullCustomData.map(item => ({
        ...item,
        CUSTOMID: 0
      }))
    );

    // send quote end users if this quote has multi end users
    saveDuplicateData["quoteEndUsers"] = this.isMultiEndUsers ? this.splitBySubQuoteId.map(item => ({
      ...item.euInfo
    })) : [];
    // quote end user custom fields
    saveDuplicateData["dataFields"] = this.isMultiEndUsers ? this.splitBySubQuoteId.map(item => ({
      subQuoteId: item.subQuoteId,
      euCD: item.euCD.map(t => ({
        customFieldId: t.customFieldId,
        customValue: t.customValue
      }))
    })) : [];

    // shipping/billing address
    saveDuplicateData["DefaultShippingID"] = this.selectedAddress.length ? this.selectedAddress.join(",") : 0;
    saveDuplicateData["DefaultBillingID"] = this.selectedBillingAddress.length ? this.selectedBillingAddress.join(",") : 0;
    if(this.selectedAddress.length && saveDuplicateData["DefaultShippingID"] == 0) {
      // if use a one time shipping address
      saveDuplicateData["onetimeAddress"] = this.onetimeAddress;
    }
    if (this.selectedBillingAddress.length && saveDuplicateData["DefaultBillingID"] == 0) {
      // if use a one time billing address
      saveDuplicateData["onetimeAddressBilling"] = this.onetimeAddressBilling;
    }

    const sourceIDs: any = [];
    let totalContractFee = 0;
    let itemOrder = 0;
    $.each(this.itemNames, function(i, val) {
      var ind = i.replace("productName_", "");
      saveDuplicateData["ItemDescription" + ind] = val.name;
      saveDuplicateData["ItemNumber" + ind] = val.sku;
      saveDuplicateData["ItemQuantity" + ind] =
        val.quantity > 0
          ? val.quantity.toString()
          : "";
      saveDuplicateData["RegPrice" + ind] =
        val.price != 0
          ? parseFloat(val.price).toFixed(comInstance.expandDecimalPrecision)
          : "";
      saveDuplicateData["ItemTotal" + ind] =
        val.total > 0
          ? val.total.toString()
          : "";
      saveDuplicateData["ItemCustomerPrice" + ind] =
        val.customerPrice != 0
          ? parseFloat(val.customerPrice).toFixed(comInstance.expandDecimalPrecision)
          : "";
      saveDuplicateData["ItemMargin" + ind] = val.margin.toString();
      saveDuplicateData["ItemMarginRate" + ind] = (typeof val.marginRate != "undefined" ? val.marginRate : 0);
      saveDuplicateData["ItemShipping" + ind] =
        val.shipping > 0
          ? val.shipping.toString()
          : "";
      saveDuplicateData["ItemTaxRate" + ind] =
        val.taxRate > 0
          ? val.taxRate.toString()
          : "";
      saveDuplicateData["ItemTax" + ind] =
        val.tax > 0
          ? val.tax.toString()
          : "";
      saveDuplicateData["ItemNoTax" + ind] = val.noTax
        ? 1
        : 0;
      saveDuplicateData["ItemIncluded" + ind] = val
        .included
        ? 1
        : 0;
      saveDuplicateData["ItemConfigID" + ind] =
        typeof val.configID != "undefined"
          ? val.configID
          : "";
      saveDuplicateData["ItemCategory" + ind] = typeof val.ItemCategory != "undefined" ? val.ItemCategory : 0;
      saveDuplicateData["ItemPLID" + ind] = typeof val.ItemPLID != "undefined" ? val.ItemPLID : 0;
      saveDuplicateData["productLine" + ind] = val.ItemPLName || "";
      saveDuplicateData["buildHeader" + ind] = typeof val.buildHeader != "undefined" ? val.buildHeader : "";
      saveDuplicateData["sourceID" + ind] = val.dynamicInfo ?  (val.dynamicInfo.selectedDistyID || 0) : 0;

      if(saveDuplicateData[`sourceID${ind}`] > 0) {
        sourceIDs.push(saveDuplicateData[`sourceID${ind}`]);
      }
      // calculate total contract fee
      if(val.isContractFee || false) {
        totalContractFee += val.price;
      }

      saveDuplicateData[`ItemSubQuoteId${ind}`] = val.subQuoteId || 0;
      saveDuplicateData[`ItemIsConfig${ind}`] = val.ISCONFIG || 0;

      // specify item order
      itemOrder++;
      saveDuplicateData[`ItemOrder${ind}`] = itemOrder;
    });
    saveDuplicateData["totalContractFee"] = totalContractFee;

    // auto update source to a distributor in case dynamic
    if(saveDuplicateData["source"] == 10 && sourceIDs.length) {
      const usedMultiSource = sourceIDs.find((s: any) => s != sourceIDs[0]);
      if(!usedMultiSource) {
        saveDuplicateData["source"] = sourceIDs[0];
      }
    }

    var self = this;
    var duplicateRecord = getRouteData(saveDuplicateData);
    return duplicateRecord.then(function(response, statusText, jqXHR) {
      try {
        if (response.data.STATUS) {
          notifier.success(
            `Quote ${quoteID} Duplicated Successfully`
          );
          self.$router.push({ name: "ViewQuote", params: { id: response.data.createdQuoteId } });
        } else {
          notifier.alert(response.data.STATUSMESSAGE);
        }
      } catch (err) {
        //handle error
        notifier.alert(err.message);
      } finally {
        self.duplicateLoading = false;
      }
    })
  }

  updateQuoteTaxRate(newTaxRate, forceUpdate = false) {
    // if user changed a tax rate
    if((newTaxRate != "" && this.currentTaxRate != newTaxRate) || forceUpdate) {
      this.currentTaxRate = newTaxRate;
      // let rowInd = 0;
      for(const i in this.itemNames) {
        const item = this.itemNames[i];
        // !this.itemNames[i].included
        // if((this.isLastRow(rowInd)) || this.isEmptyRow(this.itemNames[i], i)) {
          // case for last row/empty row
          // this.itemNames[i].taxRate = newTaxRate;
        // }else {
          // if(this.itemNames[i].included || this.itemNames[i].taxRate == 0) {
          //   this.itemNames[i].taxRate = 0;
          // }else {
          //   this.itemNames[i].taxRate = newTaxRate;
          // }
          if((item.taxRate || 0) != 0) {
            item.taxRate = newTaxRate;
          }
        // }

        // rowInd++;
      }
      if (newTaxRate == 0) {
        this.checkAllTax = false;
      }

      if(this.selectedIndexForTax != "") {
        const selectedItemForTax = this.itemNames[this.selectedIndexForTax];
        // if(!(selectedItemForTax.ISCONFIG || 0)) {
          selectedItemForTax.taxRate = newTaxRate;
          const configItems = this.getIncludedItems(selectedItemForTax, this.selectedIndexForTax).filter(t => t.ISCONFIG || 0);
          for(const t of configItems) {
            t.taxRate = newTaxRate;
          }
        // }
        this.selectedIndexForTax = "";
      }

      this.USECUSTOMTAX = 0;
      this.$refs.quoteView.$forceUpdate();
      this.getTotals();
    }

    // clear selected index for tax on a line item
    if((newTaxRate == "" || newTaxRate == 0) && this.selectedIndexForTax != "") {
      this.selectedIndexForTax = "";
    }
  }

  isLastRow(rowInd) {
    return rowInd == Object.keys(this.itemNames).length - 1 ? true : false;
  }

  showConfirmSwitchTaxRateBySource() {
    if(!!this.customer == true) {
      const tmpTaxRate = this.getTaxRateBySource();
      if(tmpTaxRate != 0 && tmpTaxRate != this.currentTaxRate) {
        // confirm apply tax rate by source
        this.switchCustomerMsg2 = "<strong>" + this.customer + "</strong> has a tax rate of " + tmpTaxRate + "% for <strong>" + this.source + "</strong>. Do you want to override the current tax of " + this.currentTaxRate + "%?"
        this.confirmSwitchTaxRateVisible = true;
      }
    }
  }

  showPrevSource() {
    this.sourceID = this.prevsource.VARSOURCE_ID;
    this.source = this.prevsource.VARSOURCE_NAME;
  }

  isEmptyRow(item, i) {
    if((i == Object.keys(this.itemNames).length - 1)) {
      // last row
      return true;
    }
    if(item.sku == "" && item.name == "" && item.quantity == 0) {
      return true;
    }

    return false;
  }

  checkItemChangedValue(index, type, oldVal: any, newVal: any) {
    let id = "";
    if(type == "price") {
      id = `cust_cost_${index}`;
    }else if(type == "margin") {
      id = `margin_${index}`;
    }
    if((oldVal != newVal) && id != "") {
      const item = $(`#${id}`).parent();
      item.removeClass("changedCalculation");
      setTimeout(() => {
        item.addClass("changedCalculation");
      }, 300);
    }
  }

  getCustomerPriceByMarinRate(item) {
    if(item.marginRate == 100) {
      return item.price * 2; 
    }

    // return (item.price * 100) / (100 - item.marginRate);
    const tmpMargin = (item.price * (item.marginRate / 100)).toFixed(5);
    const tmpCustomerPrice = parseFloat(item.price) + parseFloat(tmpMargin);
    return parseFloat(tmpCustomerPrice.toFixed(5));
  }

  isDynamic() {
    if(this.sourceID == 10) {
      return true;
    }

    return false;
  }

  getDistyStockStatus(item: any): string {
    let ret = "";
    if(item.StockQty == item.TotalQty) {
      ret = "all in stock";
    }else if(item.StockQty > 0 && item.StockQty < item.TotalQty) {
      ret = "partial stock";
    }else {
      ret = "no availability";
    }

    return ret;
  }

  updateDistyTotal() {
    if(!this.isDynamic()) {
      return;
    }

    // reset disty total
    for(const i in this.distyIDs) {
      const distyID = this.distyIDs[i];
      this.DistyTotal[`${distyID}`] = {
        "TotalPrice": 0,
        "StockQty": 0,
        "TotalQty": 0
      };
    }

    for(const i in this.itemNames) {
      const item = this.itemNames[i];
      if(item.quantity > 0 && typeof item.dynamicInfo != "undefined") {
        for(const j in this.distyIDs) {
          const distyID = this.distyIDs[j];
          this.DistyTotal[distyID].TotalPrice += (item.dynamicInfo[`Disty_${distyID}_Price`] * item.quantity) || 0;
          this.DistyTotal[distyID].TotalQty += item.quantity;
          this.DistyTotal[distyID].StockQty += item.dynamicInfo[`Disty_${distyID}_Availability`] >= item.quantity ? item.quantity : item.dynamicInfo[`Disty_${distyID}_Availability`];
        }
      }
    }
  }

  getLowestDistyPrice(productInfo: any, item: any) {
    let prices: any = [];
    let lowestPrice = 0;
    for(const i in this.distyIDs) {
      const distyID = this.distyIDs[i];
      prices.push(productInfo[`Disty_${distyID}_Price`] || 0);
    }
    prices = prices.filter((item: any) => item > 0);
    if(prices.length == 0) {
      lowestPrice = 0;
    }else if(prices.length == 1) {
      lowestPrice = prices[0];
    }else if(prices.length > 1) {
      // get lowest disty price
      lowestPrice = Math.min(...prices);
    }

    // select a distyID for this line item
    if(lowestPrice != 0 && typeof item.dynamicInfo != "undefined") {
      for(const i in this.distyIDs) {
        const distyID = this.distyIDs[i];
        if((productInfo[`Disty_${distyID}_Price`] || 0) == lowestPrice) {
          item.dynamicInfo.selectedDistyID = distyID;
          break;
        }
      }
    }

    return lowestPrice;
  }

  getMarkupFromMargin(marginRate) {
    let markup = 0;
    if(marginRate == 100) {
      return 100;
    }
    if(marginRate != 0) {
      const tmp = parseFloat((marginRate/100).toFixed(4));
      markup = parseFloat(((tmp / (1 - tmp))*100).toFixed(4))
    }

    return markup;
  }

  autoExpandDecimalSwitch(togBtn) {
    this.expandDecimalPrecision = 2;
    if (togBtn) {
      this.expandDecimalPrecision = 5;
    }
    this.expandDecimal = !this.expandDecimal;
  }

  async addNewDepartment(departmentList) {
    if (departmentList[0].isSave) {
      this.onetimeDepartment = {};
      var department = {
        NAME: departmentList[0].Name,
        ID: 0
      };
      this.departmentItems = [department];
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Contacts",
        FunctionName: "UpdateDepartment",
        aID: this.aID,
        departmentsList: this.departmentItems,
        action: "newDept"
      });
      if(response.data.STATUS == 1 && response.data.departmentID != "") {
        this.duplicateDeptName = false;
        notifier.success(response.data.STATUSMESSAGE);
        this.selectedDepartment = [response.data.departmentID];
        this.departmentVisible = false;
        //Get customer department details
        await this.getCustomerDepartment(this.aID);
      } else {
        this.duplicateDeptName = true;
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } else {
      this.onetimeDepartment = {
        Name: departmentList[0].Name
      };
      const newDepartmentItem = {
        ID: 0,// for one time dept
        TEXT: this.onetimeDepartment.Name
      };
      let onetimeDept = this.customerDepartment.find(item => item.ID == 0);
      if(onetimeDept) {
        Object.assign(onetimeDept, newDepartmentItem);
      }else {
        this.customerDepartment.unshift(newDepartmentItem);
      }
      this.selectedDepartment = [0];
      this.departmentVisible = false;
    }
    this.selectedDepartmentName = departmentList[0].Name;
  }

  async getCustomerDepartment(aID) {
    if(aID > 0) {
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Contacts",
          FunctionName: "UpdateDepartment",
          aID: aID,
          action: "list"
        });
        if(response.data.STATUS) {
          this.customerDepartment = response.data.DEPARTMENTDETAILS.map(tmp => ({
            ID: tmp.ACCOUNTDEPARTMENTID,
            TEXT: tmp.ADEPARTMENTNAME
          }));
        }
        this.customerDepartment.push({
          ID: "onetime",
          TEXT: "Add new department"
        });
        if (Object.keys(this.onetimeDepartment).length) {
          this.customerDepartment.unshift({
            ID: 0,
            TEXT: this.onetimeDepartment.Name
          });
        }
      } catch (error) {
        // console.log(error);
      }
    }
  }

  updateDepartment(selectedID) {
    if (selectedID == "onetime") {
      this.addNewType = selectedID;
      this.departmentVisible = true;
    } else {
      this.selectedDepartment = [selectedID];
      const selectedObj = this.customerDepartment.find((item: any) => item.ID === selectedID)
      this.selectedDepartmentName = selectedObj.TEXT;
    }
  }

  async toggleSelectedContractNumber(selectedID: number, contractName, forceReload = false) {
    const index = this.orderContractsID.findIndex(id => id === selectedID);
    const contractGTags = this.globalTags.filter(tmp => tmp.CUSTOMFIELDOPTIONNAME == contractName);
    if (index === -1) {
      this.orderContractsID = [selectedID];
      this.selectedOrderContracts = contractName;
      //Add Related Global Tags
      const selectedGTags = this.selectedGlobalTags.findIndex((tmp: any) => tmp.CUSTOMFIELDOPTIONNAME.toUpperCase() == contractName.toUpperCase());
      if(selectedGTags == -1 && contractGTags.length) {
        this.selectedGlobalTagsID.push(contractGTags[0].CUSTOMFIELDOPTIONID);
        this.selectedGlobalTags.push({
          CUSTOMFIELDOPTIONID: contractGTags[0].CUSTOMFIELDOPTIONID,
          CUSTOMFIELDOPTIONNAME: contractGTags[0].CUSTOMFIELDOPTIONNAME
        });
      }
    } else {
      if (!forceReload) {
        this.orderContractsID.splice(index, 1);
        this.selectedOrderContracts = "";
        //Remove Related Global Tags
        if(contractGTags.length) {
          const tagIndex = this.selectedGlobalTagsID.findIndex(id => id === contractGTags[0].CUSTOMFIELDOPTIONID);
          this.selectedGlobalTagsID.splice(tagIndex, 1);
          this.selectedGlobalTags.splice(tagIndex, 1);
        }
      }
    }
    var search: string[] = [];
    var contractFee = 0;
    for(var skuIndex in this.itemNames) {
      if(this.itemNames[skuIndex].sku != "" && !search.includes(this.itemNames[skuIndex].sku)) {
        search.push(this.itemNames[skuIndex].sku);
      }
      //Set contract fee
      if(this.itemNames[skuIndex].sku == "" && !contractFee && this.selectedOrderContracts.toLowerCase() == 'mohave') {
        this.itemNames[skuIndex].sku = 'contractfee';
        this.itemNames[skuIndex].name = 'Mohave 1% fee';
        contractFee++;
        this.addDelete(skuIndex);
      } else if(this.itemNames[skuIndex].sku == 'contractfee' && this.selectedOrderContracts.toLowerCase() != 'mohave') {
        this.$delete(this.itemNames, skuIndex);
        this.addRow();
      }
      this.$refs.quoteView.$forceUpdate();
    }
    if(search.length) {
      // this.loading = true;
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller          : "Quotes",
          FunctionName        : "quoteLineItemList",
          search              : search,
          source              : this.sourceID,
          contractNumberValue : this.selectedOrderContracts,
          contractNumberId    : this.orderContractsID.join()
        });

        if (response.data.ERROR) {
          throw new Error(response.data.ERROR);
        }
        if (response.data.STATUS !== 1) {
          throw new Error(response.data.STATUSMESSAGE);
        }
        if (response.data.STATUS == 1) {
          for(let itemIndex in this.itemNames) {
            let productInfo = response.data.LINEITEMS.filter(val => val.PRODUCTSKU == this.itemNames[itemIndex].sku);
            if(productInfo.length) {
              productInfo = productInfo[0];
              if(this.autoCost == 1 || this.autoName == 1) {
                this.itemNames[itemIndex]["contractPrice"] = productInfo.ContractPrice;
                this.itemNames[itemIndex]["contractPriceDate"] = productInfo.ContractPriceDate;
              }
              if (this.autoName == 1 && productInfo.PRODUCTNAME != "") {
                this.itemNames[itemIndex]["name"] = productInfo.PRODUCTNAME;
              }
            }
          }
          this.$refs.quoteView.$forceUpdate();
        }
      }catch (err) {
        console.log(err);
      }finally {
        // this.loading = false;
      }
    }
  }

  autoIncludePriceSwitch() {
    this.includedPrice = !this.includedPrice;
    // this.setIncludedItemPrice();
  }

  // setIncludedItemPrice(updateSummary = true) {
    /* var parentPrice = 0;
    var parentCustomerPrice = 0;
    for(const lineIndex in this.itemNames) {
      var lineItem = this.itemNames[lineIndex];
      if(lineItem.included) {
        if(!this.includedItem) {
          this.includedItem = true;
        }
        const parent = findParent(lineIndex, this.itemNames);
        if (parent) {
          if(this.includedPrice) {
            parentPrice += lineItem.orgPrice;
            parentCustomerPrice += lineItem.orgCustomerPrice;
            parent.price = parentPrice;
            parent.customerPrice = parentCustomerPrice;
            parent.disable = true;
            lineItem.price = lineItem.orgPrice;
            lineItem.customerPrice = lineItem.orgCustomerPrice;
            lineItem.disable = false;
          } else {
            parent.price = parent.orgPrice;
            parent.customerPrice = parent.orgCustomerPrice;
            parent.disable = false;
            lineItem.price = 0;
            lineItem.customerPrice = 0;
            lineItem.quantity = 0;
            lineItem.disable = true;
            lineItem.margin = 0;
          }
          parent.margin = parseFloat((parent.customerPrice - parent.price).toFixed(this.$decimals));
          this.reCalculateMargin(parent, "rate");
        }
      } else {
        parentPrice = 0;
        parentCustomerPrice = 0;
        lineItem.price = lineItem.orgPrice;
        lineItem.customerPrice = lineItem.orgCustomerPrice;
        lineItem.disable = false;
      }
      lineItem.margin = parseFloat((lineItem.customerPrice - lineItem.price).toFixed(this.$decimals));
      this.reCalculateMargin(lineItem, "rate");
    }
    if(updateSummary) {
      this.getTotals();
      this.reCalculateGlobalMargin();
    }
    this.$refs.quoteView.$forceUpdate();
    this.$forceUpdate(); */
  // }
  findParentIndex(indexName, itemNames)  {
    const index = parseInt(indexName.split("_")[1]);
    // find parent index
    for (let i = index - 1; i >= 1; i--) {
      const lineItem = itemNames[`productName_${i}`];
      if (!lineItem) {
        continue;
      }
      if (lineItem.included === 0) {
        return i;
      }
    }

    return 0;

  }

  updateBusinessLine(id) {
    this.selectedBusinessLine = [id];
  }

  showCustomData() {
    this.customDataVisible = !this.customDataVisible;
  }

  isParentLi(item, index) {
    let ret = false;
    // convert o array
    const itemNames: any = [];
    for(const i in this.itemNames) {
      itemNames.push({
        key: i,
        ...this.itemNames[i]
      });
    }

    // is parent if item.parentLiId = 0, and next item.parentLiId = item.quoteLiId
    const findIndex = itemNames.findIndex(t => t.key == index);
    const currentLine = itemNames[findIndex];
    const nextLine = itemNames[findIndex + 1];
    if(currentLine && nextLine && currentLine.parentLiId == 0 && nextLine.parentLiId > 0 && nextLine.parentLiId == currentLine.quoteLiId) {
      ret = true;
    }
    
    return ret;
  }

  categoryImgLink(item) {
    const checkSku = item.sku != '' && !this.isFee(item);
    if(checkSku && (item.ItemCategory || (item.ISCONFIG || 0))) {
      const configCategoryId = this.configCategory.CATEGORYID || 0;
      if((configCategoryId > 0 && configCategoryId == item.ItemCategory) || (item.ISCONFIG || 0)) {
        // specify image for config item
        return require('@/assets/images/config-cat-ico.svg');
      }

      return require('@/assets/images/category_icon.svg');
    } else {
      return require('@/assets/images/category-not-available.svg');
    }
  }

  get isMultiEndUsers() {
    // check data of splitBySubQuoteId
    if (
      this.splitBySubQuoteId.length >= 1 &&
      this.splitBySubQuoteId[0].subQuoteId > 0
    ) {
      return true;
    }

    return false;
  }

  isFee(item) {
    return item.isFee || item.isContractFee || false;
  }

  showIncludeBtn(item) {
    // !this.includedEnabled || 
    if(this.isFee(item) || item.included) {
      return false;
    }

    return true;
  }

  lastGroupItem(index) {
    if(!this.itemNames[index] || !this.itemNames[index].included) {
      return false;
    }

    // check if next item is a base, then this is last item
    const keys = Object.keys(this.itemNames);
    for (let i = 0; i <= keys.length - 1; i++) {
      if (index == keys[i]) {
        const nextItem = this.itemNames[keys[i + 1]];
        if(nextItem && !nextItem.included) {
          return true;
        }
        break;
      }
    }

    return false;
  }

  hasConfigs(item, index) {
    if(item.included || 0) return false;

    let ret = false;
    const keys = Object.keys(this.itemNames);
    for (let i = 0; i <= keys.length - 1; i++) {
      if (index == keys[i]) {
        for(let j = i + 1; j <= keys.length - 1; j++) {
          const nextItem = this.itemNames[keys[j]];
          if(!nextItem) break;

          // check if this base has a config item
          if(!(nextItem.included || 0)) {
            // this is an other base
            break;
          }else if(nextItem.ISCONFIG || 0) {
            ret = true;
            break;
          }
        }

        break;
      }
    }

    return ret;
  }

  getBaseTotal(item, index) {
    const ret = {
      price: 0,
      customerPrice: 0,
      margin: 0,
      marginRate: 0,
      total: 0
    };
    const configItems = this.getIncludedItems(item, index).filter(t => t.ISCONFIG || 0);
    for(const t of configItems) {
      // cost
      ret.price += parseFloat((t.price || 0).toFixed(this.expandDecimalPrecision));
      ret.price = parseFloat(ret.price.toFixed(this.expandDecimalPrecision));
      // price
      ret.customerPrice += parseFloat((t.customerPrice || 0).toFixed(this.expandDecimalPrecision));
      ret.customerPrice = parseFloat(ret.customerPrice.toFixed(this.expandDecimalPrecision));
      // total
      ret.total += parseFloat((t.total || 0).toFixed(this.expandDecimalPrecision));
      ret.total = parseFloat(ret.total.toFixed(this.expandDecimalPrecision));
    }

    // append base cost per/price per
    ret.price += parseFloat((item.price || 0).toFixed(this.expandDecimalPrecision));
    ret.price = parseFloat(ret.price.toFixed(this.expandDecimalPrecision));
    ret.customerPrice += parseFloat((item.customerPrice || 0).toFixed(this.expandDecimalPrecision));
    ret.customerPrice = parseFloat(ret.customerPrice.toFixed(this.expandDecimalPrecision));
    ret.total += parseFloat((item.total || 0).toFixed(this.expandDecimalPrecision));
    ret.total = parseFloat(ret.total.toFixed(2));
    // ret.total = parseFloat(
    //   (ret.customerPrice * (item.quantity || 0)).toFixed(2)
    // );

    // margin
    ret.margin = parseFloat((ret.customerPrice - ret.price).toFixed(2));
    // marginRate
    if(ret.margin == 0) {
      ret.marginRate = 0;
    }else if(ret.price == 0) {
      ret.marginRate = ret.customerPrice > 0 ? 100 : ret.customerPrice < 0 ? -100 : 0;
    }else {//just allow 4 decimals for rate
      ret.marginRate = parseFloat((ret.margin / ret.price * 100).toFixed(this.$decimals));
    }

    return ret;
  }

  getItemQuantity(item, index) {
    if(item.included && (item.ISCONFIG || 0)) {
      // get base quantity of item is config
      const parent = findParent(index, this.itemNames);
      return parent.quantity || 0;
    }

    return item.quantity || 0;
  }

  // get included items of a base product
  getIncludedItems(item, index) {
    if(item.included) return [];

    const ret: any = [];
    const keys = Object.keys(this.itemNames);
    for (let i = 0; i <= keys.length - 1; i++) {
      if (index == keys[i]) {
        for(let j = i + 1; j <= keys.length - 1; j++) {
          const nextItem = this.itemNames[keys[j]];
          if(!nextItem || !nextItem.included) break;

          if(nextItem.included) {
            nextItem.indexKey = keys[j];
            ret.push(nextItem);
          }
        }

        break;
      }
    }

    return ret;
  }

  mouseEnterBase(type, item, index) {
    if(!this.hasConfigs(item, index)) {
      return;
    }

    // outline related config items
    const configItems = this.getIncludedItems(item, index).filter(t => t.ISCONFIG || 0);
    if(!configItems.length) return;

    // reset
    item.priceHover = 0;
    item.customerPriceHover = 0;
    for(const t of configItems) {
      t.priceHover = 0;
      t.customerPriceHover = 0;
    }

    if(type == "price") {
      item.priceHover = 1;
      for(const t of configItems) {
        t.priceHover = 1;
      }
    }else if(type == "customerPrice") {
      item.customerPriceHover = 1;
      for(const t of configItems) {
        t.customerPriceHover = 1;
      }
    }
    this.$refs.quoteView.$forceUpdate();
  }

  mouseLeaveBase(type, item, index) {
    if(!this.hasConfigs(item, index)) {
      return;
    }
    // reset
    item.priceHover = 0;
    item.customerPriceHover = 0;
    for(const t of this.getIncludedItems(item, index).filter(t => t.ISCONFIG || 0)) {
      t.priceHover = 0;
      t.customerPriceHover = 0;
    }
    this.$refs.quoteView.$forceUpdate();
  }

  baseQuantityChange(item, index) {
    const baseQtyNew = item.quantity || 0;
    const baseQtyBk = item.quantityBk || 0;
    if(!baseQtyNew) return;

    const configItems = this.getIncludedItems(item, index).filter(t => t.ISCONFIG || 0);
    if(!configItems.length) return;

    for(const t of configItems) {
      if(!t.quantity) {
        // config has no quantity, follow quantity of base
        t.quantity = t.quantityBk = baseQtyNew;
      }else if(baseQtyBk) {
        // check quantity per base of a config
        let qtyPerBase = 0;
        if(t.quantity % baseQtyBk == 0) {
          // is correct rule
          qtyPerBase = t.quantity/baseQtyBk;
          t.quantity = t.quantityBk = qtyPerBase * baseQtyNew;
        }else {
          // detect changed config quantity manually, keep this qty (or change later more for this case)
        }
      }
      t.total = (t.customerPrice || 0) * t.quantity;
    }

    item.quantityBk = baseQtyNew;
    this.$refs.quoteView.$forceUpdate();
  }

  configToggleChange(item, index) {
    // if set a base to config
    if(!item.included) {
      this.includeItem(index);
    }
    if(item.included && (item.ISCONFIG || 0)) {
      // if item is config, follow base taxRate
      const parent = findParent(index, this.itemNames);
      if(parent) {
        // item.quantity = item.quantityBk = parent.quantity || 0;
        item.taxRate = parent.taxRate || 0;
        item.total = (item.customerPrice || 0) * (item.quantity || 0);
      }
    }
    this.getTotals();
  }

  showEUCustomData(ss) {
    this.selectedSubQuote = ss;
    this.euCustomDataVisible = true;
  }

  getSSTotal(subQuoteId) {
    const ret = {
      total: 0,
      totalFormatted: ""
    };
    if(!subQuoteId) return ret;

    for(const i in this.itemNames) {
      const item = this.itemNames[i];
      if(item.subQuoteId != subQuoteId) {
        continue;
      }

      ret.total += item.total || 0;
      ret.total = parseFloat(ret.total.toFixed(2));
    }
    ret.totalFormatted = dollarFormat(ret.total);

    return ret;
  }

  addNewSSLine(e, subQuoteId, item, index) {
    // fix: if add new line below base, move to the end of configs list
    let belowIndex = index;
    if (this.hasConfigs(item, index) && !(item.expanded || false)) {
      const keys = Object.keys(this.itemNames);
      for (let i = 0; i <= keys.length - 1; i++) {
        if (keys[i] == index) {
          // check next configs item
          for (let j = i + 1; j <= keys.length - 1; j++) {
            const config = this.itemNames[keys[j]];
            if (config && (config.ISCONFIG || 0)) {
              // below the last config of this item
              belowIndex = keys[j];
            } else {
              break;
            }
          }
        }
      }
    }

    const newItems = {};
    let newIndex = "";
    for(const key in this.itemNames) {
      newItems[key] = this.itemNames[key];
      if(key == belowIndex) {
        let qty = 0;
        let included = item.included || 0;
        let ISCONFIG = item.ISCONFIG || 0;
        let includedparent = 0;
        let baseProductId = 0;

        if(this.hasConfigs(item, index)) {
          // add new line below a base sku
          qty = item.quantity || 0;
          included = 1;
          ISCONFIG = 1;
          includedparent = item.quoteLiId || 0;
          baseProductId = item.quoteLiId || 0;
        }else if(item.included || 0) {
          // add new line below a included/config sku
          const parent = findParent(index, this.itemNames);
          if(parent) {
            // follow parent quantity if this item is set config
            if(item.ISCONFIG || 0) {
              qty = parent.quantity || 0;
            }
            includedparent = parent.quoteLiId || 0;
            baseProductId = parent.quoteLiId || 0;
          }
        }

        // add more line below this index
        newIndex = `productName_${this.getMaxIndex() + 1}`;
        newItems[newIndex] = {
          subQuoteId,
          quoteLiId: 0,
          sku: "",
          name: "",
          price: 0,
          customerPrice: 0,
          margin: 0,
          marginRate: this.getConfirmedGlobalMargin(true),
          quantity: qty,
          tax: 0,
          taxRate: 0,
          total: 0,
          included,
          ISCONFIG,
          includedparent,
          baseProductId
        };
        this.length += 1;
      }
    }
    this.itemNames = newItems;
    this.$refs.quoteView.$forceUpdate();
    // auto focus sku box
    this.$nextTick().then(() => {
      if(newIndex != "" && $(`#sku_${newIndex}`).length) {
        $(`#sku_${newIndex}`).focus();
      }
    });
  }

  getMaxIndex() {//get max index
    const indArr: number[] = [];
    let maxInd = 0;
    $.each(this.itemNames, function(i, val) {
      indArr.push(parseInt(i.replace("productName_", "")));
    });
    if (indArr.length) {
      maxInd = Math.max.apply(Math, indArr);
    }
    
    return maxInd;
  }

  isEndItem(index) {
    const keys = Object.keys(this.itemNames);
    const lastKey = keys.pop();
    return lastKey === index;
  }

  configsCount(item, index) {
    const configItems = this.getIncludedItems(item, index).filter(t => t.ISCONFIG || 0);
    return configItems.length;
  }

  showEUData(ss) {
    this.selectedSubQuote = ss;
    this.euDataVisible = true;
  }

  checkExpandedItems(type) {
    const keys = Object.keys(this.itemNames);
    if (type == "upload") {
      for (let i = 0; i <= keys.length - 1; i++) {
        const index = keys[i];
        const item = this.itemNames[index];
        if (item.included || 0) continue;

        // is base
        item.expanded = true;
        if (!(item.baseProductId || 0)) {
          item.baseProductId = getRandomNumber(100000, 999999);
        }
        const collapseBtn = $(
          `.list-item.has-configs[data-index="${index}"] .calloutExpandContainer .collapseBtn`
        );

        if (collapseBtn.length) {
          // mark this button is expanded
          collapseBtn.removeClass("collapsed").attr("aria-expanded", true);

          // check grouped/config item
          const includedItems = this.getIncludedItems(item, index);
          for (const t of includedItems) {
            t.includedparent = item.baseProductId;
            if (t.ISCONFIG || 0) {
              t.savedAsConfig = 1;
              // show configs
              this.$nextTick().then(() => {
                if (t.indexKey) {
                  $(`.list-item[data-index="${t.indexKey}"]`)
                    .parent()
                    .addClass("show");
                }
              });
            }
          }
        }
      }
    }
  }

  getTitle() {
    let customTitle = this.title;
    if (this.$route.params.qID && parseInt(this.$route.params.qID) != 0 && this.quoteRevisionNumber.length) {
      customTitle =  this.title + "<span class='white-space-pre'> - " + this.quoteRevisionNumber + "</span>";
    }
    return customTitle;
  }

  updateOpportunity(data) {
    this.opportunityID = data.option.value || 0;
  }

  updateCustomerAddress(selectedID) {
    if(selectedID == "permanent" || selectedID == "onetime") {
      this.addNewType = selectedID;
      this.customerAddressVisible = true;
    }else {
      this.selectedAddress = [selectedID];
      // this.checkAutomateTaxRate("shipping");
    }
    // To list the selected address on the top
    this.sortingAddresses(this.customerAddresses, this.selectedAddress);
  }

  updateBillingAddress(selectedID) {
    if (selectedID == "permanent" || selectedID == "onetime") {
      this.addNewType = selectedID;
      this.billingAddressVisible = true;
    } else {
      this.selectedBillingAddress = [selectedID];
      // this.checkAutomateTaxRate("billing");
    }
    // To list the selected address on the top
    this.sortingAddresses(this.customerAddressesBilling, this.selectedBillingAddress);
  }

  async getCustomerAddresses(aID, setDefault = false) {
    // var ret: object[] = [];
    if(aID > 0) {
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Queries",
          subsystem: "Helpers",
          FunctionName: "getAccountAddresses",
          aID: aID,
          addressType: "1,2"
        });
        if(response.data.STATUS) {
          // setting for auto tax when change shipping/billing address based on account setting
          // this.autoTaxSetting.shippingAutoTax = response.data.shippingAutoTax || 0;
          // this.autoTaxSetting.billingAutoTax = response.data.billingAutoTax || 0;
          // this.accountGenerateHardware = parseInt(response.data.generateHardware) || 0;

          const shippingAddr = response.data.addressArr.filter(tmp => tmp.ACCOUNTSADDRESS_TYPE == 1);
          const billingAddr = response.data.addressArr.filter(tmp => tmp.ACCOUNTSADDRESS_TYPE == 2);
          this.customerAddresses = shippingAddr.map(tmp => ({
            ID: tmp.ACCOUNTSADDRESS_ID,
            TEXT: this.getAddressText(tmp),
            data: tmp
          }));
          this.customerAddressesBilling = billingAddr.map(tmp => ({
            ID: tmp.ACCOUNTSADDRESS_ID,
            TEXT: this.getAddressText(tmp),
            data: tmp
          }));
        }
        // option to add a permanent address, and one time using
        this.customerAddresses.push({
          ID: "onetime",
          TEXT: "Enter a shipping address"
        });
        this.customerAddressesBilling.push({
          ID: "onetime",
          TEXT: "Enter a billing address"
        });
        
        if(Object.keys(this.onetimeAddress).length) {
          this.customerAddresses.unshift({
            ID: 0,
            TEXT: this.getAddressText(this.onetimeAddress)
          });
        }
        if (Object.keys(this.onetimeAddressBilling).length) {
          this.customerAddressesBilling.unshift({
            ID: 0,
            TEXT: this.getAddressText(this.onetimeAddressBilling)
          });
        }
        // const endUserArr = response.data.contactListArr.filter(tmp => tmp.ACONTACTTYPE == 1 || tmp.ACONTACTTYPE == 3 || tmp.UTYPE);
        // const invoiceMailArr = response.data.contactListArr.filter(tmp => tmp.ACONTACTTYPE == 2 || tmp.ACONTACTTYPE == 3);
        // const defaultEndUserItem = response.data.contactListArr.find(tmp => (tmp.ACONTACTTYPE == 1 || tmp.ACONTACTTYPE == 3) && tmp.ISDEFAULT);
        // const defaultInvoiceMailItem = response.data.contactListArr.find(tmp => (tmp.ACONTACTTYPE == 2 || tmp.ACONTACTTYPE == 3) && tmp.ISDEFAULT);
        // this.endUsers = endUserArr.map(tmp => ({
        //   ID: tmp.ACCOUNTCONTACTID,
        //   TEXT: tmp.ENDUSERDETAILS,
        //   NAME: tmp.ACONTACTNAME,
        //   PHONE: tmp.ACONTACTPHONE,
        //   EMAIL: tmp.ACONTACTEMAIL,
        //   PHONEEXT: tmp.ACONTACTPHONEEXT,
        //   ACCOUNTUSERID: tmp.ACCOUNTUSERID
        // }));
        // this.endUsers.push({
        //   ID: "onetime",
        //   TEXT: "Enter New End User"
        // });
        // if (this.selectedEndUsers.length) {
        //   const selectedEndUserItem = this.endUsers.find(tmp => (tmp.ID == this.selectedEndUsers));
        //   this.euName = selectedEndUserItem.NAME;
        //   this.euPhone = selectedEndUserItem.PHONE;
        //   this.euEmail = selectedEndUserItem.EMAIL;
        //   this.euPhoneText = selectedEndUserItem.PHONEEXT;
        // }
        // if(defaultEndUserItem) {
        //   this.defaultEndUser = defaultEndUserItem.ACCOUNTCONTACTID;
        //   if(setDefault) {
        //     this.selectedEndUsers = [defaultEndUserItem.ACCOUNTCONTACTID];
        //     this.euName = defaultEndUserItem.ACONTACTNAME;
        //     this.euPhone = defaultEndUserItem.ACONTACTPHONE;
        //     this.euEmail = defaultEndUserItem.ACONTACTEMAIL;
        //     this.euPhoneText = defaultEndUserItem.ACONTACTPHONEEXT;
        //   }
        // }
        // this.invoiceMail = invoiceMailArr.map(tmp => ({
        //   ID: tmp.ACCOUNTCONTACTID,
        //   TEXT: tmp.ACONTACTEMAIL
        // }));
        // this.invoiceMail.push({
        //   ID: "onetime",
        //   TEXT: "Enter E-mail"
        // });
        // if(defaultInvoiceMailItem) {
        //   this.defaultInvoiceMail = defaultInvoiceMailItem.ACCOUNTCONTACTID;
        //   if(setDefault) {
        //     const defaultInvoiceMailIDs = response.data.contactListArr.filter(tmp => (tmp.ACONTACTTYPE == 2 || tmp.ACONTACTTYPE == 3) && tmp.ISDEFAULT);
        //     if (defaultInvoiceMailIDs.length) {
        //       this.contactEmail = defaultInvoiceMailIDs.map(item => item.ACCOUNTCONTACTID);
        //     }
        //   }
        // }
      } catch (error) {
        console.log(error);
      }
    }
    // return ret;
  }

  getAddressText(address) {
    var ret = "";
    const name = address.ADDRESSNAME || "";
    const addr1 = address.ACCOUNTSADDRESS_ADDRESS1 || "";
    const city = address.ACCOUNTSADDRESS_CITY || "";
    const state = address.ACCOUNTSADDRESS_STATE || "";
    const zip = address.ACCOUNTSADDRESS_ZIP || "";
    if(name != "") {
      ret += name + " -";
    }
    if(addr1 != "") {
      ret += " " + addr1;
    }
    if(city != "") {
      ret += " " + city;
    }
    if(state != "" || zip != "") {
      ret += ",";
      if(state != "") {
        ret += " " + state;
      }
      if(zip != "") {
        ret += " " + zip;
      }
    }

    return ret;
  }

  sortingAddresses(addressArray, selectAddress) {
    var arr: any = [];
    for (let i=0; i < (addressArray.length); i++) {
      if($.inArray(addressArray[i].ID,selectAddress) != -1) {
        arr.push(addressArray[i]);
        addressArray.splice(i,1);
        i--;
      }
    }
    arr.reverse().forEach(item => {
      addressArray.splice(0, 0, item);
    });
  }

  async addNewAddress(addressList, type) {
    if (type == "shipping") {
      if(addressList[0].isSave) {
        this.onetimeAddress = {};
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Accounts",
          FunctionName: "Update",
          aID: this.aID,
          addressList: addressList,
          addOneAddress: true
        });
        if(response.data.STATUS == 1 && typeof response.data.AccountsAddress_ID != "undefined") {
          await this.getCustomerAddresses(this.aID);
          this.selectedAddress = [response.data.AccountsAddress_ID];
          this.customerAddressVisible = false;
          // this.checkAutomateTaxRate("shipping");
        } else if (response.data.STATUS == 2) {
          $('#customer_address_name').addClass("error");
          notifier.alert(response.data.MESSAGE);
        }
      }else {
        this.onetimeAddress = {
          ADDRESSNAME: addressList[0].NAME,
          ACCOUNTSADDRESS_ADDRESS1: addressList[0].ADDR1,
          ACCOUNTSADDRESS_ADDRESS2: addressList[0].ADDR2,
          ACCOUNTSADDRESS_CITY: addressList[0].CITY,
          ACCOUNTSADDRESS_STATE: addressList[0].STATE,
          ACCOUNTSADDRESS_ZIP: addressList[0].ZIP
        };
        const newAddressItem = {
          ID: 0,// for one time address
          TEXT: this.getAddressText(this.onetimeAddress),
          data: this.onetimeAddress
        };
        let onetimeAddr = this.customerAddresses.find(item => item.ID == 0);
        if(onetimeAddr) {
          Object.assign(onetimeAddr, newAddressItem);
        }else {
          this.customerAddresses.unshift(newAddressItem);
        }
        this.selectedAddress = [0];
        this.customerAddressVisible = false;
        // this.checkAutomateTaxRate("shipping");
      }
    } else if (type == "billing") {
      if(addressList[0].isSave) {
        this.onetimeAddressBilling = {};
        addressList[0].ADDRTYPE = 2;
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Accounts",
          FunctionName: "Update",
          aID: this.aID,
          addressList: addressList,
          addOneAddress: true
        });
        if(response.data.STATUS == 1 && typeof response.data.AccountsAddress_ID != "undefined") {
          await this.getCustomerAddresses(this.aID);
          this.selectedBillingAddress = [response.data.AccountsAddress_ID];
          this.billingAddressVisible = false;
          // this.checkAutomateTaxRate("billing");
        } else if (response.data.STATUS == 2) {
          $('#customer_address_name').addClass("error");
          notifier.alert(response.data.MESSAGE);
        }
      }else {
        this.onetimeAddressBilling = {
          ADDRESSNAME: addressList[0].NAME,
          ACCOUNTSADDRESS_ADDRESS1: addressList[0].ADDR1,
          ACCOUNTSADDRESS_ADDRESS2: addressList[0].ADDR2,
          ACCOUNTSADDRESS_CITY: addressList[0].CITY,
          ACCOUNTSADDRESS_STATE: addressList[0].STATE,
          ACCOUNTSADDRESS_ZIP: addressList[0].ZIP
        };
        const newAddressItem = {
          ID: 0,// for one time address
          TEXT: this.getAddressText(this.onetimeAddressBilling),
          data: this.onetimeAddressBilling
        };
        let onetimeAddr = this.customerAddressesBilling.find(item => item.ID == 0);
        if(onetimeAddr) {
          Object.assign(onetimeAddr, newAddressItem);
        }else {
          this.customerAddressesBilling.unshift(newAddressItem);
        }
        this.selectedBillingAddress = [0];
        this.billingAddressVisible = false;
        // this.checkAutomateTaxRate("billing");
      }
    }
  }
  
  async downloadQuoteData() {
    if (this.$route.params.qID.length) {
      this.loading = true;
      try {
        var response = await ApiHelper.callApi(
          'post',
          {
            controller: "Quotes",
            FunctionName: "ExportView",
            ObjID: this.$route.params.qID,
            Content: 'Detailed',
            ExportType:'CSV',
            ExportMode: 'QuoteTemplate'
          }
        );
        if (response.STATUS) {
          this.loading = false;
          let fileUrl = response.S3URL;
          downloadFileUrl(fileUrl);
        } else {
          notifier.alert("Error occurred");
        }
      } catch (err) {
        notifier.alert(err.message);
      } finally {
        this.loading = false;
      }
    } else {
      notifier.alert("Invalid quote id");
    }
  }
}
