



























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import SortIndicator from "../components/SortIndicator.vue";
import { Component, Prop } from "vue-property-decorator";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
declare function require(params: any): any;

import FilterCurrencyBox from "../components/FilterCurrencyBox.vue";
import { notifier,
downloadFile,
downloadFileUrl 
} from "../models/common";
import { LedgersAPIRepo, Filters, Sort, SortField } from "../repos/LedgersAPIRepo";
import LedgerGenerateModal from "../components/LedgerGenerateModal.vue";
import Datepicker from "../components/Datepicker.vue";
import ExportModal, { ExportFormat } from "../components/ExportModal.vue";
import LedgerPaymentModal from "../components/LedgerPaymentModal.vue";
import FilterModal from "../components/FilterModal.vue";
import moment from "moment";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import { getRangeFilter, getDateRangeFilter } from "@/helpers/ApiHelper";

const template = require("../templates/orderLedgers/ledgersList.handlebars");

const ledgerRepo = new LedgersAPIRepo();

declare const $: any;
declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    ListHeaderActions,
    Pagination,
    SortIndicator,
    FilterCurrencyBox,
    LedgerGenerateModal,
    Datepicker,
    ExportModal,
    LedgerPaymentModal,
    FilterModal
  },
  methods: {
    getRangeFilter: (min, max) => {
      return getRangeFilter(min, max);
    },
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    }
  }
})

export default class pays extends TSXComponent<void> {
  $allowedFunctions: any;
  $userFunctions: any;
  loading = false;
  isSearch = 0;
  totalAll = 0;
  ret = {
    CURRENTPAGE: 1,
    TOTALPAGES: 1
  };
  totalLedgeritems = 0;
  pageNumber = sessionStorage.pageDetails && JSON.parse(sessionStorage.pageDetails)["ledgers"] ? JSON.parse(sessionStorage.pageDetails)["ledgers"] : 1;
  detail = [];
  statusList = [];
  Employee = 1;
  ledgerItems:any = [];
  sourceList = [];
  statusObj = [];
  entryTypeObj = [];
  isGrouped = 0;
  filters: Filters = {
    amount: "",
    entryType: [],
    purchaseID: "",
    source: [],
    sTotalMin: "",
    sTotalMax: "",
    createdDate: {
      sDate: "",
      eDate: ""
    },
    ledgerStatus: [],
    invoiceID: "",
    invoiceDueDate: {
      sDate: "",
      eDate: ""
    },
    paidDate: {
      sDate: "",
      eDate: ""
    },
    AccountTagID: [],
    gTagIDs: [],
    groupIDs: [],
    sourceName: [],
    entryTypeName: [],
    statusName: [],
    filterInvoiceDate: undefined,
    filterInvoiceDueDate: undefined,
    filterPaidDate: undefined,
    ledgerCategoryTitle: "Open Ledger"
  };
  sort = {
    field: 0,
    direction: {
      [SortField.purchaseID]: 2,
      [SortField.source]: 1,
      [SortField.entryType]: 1,
      [SortField.amount]: 1,
      [SortField.status]: 1,
      [SortField.invoiceID]: 1,
      [SortField.createdDate]: 1,
      [SortField.invoiceDueDate]: 1,
      [SortField.paidDate]: 1
    }
  };
  viewType = "All";
  selectedAll = false;
  selectPageAll = false;
  excludedIDs: number[] = [];
  selectedView = false;
  grouped = 1;
  exportModalVisible = false;
  ledgerGenerateModalVisible = false;
  selectedID: (number | undefined)[] = [];
  newPageLedger: (number | undefined)[] = [];
  totalLedgers: (number | undefined)[] = [];
  selectedPage = 0;
  CurrentView = "My Orders";
  accountTags: any = [];
  tagIDs: number[] = [];
  gTagIDs: number[] = [];
  groupTagIDs: number[] = [];
  selectedTags = [];
  searchTagsTxt = "";
  searchAccTxt = "";
  varAccs: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  varAccsList: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  selectedAccs: number[] = [];
  showFilter = 0;
  editLedgerInfoVisible = false;
  editStatusForLedgerID = 0;
  ledgerCategory = 1;
  ledgerCategoryTitle = "Open Ledger";
  paymentModalVisible = false;
  filterModalVisible = false;
  position = {
    purchaseID : 1,
    sourceName : 2,
    entryTypeName : 3,
    amount : 4,
    statusName : 5,
    invoiceID : 6,
    filterInvoiceDate : 7,
    filterInvoiceDueDate : 8,
    filterPaidDate : 9,
    ledgerCategoryTitle: 10
  };
  usedFilters = {
    purchaseID: {
      header: "VAR360 Order",
      function: "resetSearchById"
    },
    sourceName: {
      header: "Funding Source",
      function: "resetSourceList"
    },
    entryTypeName: {
      header: "Ledger Entry Type",
      function: "resetEntryTypeList"
    },
    amount: {
      header: "$ Amount",
      function: "resetSearchByAmount"
    },
    statusName: {
      header: "Status",
      function: "resetLedgerStatusList"
    },
    invoiceID: {
      header: "Invoice ID",
      function: "resetSearchByInvoiceID"
    },
    filterInvoiceDate: {
      header: 'Invoice Date',
      function: "resetSearchByCreatedDate"
    },
    filterInvoiceDueDate: {
      header: "Due Date",
      function: "resetSearchByDueDate"
    },
    filterPaidDate: {
      header: "Paid Date",
      function: "resetSearchByPaidDate"
    },
    ledgerCategoryTitle: {
      header: "Ledger Category",
      function: ""
    }
  }
  usedFilterDetails: any = [];
  searchFilters: Filters = {
    amount: "",
    entryType: [],
    purchaseID: "",
    source: [],
    sTotalMin: "",
    sTotalMax: "",
    createdDate: {
      sDate: "",
      eDate: ""
    },
    ledgerStatus: [],
    invoiceID: "",
    invoiceDueDate: {
      sDate: "",
      eDate: ""
    },
    paidDate: {
      sDate: "",
      eDate: ""
    },
    AccountTagID: [],
    gTagIDs: [],
    groupIDs: [],
    sourceName: [],
    entryTypeName: [],
    statusName: [],
    filterInvoiceDate: undefined,
    filterInvoiceDueDate: undefined,
    filterPaidDate: undefined,
    ledgerCategoryTitle: "Open Ledger"
  };

  async created() {
    if (typeof this.$route.query.sTotalMin === "string") {
      this.filters.sTotalMin  = this.searchFilters.sTotalMin = this.$route.query.sTotalMin;
    }
    if (typeof this.$route.query.sTotalMax === "string") {
      this.filters.sTotalMax = this.searchFilters.sTotalMax = this.$route.query.sTotalMax;
    }
    await this.fetchData(false,true);
    await this.accsLoad();
  }
  
  async searchById() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.purchaseID == "") {
      return;
    } else {
      this.filters.purchaseID = this.searchFilters.purchaseID;
      await this.fetchData(this.grouped);
    }
  }
  
  async resetSearchById(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.purchaseID = this.searchFilters.purchaseID = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if(allowFetchData) {
      await this.fetchData(this.grouped);
    }
  }
  async searchByCreatedDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.searchFilters.createdDate.sDate == undefined ||
        this.searchFilters.createdDate.sDate == "") &&
      (this.searchFilters.createdDate.eDate == "" ||
        this.searchFilters.createdDate.eDate == undefined)
    ) {
      notifier.alert("Please fill From or To Date");
    } else {
      this.selectedAll = false;
      this.excludedIDs = [];
      this.filters.createdDate.sDate = this.searchFilters.createdDate.sDate;
      this.filters.createdDate.eDate = this.searchFilters.createdDate.eDate;
      await this.fetchData(this.grouped);
    }
  }
  async searchByInvoiceDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.searchFilters.invoiceDueDate.sDate == undefined ||
        this.searchFilters.invoiceDueDate.sDate == "") &&
      (this.searchFilters.invoiceDueDate.eDate == "" ||
        this.searchFilters.invoiceDueDate.eDate == undefined)
    ) {
      notifier.alert("Please fill From or To Date");
    } else {
      this.selectedAll = false;
      this.excludedIDs = [];
      this.filters.invoiceDueDate.sDate = this.searchFilters.invoiceDueDate.sDate;
      this.filters.invoiceDueDate.eDate = this.searchFilters.invoiceDueDate.eDate;
      await this.fetchData(this.grouped);
    }
  }
  
  async resetSearchByCreatedDate(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (
      (this.searchFilters.createdDate.sDate == undefined ||
        this.searchFilters.createdDate.sDate == "") &&
      (this.searchFilters.createdDate.eDate == "" ||
        this.searchFilters.createdDate.eDate == undefined)
    ) {
      return;
    } else {
      this.filters.createdDate.sDate = this.searchFilters.createdDate.sDate = "";
      this.filters.createdDate.eDate = this.searchFilters.createdDate.eDate = "";
      this.filters.filterInvoiceDate = "";
      if(allowFetchData) {
        await this.fetchData(this.grouped);
      }
    }
  }
  async resetSearchByInvoiceDate(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (
      (this.searchFilters.invoiceDueDate.sDate == undefined ||
        this.searchFilters.invoiceDueDate.sDate == "") &&
      (this.searchFilters.invoiceDueDate.eDate == "" ||
        this.searchFilters.invoiceDueDate.eDate == undefined)
    ) {
      return;
    } else {
      this.filters.invoiceDueDate.sDate = this.searchFilters.invoiceDueDate.sDate = "";
      this.filters.invoiceDueDate.eDate = this.searchFilters.invoiceDueDate.eDate = "";
      this.filters.filterInvoiceDate = "";
      if(allowFetchData) {
        await this.fetchData(this.grouped);
      }
    }
  }
  async searchByPaidDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.searchFilters.paidDate.sDate == undefined ||
        this.searchFilters.paidDate.sDate == "") &&
      (this.searchFilters.paidDate.eDate == "" ||
        this.searchFilters.paidDate.eDate == undefined)
    ) {
      notifier.alert("Please fill From or To Date");
    } else {
      this.selectedAll = false;
      this.excludedIDs = [];
      this.filters.paidDate.sDate = this.searchFilters.paidDate.sDate;
      this.filters.paidDate.eDate = this.searchFilters.paidDate.eDate;
      await this.fetchData(this.grouped);
    }
  }
  async resetSearchByPaidDate(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (
      (this.searchFilters.paidDate.sDate == undefined ||
        this.searchFilters.paidDate.sDate == "") &&
      (this.searchFilters.paidDate.eDate == "" ||
        this.searchFilters.paidDate.eDate == undefined)
    ) {
      return;
    } else {
      this.filters.paidDate.sDate = this.searchFilters.paidDate.sDate = "";
      this.filters.paidDate.eDate = this.searchFilters.paidDate.eDate = "";
      this.filters.filterPaidDate = "";
      if(allowFetchData) {
        await this.fetchData(this.grouped);
      }
    }
  }
  async resetSearchByAmount(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sTotalMin = this.searchFilters.sTotalMin = "";
    this.filters.sTotalMax = this.searchFilters.sTotalMax = "";
    this.filters.amount = "";
    if(allowFetchData) {
      await this.fetchData(this.grouped);
    }
  }
  async searchByAmount() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sTotalMin = this.searchFilters.sTotalMin;
    this.filters.sTotalMax = this.searchFilters.sTotalMax;
    await this.fetchData(this.grouped);
  }
  hasChangedSourceFilters = false;
  async checkSourceList(source: string, name: string) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    const i = this.filters.source.findIndex(s => s === source);
    const sourceIndex = this.filters.sourceName.findIndex((sourceValue) => sourceValue === name);
    if (i === -1) {
      this.filters.source.push(source);
    } else {
      this.filters.source.splice(i, 1);
    }
    if (sourceIndex === -1) {
      this.filters.sourceName.push(name);
    } else {
      this.filters.sourceName.splice(sourceIndex, 1);
    }
    this.hasChangedSourceFilters = true;
    // await this.fetchData(this.grouped);
  }
  async checkAllSourceList(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.source = Object.keys(this.sourceList);
    this.filters.sourceName = Object.values(this.sourceList);
    if(allowFetchData) {
      await this.fetchData(this.grouped);
    }
  }
  async uncheckAllSourceList() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.source = [];
    this.filters.sourceName = [];
    await this.fetchData(this.grouped);
  }
  async resetSourceList(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.source = [];
    this.filters.sourceName = [];
    this.checkAllSourceList(allowFetchData);
    //await this.fetchData(this.grouped);
  }
  hasChangedEntryTypeFilters = false;
  async checkEntryTypeList(entryType: string, name: string) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    const i = this.filters.entryType.findIndex(s => s === entryType);
    const entryTypeIndex = this.filters.entryTypeName.findIndex((entryTypeValue) => entryTypeValue === name);
    if (i === -1) {
      this.filters.entryType.push(entryType);
    } else {
      this.filters.entryType.splice(i, 1);
    }
    if (entryTypeIndex === -1) {
      this.filters.entryTypeName.push(name);
    } else {
      this.filters.entryTypeName.splice(entryTypeIndex, 1);
    }
    this.hasChangedEntryTypeFilters = true;
    // await this.fetchData(this.grouped);
  }
  async checkAllEntryTypeList(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.entryType = Object.keys(this.entryTypeObj);
    this.filters.entryTypeName = Object.values(this.entryTypeObj);
    if(allowFetchData) {
      await this.fetchData(this.grouped);
    }
  }
  async uncheckAllEntryTypeList() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.entryType = [];
    this.filters.entryTypeName = [];
    await this.fetchData(this.grouped);
  }
  async resetEntryTypeList(allowFetchData = true) {
    this.pageNumber = 1; 
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.entryType = [];
    this.filters.entryTypeName = [];
    this.checkAllEntryTypeList(allowFetchData);
    //await this.fetchData(this.grouped);
  }

async checkLedgerStatusList(ledgerStatus: string, name: string) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    const i = this.filters.ledgerStatus.findIndex(s => s === ledgerStatus);
    const ledgerStatusIndex = this.filters.statusName.findIndex((ledgerStatusValue) => ledgerStatusValue === name);
    if (i === -1) {
      this.filters.ledgerStatus.push(ledgerStatus);
    } else {
      this.filters.ledgerStatus.splice(i, 1);
    }
    if (ledgerStatusIndex === -1) {
      this.filters.statusName.push(name);
    } else {
      this.filters.statusName.splice(ledgerStatusIndex, 1);
    }
    await this.fetchData(this.grouped);
  }

  async checkAllLedgerStatusList(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.ledgerStatus = Object.keys(this.statusObj);
    this.filters.statusName = Object.values(this.statusObj);
    if(allowFetchData) {
      await this.fetchData(this.grouped);
    }
  }

  async uncheckAllLedgerStatusList() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.ledgerStatus = [];
    this.filters.statusName = [];
    await this.fetchData(this.grouped);
  }

  async resetLedgerStatusList(allowFetchData = true) {
    this.pageNumber = 1; 
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.ledgerStatus = [];
    this.filters.statusName = [];
    this.checkAllLedgerStatusList(allowFetchData);
    //await this.fetchData(this.grouped);
  }

  async searchByInvoiceID() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.invoiceID == "") {
      return;
    } else {
      this.filters.invoiceID = this.searchFilters.invoiceID;
      await this.fetchData(this.grouped);
    }
  }
  async resetSearchByInvoiceID(allowFetchData = true) {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = []; 
    this.filters.invoiceID = this.searchFilters.invoiceID = "";
    if(allowFetchData) {
      await this.fetchData(this.grouped);
    }
  }
  async searchByDueDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.searchFilters.invoiceDueDate.sDate == undefined ||
        this.searchFilters.invoiceDueDate.sDate == "") &&
      (this.searchFilters.invoiceDueDate.eDate == "" ||
        this.searchFilters.invoiceDueDate.eDate == undefined)
    ) {
      notifier.alert("Please fill From or To Date");
    } else {
      this.selectedAll = false;
      this.excludedIDs = [];
      this.filters.invoiceDueDate.sDate = this.searchFilters.invoiceDueDate.sDate;
      this.filters.invoiceDueDate.eDate = this.searchFilters.invoiceDueDate.eDate;
      await this.fetchData(this.grouped);
    }
  }
  async resetSearchByDueDate(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (
      (this.searchFilters.invoiceDueDate.sDate == undefined ||
        this.searchFilters.invoiceDueDate.sDate == "") &&
      (this.searchFilters.invoiceDueDate.eDate == "" ||
        this.searchFilters.invoiceDueDate.eDate == undefined)
    ) {
      return;
    } else {
      this.filters.invoiceDueDate.sDate = this.searchFilters.invoiceDueDate.sDate = "";
      this.filters.invoiceDueDate.eDate = this.searchFilters.invoiceDueDate.eDate = "";
      this.filters.filterInvoiceDueDate = "";
      if(allowFetchData) {
        await this.fetchData(this.grouped);
      }
    }
  }
  
  async sortBy(field: SortField) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData(this.grouped);
  }
  async sortingByPurchaseID() {
    await this.sortBy(SortField.purchaseID);
  }
  async linkByPurchaseID() {
    await this.fetchData(true);
  }
  async sortingByFundingSource() {
    await this.sortBy(SortField.source);
  }
  async sortingByAmount() {
    await this.sortBy(SortField.amount);
  }
  async sortingByType() {
    await this.sortBy(SortField.entryType);
  }
  async sortingByCreatedDate() {
    await this.sortBy(SortField.createdDate);
  }
  async sortingByPaidDate() {
    await this.sortBy(SortField.paidDate);
  }
  async sortingByStatus() {
    await this.sortBy(SortField.status);
  }
  async sortingByInvoiceID() {
    await this.sortBy(SortField.invoiceID);
  }
  async sortingByDueDate() {
    await this.sortBy(SortField.invoiceDueDate);
  }
  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    if(sessionStorage.pageDetails != undefined && JSON.parse(sessionStorage.pageDetails)["ledgers"]){
      var oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["ledgers"] = this.pageNumber;
      sessionStorage.setItem('pageDetails',JSON.stringify(oldData))
    }

    if (this.pageNumber <= this.ret.TOTALPAGES && this.pageNumber >= 1) {
      await this.fetchData(this.grouped);
    }
  }
  
  async fetchData(grouped, initialLoad: boolean = false) {
    if (!grouped || (grouped && grouped != true)) {
      this.grouped = 0;
    }
    else{
      this.grouped = 1;
    }
    this.hasChangedSourceFilters = false;
    this.hasChangedEntryTypeFilters = false;
    this.loading = true;
    try {
      const ret = await ledgerRepo.find(
        this.filters,
        this.sort,
        this.pageNumber,
        this.viewType,
        this.selectedView,
        this.grouped,
        this.ledgerCategory,
        initialLoad
      );
      this.ret = ret;
      this.ledgerItems = ret.LEDGEROVERVIEW;
      this.isGrouped = ret.ISGROUPED;
      this.isSearch = ret.ISSEARCH;
      this.totalAll = ret.TOTALALL;
      this.totalLedgeritems = ret.TOTALLEDGERITEMS;
      this.statusList = ret.STATUSLIST;
      this.sourceList = ret.sourcesObj;
      this.statusObj = ret.statusObj;
      this.entryTypeObj = ret.entryTypeObj;
      if (this.searchTagsTxt == "" &&	typeof ret.accountTags != "undefined") {
        this.accountTags = ret.accountTags;
      }	
      if ((this.filters.AccountTagID && this.filters.AccountTagID.length) || (this.filters.gTagIDs && this.filters.gTagIDs.length) || (this.filters.groupIDs && this.filters.groupIDs.length)) {
          this.selectedView = true;
          this.CurrentView = this.getCurrentViewTitle();
        } else {
          // reset
          this.selectedView = false;
      }
      if (
        !this.selectedView &&
        typeof ret["userSelectedCurrentView"] != "undefined"
      ) {
        this.selectedView = true;
        this.viewType = ret["userSelectedCurrentView"];
        this.CurrentView =
        this.viewType == "All" ? "Company Orders" : "My Orders";
      }
      if(typeof this.sourceList != 'undefined' && this.filters.source.length == 0 && initialLoad) {
				this.filters.source = Object.keys(this.sourceList);
        this.filters.sourceName = Object.values(this.sourceList);
			}
      if(typeof this.statusObj != 'undefined' && this.filters.ledgerStatus.length == 0 && initialLoad) {
				this.filters.ledgerStatus = Object.keys(this.statusObj);
				this.filters.statusName = Object.values(this.statusObj);
			}
      if(typeof this.entryTypeObj != 'undefined' && this.filters.entryType.length == 0 && initialLoad) {
				this.filters.entryType = Object.keys(this.entryTypeObj);
				this.filters.entryTypeName = Object.values(this.entryTypeObj);
			}

      this.filters['filterInvoiceDate'] = "";
      if(this.filters.createdDate.sDate != "") {
        this.filters['filterInvoiceDate'] = moment(this.filters.createdDate.sDate).format("MM/DD/YYYY");
      }
      if(this.filters.createdDate.sDate != "" && this.filters.createdDate.eDate != ""){
        this.filters['filterInvoiceDate'] += ' - ';
      }
      if(this.filters.createdDate.eDate != ""){
        this.filters['filterInvoiceDate'] += moment(this.filters.createdDate.eDate).format("MM/DD/YYYY");
      }

      this.filters['filterInvoiceDueDate'] = "";
      if(this.filters.invoiceDueDate.sDate != "") {
        this.filters['filterInvoiceDueDate'] = moment(this.filters.invoiceDueDate.sDate).format("MM/DD/YYYY");
      }
      if(this.filters.invoiceDueDate.sDate != "" && this.filters.invoiceDueDate.eDate != ""){
        this.filters['filterInvoiceDueDate'] += ' - ';
      }
      if(this.filters.invoiceDueDate.eDate != ""){
        this.filters['filterInvoiceDueDate'] += moment(this.filters.invoiceDueDate.eDate).format("MM/DD/YYYY");
      }

      this.filters['filterPaidDate'] = "";
      if(this.filters.paidDate.sDate != "") {
        this.filters['filterPaidDate'] = moment(this.filters.paidDate.sDate).format("MM/DD/YYYY");
      }
      if(this.filters.paidDate.sDate != "" && this.filters.paidDate.eDate != ""){
        this.filters['filterPaidDate'] += ' - ';
      }
      if(this.filters.paidDate.eDate != ""){
        this.filters['filterPaidDate'] += moment(this.filters.paidDate.eDate).format("MM/DD/YYYY");
      }

      if(this.filters['sTotalMin'] != "" && this.filters['sTotalMax'] != ""){
        this.filters['amount'] = '$' + this.filters['sTotalMin'] + ' - ' + this.filters['sTotalMax'];
      } else if(this.filters['sTotalMin'] != "") {
        this.filters['amount'] = '$' + this.filters['sTotalMin'] + '+';
      } else if(this.filters['sTotalMax'] != ""){
        this.filters['amount'] = '$' + this.filters['sTotalMax'] + '-';
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }

    if (this.selectedAll === true) {
      this.selectAll(true);
    }
    var checkAllPages = this.newPageLedger.includes(this.pageNumber);
    if (checkAllPages)  {
      this.totalLedgers = [];
      for (var val of this.ledgerItems) {
        if (!this.excludedIDs.includes(val.PLID)) {
          this.totalLedgers.push(val.PLID);
        }
      }
      var intersectingArrays = this.getArraysIntersection(this.totalLedgers, this.selectedID);
      if (intersectingArrays.length == this.ledgerItems.length) {
        this.ledgersCheckAll = true;
      } else {
        this.ledgersCheckAll = false;
      }
    }
    else {
      this.ledgersCheckAll = false;
    }
    if(this.isSearch ==1 && !initialLoad) {
      this.showFilter = 1;
    }
    this.usedFilterDetails = this.query;
  }

  onChange(event,id,items) {
    var $this = this;
    getRouteData({
      controller: "Finances",
      FunctionName: "UpdateLedgerStatus",
      oppStatus: event.target.value,
      oppID: id,
      oppType: items.D_FINTYPE_ID,
      purchaseID: items.PURCHASEID,
      ledgerType: items.PL_TYPE
    }).then(function(response, statusText, jqXHR) {
      if (typeof response.data.PAIDDATE != 'undefined') {
        items.IPAIDDATE = response.data.PAIDDATE
      }
      if(response.data.STATUS == 1) {
        $this.fetchData($this.grouped);
      }
    });
  }

  //updateList(updatedItems){
  // console.dir(updatedItems.data);

  //this.ledgerItems = updatedItems.data.LedgerList;
  //this.totalAll = updatedItems.data.LedgerList.length;

  // this.ledgerGenerateModalVisible = false;
  //}

  async performExport(formats: ExportFormat[],customtemplateId,saveAsDefaultTemplate,displayOnExport,bigdeal,exportPO,aztempidx,advancedFilters,customMessages,exportOrderDetail,reportingOrderDetail,exportDirectPaycheckDetail,CustomDistyName,CustomDistyAddr1,CustomDistyAddr2,CustomDistyCity,CustomDistyState,CustomDistyZip,varSourceName,exportInvoiceAgingReport,exportLedgerAgingReport) {
    this.exportModalVisible = false;
    this.loading = true;

    const exportFns: Function[] = [];

    if (formats.includes(ExportFormat.csv)) {
      exportFns.push(await this.csvDownLoadFn());
    }

    if (formats.includes(ExportFormat.html)) {
      exportFns.push(await this.htmlDownLoadFn());
    }

    if (formats.includes(ExportFormat.pdf)) {
      exportFns.push(await this.pdfDownLoadFn());
    }

    if (exportLedgerAgingReport) {
      exportFns.push(await this.agingReportDownLoadFn());
    }

    exportFns.forEach(fn => fn());

    this.loading = false;
  }

  async csvDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await ledgerRepo.exportListsCSVFileUrl(
        this.filters,
        this.sort,
        this.viewType,
        this.selectedView,
        this.selectedAll,
        this.excludedIDs,
        this.selectedID,
        this.ledgerCategory
      );
    } catch (err) {
      notifier.alert("Export CSV error: " + err.message);
    }

    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async htmlDownLoadFn() {
    const html = await this.selectedQuoteHtml("html");

    return () => {
      if (!html) return;
      var fileNameExport =
        "orderLedgers_" + Math.round(+new Date() / 1000) + ".html";
      downloadFile(fileNameExport, html);
    };
  }

  async pdfDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await ledgerRepo.exportListsPDFFileUrl(
        this.filters,
        this.sort,
        this.viewType,
        this.selectedView,
        this.selectedAll,
        this.excludedIDs,
        this.selectedID,
        this.ledgerCategory
      );
    } catch (err) {
      notifier.alert("Export PDF error: " + err.message);
    }

    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async agingReportDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await ledgerRepo.exportLedgerAgingReportFileUrl(
        this.filters,
        this.sort,
        this.viewType,
        this.selectedView,
        this.selectedAll,
        this.excludedIDs,
        this.selectedID,
        this.ledgerCategory
      );
    } catch (err) {
      notifier.alert("Export CSV error: " + err.message);
    }

    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async selectedQuoteHtml(from: string): Promise<string | false> {
    try {
      const LedgersList = await ledgerRepo.findByIDs(
        this.filters,
        this.sort,
        this.viewType,
        this.selectedView,
        this.selectedAll,
        this.excludedIDs,
        this.selectedID,
        this.ledgerCategory
      );

      return template(LedgersList);
    } catch (err) {
      notifier.alert(err.message);
      return false;
    }
  }  

  ledgersCheckAll = false;
  ledgersCheckAllChange() {
    this.ledgersCheckAll = !this.ledgersCheckAll;
    if (!this.ledgersCheckAll) {
      this.selectInPage(false);
    } else {
      this.selectInPage(true);
    }
  }

  selectInPage(selectedAllPage = true) {
    this.selectPageAll = selectedAllPage;
    this.showFilter = 0;
    if (this.selectPageAll == true) {
      this.selectedPage = this.pageNumber
      this.newPageLedger.push(this.selectedPage);
      for (var val of this.ledgerItems) {
        if (!this.excludedIDs.includes(val.PLID)) {
          if(this.selectedID.indexOf(val.PLID) == -1)  {
            this.selectedID.push(val.PLID);
            this.totalLedgers.push(val.PLID);
          }
        }
      }
    } else {
      for (var val of this.ledgerItems) {
        let selectedIDIndex = this.selectedID.indexOf(val.PLID);
        let totalLedgersIndex = this.totalLedgers.indexOf(val.PLID);
        this.selectedID.splice(selectedIDIndex, 1);
        this.totalLedgers.splice(totalLedgersIndex, 1);
      }
      let newPageLedgerIndex = this.newPageLedger.indexOf(this.pageNumber)
      this.newPageLedger.splice(newPageLedgerIndex, 1);
    }
  }

  checkExcludedIDs(items, key) {
    if (this.selectedAll || this.selectPageAll) {
      for (var val of items) {
        if (!this.selectedID.includes(val[key])) {
          if (!this.excludedIDs.includes(val[key])) {
            this.excludedIDs.push(val[key]);
            this.ledgersCheckAll = false;
          }
        } else if (this.excludedIDs.includes(val[key])) {
          this.$delete(
            this.excludedIDs,
            this.excludedIDs.findIndex((id) => id == val[key])
          );
        }
      }

      if (this.getSelectedTotal(this.totalLedgeritems) == 0) {
        this.selectedAll = false;
        this.excludedIDs = [];
      }
    }
    if(this.selectedID.length == this.totalLedgers.length && this.selectedID.length !=0) {
      this.ledgersCheckAll = true;
    }
    else{
      this.ledgersCheckAll = false;
    }
  }

  selectAll(selectedAll = true) {
    this.ledgersCheckAll = false;
    this.excludedIDs = [];
    this.selectedAll = selectedAll;
    this.selectedID = [];
    this.showFilter = 0;
    if (this.selectedAll == true) {
      for (var val of this.ledgerItems) {
        if (!this.excludedIDs.includes(val.PLID)) {
          this.selectedID.push(val.PLID);
        }
      }
    } else {
      //uncheck
      this.excludedIDs = [];
    }
  }
  
  getSelectedTotal(total) {
    var ret = 0;
    if (this.selectedAll) {
      ret = total - this.excludedIDs.length;
    } else {
      ret = this.selectedID.length;
    }
    return ret;
  }

  getArraysIntersection(getLedgersOne,getLedgersTwo) {
    return getLedgersOne.filter(function(n) { return getLedgersTwo.indexOf(n) !== -1;});
  }
  getCurrentViewTitle() {
    if ((this.filters.AccountTagID && this.filters.AccountTagID.length) || (this.filters.gTagIDs && this.filters.gTagIDs.length) || (this.filters.groupIDs && this.filters.groupIDs.length)) {
      var fulltitle:any = [];

      var $this = this;
      this.accountTags.forEach(function (val: any) {
        if (!isNaN(val.ACCOUNTTAGID)) {
          if (typeof val.ISGLOBALTAG == 'undefined' && typeof val.ISGROUP == 'undefined' && $this.filters.AccountTagID && $this.filters.AccountTagID.includes(val.ACCOUNTTAGID)) {
            fulltitle.push(val.TAGNAME);
          }
          if (typeof val.ISGLOBALTAG != 'undefined' && $this.filters.gTagIDs && $this.filters.gTagIDs.includes(val.ACCOUNTTAGID)) {
            fulltitle.push(val.TAGNAME);
          }
          if (typeof val.ISGROUP != 'undefined' && $this.filters.groupIDs && $this.filters.groupIDs.includes(val.ACCOUNTTAGID)) {
            fulltitle.push(val.TAGNAME);
          }
        }
      });
      return fulltitle.join(", ");
    }

    return "";
  }
  async accsLoad() {
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        FunctionName: "ListExt",
        controller: "Accounts",
        queryType: "top5UsedAcct",
        view: this.viewType,
        isOrder: 1,
        accSearch: 1,
      });
      if (response.data.STATUS) {
        this.varAccs = response.data.ACCOUNTS;
        this.selectedAccs = response.data.VARACCOUNTSIDSRR;
        this.varAccsList = response.data.ACCOUNTS;
        if (this.searchAccTxt != "") {
          await this.filterAccsInBox();
        }
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
  }

  async selectView(item, e) {
    if (isNaN(item.ACCOUNTTAGID)) {
      // selected My Orders / company Orders
      this.tagIDs = [];
      this.filters.gTagIDs = [];
      this.filters.groupIDs = [];
      this.filters.AccountTagID = []; // reset
      this.selectedTags = []; // reset
      this.CurrentView = item.TAGNAME;
      this.viewType = item.ACCOUNTTAGID;
      this.selectedView = true;
      await this.fetchData(this.grouped);
      await this.accsLoad();
    } else {
      e.stopPropagation();
    }
  }

  filterAccsInBox() {
    let options = this.varAccsList;

    if (this.searchAccTxt) {
      options = options.filter(
        (option) =>
          `${option.ANAME}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTALIAS}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTID}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1
      );
    }
    this.varAccs = options;
  }

  async filterByTag() {
    this.tagIDs = [];
    if ((this.filters.AccountTagID && this.filters.AccountTagID.length) || (this.filters.gTagIDs && this.filters.gTagIDs.length) || (this.filters.groupIDs && this.filters.groupIDs.length)) {
      this.selectedView = true;
      this.CurrentView = this.getCurrentViewTitle();
    } else {
      // reset
      this.selectedView = false;

      if(!this.$allowedFunctions.includes(this.$userFunctions.All_Milestone_Accounts)) {
        this.CurrentView = "My Orders";
        this.viewType = "CurrentUsers";
      }

    }
    this.resetSelected();
    this.pageNumber = 1;
    await this.fetchData(this.grouped);
  }

  resetSelected() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
  }
  openLedgerInfo(ledgerID) {
    this.editStatusForLedgerID = ledgerID;
    this.editLedgerInfoVisible = true;
  }

  async ledgerAdd(ledgerVals) {
      try {
        this.loading = true;
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Finances",
          FunctionName: "ledgerGenerate",
          pl_amount: ledgerVals.pl_amount,
          fType: ledgerVals.fType,
          fSource: ledgerVals.fSource,
          fStatus: ledgerVals.fStatus,
          purchaseID: ledgerVals.purchaseID,
          invoiceID: ledgerVals.invoiceID,
          orderInvoiceDate: ledgerVals.orderInvoiceDate,
          invoiceDueDate: ledgerVals.invoiceDueDate,
          ledgerPaidDate: ledgerVals.ledgerPaidDate
        });

        if (response.data.ERROR) {
          throw new Error(response.data.ERROR);
        }

        if (response.data.STATUS == 1) {
          await this.fetchData(false);
        }
      } catch (err) {
        //console.log(err.message);
      } finally {
        this.ledgerGenerateModalVisible = false;
        this.loading = false;
      }
  }

  async ledgerUpdate(ledgerVals) {
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Finances",
          FunctionName: "ledgerGenerate",
          pl_amount: ledgerVals.pl_amount,
          fType: ledgerVals.fType,
          fSource: ledgerVals.fSource,
          fStatus: ledgerVals.fStatus,
          purchaseID: ledgerVals.purchaseID,
          invoiceID: ledgerVals.invoiceID,
          orderInvoiceDate: ledgerVals.orderInvoiceDate,
          invoiceDueDate: ledgerVals.invoiceDueDate,
          ledgerPaidDate: ledgerVals.ledgerPaidDate,
          action : "ledgerEdit",
          ledgerID : this.editStatusForLedgerID
        });

        if (response.data.ERROR) {
          throw new Error(response.data.ERROR);
        }

        if (response.data.STATUS == 1) {
          notifier.success(response.data.STATUSMESSAGE);
          await this.fetchData(false);
        }
      } catch (err) {
        //console.log(err.message);
      } finally {
        this.editLedgerInfoVisible = false;
      }
  }
  async toggleLedgerView(category) {
    if(category == 1) {
      this.ledgerCategoryTitle ="Open Ledger";
    } else {
      this.ledgerCategoryTitle ="Complete Ledger";
    }
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];

    this.ledgerCategory = category;
    this.filters.ledgerCategoryTitle = this.ledgerCategoryTitle;
    await this.fetchData(false);
  }
  validFilters(query: object, position: object) {
    var arr: any[] = [];
    query["sourceName"] = query["sourceName"] ? query["sourceName"] : "None";
    query["entryTypeName"] = query["entryTypeName"] ? query["entryTypeName"] : "None";
    query["statusName"] = query["statusName"] ? query["statusName"] : "None";
    const queryFilters = Object.entries(query).reduce((a, [k, v]) => {
      if (k == "source" || k == "sourceName") {
        return (v.length == Object.keys(this.sourceList).length) ? a : { ...a, [k]: v };
      }

      if (k == "entryType" || k == "entryTypeName") {
        return (v.length == Object.keys(this.entryTypeObj).length) ? a : { ...a, [k]: v };
      }

      if (k == "ledgerStatus" || k == "statusName") {
        return (v.length == Object.keys(this.statusObj).length) ? a : { ...a, [k]: v };
      }

      return v == null || v === "" || k == "unassigned" ? a : { ...a, [k]: v };

    }, {});
    $.each(queryFilters, function(key,valueObj){
      if(position[key] != undefined) {
        let tempStruct: any = {};
        tempStruct[key] = valueObj;
        tempStruct["position"] = position[key];
        arr.push(tempStruct);
      }
    });
    var sortable = Object.entries(arr).sort(function(a, b) {
      return a[1].position - b[1].position;
    });

    var validFilters: any[] = [];
    for(var i in sortable){
      let val = sortable[i]
      let lastItem = val[sortable[i].length - 1];
      let key = Object.keys(lastItem)[0];
      var value = Object.values(lastItem)[0];
      if(Array.isArray(value)) {
        value = value.join(" ,");
      }
      let tempFilterStruct: any = {};
      tempFilterStruct[key] = value;
      validFilters.push(tempFilterStruct);
    }
    return validFilters;
  }

  get query() {
    const query = Object.entries(this.filters).reduce((a, [k, v]) => {
      if (Array.isArray(v)) {
        return v.length === 0 ? a : { ...a, [k]: v };
      }

      return v == null || v === "" ? a : { ...a, [k]: v };
    }, {});
    return query;
  }

  async clearFilter() {
    this.resetSearchById(false);
    this.resetSearchByAmount(false);
    this.resetSearchByInvoiceID(false);
    this.resetSearchByCreatedDate(false);
    this.resetSearchByDueDate(false);
    this.resetSearchByPaidDate(false);
    this.resetSourceList(false);
    this.resetEntryTypeList(false);
    this.resetLedgerStatusList(false);
    this.filterModalVisible = false;
    await this.fetchData(this.grouped);
  }


  getFilterValue(header) {
    let filter = '';
    let searchKey: string | undefined = '';
    switch (header) {
      case 'STATUS':
        if (this.filters.statusName && !this.filters.statusName.length)
          filter = "None";
        else if (
          this.filters.statusName &&
          !(Object.keys(this.statusObj).length == this.filters.statusName.length)
        ) {
          filter = this.filters.statusName.join(", ");
        }
        break;
      case 'SOURCE':
        if (this.filters.sourceName && !this.filters.sourceName.length)
          filter = "None";
        else if (
          this.filters.sourceName &&
          !(Object.keys(this.sourceList).length == this.filters.sourceName.length)
        ) {
          filter = this.filters.sourceName.join(", ");
        }
        break;
      case 'ENTRYTYPE':
        if (this.filters.entryTypeName && !this.filters.entryTypeName.length)
          filter = "None";
        else if (
          this.filters.entryTypeName &&
          !(Object.keys(this.entryTypeObj).length == this.filters.entryTypeName.length)
        ) {
          filter = this.filters.entryTypeName.join(", ");
        }
        break;
      default:
        // nothing
        break;
    }
    return (filter ? `${filter}` : '') + (searchKey ? (filter ? ' - ' : '') + searchKey : '');
  }

  async onFileChange(e) {
    e.stopPropagation();
    e.preventDefault();

    const fileName = e.target.files[0].name;
    const allowedExtensions = /(\.csv|\.xls|\.xlsx)$/i;
    if (!allowedExtensions.exec(fileName)) {
      notifier.alert("Please upload file having extensions .csv/.xls/.xlsx only.");
      return false;
    }

    try {
      //send data
      const requestObj = {
        Controller: "Finances",
        FunctionName: "cogsImport",
        filePath: e.target.files[0],
        fileName,
        fileType:
          fileName.indexOf(".csv") != -1
            ? "csv"
            : fileName.indexOf(".xls") != -1
            ? "xls"
            : fileName.indexOf(".xlsx") != -1
            ? "xlsx"
            : ""
      };
      const formData = new FormData();
      for (let key in requestObj) {
        formData.append(key, requestObj[key]);
      }
      formData.append("session", sessionStorage.getItem("sessionID") || "");

      const response = await axios.post(dataURL + "?ReturnType=JSON", formData);

      const message = response.data.STATUSMESSAGE || "";
      if (response.data.STATUS == 1 || response.data.STATUS == 2) {
        if (response.data.STATUS == 1) {
          notifier.success(message);
        } else {
          notifier.alert(message);
        }
        await this.fetchData(this.grouped);
      } else {
        notifier.alert(message);
      }
    } catch (error) {
      // console.log(error);
    }
  }
}
