var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.footerContent : stack1), depth0))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.INVOICEDETAILS : depth0)) != null ? stack1.INOTES_FORMATTED : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":17,"column":15}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.INVOICEDETAILS : depth0)) != null ? stack1.INOTES_FORMATTED : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.INVOICEDETAILS : depth0)) != null ? stack1.ENDMESSAGE : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":12,"column":10},"end":{"line":16,"column":17}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.INVOICEDETAILS : depth0)) != null ? stack1.ENDMESSAGE : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "            Thank you for your business! \n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <tr>\n      <td>\n        <span style=\"font-weight: bold;\">Serial Number:</span>\n        <div style=\"padding: 0 10px\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.INVOICEITEMS : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":10},"end":{"line":33,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n      </td>\n    </tr>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.SERIALNUMBER : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":32,"column":19}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "              <span style=\"font-weight: bold;\"><span style=\"text-transform: uppercase\">"
    + alias4(((helper = (helper = helpers.IPRODSKU || (depth0 != null ? depth0.IPRODSKU : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"IPRODSKU","hash":{},"data":data,"loc":{"start":{"line":31,"column":87},"end":{"line":31,"column":101}}}) : helper)))
    + "</span> / "
    + alias4(((helper = (helper = helpers.PRODUCTNAME || (depth0 != null ? depth0.PRODUCTNAME : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PRODUCTNAME","hash":{},"data":data,"loc":{"start":{"line":31,"column":111},"end":{"line":31,"column":126}}}) : helper)))
    + ":</span><br />"
    + alias4(((helper = (helper = helpers.SERIALNUMBER || (depth0 != null ? depth0.SERIALNUMBER : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SERIALNUMBER","hash":{},"data":data,"loc":{"start":{"line":31,"column":140},"end":{"line":31,"column":158}}}) : helper)))
    + "<br /><br />\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <tr>\n      <td>\n        <span style=\"font-weight: bold;\">Asset Tag:</span>\n        <div style=\"padding: 0 10px\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.INVOICEITEMS : depth0),{"name":"each","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":10},"end":{"line":47,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n      </td>\n    </tr>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.ASSETTAG : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":12},"end":{"line":46,"column":19}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "              <span style=\"font-weight: bold;\"><span style=\"text-transform: uppercase\">"
    + alias4(((helper = (helper = helpers.IPRODSKU || (depth0 != null ? depth0.IPRODSKU : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"IPRODSKU","hash":{},"data":data,"loc":{"start":{"line":45,"column":87},"end":{"line":45,"column":101}}}) : helper)))
    + "</span> / "
    + alias4(((helper = (helper = helpers.PRODUCTNAME || (depth0 != null ? depth0.PRODUCTNAME : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PRODUCTNAME","hash":{},"data":data,"loc":{"start":{"line":45,"column":111},"end":{"line":45,"column":126}}}) : helper)))
    + ":</span><br />"
    + alias4(((helper = (helper = helpers.ASSETTAG || (depth0 != null ? depth0.ASSETTAG : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ASSETTAG","hash":{},"data":data,"loc":{"start":{"line":45,"column":140},"end":{"line":45,"column":154}}}) : helper)))
    + "<br /><br />\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <tr>\n      <td>\n        <span style=\"font-weight: bold;\">Tracking Information(s):</span>\n        <div style=\"padding: 0 10px\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.VARactivity_details : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":10},"end":{"line":61,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n      </td>\n    </tr>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.VARactivity_details : depth0),{"name":"each","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":12},"end":{"line":60,"column":21}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <div style=\"padding: 5px 0;\">"
    + ((stack1 = container.lambda(depth0, depth0)) != null ? stack1 : "")
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<br />\n<table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\" style=\"padding-left: 10px !important; padding-right: 10px !important;\">\n  <tr>\n    <td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.footerContent : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":18,"column":13}}})) != null ? stack1 : "")
    + "    </td>\n  </tr>\n</table>\n<br />\n<table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\" style=\"padding-left: 10px !important; padding-right: 10px !important;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.serialNumber : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":2},"end":{"line":37,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.assetTag : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":2},"end":{"line":51,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.trackingInfo : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":2},"end":{"line":65,"column":9}}})) != null ? stack1 : "")
    + "</table>";
},"useData":true});