

































































































































































































































































































































































































































































































































import axios from "axios";
import SortIndicator from "../components/SortIndicator.vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import FilterCurrencyBox from "../components/FilterCurrencyBox.vue";
import hpagentstatuscomponent, {
  HPAgentStatusValue
} from "../components/HPAgentStatus.vue";
import { notifier } from "../models/common";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import { getDateRangeFilter } from "@/helpers/ApiHelper";

declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    ListHeaderActions,
    Pagination,
    SortIndicator,
    FilterCurrencyBox,
    hpagentstatuscomponent
  },
  methods: {
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    }
  }
})
export default class AgentReports extends TSXComponent<void> {
  filters = {
    sDate: "",
    eDate: "",
    sSalesMin: undefined,
    sSalesMax: undefined,
    sPaymentMin: undefined,
    sPaymentMax: undefined,
    sSource: ['HPI','HPE'],
    sStatus: [0,1,2],
    sPaycheckDate: "",
    ePaycheckDate: "",
    paymentNumber: "",
    statementNumber: "",
  };
  currentRole = sessionStorage.getItem("userRole");
  dataObj = {
    controller: "Finances",
    FunctionName: "HPAgentReports",
    maxRows:
      sessionStorage.getItem("maxRows") != ""
        ? sessionStorage.getItem("maxRows")
        : getMaxRows(),
    pageNumber: 1
  };
  details = {
    TOTALPAGES: 1,
    sSalesMin: undefined,
    sSalesMax: undefined,
    sPaymentMin: undefined,
    sPaymentMax: undefined,
    sPaycheckDate: "",
    ePaycheckDate: "",
    sDate: "",
    eDate: "",
    paymentNumber: "",
    statementNumber: ""
  };
  items = [];
  loading = false;
  pageNumber = sessionStorage.pageDetails && JSON.parse(sessionStorage.pageDetails)["HPAgent"] ? JSON.parse(sessionStorage.pageDetails)["HPAgent"] : 1 ;

  loadNextpage(pageNumber) {
    this.dataObj.pageNumber = pageNumber;
    this.fetchFinancesData(this.dataObj, this);
  }

  reloadData() {
    this.fetchFinancesData(this.dataObj, this);
  }

  loadPage(pageNumber, type) {
    if (type == "prev") {
      pageNumber = parseInt(pageNumber) - 1;
    } else if (type == "next") {
      pageNumber = parseInt(pageNumber) + 1;
    }
    if(sessionStorage.pageDetails != undefined && JSON.parse(sessionStorage.pageDetails)["HPAgent"]) {
      var oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["HPAgent"] = pageNumber;
      sessionStorage.setItem('pageDetails',JSON.stringify(oldData))
    }
    if (
      parseInt(pageNumber) <= this.details.TOTALPAGES &&
      parseInt(pageNumber) >= 1
    ) {
      this.dataObj.pageNumber = pageNumber;
      this.fetchFinancesData(this.dataObj, this, "", "", "page");
    }
  }

  created() {
    this.grabAgentReportsData(this);
  }

  grabAgentReportsData(container) {
    var dataObj = {
      controller: "Finances",
      FunctionName: "HPAgentReports",
      maxRows:
        sessionStorage.getItem("maxRows") != ""
          ? sessionStorage.getItem("maxRows")
          : getMaxRows(),
      pageNumber : this.pageNumber,
      agentStatus : container.filters.sStatus,
      agentSource : container.filters.sSource
    };
    this.fetchFinancesData(dataObj, container, "", "", "new");
  }
  //Sort fields
  sortingFields(orderSort, nameSort) {
    var container = this;

    this.dataObj["order"] = orderSort;
    if (typeof container.details[nameSort] == "undefined") {
      container.details[nameSort] = 1;
      if (nameSort == "sortingDate") {
        container.details[nameSort] = 2;
      }
    }
    this.dataObj["direction"] =
      parseInt(container.details[nameSort]) === 1 ? 2 : 1;
    this.fetchFinancesData(this.dataObj, this, nameSort, "direction", "");
  }

  async resetSearchBySales(allowFetchData = true) {
    this.dataObj.pageNumber = 1;
    this.filters.sSalesMin = undefined;
    this.filters.sSalesMax = undefined;
    this.dataObj["sSalesMin"] = this.filters.sSalesMin;
    this.dataObj["sSalesMax"] = this.filters.sSalesMax;
    if (allowFetchData) {
      this.fetchFinancesData(this.dataObj, this, "", "", "page");
    }
  }
  searchBySales() {
    this.dataObj.pageNumber = 1;
    this.filters.sSalesMin = this.details.sSalesMin;
    this.filters.sSalesMax = this.details.sSalesMax;
    this.dataObj["sSalesMin"] = this.filters.sSalesMin;
    this.dataObj["sSalesMax"] = this.filters.sSalesMax;
    this.fetchFinancesData(this.dataObj, this, "", "", "page");
  }

  async resetSearchByPayment(allowFetchData = true) {
    this.dataObj.pageNumber = 1;
    this.filters.sPaymentMin = undefined;
    this.filters.sPaymentMax = undefined;
    this.dataObj["sPaymentMin"] = this.filters.sPaymentMin;
    this.dataObj["sPaymentMax"] = this.filters.sPaymentMax;
    if (allowFetchData) {
      this.fetchFinancesData(this.dataObj, this, "", "", "page");
    }
  }
  searchByPayment() {
    this.dataObj.pageNumber = 1;
    this.filters.sPaymentMin = this.details.sPaymentMin;
    this.filters.sPaymentMax = this.details.sPaymentMax;
    this.dataObj["sPaymentMin"] = this.filters.sPaymentMin;
    this.dataObj["sPaymentMax"] = this.filters.sPaymentMax;
    this.fetchFinancesData(this.dataObj, this, "", "", "page");
  }
  //filter
  searchByDate() {
    this.dataObj.pageNumber = 1;
    var container = this;
    // if((container.details.sDate == undefined || container.details.sDate ==  '') && (container.details.eDate == '' || container.details.eDate == undefined)) {
    //     container.notification('alert','Please Fill From or To Date');
    // }else {
    //     dataObj["sDate"] = container.details.sDate;
    //     dataObj["eDate"] = container.details.eDate;
    //     fetchFinancesData(dataObj, container, '', '', 'page');
    // }
    if (
      (container.details.sDate != "" || container.details.eDate != "") &&
      (container.details.sDate != undefined ||
        container.details.eDate != undefined)
    ) {
      this.filters.sDate = container.details.sDate;
      this.filters.eDate = container.details.eDate;
      this.dataObj["sDate"] = container.details.sDate;
      this.dataObj["eDate"] = container.details.eDate;
      this.fetchFinancesData(this.dataObj, container, "", "", "page");
    }
  }
  searchByPaymentNumber() {
    var container = this;
    this.dataObj.pageNumber = 1;
    this.filters.paymentNumber = container.details.paymentNumber;
    this.dataObj["paymentNumber"] = container.details.paymentNumber;
    this.fetchFinancesData(this.dataObj, container, "", "", "page");
  }
  searchByStatementNumber() {
    var container = this;
    this.dataObj.pageNumber = 1;
    this.filters.statementNumber = container.details.statementNumber;
    this.dataObj["statementNumber"] = container.details.statementNumber;
    this.fetchFinancesData(this.dataObj, container, "", "", "page");
  }

  //reset filter
  resetSearchByDate() {
    var container = this;
    this.dataObj.pageNumber = 1;
    this.filters.sDate = this.details.sDate = "";
    this.filters.eDate = this.details.eDate = "";
    this.dataObj["sDate"] = this.details.sDate;
    this.dataObj["eDate"] = this.details.eDate;
    this.fetchFinancesData(this.dataObj, this, "", "", "page");
  }
  resetByPaymentNumber() {
    this.dataObj.pageNumber = 1;
    this.filters.paymentNumber = "";
    this.details.paymentNumber = "";
    this.searchByPaymentNumber();
  }
  resetByStatementNumber() {
    this.dataObj.pageNumber = 1;
    this.details.statementNumber = "";
    this.searchByStatementNumber();
  }

  async checkSourceList(agentSource : string) {
    this.dataObj.pageNumber = 1;
    const i = this.filters.sSource.findIndex(s => s === agentSource);
    if (i === -1) {
      this.filters.sSource.push(agentSource);
    } else {
      this.filters.sSource.splice(i, 1);
    }
    this.dataObj["agentSource"] = this.filters.sSource;
    this.fetchFinancesData(this.dataObj, this, "", "", "page");
  }

  async checkAllSourceList() {
    this.filters.sSource = ['HPI','HPE'];
    this.dataObj["agentSource"] = this.filters.sSource;
    this.fetchFinancesData(this.dataObj, this, "", "", "page");
  }

  async uncheckAllSourceList() {
    this.dataObj.pageNumber = 1;
    this.filters.sSource = ['-1'];
    this.dataObj["agentSource"] = this.filters.sSource;
    this.fetchFinancesData(this.dataObj, this, "", "", "page");
  }

  async resetSourceList() {
    this.dataObj.pageNumber = 1;
    this.filters.sSource = [];
    // this.dataObj["agentSource"] = this.filters.sSource;
    // this.fetchFinancesData(this.dataObj, this, "", "", "page");
    this.checkAllSourceList();
  }

  searchByPaycheckDate() {
    this.dataObj.pageNumber = 1;
    if (
      ((this.details.sPaycheckDate && this.details.sPaycheckDate.length) ||
      (this.details.ePaycheckDate && this.details.ePaycheckDate.length))
    ) {
      this.filters.sPaycheckDate = this.details.sPaycheckDate;
      this.filters.ePaycheckDate = this.details.ePaycheckDate;
      this.dataObj["sPaycheckDate"] = this.filters.sPaycheckDate;
      this.dataObj["ePaycheckDate"] = this.filters.ePaycheckDate;
      this.fetchFinancesData(this.dataObj, this, "", "", "page");
    }
  }

  resetByPaycheckDate() {
    this.dataObj.pageNumber = 1;
    this.details.sPaycheckDate = this.filters.sPaycheckDate = "";
    this.details.ePaycheckDate = this.filters.ePaycheckDate = "";
    this.dataObj["sPaycheckDate"] = this.filters.sPaycheckDate;
    this.dataObj["ePaycheckDate"] = this.filters.ePaycheckDate;
    this.fetchFinancesData(this.dataObj, this, "", "", "page");
  }

  fetchFinancesData(dataObj, container, keyName?, fieldname?, option?) {
    container.loading = true;
    dataObj["maxRows"] =
      sessionStorage.getItem("maxRows") != ""
        ? sessionStorage.getItem("maxRows")
        : getMaxRows();
    var tmp = getRouteData(dataObj)
      .then(function(response, statusText, jqXHR) {
        try {
          if (response.data.STATUS && response.data.STATUSMESSAGE) {
            container.loading = false;
            container.details = {...container.details, ...response.data || {}};

            if (dataObj["FunctionName"] == "HPAgentReports") {
              container.items = response.data.commissionStatement || [];
            } else if (dataObj["FunctionName"] == "AgentReportsDetails") {
              container.items = response.data.commissionDetailsArray || [];
            }

            if (option == "page") {
              if (dataObj["sDate"] != "" || dataObj["eDate"] != "") {
                container.details.sDate = dataObj["sDate"];
                container.details.eDate = dataObj["eDate"];
              }
              if (dataObj["paymentNumber"] != "") {
                container.details.paymentNumber = dataObj["paymentNumber"];
              }
              if (dataObj["statementNumber"] != "") {
                container.details.statementNumber = dataObj["statementNumber"];
              }
            } else {
              if (option === "new") {
                // container.details['searchName'] = '';
                // container.details['searchId'] = '';
              } else {
                container.details[keyName] = dataObj[fieldname];
              }
            }
          } else if (response.data.ERROR) {
            // console.log(response.data.ERROR);
          }
        } catch (e) {
          //handle error
        }
      })
      .catch(function(error) {
        // console.log(error);
      });
  }
  async checksStatusList(agentStatus : number) {
    this.dataObj.pageNumber = 1;
    const i = this.filters.sStatus.findIndex(s => s === agentStatus);
    if (i === -1) {
      this.filters.sStatus.push(agentStatus);
    } else {
      this.filters.sStatus.splice(i, 1);
    }
    this.dataObj["agentStatus"] = this.filters.sStatus.length ? this.filters.sStatus : [-1];
    this.fetchFinancesData(this.dataObj, this, "", "", "page");
  }
  async checkAllsStatusList() {
    this.filters.sStatus = [0,1,2];
    this.dataObj["agentStatus"] = this.filters.sStatus;
    this.fetchFinancesData(this.dataObj, this, "", "", "page");
  }
  async uncheckAllsStatusList() {
    this.dataObj.pageNumber = 1;
    this.filters.sStatus = [];
    this.dataObj["agentStatus"] = [-1];
    this.fetchFinancesData(this.dataObj, this, "", "", "page");
  }

  async resetsStatusList() {
    this.dataObj.pageNumber = 1;
    // this.filters.sStatus = [];
    // this.dataObj["agentStatus"] = this.filters.sStatus;
    // this.fetchFinancesData(this.dataObj, this, "", "", "page");
    this.checkAllsStatusList();
  }

  async changeStatus(
    statementId: string,
    status: HPAgentStatusValue,
    paycheckId: string
  ) {
    const statusIndex =
      status === HPAgentStatusValue.Pending
        ? "1"
        : status === HPAgentStatusValue.Open
        ? "0"
        : status === HPAgentStatusValue.Closed
        ? "2"
        : "0";

    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Finances",
        FunctionName: "updateAgentStatus",
        statementId: statementId,
        agentStatus: statusIndex,
        paycheckId : paycheckId
      });
      
      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS == 1) {
        await this.fetchFinancesData(this.dataObj, this, "", "", "page");
        notifier.success(response.data.STATUSMESSAGE);
      } else {
        notifier.alert(response.data.STATUSMESSAGE);
      } 
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
  }

  getFilterValue(header) {
    let filter = '';
    let searchKey: string | undefined = '';
    switch (header) {
      case 'STATUS':
        if ( this.filters.sStatus && !this.filters.sStatus.length) {
          filter = "None";
        } else if (this.filters.sStatus && this.filters.sStatus.length != 3) {
          const arrStatusNames: string[] = [];
          if (this.filters.sStatus.includes(0)) {
            arrStatusNames.push('Open');
          }
          if (this.filters.sStatus.includes(1)) {
            arrStatusNames.push('Pending');
          }
          if (this.filters.sStatus.includes(2)) {
            arrStatusNames.push('Closed');
          }
          filter = arrStatusNames.join(', ');
        }
        break;
      case 'SOURCE':
        if (this.filters.sSource && !this.filters.sSource.length)
          filter = "None";
        else if (
          this.filters.sSource &&
          this.filters.sSource.length != 2
        ) {
          filter = this.filters.sSource.join(", ");
        }
        break;
      case "SALES":
        filter = this.getRangeFilter(
          this.filters.sSalesMin,
          this.filters.sSalesMax
        );
        searchKey = "";
        break;
      case "PAYMENT":
        filter = this.getRangeFilter(
          this.filters.sPaymentMin,
          this.filters.sPaymentMax
        );
        searchKey = "";
        break;
      case "LAST_PAYCHECK_RUN":
        filter = getDateRangeFilter(
          this.filters.sPaycheckDate,
          this.filters.ePaycheckDate
        );
        searchKey = "";
        break;
      case "PAYMENT_DATE":
        filter = getDateRangeFilter(
          this.filters.sDate,
          this.filters.eDate
        );
        searchKey = "";
        break;
      default:
        // nothing
        break;
    }
    return (filter ? `${filter}` : '') + (searchKey ? (filter ? ' - ' : '') + searchKey : '');
  }

  getRangeFilter(min, max) {
    const arr: string[] = [];
    if (min) {
      arr.push(`Min: ${min}`);
    }
    if (max) {
      arr.push(`Max: ${max}`);
    }
    return arr.join("; ");
  }
}
