
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import SortIndicator from "@/components/SortIndicator.vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import LaddaButton from "@/components/LaddaButton.vue";

import {
  notifier,
  downloadFile,
  printHtml,
  downloadFileUrl
} from "../models/common";
import {
  ReportAPIRepo,
  SortField,
  Filters,
  Sort,
  ReportTypeFilter
} from "../repos/ReportAPIRepo";
import ExportModal, { ExportFormat } from "../components/ExportModal.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import ReportModal from "../components/ReportModal.vue";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
// import UpdateReportBuildSourceModal from "@/components/UpdateReportBuildSourceModal.vue";
import {
  colsResizeable,
  getRangeFilter,
  getDateRangeFilter,
  getInitials
} from "@/helpers/ApiHelper";
import { ApiHelper } from "@/helpers/all";
import AddAccountsModal from "@/components/AddAccountsModal.vue";

const reportRepo = new ReportAPIRepo();
declare const dataURL: string;
const template = require("../templates/reports/reportList.handlebars");

@Component({
  inheritAttrs: false,
  components: {
    ListHeaderActions,
    ConfirmRemoveItemModal,
    SortIndicator,
    // UpdateReportBuildSourceModal,
    Pagination,
    ExportModal,
    ReportModal,
    LaddaButton,
    AddAccountsModal
  },
  methods: {
    getRangeFilter: (min, max) => {
      return getRangeFilter(min, max);
    },
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    },
    getInitials
  }
})
export default class Reports extends TSXComponent<void> {
  selectedID: string[] = [];
  loading = false;
  items = [];
  currentRole = sessionStorage.getItem("userRole");
  reportList: any = [];
  prodctsArr = [];
  filters: Filters = {
    searchAll: "",
    searchId: "",
    searchName: "",
    sDate: undefined,
    eDate: undefined,
    sRunDate: undefined,
    eRunDate: undefined,
    rStatus: [1, 0],
    rBuildSource: [1, 2],
    rTypeFilter: [],
    rUsers: [],
  };
  sort: Sort = {
    field: null,
    direction: {
      [SortField.Id]: 1,
      [SortField.ReportName]: 1,
      [SortField.ReportType]: 1,
      [SortField.ReportDate]: 1,
      [SortField.LastRunDate]: 1
    }
  };
  pageNumber = 1;
  totalRows = 0;
  selectedType = 1;
  curPage = 1;
  exportModalVisible = false;
  reportModalVisible = false;
  filterModalVisible = false;
  // updateReportBuildSourceModalVisible = false;
  selectedReport = {
    id: 0,
    startdate: "",
    enddate: "",
    ExportType: "",
    name: ""
  };
  selectedAll = false;
  excludedIDs: string[] = [];
  total = 0;
  totalPages = 1;
  confirmReportDelete = false;
  selectedReportDelete = {};
  showAccountModal: boolean = false;
  accountsList: any[] = [];
  dynamicAccounts: any[] = [];
  exportReportItem: any = {};
  groupList: any[] = [];
  dynamicGroups: any[] = [];
  allUsers: any[] = [];
  hasChangedUserFilters: boolean = false;
  searchUserTxt= "";
  userAccount: any = [];

  get isFiltering() {
    if (
      this.filters.sDate || this.filters.eDate
      || this.filters.sRunDate || this.filters.eRunDate
      || this.filters.rBuildSource.length === 1
      || this.filters.searchId || this.filters.searchName
    ) {
      return true;
    }

    return false;
  }

  async created() {
    if (typeof this.$route.query.searchAll === "string") {
      this.filters.searchAll = this.$route.query.searchAll;
    }
    if (typeof this.$route.query.searchId === "string") {
      this.filters.searchId = this.$route.query.searchId;
    }
    if (typeof this.$route.query.searchName === "string") {
      this.filters.searchName = this.$route.query.searchName;
    }
    if (typeof this.$route.query.sDate === "string") {
      this.filters.sDate = this.$route.query.sDate;
    }
    if (typeof this.$route.query.eDate === "string") {
      this.filters.eDate = this.$route.query.eDate;
    }
    if (typeof this.$route.query.sRunDate === "string") {
      this.filters.sRunDate = this.$route.query.sRunDate;
    }
    if (typeof this.$route.query.eRunDate === "string") {
      this.filters.eRunDate = this.$route.query.eRunDate;
    }
    if (typeof this.$route.query.rStatus === "string") {
      this.filters.rStatus = [parseInt(this.$route.query.rStatus)];
    } else if (Array.isArray(this.$route.query.rStatus)) {
      this.filters.rStatus = this.$route.query.rStatus.map(s =>
        parseInt(s || "0")
      );
    }
    if (typeof this.$route.query.rTypeFilter === "string") {
      this.filters.rTypeFilter = [this.$route.query.rTypeFilter];
    } else if (Array.isArray(this.$route.query.rTypeFilter)) {
      this.filters.rTypeFilter = this.$route.query.rTypeFilter.map(
        s => s || "0"
      );
    }
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      "FunctionName": "List",
      "controller": "Users"
    });
    var accounts = response.data.USERS;
    this.allUsers = accounts.map((val: any) => ({
      id: val.USERID,
      text: val.UFNAME + ' ' + val.ULNAME
    }));
    this.userAccount = this.allUsers;  

    await this.fetchData();
    // await this.generateReport(1);
  }

  async fetchData() {
    this.loading = true;
    this.hasChangedUserFilters = false;  
    try {
      const reportData = await reportRepo.reportList(
        this.filters,
        this.sort,
        this.pageNumber
      );
      this.reportList = reportData.REPORTS;
      this.total = reportData.TOTAL;
      this.totalPages = reportData.TOTALPAGES || 1;
      this.accountsList = reportData.ACCOUNTLIST || [];
      this.groupList = reportData.GROUPLIST || [];
      // console.log('reports',this.reportList)
      if(reportData.RUSERLIST.length) {
        this.filters.rUsers = reportData.RUSERLIST.split(',').map(item => Number(item.trim()))
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
    this.loading = false;

    colsResizeable({ ms: 500 });
  }

  getSelectedUser() {
    let Filter = ''
    Filter = (this.allUsers
      .filter(user => this.filters.rUsers.includes(user.id))
      .map(user => user.text)).join(", ");   
    if(this.allUsers.length == this.filters.rUsers.length) {
      Filter = '';
    } else if (this.filters.rUsers.length == 1 && !this.filters.rUsers[0] ){
        Filter = 'None';
    }
    return Filter;
  }

  filterUserInBox() {
    let options = this.allUsers;
    if (this.searchUserTxt) {
      options = options.filter((option) => `${option.text}`.toLowerCase().indexOf(this.searchUserTxt.toLowerCase()) !== -1)
    }
    this.userAccount = options;
  }

  async sortBy(field: SortField) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  async sortingById() {
    await this.sortBy(SortField.Id);
  }

  async sortingByName() {
    await this.sortBy(SortField.ReportName);
  }

  async sortingByType() {
    await this.sortBy(SortField.ReportType);
  }

  async sortingByDate() {
    await this.sortBy(SortField.ReportDate);
  }

  async sortingByLastRunDate() {
    await this.sortBy(SortField.LastRunDate);
  }

  async searchById() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.filters.searchId == "") {
      return;
    } else {
      await this.fetchData();
    }
  }

  async resetSearchById() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchId = "";
    await this.fetchData();
  }

  async searchByName() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.filters.searchName == "") {
      return;
    } else {
      await this.fetchData();
    }
  }

  async resetSearchByName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchName = "";
    await this.fetchData();
  }

  async searchByDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    await this.fetchData();
  }

  async resetSearchByDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sDate = undefined;
    this.filters.eDate = undefined;
    await this.fetchData();
  }

  // async searchReports(field: string, value: string) {
  //   this.loading = true;
  //   const html = await this.selectedOrdersHtml();
  //   this.loading = false;
  //   if (!html) return;

  //   printHtml(html);
  // }

  async csvDownLoadFn() {
    let fileUrl = "";
    try {
      fileUrl = await reportRepo.exportListsCSVFileUrl(
        this.selectedType,
        this.selectedID,
        this.filters,
        this.sort
      );
    } catch (err) {
      notifier.alert("Export CSV error: " + err.message);
    }
    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async performExport(formats: ExportFormat[]) {
    this.loading = true;
    this.exportModalVisible = false;

    const exportFns: Function[] = [];

    if (formats.includes(ExportFormat.csv)) {
      exportFns.push(await this.csvDownLoadFn());
    }

    if (formats.includes(ExportFormat.html)) {
      // Commented out for Currently we dont have HTML download funtion for Reports so
      exportFns.push(await this.htmlDownLoadFn());
    }

    if (formats.includes(ExportFormat.pdf)) {
      exportFns.push(await this.pdfDownLoadFn());
    }

    exportFns.forEach(fn => fn());

    this.loading = false;
  }

  async print() {
    this.loading = true;
    const html = await this.selectedReportHtml("print");
    this.loading = false;
    if (!html) return;

    printHtml(html);
  }

  async htmlDownLoadFn() {
    const html = await this.selectedReportHtml("html");

    return () => {
      if (!html) return;
      var fileNameExport =
        "ReportList_" + Math.round(+new Date() / 1000) + ".html";
      downloadFile(fileNameExport, html);
    };
  }

  async selectedReportHtml(from: string): Promise<string | false> {
    try {
      const reportList = await reportRepo.exportReportBySelect(
        this.selectedType,
        this.selectedID,
        this.filters,
        this.sort
      );
      if (from == "print") {
        reportList["type"] = "print";
      }
      return template(reportList);
    } catch (err) {
      notifier.alert(err.message);
      return false;
    }
  }

  async pdfDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await reportRepo.exportListsPDFFileUrl(
        this.selectedID,
        this.selectedType,
        this.filters,
        this.sort
      );
    } catch (err) {
      notifier.alert("Export PDF error: " + err.message);
    }
    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  // async generateReport(typeId){
  //   console.log('typeId',typeId)
  //   this.selectedType = typeId;

  //   this.loading = true;
  //   try {
  //     const products = await reportRepo.getReports(typeId,this.curPage);
  //     this.prodctsArr = products.REPORTS;
  //     this.curPage = products.CURRENTPAGE;
  //     this.totalRows = products.TOTALPAGES;
  //     console.log('reports',this.prodctsArr)
  //   } catch (err) {
  //     notifier.alert(err.message);
  //   } finally {
  //     this.loading = false;
  //   }
  //   this.loading = false;
  // }

  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    let allowLoadData = true;
    if (this.pageNumber > this.totalPages) {
      this.pageNumber = this.totalPages;
      allowLoadData = false;
    }
    if (this.pageNumber < 1) {
      this.pageNumber = 1;
      allowLoadData = false;
    }
    if (this.pageNumber <= this.totalPages && this.pageNumber >= 1 && allowLoadData) {
    // if (this.curPage <= this.totalRows && this.curPage >= 1) {
      await this.fetchData();
    }
  }

  async showFilter(id, type, item) {
    if (item.ISREPORTBUILDER || 0) {
      return;
    }

    // console.log("params", id, type, item);
    if (item.REPORTSTATUS == 1) {
      if (type == "XLSX" || type == "CSV") {
        // console.log("show Filter");
        this.reportModalVisible = true;
        this.selectedReport = {
          id: item.REPORTID,
          startdate: item.FROMDATE,
          enddate: item.TODATE,
          ExportType: type,
          name: item.REPORTNAME
        };
      } else if (type == "HTML") {
        this.$router.push({
          name: "ReportDetails",
          params: { id: id }
        });
      }
    }
  }
  async updateStatus(reportId, reportStatus) {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "reports",
      FunctionName: "reportStatus",
      id: reportId,
      status: reportStatus ? 0 : 1
    });
    if (response.data.STATUS == 1) {
      notifier.success(response.data.MESSAGE);
      await this.fetchData();
    } else {
      notifier.alert(response.data.MESSAGE);
    }
  }

  // async saveReportBuildSource() {
  //   this.updateReportBuildSourceModalVisible = false;
  //   await this.fetchData();
  // }

  // showBuildSourceDialog(report) {
  //   this.selectedReport = report;
  //   this.updateReportBuildSourceModalVisible = true;
  // }

  async searchByLastRunDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    await this.fetchData();
  }

  async resetSearchByLastRunDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sRunDate = undefined;
    this.filters.eRunDate = undefined;
    await this.fetchData();
  }

  async checkStatusList(runReportStatus: number) {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    const i = this.filters.rStatus.findIndex(s => s === runReportStatus);
    if (i === -1) {
      this.filters.rStatus.push(runReportStatus);
    } else {
      this.filters.rStatus.splice(i, 1);
    }
    await this.fetchData();
  }

  async checkUserList(id: number, userName: string) {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (!id && this.filters.rUsers != undefined) {
      const tagIndex = this.filters.rUsers.findIndex(
        userId => userId === id
      );
      if (tagIndex === -1) {
        this.filters.rUsers.push(id);
      } else {
        this.filters.rUsers.splice(tagIndex, 1);
      }
    }
    this.hasChangedUserFilters = true;
  }

  async resetUserHeader() {
    this.resetUserList();
  }

  async uncheckUserList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.rUsers = [0];
    await this.fetchData();
  }

   async resetUserList() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.rUsers = [];
    this.allUsers.map(item => {
      this.filters.rUsers.push(item.id); 
    });
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async checkAllStatusList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.rStatus = [1, 0];
    await this.fetchData();
  }

  async resetStatusList() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.rStatus = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.checkAllStatusList();
    await this.fetchData();
  }

  async checkBuildSourceList(runReportBuildSource: number) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    const i = this.filters.rBuildSource.findIndex(s => s === runReportBuildSource);
    if (i === -1) {
      this.filters.rBuildSource.push(runReportBuildSource);
    } else {
      this.filters.rBuildSource.splice(i, 1);
    }
    await this.fetchData();
  }

  async checkAllBuildSourceList() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.rBuildSource = [1, 2];
    await this.fetchData();
  }

  async resetBuildSourceList() {
    this.pageNumber = 1;
    this.checkAllBuildSourceList();
  }

  async checkReportList(rTypeFilters: any) {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    const i = this.filters.rTypeFilter.findIndex(s => s === rTypeFilters);
    if (i === -1) {
      this.filters.rTypeFilter.push(rTypeFilters);
    } else {
      this.filters.rTypeFilter.splice(i, 1);
    }
    await this.fetchData();
  }

  async checkAllReportList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.rTypeFilter = [ReportTypeFilter.Html, ReportTypeFilter.Xlsx];
    await this.fetchData();
  }

  async resetReportList() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.rTypeFilter = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.checkAllStatusList();
    await this.fetchData();
  }

  getSelectedTotal(total) {
    var ret = 0;
    if (this.selectedAll) {
      ret = total - this.excludedIDs.length;
    } else {
      ret = this.selectedID.length;
    }

    return ret;
  }

  checkExcludedIDs(items, key) {
    if (this.selectedAll) {
      for (var val of items) {
        if (!this.selectedID.includes(val[key])) {
          if (!this.excludedIDs.includes(val[key])) {
            this.excludedIDs.push(val[key]);
          }
        } else if (this.excludedIDs.includes(val[key])) {
          this.$delete(
            this.excludedIDs,
            this.excludedIDs.findIndex(id => id == val[key])
          );
        }
      }

      if (this.getSelectedTotal(this.total) == 0) {
        this.selectedAll = false;
        this.excludedIDs = [];
      }
    }
  }

  async deleteReport(reportId) {
    this.confirmReportDelete = false;
    const response = await ApiHelper.callApi("post", {
      controller: "Reports",
      FunctionName: "UpdateReportBuilder",
      action: "delete",
      reportId: reportId
    });
    if (response.STATUS) {
      notifier.success("Report Deleted Successfully");
    } else {
      notifier.alert("Error Occured");
    }
    await this.fetchData();
  }

  async exportReport(data: any = {}) {
    let reportDetails = this.exportReportItem;
    if (data.accountIds) {
      this.dynamicAccounts = data.accountIds;
    }
    if (data.groupIds) {
      this.dynamicGroups = data.groupIds;
    }
    let fileUrl;
    let selectedColumns: string[] = [];
    for (let i = 0; i < JSON.parse(reportDetails.REPORTPARAMS).filters.outputColumns.length; i++) {
      selectedColumns.push(JSON.parse(reportDetails.REPORTPARAMS).filters.outputColumns[i].columnName)
    }
    try {
      this.loading = true;
      const exportResult = await ApiHelper.callApi("post", {
        controller: "Reports",
        FunctionName: "ReportBuilderContent",
        reportId: reportDetails.REPORTID,
        ExportType: "CSV",
        reportColumns: selectedColumns.toString(),
        contentView: JSON.parse(reportDetails.REPORTPARAMS).details.view,
        contentType: JSON.parse(reportDetails.REPORTPARAMS).filters.contentType,
        dynamicAccounts: this.dynamicAccounts,
        dynamicGroups: this.dynamicGroups
      });
      if (exportResult.STATUS) {
        fileUrl = exportResult.S3URL;
      } else {
        notifier.alert(exportResult.STATUSMESSAGE || "Something Went Wrong");
      }
      this.showAccountModal = false;
      this.loading = false;
    } catch (err) {
      notifier.alert("Export CSV error");
    }
    downloadFileUrl(fileUrl);
  }

  // async runreport(e){
  //   console.log("r",e,this.selectedReport.id)
  //   // this.$router.push({
  //   //   name: "ReportDetails",
  //   //   params: { id: (this.selectedReport.id).toString() },
  //   //   query: { startdate : e.start, enddate : e.end }
  //   // });
  //    try {
  //     const reportData = await reportRepo.reportGenerate(
  //      this.selectedReport.id,e.start,e.end
  //     );
  //     // this.reportList = reportData.REPORTS;
  //     console.log('reports',reportData)
  //   } catch (err) {
  //     notifier.alert(err.message);
  //   } finally {
  //     this.loading = false;
  //   }
  // }
}
