












import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';

@Component
export default class ExceptionLogCheck extends Vue {
  logData: string = '';

  async mounted() {
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/ExceptionLog/get`);
      this.logData = response.data;
    } catch (error) {
      console.error('Failed to fetch exception log:', error);
      this.logData = 'Error loading exception log';
    }
  }
}
