
































































































































































































































































































import axios from "axios";
import SortIndicator from "../components/SortIndicator.vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import Pagination from "../components/Pagination.vue";
import { notifier } from "../models/common";
import { colsResizeable } from "@/helpers/ApiHelper";

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    ConfirmRemoveItemModal,
    SortIndicator
  }
})
export default class CustomData extends TSXComponent<void> {
  selectedID = [];
  loading = false;
  details: any = {
    TOTAL: 0,
    ISSEARCH: 0,
    TOTALALL: 0
  };
  sort = {
    field: 1,
    direction: {
      name: 1,
      type: 1,
      account: 1,
      searchable: 1
    }
  };
  directionField = "name";
  filters: any = {
    name: "",
    sStatus: [1, 2, 3, 4, 5, 6],
    account: ""
  };
  confirmRemoveModalVisible = false;
  pageNumber = 1;
  items = [];
  searchFilters: any = {
    name: "",
    sStatus: [1, 2, 3, 4, 5, 6],
    account: ""
  };
  customFieldTypes: any = [
    {
      id: 1,
      text: "Order"
    },
    {
      id: 2,
      text: "Quote"
    },
    {
      id: 3,
      text: "Invoice"
    },
    {
      id: 4,
      text: "Account"
    },
    {
      id: 5,
      text: "Deployment"
    },
    {
      id: 6,
      text: "Hardware"
    }
  ];

  async created() {
    this.loading = true;
    this.fetchData();
  }

  async sortBy(field, directionField) {
    this.sort.field = field;
    this.sort.direction[directionField] =
      this.sort.direction[directionField] == 1 ? 2 : 1;
    this.directionField = directionField;

    //reset
    for (const dirField in this.sort.direction) {
      if (dirField === directionField) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }
  async fetchData() {
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Setting",
        FunctionName: "customDataList",
        maxRows:
          sessionStorage.getItem("maxRows") != ""
            ? sessionStorage.getItem("maxRows")
            : getMaxRows(),
        pageNumber: this.pageNumber,
        name: this.filters.name,
        account: this.filters.account,
        type: this.filters.sStatus.join(","),
        order: this.sort.field ? this.sort.field : 0,
        direction:
          this.directionField != ""
            ? this.sort.direction[this.directionField]
            : 0
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1 && response.data.STATUSMESSAGE) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      if (response.data.STATUS == 1) {
        this.details = response.data;
        this.items = this.details.CUSTOMDATA;
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }

    colsResizeable({ms: 500});
  }
  async checkStatusList(status: number) {
    this.selectedID = [];
    const i = this.filters.sStatus.findIndex(s => s === status);
    if (i === -1) {
      this.filters.sStatus.push(status);
    } else {
      this.filters.sStatus.splice(i, 1);
    }

    await this.fetchData();
  }

  async checkAllStatusList() {
    this.selectedID = [];
    this.filters.sStatus = [1, 2, 3, 4, 5, 6];
    await this.fetchData();
  }
  async uncheckAllStatusList() {
    this.selectedID = [];
    this.filters.sStatus = [-1];
    await this.fetchData();
  }
  async resetsearchByName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.name = this.searchFilters.name = "";
    await this.fetchData();
  }

  async searchByName() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.searchFilters.name == "") {
      return;
    } else {
      this.filters.name = this.searchFilters.name;
      await this.fetchData();
    }
  }

  async resetsearchByAcc() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.account = "";
    await this.fetchData();
  }

  async searchByAcc() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.filters.account == "") {
      return;
    } else {
      await this.fetchData();
    }
  }
  async removeSelected() {
    var refModal: any = this.$refs.confirmRemoveModal;
    refModal.deleting = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Setting",
        FunctionName: "DeleteAccountCustomData",
        customerData: this.selectedID.join(",")
      });

      if (response.data.ERROR) {
        refModal.deleting = "error";
        throw new Error(response.data.ERROR);
      }

      if (response.data.STATUS == 1) {
        refModal.deleting = false;
        this.selectedID = [];
        this.fetchData();
      }
    } catch (err) {
      refModal.deleting = "error";
    } finally {
      this.confirmRemoveModalVisible = false;
    }
  }

  loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (this.pageNumber <= this.details.TOTALPAGES && this.pageNumber >= 1) {
      this.fetchData();
    }
  }

  getCustomDTypeText(dTypeId) {
    let ret = "";
    switch (dTypeId) {
      case 1:
        ret = "Text";
        break;
      case 3:
        ret = "Dropdown";
        break;
    }

    return ret;
  }
}
