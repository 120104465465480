






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import SortIndicator from "../components/SortIndicator.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import {
  getRangeFilter,
  colsResizeable,
  colsResizeableReset,
  getDateRangeFilter
} from "@/helpers/ApiHelper";
import { any } from "prop-types";
import DeployStatusComponent, {
  DeployStatusValue
} from "../components/DeployStatus.vue";
import DeploymentOrdersTooltip from "@/components/DeploymentOrdersTooltip.vue";
import { TooltipPlugin } from "bootstrap-vue";
import DeploymentsGridView from "@/components/Deployment/DeploymentsGridView.vue";
import draggable from "vuedraggable";

@Component({
  inheritAttrs: false,
  components: {
    DeploymentsGridView,
    ListHeaderActions,
    Pagination,
    draggable,
    DeployStatusComponent,
    DeploymentOrdersTooltip,
    SortIndicator
  },
  methods: {
    getRangeFilter: (min, max) => {
      return getRangeFilter(min, max);
    },
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    }
  }
})
export default class Deployments extends TSXComponent<void> {
  tab = 1;
  tooltipVisibleIndex: any = -1;
  $allowedFunctions: any;
  $userFunctions: any;
  $isHideReseller: any;
  loading = false;
  pageNumber = 1;
  details: any = {};
  deploymentDetails: any = {};
  filters: any = {
    deploymentId: "",
    deploymentName: "",
    customerName: "",
    sDate: "",
    eDate: "",
    sEstCompletion: "",
    eEstCompletion: "",
    purchaseId: "",
    deploymentStatuses: [1,2,3,4,5,6]
  };
  sort = {
    field: null,
    direction: {
      deploymentId: 1,
      deploymentName: 1,
      customerName: 1,
      date: 1,
      estCompletion: 1,
      purchaseId: 1
    }
  };
  directionField = "";
  currentRole = sessionStorage.getItem("userRole");
  customerHeader = this.currentRole === "Reseller" ? "Customer" : "Reseller";
  searchHeader = "";
  pageHeaders = [
    { key: "deploymentId", value: "Deployment Id" },
    { key: "deploymentName", value: "Deployment Name" },
    { key: "customerName", value: "Customer" },
    { key: "date", value: "Date" },
    { key: "estCompletion", value: "Est. Completion" },
    { key: "endUsers", value: "End Users" },
    { key: "assets", value: "Assets" },
    { key: "services", value: "Services" },
    { key: "orders", value: "Orders" },
    { key: "completion", value: "Completion %" },
    { key: "status", value: "Status" },
  ];
  selectedHeaders: any = [
    "deploymentId",
    "deploymentName",
    "customerName",
    "date",
    "estCompletion",
    "endUsers",
    "assets",
    "services",
    "orders",
    "completion",
    "status"
  ];
  headerOrder: any = [
    "deploymentId",
    "deploymentName",
    "customerName",
    "date",
    "estCompletion",
    "endUsers",
    "assets",
    "services",
    "orders",
    "completion",
    "status"
  ];
  listCustomFields: any = [];
  // gridData: any = {
  //   activeDeployments: [],
  //   completeDeployments: []
  // };

  deployementStatuses: any[] = [
    {
      Status_ID: 1,
      Status_Name: "Planning"
    },
    {
      Status_ID: 2,
      Status_Name: "Building"
    },
    {
      Status_ID: 3,
      Status_Name: "Delivering"
    },
    {
      Status_ID: 4,
      Status_Name: "Complete"
    },
    {
      Status_ID: 5,
      Status_Name: "On Hold"
    },
    {
      Status_ID: 6,
      Status_Name: "Closed"
    }
  ];

  @Watch("selectedHeaders")
  colsChange(val: any) {
    colsResizeable();
  }

  async created() {
    this.tab = this.$route.query.tab
      ? Number.parseInt(this.$route.query.tab + "", 10) || 1
      : 1;
    await Promise.all([this.fetchData(), this.getFilters()]);
    colsResizeable({ ms: 500 });
  }

  async fetchData() {
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "DeploymentsList",
        maxRows:
          sessionStorage.getItem("maxRows") != ""
            ? sessionStorage.getItem("maxRows")
            : getMaxRows(),
        pageNumber: this.pageNumber,
        deploymentId: this.filters.deploymentId,
        deploymentName: this.filters.deploymentName,
        customerName: this.filters.customerName,
        sDate: this.filters.sDate,
        eDate: this.filters.eDate,
        sEstCompletion: this.filters.sEstCompletion,
        eEstCompletion: this.filters.eEstCompletion,
        purchaseId: this.filters.purchaseId || "",
        statuses: this.filters.deploymentStatuses.length > 0 ? this.filters.deploymentStatuses : [-1],
        order: this.sort.field ? this.sort.field : 0,
        direction:
          this.directionField != ""
            ? this.sort.direction[this.directionField]
            : 0
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1 && response.data.STATUSMESSAGE) {
        throw new Error(response.data.STATUSMESSAGE);
      }
      if (response.data.STATUS == 1) {
        this.details = response.data;
        this.deploymentDetails = response.data.DEPLOYMENTS;
        // const deploymentHeaders = this.details.deploymentHeaders || [];
        // if (deploymentHeaders.length) {
        //   for (const item of deploymentHeaders) {
        //     if (!this.selectedHeaders.includes(item)) {
        //       this.selectedHeaders.push(item);
        //     }
        //   }
        // }

        /*         if (this.deploymentDetails.TIEDORDERSCOUNT == 1) {
          const orderIDListString = this.deploymentDetails.LINKEDORDER.split(',').map(item => item.trim());
          const orderIDList = orderIDListString.join('\n')
        } */

        // append custom fields to custom colums list (make sure names are unique)
        this.listCustomFields = this.details.listCustomFields || [];
        for (const item of this.listCustomFields) {
          const field = `customData${item.CUSTOMFIELDID}`;
          let existedName = false;
          for (const header of this.pageHeaders) {
            if (
              header.key.indexOf("customData") != -1 &&
              header.value.toLowerCase() ==
                item.CUSTOMFIELDNAME.toLowerCase()
            ) {
              existedName = true;
              break;
            }
          }
          if (!existedName) {
            if ((this.pageHeaders.find((header) => header.key == field) || { key: '', value: ''}).value == "") {
              this.pageHeaders.push({
                key: field,
                value: item.CUSTOMFIELDNAME
              });
            }
            if (!this.headerOrder.includes(field)) {
              this.headerOrder.push(field);
            }
          }
        }

        const deploymentHeaders = this.details.deploymentHeaders || [];
        if (deploymentHeaders.length) {
          const uniqueFieldNames: string[] = [];
          for (const item of deploymentHeaders) {
            const fieldName = (this.pageHeaders.find((header) => header.key == item) || { key: '', value: ''}).key;
            if (
              !this.selectedHeaders.includes(item) &&
              fieldName &&
              !uniqueFieldNames.includes(fieldName)
            ) {
              uniqueFieldNames.push(fieldName);
              this.selectedHeaders.push(item);
            }
          }
        }

        // if sorted header
        const savedSorting = this.details.userDeploymentColumnOrder || [];
        if (savedSorting.length) {
          let newSorting: any = [];
          for (const header of savedSorting) {
            const inList = this.pageHeaders.find(item => item.key == header);
            if (inList) {
              newSorting.push(inList);
            }
          }
          const newItems = this.pageHeaders.filter(
              item => !savedSorting.includes(item.key)
          );
          newSorting = [...newSorting, ...newItems];
          this.pageHeaders = newSorting;

          // need to sort selectedHeaders
          this.sortSelectedHeader();
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  showTooltip(index) {
    if (this.tooltipVisibleIndex == index) {
      return;
    }
    this.tooltipVisibleIndex = index;
  }

  loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (this.pageNumber <= this.details.TOTALPAGES && this.pageNumber >= 1) {
      this.fetchData();
    }
  }

  async sortBy(field, directionField) {
    this.sort.field = field;
    this.sort.direction[directionField] =
      this.sort.direction[directionField] == 1 ? 2 : 1;
    this.directionField = directionField;

    //reset
    for (const dirField in this.sort.direction) {
      if (dirField === directionField) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  searchBy(field) {
    if (this.filters[field] == "") {
      return;
    }
    this.pageNumber = 1;
    this.fetchData();
  }

  resetBy(field) {
    if (this.filters[field] == "") {
      return;
    }
    this.pageNumber = 1;
    this.filters[field] = "";
    this.fetchData();
  }

  async searchByDate() {
    this.pageNumber = 1;
    if (
      (this.filters.sDate == undefined || this.filters.sDate == "") &&
      (this.filters.eDate == "" || this.filters.eDate == undefined)
    ) {
      return;
    } else {
      if (this.filters.sDate == "") {
        this.filters.sDate = undefined;
      } else if (this.filters.eDate == "") {
        this.filters.eDate = undefined;
      }
      await this.fetchData();
    }
  }

  async resetSearchByDate() {
    this.pageNumber = 1;
    if (
      (this.filters.sDate == undefined || this.filters.sDate == "") &&
      (this.filters.eDate == "" || this.filters.eDate == undefined)
    ) {
      return;
    } else {
      this.filters.sDate = undefined;
      this.filters.eDate = undefined;
      await this.fetchData();
    }
  }

  async searchByEstCompletion() {
    this.pageNumber = 1;
    if (
      (this.filters.sEstCompletion || "") == "" &&
      (this.filters.eEstCompletion || "") == ""
    ) {
      return;
    } else {
      if (this.filters.sEstCompletion == "") {
        this.filters.sEstCompletion = undefined;
      } else if (this.filters.eEstCompletion == "") {
        this.filters.eEstCompletion = undefined;
      }
      await this.fetchData();
    }
  }

  async resetSearchByEstCompletion() {
    this.pageNumber = 1;
    if (
      (this.filters.sEstCompletion || "") == "" &&
      (this.filters.eEstCompletion || "") == ""
    ) {
      return;
    } else {
      this.filters.sEstCompletion = undefined;
      this.filters.eEstCompletion = undefined;
      await this.fetchData();
    }
  }

  async addHeader(c) {
    const addedCol = this.selectedHeaders.includes(c);
    colsResizeableReset();
    var sortedHeaders: string[] = [];
    for (const item of this.headerOrder) {
      if (this.selectedHeaders.includes(item)) {
        sortedHeaders.push(item);
      }
    }
    this.selectedHeaders = sortedHeaders;

    // data to save selected headers
    const saveHeaders: string[] = [];
    for (const fieldId of this.selectedHeaders) {
      saveHeaders.push(fieldId);

      // select all custom fields have same name
      const fieldName = (this.pageHeaders.find(header => header.key == fieldId) || {key: '', value: ''}).value;
      if (fieldId.indexOf("customData") != -1 && fieldName) {
        const relatedFields = this.listCustomFields.filter(
          t =>
            t.CUSTOMFIELDNAME.toLowerCase() == fieldName.toLowerCase() &&
            !saveHeaders.includes(`customData${t.CUSTOMFIELDID}`)
        );
        for (const t of relatedFields) {
          saveHeaders.push(`customData${t.CUSTOMFIELDID}`);
        }
      }
    }

    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Users",
      FunctionName: "paramsUpdate",
      headers: saveHeaders.join(","),
      from: "deployment"
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (addedCol && c.indexOf("customData") != -1) {
      // reload list if show custom fields on list
      await this.fetchData();
    }

    colsResizeable();
  }

  removeColumn(header) {
    this.selectedHeaders = this.selectedHeaders.filter(item => item != header);
    this.addHeader(header);
  }

  getColumnWidth(header) {
    let headerCls = "";
    switch (header) {
      case "deploymentId":
      case "customerName":
        headerCls = "col-1-5";
        break;
      case "deploymentName":
        headerCls = "col-2";
        break;
      case "date":
      case "endUsers":
      case "assets":
      case "services":
      case "orders":
      case "status":
        headerCls = "col-1";
        break;
    }
    if (header.indexOf("customData") != -1) {
      headerCls = "col-1";
    }

    return headerCls;
  }

  getListHeader(key: string) {
    let ret = "";
    if (key.indexOf("customData") != -1) {
      const fieldId = key.replace("customData", "");
      const inList = this.listCustomFields.find(
        item => item.CUSTOMFIELDID == fieldId
      );
      if (inList) {
        ret = inList.CUSTOMFIELDNAME || "";
      }
    } else {
      ret = this.pageHeaders[key];
    }
    return ret;
  }

  getCustomDataField(item, header) {
    // specify correct field by using listCustomFields
    const fieldName = (this.pageHeaders.find(headerItem => headerItem.key == header) || {key: '', value: ''}).value;
    const relatedField = this.listCustomFields.find(
      t =>
        t.CUSTOMFIELDNAME.toLowerCase() == fieldName.toLowerCase() &&
        (t.AID || 0) == (item.AID || 0)
    );
    if (relatedField) {
      return `CUSTOMDATA${relatedField.CUSTOMFIELDID}`;
    }

    return `CUSTOMDATA${header.replace("customData", "")}`;
  }

  getCustomDataValue(item, header) {
    let value = item[this.getCustomDataField(item, header)] || "";
    try {
      // if saved as an array (case multi select)
      value = JSON.parse(value);
      value = value.join(", ");
    } catch (error) {
      // do nothing
    }
    return value;
  }

  disabledHeader(x) {
    const shows = [
      "deploymentName",
      "customerName",
      "date",
      "estCompletion",
      "endUsers",
      "assets",
      "orders",
      "completion",
      "status"
    ];
    if (shows.includes(x)) {
      return true;
    }
    if (!this.selectedHeaders.includes(x) && this.selectedHeaders.length > 13) {
      // prevent selecting over 13 cols
      return true;
    }

    return false;
  }

  searchByHeader() {
    const filteredHeader = this.pageHeaders
            .filter((item) => item.value.toLowerCase().search(this.searchHeader.toLowerCase()) !== -1);

    return filteredHeader;
  }

  hasCustomData() {
    const inList = this.selectedHeaders.find(
      key => key.indexOf("customData") != -1
    );
    if (inList) {
      return true;
    }

    return false;
  }

  async changeStatus(deployId: string, status: DeployStatusValue) {
    try {
      this.loading = true;
      await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "UpdateDeploymentStatus",
        deploymentId: deployId,
        deployStatus: status
      });
      await this.fetchData();
    } catch (err) {
      console.log(err.message);
    } finally {
      this.loading = false;
    }
  }

  resetTooltip() {
    // console.log("resetTooltip");
    this.tooltipVisibleIndex = -1;
  }

  // async getDeployments() {
  //   try {
  //     this.loading = true;
  //     if (this.tab == 2) {
  //       // grid view
  //       const response = await axios.post(dataURL + "?ReturnType=JSON", {
  //         controller: "Deployments",
  //         FunctionName: "DeploymentsGrid",
  //         maxRows: sessionStorage.getItem("maxRows") || getMaxRows()
  //       });

  //       if (response.data.STATUS == 1) {
  //         this.gridData.activeDeployments =
  //           response.data.activeDeployments || [];
  //         this.gridData.activeTotal = response.data.activeTotal || 0;
  //         this.gridData.activeTotalPages = response.data.activeTotalPages || 0;
  //         this.gridData.activeCurrentPage = response.data.activeCurrentPage || 1;

  //         this.gridData.completeDeployments =
  //           response.data.completeDeployments || [];
  //         this.gridData.completeTotal = response.data.completeTotal || 0;
  //         this.gridData.completeTotalPages = response.data.completeTotalPages || 0;
  //       }
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     this.loading = false;
  //   }
  // }

  gridviewFilters = {
    isFiltering: false,
    agency: "",
    agencyNames: "",
    deliveryCenter: "",
    deliveryCenterNames: ""
  };

  fullCustomData: any = [];
  filterDeliveryCenter = {
    AID: 0,
    CUSTOMDTYPE: 0,
    CUSTOMFIELDID: 0,
    CUSTOMFIELDNAME: "",
    CUSTOMID: 0,
    CUSTOMVALUE: "",
    OPTIONS: []
  };
  filterAgency = {
    AID: 0,
    CUSTOMDTYPE: 0,
    CUSTOMFIELDID: 0,
    CUSTOMFIELDNAME: "",
    CUSTOMID: 0,
    CUSTOMVALUE: "",
    OPTIONS: []
  };
  async getFilters() {
    const ignoreFieldIds = [26];
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Helpers",
      FunctionName: "CustomData",
      DataType: "1",
      Id: 0,
      DropdownField: 25,
      contractDropdown: 26,
      orderCustomData: true,
      ignoreFieldIds: ignoreFieldIds.join(",")
    });

    if (response.data.STATUS == 1) {
      this.fullCustomData = [];
      for (const val of response.data.CUSTOMDEFINITION) {
        let tmpValue: any = [];
        if (typeof response.data.CUSTOMDATA != "undefined") {
          tmpValue = response.data.CUSTOMDATA.filter(
            tmp => tmp.CUSTOMFIELDID == val.CUSTOMFIELDID
          );
        }
        this.fullCustomData.push({
          AID: val.AID,
          CUSTOMFIELDID: val.CUSTOMFIELDID,
          CUSTOMFIELDNAME: val.CUSTOMFIELDNAME,
          CUSTOMVALUE: tmpValue.length ? tmpValue[0].CUSTOMVALUE : "",
          CUSTOMID: tmpValue.length ? tmpValue[0].CUSTOMID : 0,
          CUSTOMDTYPE: val.CUSTOMDTYPE,
          OPTIONS: (val.OPTIONS || []).map(item => {
            return { ...item, checked: false };
          })
        });
      }
      const filterDeliveryCenter = this.fullCustomData.find(
        item => item.CUSTOMFIELDNAME == "Delivery Center"
      );
      if (filterDeliveryCenter) {
        this.filterDeliveryCenter = {
          ...this.filterDeliveryCenter,
          AID: filterDeliveryCenter.AID || 0,
          CUSTOMDTYPE: filterDeliveryCenter.CUSTOMDTYPE || 0,
          CUSTOMFIELDID: filterDeliveryCenter.CUSTOMFIELDID || 0,
          CUSTOMFIELDNAME:
            filterDeliveryCenter.CUSTOMFIELDNAME || "Delivery Center",
          CUSTOMID: filterDeliveryCenter.CUSTOMID || 0,
          CUSTOMVALUE: filterDeliveryCenter.CUSTOMVALUE || "",
          OPTIONS: filterDeliveryCenter.OPTIONS || []
        };
      }
      const filterAgency = this.fullCustomData.find(
        item => item.CUSTOMFIELDNAME == "Agency"
      );
      if (filterAgency) {
        this.filterAgency = {
          ...this.filterAgency,
          AID: filterAgency.AID || 0,
          CUSTOMDTYPE: filterAgency.CUSTOMDTYPE || 0,
          CUSTOMFIELDID: filterAgency.CUSTOMFIELDID || 0,
          CUSTOMFIELDNAME: filterAgency.CUSTOMFIELDNAME || "Agency",
          CUSTOMID: filterAgency.CUSTOMID || 0,
          CUSTOMVALUE: filterAgency.CUSTOMVALUE || "",
          OPTIONS: filterAgency.OPTIONS || []
        };
      }
    }
  }

  async syncAgencyOptions() {
    const selectedOptions = this.filterAgency.OPTIONS.filter(
      (option: any) => option.checked
    );
    this.gridviewFilters.agency = selectedOptions
      .map((option: any) => option.CUSTOMFIELDOPTIONNAME)
      .join(",");
    this.gridviewFilters.agencyNames = selectedOptions
      .map((option: any) => option.CUSTOMFIELDOPTIONNAME)
      .join(", ");
  }

  async syncDeliveryCenterOptions() {
    const selectedOptions = this.filterDeliveryCenter.OPTIONS.filter(
      (option: any) => option.checked
    );
    this.gridviewFilters.deliveryCenter = selectedOptions
      .map((option: any) => option.CUSTOMFIELDOPTIONNAME)
      .join(",");
    this.gridviewFilters.deliveryCenterNames = selectedOptions
      .map((option: any) => option.CUSTOMFIELDOPTIONNAME)
      .join(", ");
  }

  checkFilterExists(header) {
    if (typeof this.filters[header] != "undefined") {
      return !this.filters[header].length;
    } else {
      return true;
    }
  }

  getInitials(name) {
    const words = name.split(" ");
    let initials = "";
    for (let i = 0; i < Math.min(2, words.length); i++) {
      initials += words[i].charAt(0);
    }
    return initials.toUpperCase();
  }

  sortSelectedHeader() {
    const newSelectedHeaders: string[] = [];
    for (const item of this.pageHeaders) {
      if (!this.selectedHeaders.includes(item.key)) continue;
      newSelectedHeaders.push(item.key);
    }
    this.selectedHeaders = newSelectedHeaders;
  }

  async handleListChange(event) {
    this.sortSelectedHeader();
    const dataObj = {
      controller: "Users",
      FunctionName: "sortListColumns",
      page: 'Deployments',
      userid: sessionStorage.getItem("userId"),
      params: this.pageHeaders.map(item => item.key)
    };
    await axios.post(dataURL + "?ReturnType=JSON", dataObj);
  }

  filterByStatus(status) {
    if (this.filters.deploymentStatuses.includes(status)) {
      this.filters.deploymentStatuses.splice(
        this.filters.deploymentStatuses.indexOf(status),
        1
      );
    } else {
      this.filters.deploymentStatuses.push(status);
    }

    this.pageNumber = 1;
    this.fetchData();
  }

  checkAllStatuses() {
    this.filters.deploymentStatuses = this.deployementStatuses.map((item) => item.Status_ID);
    this.pageNumber = 1;
    this.fetchData();
  }

  uncheckAllStatuses() {
    this.filters.deploymentStatuses = [];
    this.pageNumber = 1;
    this.fetchData();
  }

  getStatusFilterValue() {
    let filteredValue = '';
    if(this.filters.deploymentStatuses.length == 0) {
      filteredValue = 'None';
    } else if(this.deployementStatuses.length != this.filters.deploymentStatuses.length) {
      filteredValue = this.deployementStatuses.filter((item) => this.filters.deploymentStatuses.includes(item.Status_ID)).map((item) => item.Status_Name).join(', ');
    }
    return filteredValue;
  }
}
