






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { validateFn } from "../helpers";
import SortIndicator from "../components/SortIndicator.vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import {
	notifier,
	downloadFile,
	printHtml,
	downloadFileUrl,
} from "../models/common";
import log from "../helpers/log";
import {
  DashboardCashflowAPIRepo,
  Filters,
  Sort,
  SortField
} from "../repos/DashboardCashflowAPIRepo";
const template = require("../templates/dashboard/dashboard.handlebars");
import InfoTooltip from "../components/InfoTooltip.vue";
import ActivitiesViewTooltip from "../components/DataFilterTooltip.vue";
import FilterCurrencyBox from "../components/FilterCurrencyBox.vue";
import Pagination from "../components/Pagination.vue";
import CustomerSearchTooltip from "../components/CustomerSearchTooltip.vue";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import { getRangeFilter } from "@/helpers/ApiHelper";

const dashboardRepo = new DashboardCashflowAPIRepo();

interface ILineChart extends TSXComponent<void> {
	renderLineChart();
}

interface apexchart extends TSXComponent<void> {}

interface IBarChart extends TSXComponent<void> {
	renderBarChart();
}
declare const $: any;
declare const dataURL: string;
declare const getRouteData: Function;
declare const require: any;
const allMonths = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];
var braekmonth = 11 - new Date().getMonth() ;
var startPoint = new Date().getMonth();
var lastYear:string[] = [];
var Curryear:string[] = [];
var Currmon:string[] = [];
var lastmon:string[] = [];
for (var i = 0; i < 12; i++) {
	var mon = allMonths[i].substring(0,3);
	if(startPoint < i){
		lastYear.push(mon+", "+( new Date().getFullYear() - 1));
		lastmon.push(allMonths[i]);
	}
	else{
		Curryear.push(mon+", "+(new Date().getFullYear()));
		Currmon.push(allMonths[i]);
	}
}
const last12 = lastYear.concat(Curryear)
const last12months = lastmon.concat(Currmon)

@Component({
	inheritAttrs: false,
	components: {
    ListHeaderActions,
		SortIndicator,
		InfoTooltip,
		ActivitiesViewTooltip,
		FilterCurrencyBox,
		Pagination,
		CustomerSearchTooltip
	},
  methods: {
    getRangeFilter: (min, max) => {
      return getRangeFilter(min, max);
    }
  }
})
export default class Dashboard extends TSXComponent<void> {
	$root: any;
	$allowedFunctions: any;
	$systemGroups: any;
	$userFunctions: any;
	currYear = new Date().getFullYear();
	loading = false;
	CurrentView = "My Orders";
	viewType = "CurrentUsers";
	CurrentHeader = "My Orders";
	role = sessionStorage.getItem("userRole");
	items: {
		sortingYear: number;
		salesLineChartData1?: null | number[];
		salesLineChartData2?: null | number[];
		salesLineChartData3?: null | number[];
		orderBarChartData?: null | number[];
		delvieryBarChartData?: null | number[];
		salesLineChartLabel?: null | string[];
		categoryTrend?: null | number[];
		shipmentTrend?: { AVGDAYHOUR: string }[] | null;
		dataOrderTrend?: null | number[];
	} = {
		sortingYear: this.currYear,
		salesLineChartData1: null,
		salesLineChartData2: null,
		salesLineChartData3: null,
		orderBarChartData: null,
		delvieryBarChartData: null,
		salesLineChartLabel: null,
		categoryTrend: null,
		shipmentTrend: null,
		dataOrderTrend: null,
	};
	reportedDates = [];
	salesLineChartData1 = [];
	salesLineChartData2 = [];
	salesLineChartData3 = [];
	salesLineChartLabel: string[] = [];
	orderBarChartData = [
		{
			label: "Units",
			backgroundColor: "#E3F2FD",
			borderColor: "#009cff",
			borderWidth: 2,
			data: [],
		},
	];
	orderBarChartLabel: string[] = [];
	delvieryBarChartData: {
		label: string;
		backgroundColor: string;
		data: string[];
	}[] = [
		{
			label: "Days",
			backgroundColor: "#009cff",
			data: [],
		},
	];
	deliveryBarChartLabel: string[] = [];
	salesLineChartData = [
		{
			label: "Prior Year",
			backgroundColor: "transparent",
			borderColor: "#3c3564",
			borderWidth: 3,
			name: "sales",
			linetype: "Orders",
			fill: true,
			borderDash: [5, 5],
			data: [],
		},
		{
			label: "This Year",
			backgroundColor: "#E3F2FD",
			borderColor: "#009cff",
			name: "sales",
			linetype: "Orders",
			borderWidth: 3,
			fill: true,
			data: [],
		},
	];
	ordersLineChartData = [
		{
			label: "Prior Year",
			backgroundColor: "transparent",
			borderColor: "#38c4c5",
			borderWidth: 3,
			lineTension: 0.1,
			fill: true,
			name: "orders",
			borderDash: [5, 5],
			data: [],
		},
		{
			label: "This Year",
			backgroundColor: "#E3F2FD",
			borderColor: "#009cff",
			borderWidth: 3,
			name: "orders",
			fill: true,
			data: [],
		},
	];
	quotesLineChartData = [
		{
			label: "Prior Year",
			backgroundColor: "transparent",
			borderColor: "#38c4c5",
			borderWidth: 3,
			lineTension: 0.1,
			name: "quotes",
			fill: true,
			borderDash: [5, 5],
			data: [],
		},
		{
			label: "This Year",
			backgroundColor: "#E3F2FD",
			borderColor: "#009cff",
			borderWidth: 3,
			name: "quotes",
			fill: true,
			data: [],
		},
	];
	years = [];
	ApiMonths = [];
	dashView = "CurrentUsers";
	showView = 1;
	exportObj = {
		controller: "Dashboard",
		FunctionName: "Export",
		uploadPath: "Inbound/apiFiles/",
		keyName: "Inbound/apiFiles/",
		ExportType: "PDF",
		unit: "both",
		figure: "1,4,7",
		userRole: sessionStorage.getItem("userRole"),
		fYear: this.items.sortingYear,
	};
	totalSalesTTVisibleIndex = -1;
	totalCommTTVisibleIndex = -1;
	totalUnitTTVisibleIndex = -1;
	selectedView = false;
	cashflow: any = [];
	accountTags: any = [];
	quoteChecked = false;
	activities: object[] = [];
	ActivitiesViewTooltipVisible = false;
	selectedFilters: any = [
		"Quote",
		"Order",
		"Invoice",
		"Account",
		"Support",
		"System",
	];
	activitiesFilters: any = [];
	selectedGlobalTag = false;
	tagIDs: number[] = [];
	gTagIDs: number[] = [];
	groupTagIDs: number[] = [];
	selectedTags = [];
	cashflowData: any = {};
	filters: Filters = {
		purchaseID: "",
		searchName: "",
		searchAccountName: "",
		AccountTagID: [],
		gTagIDs: [],
		groupIDs: [],
		selectedAccs: [],
    groupName: [],
    selectedAccsName: [],
    accountTagName: [],
		varCostMin: undefined,
		varCostMax: undefined,
		poTotalMin: undefined,
		poTotalMax: undefined,
		cashflowPercentMin: undefined,
		cashflowPercentMax: undefined,
  };
	sort: Sort = {
		field: null,
		direction: {
			[SortField.purchaseID]: 1,
			[SortField.orderName]: 1,
			[SortField.Customer]: 1,
			[SortField.total]: 1,
			[SortField.poTotal]: 1,
			[SortField.cashflowPercent]: 1
		}
  };
	pageNumber = sessionStorage.pageDetails && JSON.parse(sessionStorage.pageDetails)["Cashflow"] ? JSON.parse(sessionStorage.pageDetails)["Cashflow"] : 1;
	selectedID = [];
	selectedAll = false;
	excludedIDs: number[] = [];
	orderCashflow: any = [];	 
	ret = {
    CURRENTPAGE: 1,
    TOTALPAGES: 1
  };
	searchTagsTxt = "";
	searchAccTxt = "";
	varAccs: {
		AID?: number;
		APRIMID?: number;
		ACCOUNTID?: string;
		ANAME?: string;
		ACCOUNTALIAS?: string;
	}[] = [];
	varAccsList: {
		AID?: number;
		APRIMID?: number;
		ACCOUNTID?: string;
		ANAME?: string;
		ACCOUNTALIAS?: string;
	}[] = [];
	selectedAccs: number[] = [];
	isActive = "customerAccounts";
	customerTooltipVisibleIndex = -1;
	customerTooltipLoading = false;
	loadingYear = false;
	sortingYear = new Date().getFullYear();
	cashflowYear: any = [];

	$refs!: {
		chart1: ILineChart;
		chart2: IBarChart;
		chart3: IBarChart;
	};
	salesLineSeries = [
		{
			name: "Prior Year",
			data: [28, 29, 33, 36, 32, 32, 33],
		},
		{
			name: "This Year",
			data: [412, 141, 514, 158, 167, 173, 183],
		},
	];
	saleOrderChartData = [
		{
			name: "Units",
			data: ["28", "29", 33, 36, 32, 32, 33],
		}
	];
	quoteOrderChartData = [
		{
			name: "Units",
			data: ["28", 29, 33, 36, 32, 32, 33],
		}
	];
	barOptions:any ={
		chart: {
			height: 250,
			defaultLocale: "en",
			type: "bar",
			stacked: true,
			dropShadow: {
				enabled: true,
				color: "#000",
				top: 18,
				left: 7,
				blur: 10,
				opacity: 0.2,
			},
			animations: {
				enabled: true,
				easing: "linear",
				dynamicAnimation: {
					speed: 1000,
				},
			},
			toolbar: { show: false },
			zoom: { enabled: false },
		},
		colors: ["#0ea1ff"],
		dataLabels: {
			enabled: false,
		},
		xaxis: {
			categories: [],
			tooltip: {
				enabled: false,
			},
			axisTicks: {
				show: false,
			},
			axisBorder: {
				show: false
			},
			labels: {
				show: true,
				style: {
					colors:"#656468",
						fontFamily: 'Work Sans, sans-serif',
					fontWeight: 600,
					fontSize: "10px",
				},
			},
		},
		yaxis: {
			show: false,
		},
		tooltip: {
			x: {
				formatter: function (value) {
					return value;
				},
			},
			y: {
				formatter: function (value) {
					var formatedAmount = value;
					// if (!isNaN(value) && value != 0) {
					// 	value = value.toFixed(2);
					// 	formatedAmount = "$" + value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
					// }
					return formatedAmount;
				},
			},
		},
		grid: { show: false },
	};
	searchFilters: Filters = {
		purchaseID: "",
		searchName: "",
		searchAccountName: "",
		AccountTagID: [],
		gTagIDs: [],
		groupIDs: [],
		selectedAccs: [],
    groupName: [],
    selectedAccsName: [],
    accountTagName: [],
		varCostMin: undefined,
		varCostMax: undefined,
		poTotalMin: undefined,
		poTotalMax: undefined,
		cashflowPercentMin: undefined,
		cashflowPercentMax: undefined,
  };
	cashflowVisible = false;
	activeVisible = false;
	marginVisible = false;
	projectMargingVisible = false;
	activeMargingVisible = false;
	currWeekBillVisible = false;
	thirtyDaysBillVisible = false;
	realizedVisible = false;

	@Prop({ required: false, default: 0 })
	customerid!: number;

	async created() {
		await this.fetchDashboardData();
		await this.accsLoad();
	}

	get chartOptions(){
		return {
			chart: {
				height: "350px",
				defaultLocale: "en",
				type: "line",
				// stacked: true,
				events: {
					click: (event, chartContext, config)  =>  {
						if(config.dataPointIndex >= 0 || config.seriesIndex >= 0){
							// The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
							this.saleRedirect(config.globals.categoryLabels[config.dataPointIndex].split("-")[1],config.globals.categoryLabels[config.dataPointIndex],config.seriesIndex,config.dataPointIndex,config.globals.categoryLabels[config.dataPointIndex].split("-")[0])
						}
					}
				},
				dropShadow: {
					enabled: true,
					color: "#000",
					top: 18,
					left: 7,
					blur: 10,
					opacity: 0.2,
				},
				animations: {
					enabled: true,
					easing: "linear",
					dynamicAnimation: {
						speed: 1000,
					},
				},
				toolbar: { show: false },
				zoom: { enabled: false },
			},
			colors: ["#3c3564", "#0ea1ff", "#b7b6ba"],
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: "smooth",
				width: 3,
				dashArray: [7, 0, 7],
			},
			markers: {
				size: 6,
				fillOpacity: 0,
			},
			xaxis: {
				categories: this.categories(),
				tooltip: {
					enabled: false,
				},
				crosshairs: {       
					stroke: {
						width: 0,
          			},
				},
				axisTicks: {
					show: true,
					height: 11,
				},
				labels: {
					show: true,
					style: {
						colors:"#656468",
						 fontFamily: 'Work Sans, sans-serif',
						fontWeight: 600,
						fontSize: "10px",
					},
				},
			},
			yaxis: {
				show: false,
			},
			tooltip: {
				x: {
					formatter: (value) => {
						if(isNaN(this.items.sortingYear)){
							return "Month: " + last12months[value - 1];
						}
						else{
							return "Month: " + allMonths[value - 1];
						}
					},
				},
				y: {
					formatter: function (value) {
						var formatedAmount = value;
						if (!isNaN(value)) {
							value = value.toFixed(2);
							formatedAmount = "$" + value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
						}
						return formatedAmount;
					},
				},
			},
			grid: { show: false },
			legend: {
				show: true,
				horizontalAlign: "center",
				onItemClick: {
					toggleDataSeries: true,
				},
				position: "top",
				markers: {
					width: 40,
					height: 15,
					radius: 0,
					strokeWidth: 3,
					strokeColor: ["#3c3564", "#0ea1ff", "#b7b6ba"],
				},
			},
		}
	}

	@Watch("items", { deep: true })
	itemsUpdated(
		val: {
			salesLineChartLabel: string[];
			delvieryBarChartData: string[];
			orderBarChartData: string[];
			salesLineChartData2: number[];
			salesLineChartData3: number[];
			OrderLineChartData1: number[];
			OrderLineChartData2: number[];
			quotesLineChartData1: number[];
			quotesLineChartData2: number[];
			salesLineChartData1: number[];
		},
		oldVal: string
	) {
		try {
			// this.debounce(async function (val, oldVal) {
			// 	console.log('debounce watch');
			try {
				// console.log('label',val.salesLineChartLabel)
				this.salesLineChartLabel = val.salesLineChartLabel;
				if (this.quoteChecked) {
					this.salesLineChartData[2].data = val.salesLineChartData3.length
						? JSON.parse(JSON.stringify(val.salesLineChartData3))
						: [];
					if (this.salesLineSeries.length == 2) {
						this.salesLineSeries.push({
							name: "This Year Quotes",
							data: JSON.parse(JSON.stringify(val.salesLineChartData3)),
						});
					} else {
						// this.salesLineSeries[2].name = "This Year Quotes";
						this.salesLineSeries[2].data = JSON.parse(
							JSON.stringify(val.salesLineChartData3)
						);
					}
				} else {
					if (this.salesLineSeries.length > 2) {
						this.salesLineSeries.pop();
					}
				}
				this.salesLineChartData[1].data = val.salesLineChartData1.length
					? JSON.parse(JSON.stringify(val.salesLineChartData1))
					: [];
				this.salesLineSeries[1].data = JSON.parse(
					JSON.stringify(val.salesLineChartData1)
				);
				this.salesLineSeries[0].data = JSON.parse(
					JSON.stringify(val.salesLineChartData2)
				);
				this.salesLineChartData[0].data = val.salesLineChartData2.length
					? JSON.parse(JSON.stringify(val.salesLineChartData2))
					: [];
				// Orders
				this.ordersLineChartData[1].data = val.OrderLineChartData1.length
					? JSON.parse(JSON.stringify(val.OrderLineChartData1))
					: [];
				this.ordersLineChartData[0].data = val.OrderLineChartData2.length
					? JSON.parse(JSON.stringify(val.OrderLineChartData2))
					: [];
				// Quotes
				this.quotesLineChartData[1].data = val.quotesLineChartData1.length
					? JSON.parse(JSON.stringify(val.quotesLineChartData1))
					: [];
				this.quotesLineChartData[0].data = val.quotesLineChartData2.length
					? JSON.parse(JSON.stringify(val.quotesLineChartData2))
					: [];
			
				this.orderBarChartLabel = val.salesLineChartLabel;
				this.orderBarChartData[0].data = JSON.parse(
					JSON.stringify(val.orderBarChartData)
				);
				// apex
				this.saleOrderChartData[0].data = JSON.parse(
					JSON.stringify(val.orderBarChartData)
				);
				this.deliveryBarChartLabel = val.salesLineChartLabel;
				this.delvieryBarChartData[0].data = val.delvieryBarChartData;
				// apex
				this.quoteOrderChartData[0].data = val.delvieryBarChartData;
				

				// await this.$nextTick()

				if (typeof this.$refs.chart1 != "undefined") {
					this.$refs.chart1.renderLineChart();
				}
				if (typeof this.$refs.chart2 != "undefined") {
					this.$refs.chart2.renderBarChart();
				}
				if (typeof this.$refs.chart3 != "undefined") {
					this.$refs.chart3.renderBarChart();
				}
			} catch (e) {
				// console.log("error in chart watch", e);
			}
			// }, 200)
		} catch (e) {
			// console.log("exception", e);
		}
	}

	async pdfDownLoadFn() {
		this.exportObj["fYear"] = this.items.sortingYear;
		this.exportObj["functions"] = "DataOrderTrend,DataChart,CategoryTrend";
		this.exportObj["view"] = this.dashView;
		this.exportObj["selectedView"] = this.selectedView;
		this.exportObj["tagIDs"] = this.tagIDs.join();
		this.exportObj["gTagIDs"] = this.gTagIDs.join();
		this.exportObj["groupTagIDs"] = this.groupTagIDs.join();

		var downLoadRes = getRouteData(this.exportObj);
		downLoadRes.then(function (response, statusText, jqXHR) {
			try {
				if (response.data.STATUS) {
					var link = document.createElement("a");
					link.download = "Quotes";
					link.href = response.data.S3URL;
					link.target = "_blank";
					link.click();
				} else if (response.data.ERROR) {
					// console.log(response.data.ERROR);
				}
			} catch (e) {
				//handle error
				// console.log("quotes.js : csvDownLoadFn error : ", e);
			}
		});
	}

	async printFn() {
		var exportObj = {
			controller: "Dashboard",
			FunctionName: "Export",
			uploadPath: "Inbound/apiFiles/",
			keyName: "Inbound/apiFiles/",
			ExportType: "PDF",
			unit: "both",
			figure: "1,4,7",
			print: "yes",
			functions: "DataOrderTrend,DataChart,CategoryTrend,ShipmentTrend",
			userRole: sessionStorage.getItem("userRole"),
			fYear: this.items.sortingYear,
			view: this.dashView,
			selectedView: this.selectedView,
			tagIDs: this.tagIDs.join(),
			gTagIDs: this.gTagIDs.join(),
			groupTagIDs: this.groupTagIDs.join(),
		};

		var response = getRouteData(exportObj);
		response.then(function (response, statusText, jqXHR) {
			var supportList = response.data;
			var html = template(supportList);
			printHtml(html);
		});
	}

  hasChangedAccountFilters = false;
  isFirstLoad = true;
	async fetchDashboardData() {
		try {
      this.hasChangedAccountFilters = false;
			this.loading = true;
			const ret = await dashboardRepo.find(
				this.filters,
				this.sort,
				this.pageNumber,
				this.dashView,
				this.selectedView,
			);
			this.ret = ret;
			this.cashflowData = ret.cashflow;
			
			this.cashflow = ret.cashflow;
      this.isFirstLoad = false;
			this.orderCashflow = ret.ORDERCASHFLOW;
			if (this.searchTagsTxt == "" &&	typeof ret.accountTags != "undefined") {
				this.accountTags = ret.accountTags;
			}		
			this.cashflowYear = ret.YEARS;
			if ((this.filters.AccountTagID && this.filters.AccountTagID.length) || (this.filters.gTagIDs && this.filters.gTagIDs.length) || (this.filters.groupIDs && this.filters.groupIDs.length)) {
					this.selectedView = true;
					this.CurrentView = this.getCurrentViewTitle();
				} else {
					// reset
					this.selectedView = false;
			}
			if (
				!this.selectedView &&
				typeof ret["userSelectedCurrentView"] != "undefined"
			) {
				this.selectedView = true;
				this.viewType = ret["userSelectedCurrentView"];
				this.CurrentView =
				this.viewType == "All" ? "Company Orders" : "My Orders";
			}
			this.customerTooltipVisibleIndex = -1;
			this.reportedDates = ret.reportedDates;
		}  catch (err) {
			if(sessionStorage.getItem("userFname") != null){
				notifier.alert(err.message);
			}
		} finally {
			this.loading = false;
		}
	}

	async debounce(func, wait, immediate) {
		var timeout;
		return function (this: any) {
			var context = this,
				args = arguments;
			clearTimeout(timeout);
			timeout = setTimeout(function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			}, wait);
			if (immediate && !timeout) func.apply(context, args);
		};
	}	
	
	async selectView(item, e) {
		if (isNaN(item.ACCOUNTTAGID)) {
			// selected My Orders / company Orders
			this.tagIDs = [];
			this.filters.gTagIDs = [];
			this.filters.groupIDs = [];
			this.filters.AccountTagID = []; // reset
			this.selectedTags = []; // reset
			this.CurrentView = item.TAGNAME;
			this.viewType = item.ACCOUNTTAGID;
			this.dashView = item.ACCOUNTTAGID;
			this.selectedView = true;
			await this.fetchDashboardData();
			await this.accsLoad();
		} else {
			e.stopPropagation();
		}
	}

	async filterByTag(tagName = "", groupName = "") {
		this.tagIDs = [];
		if ((this.filters.AccountTagID && this.filters.AccountTagID.length) || (this.filters.gTagIDs && this.filters.gTagIDs.length) || (this.filters.groupIDs && this.filters.groupIDs.length)) {
			this.selectedView = true;
			this.CurrentView = this.getCurrentViewTitle();
			// this.dashView = "1"; //just set a number
		} else {
			// reset
			this.selectedView = false;

			if(!this.$allowedFunctions.includes(this.$userFunctions.All_Milestone_Accounts)) {
				this.CurrentView = "My Orders";
				this.viewType = "CurrentUsers";
			}
			this.dashView = this.viewType;
		}
    if (tagName != "" && this.filters.accountTagName != undefined) {
      const tagIndex = this.filters.accountTagName.findIndex(
        tagValue => tagValue === tagName
      );
      if (tagIndex === -1) {
        this.filters.accountTagName.push(tagName);
      } else {
        this.filters.accountTagName.splice(tagIndex, 1);
      }
    }

    if (groupName != "" && this.filters.groupName != undefined) {
      const groupIndex = this.filters.groupName.findIndex(
        groupValue => groupValue === groupName
      );
      if (groupIndex === -1) {
        this.filters.groupName.push(groupName);
      } else {
        this.filters.groupName.splice(groupIndex, 1);
      }
    }
    this.hasChangedAccountFilters = true;
		// this.resetSelected();
		// this.pageNumber = 1;
		// await this.fetchDashboardData();
	}

	getCurrentViewTitle() {
		if ((this.filters.AccountTagID && this.filters.AccountTagID.length) || (this.filters.gTagIDs && this.filters.gTagIDs.length) || (this.filters.groupIDs && this.filters.groupIDs.length)) {
			var fulltitle:any = [];

			var $this = this;
			this.accountTags.forEach(function (val: any) {
				if (!isNaN(val.ACCOUNTTAGID)) {
					if ($this.filters.AccountTagID.includes(val.ACCOUNTTAGID)) {
						fulltitle.push(val.TAGNAME);
					}
					if ($this.filters.gTagIDs && $this.filters.gTagIDs.includes(val.ACCOUNTTAGID)) {
						fulltitle.push(val.TAGNAME);
					}
					if ($this.filters.groupIDs && $this.filters.groupIDs.includes(val.ACCOUNTTAGID)) {
						fulltitle.push(val.TAGNAME);
					}
				}
			});
			return fulltitle.join(", ");
		}

		return "";
	}
	categories(){
		var monthNames;
		monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];
		if(this.ApiMonths.length && isNaN(this.items.sortingYear)){
			monthNames = this.ApiMonths;
		}
		var fullMonths:string[] = [];
		for (const month in monthNames) {
			if (isNaN(this.items.sortingYear)) {
				fullMonths.push(monthNames[month] + "-" + last12[month].split(", ")[1]);
			} else {
				fullMonths.push(monthNames[month] + "-" + this.items.sortingYear);
			}
		}
		return fullMonths;
	}
	saleRedirect(year,monthtitle,index,itemIndex,monthStr){
		var monthasNum = ["01","02","03","04","05","06","07","08","09","10","11","12"];
	
		var monthNames = {
					Jan: "01",
					Feb: "02",
					Mar: "03",
					Apr: "04",
					May: "05",
					Jun: "06",
					Jul: "07",
					Aug: "08",
					Sep: "09",
					Oct: "10",
					Nov: "11",
					Dec: "12",
				};
		var month = monthasNum[itemIndex];
		if(isNaN(this.items.sortingYear)){
			month = monthNames[monthStr];
		}
		if(index <= 0){
			year = year - 1;
		}
		var sDate = year + "-" + month + "-01";
		var eDate = year + "-" + month + "-" + this.lastday(year, month);
		var filterView =
			this.gTagIDs.length || this.tagIDs.length || this.groupTagIDs.length ? 1 : this.dashView;
		var routeQuery: any = {
			sDate: sDate,
			eDate: eDate,
			filterView: filterView,
			viewFrom: "dashboard",
			tagIDs: this.tagIDs,
			gTagIDs: this.gTagIDs,
			groupIDs: this.groupTagIDs
		};
		var currItem = index == 2 ? "Quotes" : "Orders";
		if(currItem == "Quotes") {
			routeQuery["qOpenStatus"] = ["0", "1", "2", "5", "6", "7", "8"];
		}
		// if (currItem == "Orders") {
		// 	routeQuery["sStatus"] = ["0", "1", "2", "3", "4", "5"];
		// } else if (currItem == "Quotes") {
		// 	routeQuery["qOpenStatus"] = ["0", "1", "2", "5", "6", "7", "8"];
		// }

		this.$router.push({
			name: currItem,
			query: routeQuery,
		});

	}

	totalSaleEnableClick(point, e, tooltipModel, data, tooltipObj) {
		if (e.length) {
			const item = e[0];
			var currItem =
				data.datasets[tooltipObj._active[0]._datasetIndex].linetype;
			if (typeof item != "undefined" && tooltipModel.body) {
				var titleLines = tooltipModel.title[0] || [];
				var titleLinesSplit = titleLines.split("-");
				var tooltip = tooltipModel.body[0].lines[0];
				var tooltipSplit = tooltip.split(" ");
				// Skip redirect for ordr Amount zero
				if (
					tooltipSplit.length &&
					parseFloat(tooltipSplit[tooltipSplit.length - 1]) == 0
				) {
					return;
				}

				var monthNames = {
					Jan: "01",
					Feb: "02",
					Mar: "03",
					Apr: "04",
					May: "05",
					Jun: "06",
					Jul: "07",
					Aug: "08",
					Sep: "09",
					Oct: "10",
					Nov: "11",
					Dec: "12",
				};

				var year = titleLinesSplit[1];
				var month = monthNames[titleLinesSplit[0]];

				if (tooltip.startsWith("Prior Year")) {
					year -= 1;
				}

				var sDate = year + "-" + month + "-01";
				var eDate = year + "-" + month + "-" + this.lastday(year, month);
				var filterView =
					this.gTagIDs.length || this.tagIDs.length || this.groupTagIDs.length ? 1 : this.dashView;

				//hide tooltip
				tooltipModel.opacity = 0;
				$("#chartjs-tooltip").css({ opacity: 0 });
				var routeQuery: any = {
					sDate: sDate,
					eDate: eDate,
					filterView: filterView,
					viewFrom: "dashboard",
					tagIDs: this.tagIDs,
					gTagIDs: this.gTagIDs,
					groupIDs: this.groupTagIDs
				};

				// routeQuery["selectedGlobalTag"] = this.selectedGlobalTag;
				if (currItem == "Orders") {
					routeQuery["sStatus"] = ["0", "1", "2", "3", "4", "5"];
				} else if (currItem == "Quotes") {
					routeQuery["qOpenStatus"] = ["0", "1", "2", "5", "6", "7"];
				}

				this.$router.push({
					name: currItem,
					query: routeQuery,
				});
			}
		}
	}
	lastday(y, m) {
		return new Date(y, m, 0).getDate();
	}

	getSalesThisYearPercent(data) {
		var ret = data.SALESTHISYEARTRENDPERCENT;
		if (this.items.sortingYear == this.currYear) {
			//override for current year
			if (data.SALESLASTYEAR > 0) {
				ret =
					Math.round((data.SALESTHISYEAR / data.SALESLASTYEAR) * 100) +
					"% of Prior Year";
			} else if (data.SALESTHISYEAR > 0) {
				ret = "100% of Prior Year";
			}
		}
		return ret;
	}
	getCommTrendPercent(data) {
		var ret = data.COMMISSIONTRENDPERCENT;
		if (
			this.$root.getUserRole() === "Reseller" &&
			this.items.sortingYear == this.currYear
		) {
			//override for current year
			if (data.PREVCOMMISSIONVAL > 0) {
				ret =
					Math.round((data.COMMISSIONVAL / data.PREVCOMMISSIONVAL) * 100) +
					"% of Prior Year";
			} else if (data.COMMISSIONVAL > 0) {
				ret = "100% of Prior Year";
			}
		}
		return ret;
	}

	buildActivitiesDetails(details) {
		var ret = details;
		if (Array.isArray(details)) {
			ret = details.join(". ");
		}

		return ret;
	}

	getActivityType(typeID) {
		var ret = "";
		if ([1, 2, 3].includes(typeID)) {
			ret = "order";
		} else if ([5, 6, 7].includes(typeID)) {
			ret = "quote";
		} else if ([9, 10, 11, 12].includes(typeID)) {
			ret = "account";
		} else if ([13, 14, 15].includes(typeID)) {
			ret = "invoice";
		} else if ([18, 19].includes(typeID)) {
			ret = "paycheck";
		} else if ([20].includes(typeID)) {
			ret = "user_login";
		} else if ([21, 22, 23].includes(typeID)) {
			ret = "support";
		}

		return ret;
	}

	activitiesLoading = false;
	// selectedActivityFilters = false;
	async selectedFiltersChange(selectedFilters) {
		this.selectedFilters = selectedFilters;
		// this.selectedActivityFilters = true;
		this.activitiesLoading = true;
		try {
			const response = await axios.post(dataURL + "?ReturnType=JSON", {
				controller: "Queries",
				subsystem: "Helpers",
				FunctionName: "activities",
				limitActivities: 20,
				activityType: this.selectedFilters.join(","),
				view: this.dashView,
				fyear: this.items.sortingYear,
				tagIDs: this.tagIDs.join(),
				gTagIDs: this.gTagIDs.join(),
			});

			if (response.data.ERROR) {
				throw new Error(response.data.ERROR);
			}
			if (response.data.STATUS !== 1 && response.data.STATUSMESSAGE) {
				throw new Error(response.data.STATUSMESSAGE);
			}

			if (response.data.STATUS == 1) {
				this.activities = response.data.ACTIVITIES;
			}
		} catch (err) {
			// console.log(err.message);
		} finally {
			this.activitiesLoading = false;
		}
	}

	// build activity filtered options
	buildActivityFilteredOptions() {
		if (
			this.$allowedFunctions.includes(this.$userFunctions.Quote_Details) &&
			this.$allowedFunctions.includes(this.$userFunctions.Quotes)
		) {
			this.activitiesFilters.push({ id: "Quote", text: "Quote" });
		}
		if (
			this.$allowedFunctions.includes(this.$userFunctions.Order_Details) &&
			this.$allowedFunctions.includes(this.$userFunctions.Orders)
		) {
			this.activitiesFilters.push({ id: "Order", text: "Order" });
		}
		if (
			this.$allowedFunctions.includes(this.$userFunctions.Invoice_Details) &&
			this.$allowedFunctions.includes(this.$userFunctions.Invoices)
		) {
			this.activitiesFilters.push({ id: "Invoice", text: "Invoice" });
		}
		if (
			this.$allowedFunctions.includes(this.$userFunctions.Customers) ||
			this.$allowedFunctions.includes(
				this.$userFunctions.All_Milestone_Accounts
			)
		) {
			this.activitiesFilters.push({ id: "Account", text: "Account" });
		}
		if (this.$allowedFunctions.includes(this.$userFunctions.Support)) {
			this.activitiesFilters.push({ id: "Support", text: "Support" });
		}
		if (this.$systemGroups.includes("sys_admin")) {
			this.activitiesFilters.push({ id: "System", text: "System" });
		}
	}
	async formatLabel(am) {
		// console.log("am", am);
		return am;
	}

	async sortBy(field: SortField) {
		this.sort.field = field;
		this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

		for (const dirField in this.sort.direction) {
			if (dirField.toString() === field.toString()) {
				continue;
			}
			this.sort.direction[dirField] = 1;
		}
		await this.fetchDashboardData();
	}
	async sortingByPurchaseID() {
		await this.sortBy(SortField.purchaseID);
	}
	async sortingByOrderName() {
		await this.sortBy(SortField.orderName);
	}
	async sortingByCustomer() {
		await this.sortBy(SortField.Customer);
	}
	async sortingByvarCost() {
		await this.sortBy(SortField.total);
	}
	async sortingByPoPaidTotal() {
		await this.sortBy(SortField.poTotal);
	}
	async sortingByCogsPercent() {
		await this.sortBy(SortField.cashflowPercent);
	}
	
	async searchById() {
		this.pageNumber = 1;
		if (this.searchFilters.purchaseID == "") {
			return;
		} else {
			this.filters.purchaseID = this.searchFilters.purchaseID;
			await this.fetchDashboardData();
		}
	}
	async resetSearchById() {
		this.pageNumber = 1;
		this.selectedID = [];
		this.filters.purchaseID = this.searchFilters.purchaseID = "";
		await this.fetchDashboardData();
	}
	async searchByName() {
		this.pageNumber = 1;
		if (this.searchFilters.searchName == "") {
			return;
		} else {
			this.filters.searchName = this.searchFilters.searchName;
			await this.fetchDashboardData();
		}
	}
	async resetSearchByName() {
		this.pageNumber = 1;
		this.selectedID = [];
		this.filters.searchName = this.searchFilters.searchName = "";
		await this.fetchDashboardData();
	}
	async searchByVarCost() {
		this.pageNumber = 1;
		this.filters.varCostMin = this.searchFilters.varCostMin;
		this.filters.varCostMax = this.searchFilters.varCostMax;
		await this.fetchDashboardData();
	}
  async resetSearchByVarCost(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.varCostMin = this.searchFilters.varCostMin = "";
    this.filters.varCostMax = this.searchFilters.varCostMax = "";
    if(allowFetchData) {
      await this.fetchDashboardData();
    }
  }
	async searchByPoPaidTotal() {
		this.pageNumber = 1;
		this.filters.poTotalMin = this.searchFilters.poTotalMin;
		this.filters.poTotalMax = this.searchFilters.poTotalMax;
		await this.fetchDashboardData();
	}
  async resetSearchByPoPaidTotal(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.poTotalMin = this.searchFilters.poTotalMin = "";
    this.filters.poTotalMax = this.searchFilters.poTotalMax = "";
    if(allowFetchData) {
      await this.fetchDashboardData();
    }
  }
	async searchByCogsPercent() {
		this.pageNumber = 1;
		this.filters.cashflowPercentMin = this.searchFilters.cashflowPercentMin;
		this.filters.cashflowPercentMax = this.searchFilters.cashflowPercentMax;
		await this.fetchDashboardData();
	}
  async resetSearchByCogsPercent(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.cashflowPercentMin = this.searchFilters.cashflowPercentMin = "";
    this.filters.cashflowPercentMax = this.searchFilters.cashflowPercentMax = "";
    if(allowFetchData) {
      await this.fetchDashboardData();
    }
  }

	filterTagsInBox() {
		let options = this.ret["accountTags"];

		if (this.searchTagsTxt) {
			options = options.filter(
				(option) =>
					`${option.TAGNAME}`
						.toLowerCase()
						.indexOf(this.searchTagsTxt.toLowerCase()) !== -1
			);
		}

		this.accountTags = options;
	}

	resetSelected() {
		this.selectedID = [];
		this.selectedAll = false;
		this.excludedIDs = [];
	}

	async checkAllTag() {
		this.resetSelected();
		var $this = this;
		this.filters.AccountTagID = [];
		this.accountTags.forEach(function (val: any) {
      if ($this.filters.gTagIDs && !isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP == "undefined") {
        if (typeof val.ISGLOBALTAG == "undefined") {
          if (
            $this.filters.AccountTagID != undefined &&
            $this.filters.accountTagName != undefined
          ) {
            $this.filters.AccountTagID.push(val.ACCOUNTTAGID);
            $this.filters.accountTagName.push(val.TAGNAME);
          }
        } else {
          $this.filters.gTagIDs.push(val.ACCOUNTTAGID);
          if ($this.filters.accountTagName != undefined) {
            $this.filters.accountTagName.push(val.TAGNAME);
          }
        }
      }
		});
		this.filterByTag();
		this.pageNumber = 1;
		await this.fetchDashboardData();
	}

	async resetTag(allowFetchData = true) {
		this.resetSelected();
		this.filters.AccountTagID = [];
    this.filters.accountTagName = [];
		this.filters.groupIDs = [];
    this.filters.groupName = [];
		this.pageNumber = 1;
		this.filterByTag();
    if (allowFetchData) {
      await this.fetchDashboardData();
    }
	}

	filterAccsInBox() {
		let options = this.varAccsList;

		if (this.searchAccTxt) {
			options = options.filter(
				(option) =>
					`${option.ANAME}`
						.toLowerCase()
						.indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
					`${option.ACCOUNTALIAS}`
						.toLowerCase()
						.indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
					`${option.ACCOUNTID}`
						.toLowerCase()
						.indexOf(this.searchAccTxt.toLowerCase()) !== -1
			);
		}
		this.varAccs = options;
	}

	async filterByAcc(accountsName) {
		// this.resetSelected();
		// this.pageNumber = 1;
    if (
      accountsName != undefined &&
      this.filters.selectedAccsName != undefined
    ) {
      const accountsIndex = this.filters.selectedAccsName.findIndex(
        accountsValue => accountsValue === accountsName
      );
      if (accountsIndex === -1) {
        this.filters.selectedAccsName.push(accountsName);
      } else {
        this.filters.selectedAccsName.splice(accountsIndex, 1);
      }
    }
    this.hasChangedAccountFilters = true;
		// await this.fetchDashboardData();
	}
	async checkAllAcc() {
		this.resetSelected();
		this.filters.selectedAccs = this.varAccs.map((val) => val.AID);
    this.filters.selectedAccsName = this.varAccs.map(val => val.ANAME);
		this.pageNumber = 1;
		await this.fetchDashboardData();
	}
	async resetAcc(allowFetchData = true) {
		this.resetSelected();
		this.filters.selectedAccs = [];
		this.filters.selectedAccsName = [];
		this.pageNumber = 1;
    if (allowFetchData) {
      await this.fetchDashboardData();
    }
	}

	async checkAllGroupTag() {
		this.resetSelected();
		var $this = this;
		this.filters.groupIDs = [];
    this.filters.groupName = [];
		this.accountTags.forEach(function (val: any) {
			if ($this.filters.groupIDs
        && $this.filters.groupName != undefined
        && !isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP != 'undefined') {
				$this.filters.groupIDs.push(val.ACCOUNTTAGID);
        $this.filters.groupName.push(val.TAGNAME);
			}
		});
		this.filterByTag();
		this.pageNumber = 1;
		await this.fetchDashboardData();
  }	

	async searchByAccountName() {
		this.pageNumber = 1;
		this.selectedID = [];
		this.selectedAll = false;
		this.excludedIDs = [];
		if (this.searchFilters.searchAccountName == "") {
			return;
		} else {
			this.filters.searchAccountName = this.searchFilters.searchAccountName;
			await this.fetchDashboardData();
		}
	}

	async resetSearchByAccountName(allowFetchData = true) {
		this.pageNumber = 1;
		this.selectedID = [];
		this.filters.searchAccountName = this.searchFilters.searchAccountName = "";
		this.selectedAll = false;
		this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchDashboardData();
    }
	}

	async accsLoad() {
		try {
			const response = await axios.post(dataURL + "?ReturnType=JSON", {
				FunctionName: "ListExt",
				controller: "Accounts",
				queryType: "top5UsedAcct",
				view: this.viewType,
				isOrder: 1,
				accSearch: 1,
			});
			if (response.data.STATUS) {
				this.varAccs = response.data.ACCOUNTS;
				this.selectedAccs = response.data.VARACCOUNTSIDSRR;
				this.varAccsList = response.data.ACCOUNTS;
				if (this.searchAccTxt != "") {
					await this.filterAccsInBox();
				}
			}
		} catch (err) {
			notifier.alert(err.message);
		} finally {
			this.loading = false;
		}
	}

	toggleActive(div) {
		this.isActive = div;
	}

	async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    if(sessionStorage.pageDetails != undefined && JSON.parse(sessionStorage.pageDetails)["paychecks"]){
      var oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["Cashflow"] = this.pageNumber;
      sessionStorage.setItem('pageDetails',JSON.stringify(oldData))
    }

    if (this.pageNumber <= this.ret.TOTALPAGES && this.pageNumber >= 1) {
      await this.fetchDashboardData();
    }
  }

	async hideTooltipCustomers() {
		this.customerTooltipVisibleIndex = -1;
	}

	async showTooltipCustomers(item, index) {
		if (!item.length) return;
		this.customerTooltipVisibleIndex = index;
		try {
			this.customerTooltipLoading = true;
			this.customerTooltipLoading = false;
		} catch (err) {
			//notifier.alert(err.message);
			this.customerTooltipVisibleIndex = -1;
		}
	}


  getFilterValue(header) {
    let filter = "";
    let searchKey: string | undefined = "";
    switch (header) {
      case "CUSTOMER":
      {
        const arr: string[] = [];
        if(this.filters.searchAccountName) {
          arr.push(`Customer: ${this.filters.searchAccountName}`);
        }
        if((this.filters.gTagIDs && this.filters.gTagIDs.length) || this.filters.AccountTagID.length) {
          arr.push(`Customer Tags: ${this.filters.accountTagName}`);
        }
        if(this.filters.selectedAccs.length) {
          arr.push(`Customer Accounts: ${this.filters.selectedAccsName}`);
        }
        if(this.filters.groupIDs && this.filters.groupIDs.length) {
          arr.push(`Customer Groups: ${this.filters.groupName}`);
        }
        searchKey = arr.join('; ');
        break;
      }
      default:
        // nothing
        break;
    }
    return (
      (filter ? `${filter}` : "") +
      (searchKey ? (filter ? " - " : "") + searchKey : "")
    );
  }


  async resetColumn(header) {
    switch (header) {
      case "CUSTOMER":
        this.resetSearchByAccountName(false);
        this.resetAcc(false);
        this.resetTag(false);
        await this.fetchDashboardData();
        break;
      default:
        break;
    }
  }
}
