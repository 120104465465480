





























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import SortIndicator from "../components/SortIndicator.vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import FilterCurrencyBox from "../components/FilterCurrencyBox.vue";
import {
  SpiffAPIRepo,
  Filters,
  Sort,
  SortField,
  SpiffStatus
} from "../repos/SpiffAPIRepo";
import Pagination from "../components/Pagination.vue";
import SpiffStatusComponent, {
  SpiffStatusValue
} from "../components/SpiffStatus.vue";
import {
  notifier,
  downloadFile,
  printHtml,
  downloadFileUrl
} from "../models/common";
import { Spiff } from "../models/Spiff";
import UpdateSpiffStatusModal from "../components/UpdateSpiffStatusModal.vue";
import CustomerSearchTooltip from "../components/CustomerSearchTooltip.vue";
import HPAgentDetailsLinkAccountTooltip from "../components/HPAgentDetailsLinkAccountTooltip.vue";
import CustomerAdd from "../components/HPAgentDetailsCustomerAddModal.vue";
import InfoTooltip from "../components/InfoTooltip.vue";
import SpiffImport from "../components/SpiffImport.vue";
import { colsResizeable, colsResizeableReset, colsResizeableUpdate, getDateRangeFilter } from "@/helpers/ApiHelper";
import ExportModal, { ExportFormat } from "../components/ExportModal.vue";
import FilterModal from "../components/FilterModal.vue";
import moment from "moment";
import USState from "@/static/USstates.json";

declare const dataURL: any;
declare const $: any;
const spiffRepo = new SpiffAPIRepo();

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    FilterCurrencyBox,
    SortIndicator,
    SpiffStatusComponent,
    UpdateSpiffStatusModal,
    CustomerSearchTooltip,
    HPAgentDetailsLinkAccountTooltip,
    CustomerAdd,
    InfoTooltip,
    SpiffImport,
    ExportModal,
    FilterModal
  },
  methods: {
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    }
  }
})
export default class Spiffs extends TSXComponent<void> {
  $route: any;
  $router: any;
  selectedID: any = [];
  ISSEARCH = 0;
  currentRole = sessionStorage.getItem("userRole");
  loading = false;
  initialLoad = true;
  Spiffs: any = [];
  details: any = {};
  currentCustIndex = -1;
  spiffsData: Spiff.Root | any = {};
  filters: Filters = {
    aState: [],
    searchAll: "",
    searchAccountName: "",
    searchSku: "",
    searchOrder: "",
    spiffStartDate: undefined,
    spiffEndDate: undefined,
    invoiceStartDate: undefined,
    invoiceEndDate: undefined,
    sRewardMin: "",
    sRewardMax: "",
    sQuantityMin: "",
    sQuantityMax: "",
    sTotalMin: "",
    sTotalMax: "",
    sStatus: this.getDefaultSpiffStatuses(),
    unassigned: false,
    searchAccount: "",
    AccountTagID: [],
    groupIDs: [],
    spiffType: [1,2],
    selectedAccs: [],
    sSource: [],
    gTagIDs: [],
    accountTagName: [],
    selectedAccsName: [],
    groupName: [],
    statusName: [],
    sourceName: [],
    filterSpiffDate: "",
    filterInvoiceDate: "",
    filterRewardPoints: "",
    filterQty: "",
    filterTotal: "",
    searchVarOrder: ""
  };
  currentCustAddDetail = [];
  selectedAccts = [];
  pageNumber = 1;
  sort: Sort = {
    field: null,
    direction: {
      [SortField.Sku]: 1,
      [SortField.SpiffDate]: 1,
      [SortField.Order]: 1,
      [SortField.InvoiceDate]: 1,
      [SortField.Account]: 1,
      [SortField.Per]: 1,
      [SortField.Qty]: 1,
      [SortField.Total]: 1,
      [SortField.Status]: 1,
      [SortField.Type]: 1,
      [SortField.VarOrder]: 2
    }
  };
  customerTooltipVisibleIndex = -1;
  customerTooltipLoading = false;
  updateStatusModalVisible = false;
  viewType = "my_spiffs"; //default
  $allowedFunctions: any;
  $userFunctions: any;
  unassignedTotal = 0;
  acctsInSpiffs = [];
  externalId: string = "";
  selectedView = false;
  acctInfoTooltipVisibleIndex = -1;
  accountTags: any = [];
  accountTagsList: any = [];
  searchTagsTxt = "";
  isActive = "customerAccounts";
  varAccs: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  varAccsList: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  selectedAccs: (number | undefined)[] = [];
  searchAccTxt = "";
  unverifyAccSearch = "";
  pageHeaders = {
    SKU: "SKU",
    SPIFFEFFECTIVEDATEFORMATTED: "Spiff Effective",
    ORDERID: "VAR360 Order",
    ORDERSOURCE: "Source",
    PURCHASEID: "Customer PO",
    INVOICEDATEFORMATTED: "Actionable Date",
    MSPIFF_ACCOUNTNAME: "Customer",
    ASTATE: "Account State",
    POINTSPER: "Reward points",
    QTY: "Quantity",
    TOTALPOINTS: "Total",
    STATUSTEXT: "Status",
    SERIALNUMBER: "Require serial number"
  };
  headerSpiff = [
    "SKU",
    "SPIFFEFFECTIVEDATEFORMATTED",
    "ORDERID",
    "ORDERSOURCE",
    "PURCHASEID",
    "INVOICEDATEFORMATTED",
    "MSPIFF_ACCOUNTNAME",
    "ASTATE",
    "POINTSPER",
    "QTY",
    "TOTALPOINTS",
    "STATUSTEXT",
    "SERIALNUMBER"
  ];
  selectedHeaders = [
    "SKU",
    "SPIFFEFFECTIVEDATEFORMATTED",
    "ORDERSOURCE",
    "PURCHASEID",
    "MSPIFF_ACCOUNTNAME",
    "STATUSTEXT",
  ];
  searchFilters: Filters = {
    searchAll: "",
    searchAccountName: "",
    searchSku: "",
    searchOrder: "",
    spiffStartDate: undefined,
    spiffEndDate: undefined,
    invoiceStartDate: undefined,
    invoiceEndDate: undefined,
    sRewardMin: "",
    sRewardMax: "",
    sQuantityMin: "",
    sQuantityMax: "",
    sTotalMin: "",
    sTotalMax: "",
    sStatus: this.getDefaultSpiffStatuses(),
    unassigned: false,
    searchAccount: "",
    AccountTagID: [],
    groupIDs: [],
    spiffType: [1,2],
    selectedAccs: [],
    sSource: [],
    gTagIDs: [],
    accountTagName: [],
    selectedAccsName: [],
    groupName: [],
    statusName: [],
    sourceName: [],
    aState: [],
    filterSpiffDate: "",
    filterInvoiceDate: "",
    filterRewardPoints: "",
    filterQty: "",
    filterTotal: "",
    searchVarOrder: ""
  };
  spiffImportModalVisible = false;
  exportModalVisible = false;
  spiffCheckAll = false;
  showFilter = 0;
  selectPageAll = false;
  selectedAll = false;
  excludedIDs: number[] = [];
  exportHeadersArr: {
    header: string;
    column: string;
    widthPercent: string;
  }[] = [];
  filterModalVisible = false;
  usedFilterDetails: any = [];
  position = {
    searchSku: 1,
    filterSpiffDate: 2,
    sourceName: 3,
    searchOrder: 4,
    filterInvoiceDate: 5,
    searchAccountName: 6,
    accountTagName: 7,
    selectedAccsName: 8,
    groupName: 9,
    filterRewardPoints: 10,
    filterQty: 11,
    filterTotal: 12,
    statusName: 13,
    searchVarOrder: 14
  };
  usedFilters = {
    searchSku: {
      header: "SKU",
      function: "resetSearchBySKU"
    },
    filterSpiffDate: {
      header: "Spiff Effective",
      function: "resetSearchBySpiffDate"
    },
    sourceName: {
      header: "Source",
      function: "resetSourceList"
    },
    searchOrder: {
      header: "Customer PO",
      function: "resetSearchByOrder"
    },
    filterInvoiceDate: {
      header: "Actionable Date",
      function: "resetSearchByInvoiceDate"
    },
    searchAccountName: {
      header: "Customer",
      function: "resetSearchByAccountName"
    },
    accountTagName: {
      header: "Customer Tags",
      function: "resetTag"
    },
    aState: {
      header: "Account State",
      function: "resetStateList"
    },
    selectedAccsName: {
      header: "Customer Accounts",
      function: "resetAcc"
    },
    groupName: {
      header: "Customer Groups",
      function: "resetTag"
    },
    filterRewardPoints: {
      header: "Reward points",
      function: "resetSearchByReward"
    },
    filterQty: {
      header: "Quantity",
      function: "resetSearchByQuantity"
    },
    filterTotal: {
      header: "Total",
      function: "resetSearchByTotal"
    },
    statusName: {
      header: "Status",
      function: "resetStatusList"
    },
    searchVarOrder: {
      header: "VAR360 Order",
      function: "resetSearchByVarOrder"
    }
  }

  get query() {
    const query = Object.entries(this.filters).reduce((a, [k, v]) => {
      if (Array.isArray(v)) {
        return v.length === 0 ? a : { ...a, [k]: v };
      }

      return v == null || v === "" ? a : { ...a, [k]: v };
    }, {});
    return query;
  }
  stateList: any[] = [];
  async created() {
    for (const i in USState) {
      this.stateList.push({
        ID: USState[i].Abbr,
        TEXT: USState[i].Abbr,
        data: USState[i]
      });
    }
    this.filteredStates = this.stateList;

    if (typeof this.$route.query.viewType == "undefined") {
      this.viewType = this.$allowedFunctions.includes(
        this.$userFunctions.All_SPIFFs
      )
        ? "all_spiffs"
        : "my_spiffs";
      this.$router.replace({ query: { viewType: this.viewType } });
    } else {
      this.viewType = this.$route.query.viewType.toString();
      if (
        !["all_spiffs", "my_spiffs"].includes(this.viewType) ||
        this.viewType == "all_spiffs"
      ) {
        this.viewType = this.$allowedFunctions.includes(
          this.$userFunctions.All_SPIFFs
        )
          ? "all_spiffs"
          : "my_spiffs";
        if (typeof this.$route.query.externalId != "undefined") {
          this.externalId = this.$route.query.externalId;
          this.$router.replace({
            query: { viewType: this.viewType, externalId: this.externalId }
          });
        } else {
          this.$router.replace({ query: { viewType: this.viewType } }).catch(tmp => {});
        }
      }
    }

    this.viewBy(this.viewType);
    await this.accsLoad();
  }

  async fetchData() {
    this.loading = true;
    if (!this!.filters!.sStatus!.length) {
      this.initialLoad = true;
    }
    if (this.externalId) {
      this.filters.searchVarOrder = this.searchFilters.searchVarOrder = this.externalId;
    }

    const response = await spiffRepo.spiffList(
      this.filters,
      this.sort,
      this.pageNumber,
      this.viewType,
      this.selectedAccts,
      this.externalId,
      this.selectedView,
      this.initialLoad
    );

    try {
      this.Spiffs = response.SPIFF;
      this.details = response;
      this.ISSEARCH = response.ISSEARCH;
      this.customerTooltipVisibleIndex = -1;
      this.unassignedTotal = response.UNASSIGNEDTOTAL;
      this.acctsInSpiffs = response.ACCTSINSPIFFS;
      if (this.searchTagsTxt == "") {
        this.accountTags = response.accountTags;
        this.accountTagsList = response.accountTags;
      }
      if (
        !this.selectedView &&
        typeof response["userSelectedCurrentView"] != "undefined"
      ) {
        this.selectedView = true;
        this.viewType = response["userSelectedCurrentView"];
        this.$router.replace({ query: { viewType: this.viewType } }).catch(tmp => {});
      }
      if(typeof this.details.sourcesObj != 'undefined' && this.filters.sSource.length == 0 && this.initialLoad) {
        this.filters.sSource = Object.keys(this.details.sourcesObj);
        this.filters.sourceName = Object.values(this.details.sourcesObj);
      }
      if (this.details.userSpiffHeaders && this.details.userSpiffHeaders.length) {
          this.selectedHeaders = this.details.userSpiffHeaders;
          if(this.externalId != '' && !this.selectedHeaders.includes('ORDERID')) {
            this.selectedHeaders.splice(2, 0, 'ORDERID');
            await this.addHeader();
          }
      }
      if (
          typeof response["SPIFFSELECTEDSTATUS"] != "undefined" && response["SPIFFSELECTEDSTATUS"] != ""
          ) {
          this.filters.sStatus = response["SPIFFSELECTEDSTATUS"]
            .split(",")
            .map(val => parseInt(val));
          }
      if (
        this.filters.statusName != undefined &&
        this.filters.statusName.length == 0
      ) {
        for (var statusID in this.filters.sStatus) {
          if (
            $.inArray(
              SpiffStatus[this.filters.sStatus[statusID]],
              this.filters.statusName
            ) == -1 &&
            SpiffStatus[this.filters.sStatus[statusID]] != undefined
          ) {
            this.filters.statusName.push(
              SpiffStatus[this.filters.sStatus[statusID]]
            );
          }
        }
      }
      if (
          ( this.searchFilters.spiffStartDate && this.searchFilters.spiffStartDate.length ) ||
          ( this.searchFilters.spiffEndDate && this.searchFilters.spiffEndDate.length ) ||
          ( this.searchFilters.invoiceStartDate && this.searchFilters.invoiceStartDate.length ) ||
          ( this.searchFilters.invoiceEndDate && this.searchFilters.invoiceEndDate.length ) ||
          ( this.searchFilters.searchAccountName && this.searchFilters.searchAccountName.length ) ||
          this.searchFilters.searchSku.length ||
          this.searchFilters.searchOrder.length ||
          ( this.searchFilters.sRewardMin && this.searchFilters.sRewardMin.length ) ||
          ( this.searchFilters.sRewardMax && this.searchFilters.sRewardMax.length ) ||
          ( this.searchFilters.sQuantityMin && this.searchFilters.sQuantityMin.length ) ||
          ( this.searchFilters.sQuantityMax && this.searchFilters.sQuantityMax.length ) ||
          ( this.searchFilters.sTotalMin && this.searchFilters.sTotalMin.length ) ||
          ( this.searchFilters.sTotalMax && this.searchFilters.sTotalMax.length ) ||
          this.filters.AccountTagID.length ||
          this.filters.groupIDs.length ||
          this.filters.selectedAccs.length ||
          (this.filters.sStatus && this.filters.sStatus.length != this.getDefaultSpiffStatuses().length) ||
          (this.filters.sSource && this.filters.sSource.length != Object.keys(this.details.sourcesObj).length)
        ) {
          this.showFilter = 1;
        } else {
          this.showFilter = 0;
        }
        if (this.selectedAll === true) {
          this.selectAll(true);
        }

        // Filer By Spiff Date
        var spiffStartDate = "";
        var spiffEndDate = "";
        if(this.filters['spiffStartDate'] != undefined) {
          spiffStartDate = moment(this.filters['spiffStartDate']).format("MM/DD/YYYY");
        }
        if(this.filters['spiffEndDate'] != undefined) {
          spiffEndDate = moment(this.filters['spiffEndDate']).format("MM/DD/YYYY");
        }
        if(spiffStartDate.length && spiffEndDate.length) {
          this.filters['filterSpiffDate'] = spiffStartDate + ' - ' + spiffEndDate;
        } else if(spiffStartDate.length) {
          this.filters['filterSpiffDate'] = spiffStartDate;
        } else if(spiffEndDate.length) {
          this.filters['filterSpiffDate'] = spiffEndDate;
        }

        // Filer By Invoice Date
        var invoiceStartDate = "";
        var invoiceEndDate = "";
        if(this.filters['invoiceStartDate'] != undefined) {
          invoiceStartDate = moment(this.filters['invoiceStartDate']).format("MM/DD/YYYY");
        }
        if(this.filters['invoiceEndDate'] != undefined) {
          invoiceEndDate = moment(this.filters['invoiceEndDate']).format("MM/DD/YYYY");
        }
        if(invoiceStartDate.length && invoiceEndDate.length) {
          this.filters['filterInvoiceDate'] = invoiceStartDate + ' - ' + invoiceEndDate;
        } else if(invoiceStartDate.length) {
          this.filters['filterInvoiceDate'] = invoiceStartDate;
        } else if(invoiceEndDate.length) {
          this.filters['filterInvoiceDate'] = invoiceEndDate;
        }

        // Filer By Reward Points
        if(this.filters['sRewardMin'] && this.filters['sRewardMax'] && this.filters['sRewardMin'].length && this.filters['sRewardMax'].length) {
          this.filters['filterRewardPoints'] = '$' + this.filters['sRewardMin'] + ' - ' + this.filters['sRewardMax'];
        } else if(this.filters['sRewardMin'] && this.filters['sRewardMin'].length) {
          this.filters['filterRewardPoints'] = '$' + this.filters['sRewardMin'] + '+';
        } else if(this.filters['sRewardMax'] && this.filters['sRewardMax'].length) {
          this.filters['filterRewardPoints'] = '$' + this.filters['sRewardMax'] + '-';
        }

        // Filer By Qty
        if(this.filters['sQuantityMin'] && this.filters['sQuantityMax'] && this.filters['sQuantityMin'].length && this.filters['sQuantityMax'].length) {
          this.filters['filterQty'] = this.filters['sQuantityMin'] + ' - ' + this.filters['sQuantityMax'];
        } else if(this.filters['sQuantityMin'] && this.filters['sQuantityMin'].length) {
          this.filters['filterQty'] = this.filters['sQuantityMin'] + '+';
        } else if(this.filters['sQuantityMax'] && this.filters['sQuantityMax'].length) {
          this.filters['filterQty'] = this.filters['sQuantityMax'] + '-';
        }

        // Filer By Total
        if(this.filters['sTotalMin'] && this.filters['sTotalMax'] && this.filters['sTotalMin'].length && this.filters['sTotalMax'].length) {
          this.filters['filterTotal'] = '$' + this.filters['sTotalMin'] + ' - ' + this.filters['sTotalMax'];
        } else if(this.filters['sTotalMin'] && this.filters['sTotalMin'].length) {
          this.filters['filterTotal'] = '$' + this.filters['sTotalMin'] + '+';
        } else if(this.filters['sTotalMax'] && this.filters['sTotalMax'].length) {
          this.filters['filterTotal'] = '$' + this.filters['sTotalMax'] + '-';
        }

        this.usedFilterDetails = this.query;
    } catch (error) {
      notifier.alert("Error: " + error);
    } finally {
      this.loading = false;
    }

    colsResizeable({ms: 500});
  }

  async hideTooltipCustomers() {
    this.customerTooltipVisibleIndex = -1;
  }

  async showTooltipCustomers(item, index) {
    if (!item.length) return;
    this.customerTooltipVisibleIndex = index;
    this.acctInfoTooltipVisibleIndex = -1;
    try {
      this.customerTooltipLoading = true;
      this.customerTooltipLoading = false;
    } catch (err) {
      notifier.alert(err.message);
      this.customerTooltipVisibleIndex = -1;
    }
  }

  async sortBy(field: SortField) {
    this.loading = true;

    this.sort.field = field;
    const dire = this.sort.direction[field];
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  async search() {}

  async sortingBySKU() {
    await this.sortBy(SortField.Sku);
  }

  async sortingBySpiffDate() {
    await this.sortBy(SortField.SpiffDate);
  }

  async sortingByOrder() {
    await this.sortBy(SortField.Order);
  }

  async sortingByInvoiceDate() {
    await this.sortBy(SortField.InvoiceDate);
  }
  
  async sortingByAccount() {
    await this.sortBy(SortField.Account);
  }

  async sortingByPerPoints() {
    await this.sortBy(SortField.Per);
  }
  async sortingByPerQty() {
    await this.sortBy(SortField.Qty);
  }

  async sortingByTotalPoints() {
    await this.sortBy(SortField.Total);
  }

  async sortingByStatus() {
    await this.sortBy(SortField.Status);
  }

  async sortingByType() {
    await this.sortBy(SortField.Type);
  }

  async sortingByVarOrderId() {
    await this.sortBy(SortField.VarOrder);
  }

  //go to search page
  gotoSearch = function(this: any, searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for" + this.$route.path.toLowerCase()
    });
  };

  // search
  // date

  async searchBySpiffDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.searchFilters.spiffStartDate == undefined || this.searchFilters.spiffStartDate == "") 
      && (this.searchFilters.spiffEndDate == "" || this.searchFilters.spiffEndDate == undefined)
    ) {
      return;
    } else {
      this.filters.spiffStartDate = this.searchFilters.spiffStartDate;
      this.filters.spiffEndDate = this.searchFilters.spiffEndDate;
      await this.fetchData();
    }
  }
  async resetSearchBySpiffDate(allowFetchData = true) {
    this.initialLoad = true;
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.searchFilters.spiffStartDate == undefined || this.searchFilters.spiffStartDate == "") 
      && (this.searchFilters.spiffEndDate == "" || this.searchFilters.spiffEndDate == undefined)
    ) {
      return;
    } else {
      this.filters.spiffStartDate = this.searchFilters.spiffStartDate = undefined;
      this.filters.spiffEndDate = this.searchFilters.spiffEndDate = undefined;
      this.filters.filterSpiffDate = "";
      if (allowFetchData) {
        await this.fetchData();
      }
    }
  }

  async searchByInvoiceDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.searchFilters.invoiceStartDate == undefined || this.searchFilters.invoiceStartDate == "")
      && (this.searchFilters.invoiceEndDate == "" || this.searchFilters.invoiceEndDate == undefined)
    ) {
      return;
    } else {
      this.filters.invoiceStartDate = this.searchFilters.invoiceStartDate;
      this.filters.invoiceEndDate = this.searchFilters.invoiceEndDate;
      await this.fetchData();
    }
  }
  async resetSearchByInvoiceDate(allowFetchData = true) {
    this.initialLoad = true;
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.searchFilters.invoiceStartDate == undefined || this.searchFilters.invoiceStartDate == "")
      && (this.searchFilters.invoiceEndDate == "" || this.searchFilters.invoiceEndDate == undefined)
    ) {
      return;
    } else {
      this.filters.invoiceStartDate = this.searchFilters.invoiceStartDate = undefined;
      this.filters.invoiceEndDate = this.searchFilters.invoiceStartDate = undefined;
      this.filters.filterInvoiceDate = "";
      if (allowFetchData) {
        await this.fetchData();
      }
    }
  }
  // Account
  async resetSearchByAccountName(allowFetchData = true) {
    this.initialLoad = true;
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchAccountName = this.searchFilters.searchAccountName = "";
    if(allowFetchData) {
      await this.fetchData();
    }
  }
  async searchByAccountName() {
    this.initialLoad = false;
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      this.searchFilters.searchAccountName == "" ||
      this.searchFilters.searchAccountName == undefined
    ) {
      return;
    } else {
      this.filters.searchAccountName = this.searchFilters.searchAccountName;
      await this.fetchData();
    }
  }
  // Sku
  async searchBySKU() {
    this.initialLoad = false;
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.searchFilters.searchSku == "") {
      return;
    } else {
      this.filters.searchSku = this.searchFilters.searchSku;
      await this.fetchData();
    }
  }
  async resetSearchBySKU(allowFetchData = true) {
    this.initialLoad = true;
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchSku = this.searchFilters.searchSku = "";
    if (allowFetchData) {
      await this.fetchData();
    }
  }
  // order
  async searchByOrder() {
    this.initialLoad = false;
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.searchFilters.searchOrder == "") {
      return;
    } else {
      this.filters.searchOrder = this.searchFilters.searchOrder;
      await this.fetchData();
    }
  }
  async resetSearchByOrder(allowFetchData = true) {
    this.initialLoad = true;
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchOrder = this.searchFilters.searchOrder = "";
    if (allowFetchData) {
      await this.fetchData();
    }
  }
  async searchByVarOrder() {
    this.initialLoad = false;
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.searchFilters.searchVarOrder == "") {
      return;
    } else {
      this.filters.searchVarOrder = this.searchFilters.searchVarOrder;
      await this.fetchData();
    }
  }
  async resetSearchByVarOrder(allowFetchData = true) {
    this.initialLoad = true;
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchVarOrder = this.searchFilters.searchVarOrder = this.externalId = "";
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async searchByReward() {
    this.initialLoad = false;
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sRewardMin = this.searchFilters.sRewardMin;
    this.filters.sRewardMax = this.searchFilters.sRewardMax;
    await this.fetchData();
  }
  async resetSearchByReward(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sRewardMin = this.searchFilters.sRewardMin = "";
    this.filters.sRewardMax = this.searchFilters.sRewardMax = "";
    this.filters.filterRewardPoints = "";
    if (allowFetchData) {
      await this.fetchData();
    }
  } 
  
  async searchByQuantity() {
    this.initialLoad = false;
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sQuantityMin = this.searchFilters.sQuantityMin;
    this.filters.sQuantityMax = this.searchFilters.sQuantityMax;
    await this.fetchData();
  }
  async resetSearchByQuantity(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sQuantityMin = this.searchFilters.sQuantityMin = "";
    this.filters.sQuantityMax = this.searchFilters.sQuantityMax = "";
    this.filters.filterQty = "";
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async searchByTotal() {
    this.initialLoad = false;
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sTotalMin = this.searchFilters.sTotalMin;
    this.filters.sTotalMax = this.searchFilters.sTotalMax;
    await this.fetchData();
  }
  async resetSearchByTotal(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sTotalMin = this.searchFilters.sTotalMin = "";
    this.filters.sTotalMax = this.searchFilters.sTotalMax = "";
    this.filters.filterTotal = "";
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async changeStatus(spiffID: string, statusID: number) {
    const successCount = this.selectedID.length;
    if (statusID > 0) {
      try {
        this.loading = true;
        const response = await spiffRepo.updateSpiffStatus(spiffID, statusID);
        if (response.STATUS == 1) {
          await this.fetchData();
          if (successCount) {
            notifier.success(
              successCount + " Spiff(s) Status Updated Successfully"
            );
          } else {
            notifier.success(
              "Spiff " + spiffID + "'s Status Updated Successfully"
            );
          }
        }
      } catch (err) {
        notifier.alert(err.message);
      } finally {
        this.loading = false;
      }
    }
  }

  // Status
  async checkStatusList(status: number, name: string) {
    this.initialLoad = false;
    this.selectedID = [];
    this.pageNumber = 1;
    if (this.filters && this.filters.sStatus && this.filters.statusName != undefined) {
      const i = this.filters.sStatus.findIndex(s => s === status);
      const statusIndex = this.filters.statusName.findIndex(
        statusValue => statusValue === name
      );
      if (i === -1) {
        this.filters.sStatus.push(status);
      } else {
        this.filters.sStatus.splice(i, 1);
      }

      if (statusIndex === -1) {
        this.filters.statusName.push(name);
      } else {
        this.filters.statusName.splice(statusIndex, 1);
      }
    }

    await this.fetchData();
  }

  async checkAllStatusList() {
    this.initialLoad = false;
    this.selectedID = [];
    this.pageNumber = 1;
    this.filters.sStatus = [
      SpiffStatus.Open,
      SpiffStatus.Processing,
      SpiffStatus.Completed,
      SpiffStatus.Expired,
      SpiffStatus.Ignored,
      SpiffStatus.Addons
    ];
    await this.fetchData();
  }

  async uncheckAllStatusList() {
    this.initialLoad = false;
    this.selectedID = [];
    this.pageNumber = 1;
    this.filters.sStatus = [];
    this.filters.statusName = [];
    await this.fetchData();
  }

  async resetStatusList(allowFetchData = true) {
    this.initialLoad = false;
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sStatus = this.getDefaultSpiffStatuses();
    this.filters.statusName = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  getDefaultSpiffStatuses() {
    return [
      SpiffStatus.Open,
      SpiffStatus.Processing,
      SpiffStatus.Completed,
      SpiffStatus.Expired,
      SpiffStatus.Ignored,
      SpiffStatus.Addons
    ];
  }

  // Spiff Source
  async checkSource(status: number) {
    this.initialLoad = false;
    this.selectedID = [];
    this.pageNumber = 1;
    if (this.filters && this.filters.spiffType) {
      const i = this.filters.spiffType.findIndex(s => s === status);
      if (i === -1) {
        this.filters.spiffType.push(status);
      } else {
        this.filters.spiffType.splice(i, 1);
      }
    }
    await this.fetchData();
  }

  async checkAllSSource() {
    this.initialLoad = false;
    this.selectedID = [];
    this.pageNumber = 1;
    this.filters.spiffType = [
      1,2
    ];
    await this.fetchData();
  }

  async resetSSource() {
    this.initialLoad = true;
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.spiffType = [1,2]; //default
    await this.fetchData();
  }


  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (this.pageNumber <= this.details.TOTALPAGES && this.pageNumber >= 1) {
      await this.fetchData();
    }
  }

  async updateBulkStatus(statusID) {
    if (this.selectedID.length) {
      this.updateStatusModalVisible = false;
      this.changeStatus(this.selectedID.join(","), statusID);
      this.selectedID = [];
    }
  }

  filterByUnassignedAccounts() {
    this.pageNumber = 1;
    this.selectedAccts = [];
    this.fetchData();
  }

  filterBySelectedAccts() {
    this.pageNumber = 1;
    this.fetchData();
  }

  async viewBy(viewType, resetFilters = true) {
    this.pageNumber = 1;
    if (this.viewType != viewType) {
      this.externalId = "";
      this.viewType = viewType;
      this.$router.replace({ query: { viewType: this.viewType } }).catch(tmp => {});
    }

    if (resetFilters) {
      this.resetFilters();
      this.filters.aState = [];
      this.stateList.map((item) => {
        this.filters.aState.push(item.ID);
      });
    }
    await this.fetchData();
    await this.accsLoad();
  }

  resetFilters() {
    this.filters = {
      searchAll: "",
      searchSku: "",
      searchOrder: "",
      sRewardMin: "",
      sRewardMax: "",
      sQuantityMin: "",
      sQuantityMax: "",
      sTotalMin: "",
      sTotalMax: "",
      searchAccount: "",
      spiffStartDate: undefined,
      spiffEndDate: undefined,
      invoiceStartDate: undefined,
      invoiceEndDate: undefined,
      sStatus: this.getDefaultSpiffStatuses(),
      AccountTagID: [],
      groupIDs: [],
      spiffType: [1,2],
      selectedAccs: [],
      sSource: [],
      gTagIDs: [],
      aState: [],
      accountTagName: [],
      selectedAccsName: [],
      groupName: [],
      statusName: [],
      sourceName: [],
      searchVarOrder: ""
    };
  }

  linkAccountTooltipVisible = -1;
  async linkAccount(linkAccountId: string, acc: any) {
    this.linkAccountTooltipVisible = -1;
    this.currentCustIndex = -1;
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Finances",
        FunctionName: "InsertAccountLinking",
        aID: acc.AID,
        LinkAccountId: linkAccountId
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      this.viewBy(this.viewType, false); //no reset filter in this case
      notifier.success(
        "Linked " + linkAccountId + " to " + acc.ACCOUNTID + " - " + acc.ANAME
      );
    } catch (err) {
      console.log(err.message);
      //notifier.alert("search accounts fail: " + err.message);
    } finally {
      this.loading = false;
    }
  }

  showAcctInfoTooltip(index) {
    if (
      this.customerTooltipVisibleIndex == -1 ||
      this.customerTooltipVisibleIndex != index
    ) {
      this.acctInfoTooltipVisibleIndex = index;
    }
  }
  resetSelected() {
    this.selectedID = [];
    // this.selectedAll = false;
    // this.excludedIDs = [];
  }

  //filter by Tags
  async filterByTag(tagName = '', groupName = '') {
    this.resetSelected();
    this.pageNumber = 1;

    if(tagName.length) {
      const tagIndex = this.filters.accountTagName.findIndex(tagValue => tagValue === tagName);
      if (tagIndex === -1) {
        this.filters.accountTagName.push(tagName);
      } else {
        this.filters.accountTagName.splice(tagIndex, 1);
      }
    }

    if(groupName.length) {
      const groupIndex = this.filters.groupName.findIndex(groupValue => groupValue === groupName);
      if (groupIndex === -1) {
        this.filters.groupName.push(groupName);
      } else {
        this.filters.groupName.splice(groupIndex, 1);
      }
    }
    await this.fetchData();
  }
  async checkAllTag() {
    this.resetSelected();
    // this.filters.AccountTagID = this.accountTags.map(val => val.ACCOUNTTAGID);
    var $this = this;
		this.filters.AccountTagID = [];
		this.accountTags.forEach(function (val: any) {
			if (!isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP == 'undefined') {
				if (typeof val.ISGLOBALTAG == 'undefined') {
					if($this.filters.AccountTagID != undefined) {
						$this.filters.AccountTagID.push(val.ACCOUNTTAGID);
						$this.filters.accountTagName.push(val.TAGNAME);
					}
				}
				else {
					$this.filters.gTagIDs.push(val.ACCOUNTTAGID);
						$this.filters.accountTagName.push(val.TAGNAME);
				}
			}
		});
    this.pageNumber = 1;
    await this.fetchData();
  }
  async checkAllGroupTag() {
    this.resetSelected();
    // this.filters.groupIDs = this.accountTags.map(val => val.ACCOUNTTAGID);
    var $this = this;
    this.filters.groupIDs = [];
		this.accountTags.forEach(function (val: any) {
			if (!isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP != 'undefined') {
				$this.filters.groupIDs.push(val.ACCOUNTTAGID);
        $this.filters.groupName.push(val.TAGNAME);
			}
		});
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetTag(allowFetchData = true) {
    this.resetSelected();
    this.filters.AccountTagID = [];
    this.filters.groupIDs = [];
    this.filters.gTagIDs = [];
    this.filters.accountTagName = [];
    this.filters.groupName = [];
    this.pageNumber = 1;
    if(allowFetchData) {
      await this.fetchData();
    }
  }
  filterTagsInBox() {
    let options = this.accountTagsList;

    if (this.searchTagsTxt) {
      options = options.filter(
        option =>
          `${option.TAGNAME}`
            .toLowerCase()
            .indexOf(this.searchTagsTxt.toLowerCase()) !== -1
      );
    }

    this.accountTags = options;
  }

  toggleActive(div) {
    this.isActive = div;
  }
  filterAccsInBox() {
    let options = this.varAccsList;

    if (this.searchAccTxt) {
      options = options.filter(
        option =>
          `${option.ANAME}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTALIAS}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTID}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1
      );
    }

    this.varAccs = options;
  }
  async filterByAcc(accountsName: string) {
    this.resetSelected();
    this.pageNumber = 1;
    if(accountsName != undefined) {
      const accountsIndex = this.filters.selectedAccsName.findIndex(accountsValue => accountsValue === accountsName);
      if (accountsIndex === -1) {
        this.filters.selectedAccsName.push(accountsName);
      } else {
        this.filters.selectedAccsName.splice(accountsIndex, 1);
      }
    }
    await this.fetchData();
  }
  async checkAllAcc() {
    this.resetSelected();
    this.filters.selectedAccs = this.varAccs.map(val => val.AID);
    this.filters.selectedAccsName = this.varAccs.map((val) => val.ANAME);
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetAcc(allowFetchData = true) {
    this.resetSelected();
    this.filters.selectedAccs = [];
    this.filters.selectedAccsName = [];
    this.pageNumber = 1;
    if(allowFetchData) {
      await this.fetchData();
    }
  }
  async accsLoad() {
    if (this.searchAccTxt == "") {
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          FunctionName: "ListExt",
          controller: "Accounts",
          queryType: "top5UsedAcct",
          view: this.viewType,
          accSearch: 1
        });
        if (response.data.STATUS) {
          this.varAccs = response.data.ACCOUNTS;
          this.selectedAccs = response.data.VARACCOUNTSIDSRR;
          this.varAccsList = response.data.ACCOUNTS;
        }
        if (response.data.ERROR || !response.data.ACCOUNTS) {
          console.log("error", response.data.ERROR);
        }
      } catch (err) {
        notifier.alert(err.message);
      } finally {
        // this.loading = false;
      }
    }
  }
  // Unverified list
  get filterByAccounts(): any[] {
    let options: { MSPIFF_ACCOUNTID?: number; ANAME?: string;ACCOUNTID?: string;
      ACCOUNTALIAS?: string; }[] = this
      .acctsInSpiffs;
    if (this.unverifyAccSearch) {
      options = options.filter(
        option =>
          `${option.MSPIFF_ACCOUNTID}`
            .toLowerCase()
            .indexOf(this.unverifyAccSearch.toLowerCase()) !== -1 ||
          `${option.ANAME}`
            .toLowerCase()
            .indexOf(this.unverifyAccSearch.toLowerCase()) !== -1 ||
          `${option.ACCOUNTALIAS}`
            .toLowerCase()
            .indexOf(this.unverifyAccSearch.toLowerCase()) !== -1 ||
          `${option.ACCOUNTID}`
            .toLowerCase()
            .indexOf(this.unverifyAccSearch.toLowerCase()) !== -1
      );
    }
    return options;
  }

  async checkSourceList(status: string, name: string) {
    this.selectedID = [];
    this.pageNumber = 1;
    const i = this.filters.sSource.findIndex(s => s === status);
    const sourceIndex = this.filters.sourceName.findIndex(
      sourceValue => sourceValue === name
    );
    if (i === -1) {
      this.filters.sSource.push(status);
    } else {
      this.filters.sSource.splice(i, 1);
    }
    if (sourceIndex === -1) {
      this.filters.sourceName.push(name);
    } else {
      this.filters.sourceName.splice(sourceIndex, 1);
    }
    await this.fetchData();
  }

  async checkAllSourceList(allowFetchData = true) {
    this.selectedID = [];   
    this.filters.sSource = Object.keys(this.details.sourcesObj);
    this.filters.sourceName = Object.values(this.details.sourcesObj);
    if (allowFetchData) {
      await this.fetchData();
    }
  }
  
  async uncheckAllSourceList() {
    this.initialLoad = false;
    this.selectedID = [];   
    this.filters.sSource = [];
    this.filters.sourceName = [];
    await this.fetchData();
  }

  async resetSourceList(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sSource = [];
    this.filters.sourceName = [];
    this.checkAllSourceList(allowFetchData);
  }

  // account state filter
  searchStateTxt = '';
  filteredStates: any[] = [];
  filterStateInBox() {
    let options = this.stateList;

    if (this.searchStateTxt) {
      options = options.filter(
          option =>
              `${option.TEXT}`
                  .toLowerCase()
                  .indexOf(this.searchStateTxt.toLowerCase()) !== -1
      );
    }

    this.filteredStates = options;
  }

  async checkStateList(status: string, name: string) {
    this.selectedID = [];
    this.pageNumber = 1;
    const i = this.filters.aState.findIndex(s => s === status);
    if (i === -1) {
      this.filters.aState.push(status);
    } else {
      this.filters.aState.splice(i, 1);
    }
    await this.fetchData();
  }

  async checkAllStateList(allowFetchData = true) {
    this.selectedID = [];
    this.filters.aState = this.stateList.map((item) => item.ID);
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async uncheckAllStateList() {
    this.initialLoad = false;
    this.selectedID = [];
    this.filters.aState = [];
    await this.fetchData();
  }

  async resetStateList(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.aState = [];
    this.searchStateTxt = '';
    this.filterStateInBox();
    this.checkAllStateList(allowFetchData);
  }



  accountsLen() {
    var height = '275px';
    if(this.filterByAccounts.length == 1) {
      height = "156px";
      $('.filter-unassigned-box').css("height",height)

    } else if(this.filterByAccounts.length == 2) {
      height = "196px";
      $('.filter-unassigned-box').css("height",height)

    }else if(this.filterByAccounts.length == 3) {
      height = "245px";
      $('.filter-unassigned-box').css("height",height)
    }else{
      $('.filter-unassigned-box').css("height",height)
    }
  }

  getColumnWidth(headerName, from = 0) {
    var headerClass = "";
    switch (this.selectedHeaders.length) {
      case 1:
        switch (headerName) {
          case "ORDERSOURCE":
            headerClass = "col-3";
            break;
          case "SPIFFEFFECTIVEDATEFORMATTED":
            headerClass = "col-6";
            break;
          default:
            headerClass = "col-3 tar";
            break;
        }
        break;
      case 2:
        switch (headerName) {
          case "ORDERSOURCE":
            headerClass = "col-2";
            break;
          case "SPIFFEFFECTIVEDATEFORMATTED":
            if (
              this.selectedHeaders.includes("SKU") ||
              this.selectedHeaders.includes("QTY")
            ) {
              headerClass = "col-4";
            } else {
              headerClass = "col-6";
            }
            break;
          case "SKU":
            headerClass = "col-4";
            break;
          case "MSPIFF_ACCOUNTID":
            headerClass = "col-2";
            break;
          case "ASTATE":
            headerClass = "col-2";
            break;
          case "MSPIFF_ACCOUNTNAME":
            headerClass = "col-2";
            break;
          case "TOTALPOINTS":
            headerClass = "col-4";
            break;
          default:
            headerClass = "col-2 tar";
            break;
        }
        break;
      case 3:
        switch (headerName) {
          case "ORDERSOURCE":
            headerClass = "col-1-5";
            break;
          case "SPIFFEFFECTIVEDATEFORMATTED":
            if (
              this.selectedHeaders.includes("SKU") ||
              this.selectedHeaders.includes("TOTALPOINTS") ||
              this.selectedHeaders.includes("QTY")
            ) {
              headerClass = "col-4";
            } else {
              headerClass = "col-5";
            }
            break;
          case "SKU":
            if (
              this.selectedHeaders.includes("TOTALPOINTS") ||
              this.selectedHeaders.includes("QTY")
            ) {
              headerClass = "col-2-5";
            } else {
              headerClass = "col-3";
            }
            break;
          case "MSPIFF_ACCOUNTID":
            headerClass = "col-2";
            break;
          case "ASTATE":
            headerClass = "col-2";
            break;
          case "MSPIFF_ACCOUNTNAME":
            headerClass = "col-2";
            break;
          case "QTY":
            if (
              this.selectedHeaders.includes("TOTALPOINTS") ||
              this.selectedHeaders.includes("SKU")
            ) {
              headerClass = "col-2-5 tar";
            } else {
              headerClass = "col-3 tar";
            }
            break;
          case "TOTALPOINTS":
            if (
              this.selectedHeaders.includes("SKU") ||
              this.selectedHeaders.includes("QTY")
            ) {
              headerClass = "col-2-5";
            } else {
              headerClass = "col-3";
            }
            break;
          case "PURCHASEID":
            headerClass = "col-2";
            break;
          case "POINTSPER":
            headerClass = "col-2 tar";
            break;
          default:
            headerClass = "col-1-5 tar";
            break;
        }
        break;
      case 4:
        switch (headerName) {
          case "ORDERSOURCE":
            headerClass = "col-1-5";
            break;
          case "SPIFFEFFECTIVEDATEFORMATTED":
            headerClass = "col-2-5";
            break;
          case "SKU":
            headerClass = "col-2";
            break;
          case "MSPIFF_ACCOUNTID":
            headerClass = "col-1-5";
            break;
          case "ASTATE":
            headerClass = "col-2";
            break;
          case "MSPIFF_ACCOUNTNAME":
            if (this.selectedHeaders.includes("ORDERSOURCE")) {
              headerClass = "col-3";
            } else {
              headerClass = "col-1-5";
            }
            break;
          case "QTY":
            headerClass = "col-2 tar";
            break;
          case "TOTALPOINTS":
            headerClass = "col-2";
            break;
          case "POINTSPER":
            headerClass = "col-2 tar";
            break;
          case "PURCHASEID":
            headerClass = "col-2";
            break;
          default:
            headerClass = "col-1-5 tar";
            break;
        }
        break;
      case 5:
        switch (headerName) {
          case "ORDERSOURCE":
            headerClass = "col-1-5";
            break;
          case "SPIFFEFFECTIVEDATEFORMATTED":
            headerClass = "col-3";
            break;
          case "SKU":
            headerClass = "col-1-5";
            break;
          case "MSPIFF_ACCOUNTID":
            headerClass = "col-1-5";
            break;
          case "ASTATE":
            headerClass = "col-1-5";
            break;
          case "MSPIFF_ACCOUNTNAME":
            headerClass = "col-1-5";
            break;
          case "TOTALPOINTS":
            headerClass = "col-1-5";
            break;
          case "PURCHASEID":
            headerClass = "col-1-5";
            break;
          default:
            headerClass = "col-1-5 tar";
            break;
        }
        break;
      case 6:
        switch (headerName) {
          case "ORDERSOURCE":
            headerClass = "col-1";
            break;
          case "SPIFFEFFECTIVEDATEFORMATTED":
            headerClass = "col-1-5";
            break;
          case "SKU":
            headerClass = "col-2-5";
            break;
          case "MSPIFF_ACCOUNTID":
            headerClass = "col-1-5";
            break;
          case "ASTATE":
            headerClass = "col-1-5";
            break;
          case "MSPIFF_ACCOUNTNAME":
            headerClass = "col-3";
            break;
          case "QTY":
            headerClass = "col-1-5 tar";
            break;
          case "TOTALPOINTS":
            headerClass = "col-1-5";
            break;
          case "POINTSPER":
            headerClass = "col-1-5 tar";
            break;
          case "PURCHASEID":
            headerClass = "col-3";
            break;
          default:
            headerClass = "col-1 tar";
            break;
        }
        break;
      case 7:
        var totalAdd = 0;
        if (!this.selectedHeaders.includes("SKU")) {
          totalAdd += 1;
        }
        if (!this.selectedHeaders.includes("QTY")) {
          totalAdd += 1;
        }
        if (!this.selectedHeaders.includes("TOTALPOINTS")) {
          totalAdd += 1;
        }
        var includedHeaders = 4 - totalAdd;
        switch (headerName) {
          case "ORDERSOURCE":
            headerClass = "col-1";
            break;
          case "SPIFFEFFECTIVEDATEFORMATTED":
            if (
              this.selectedHeaders.includes("SKU") ||
              this.selectedHeaders.includes("TOTALPOINTS") ||
              this.selectedHeaders.includes("QTY")
            ) {
              headerClass = "col-2";
            } else {
              headerClass = "col-3";
            }
            break;
          case "SKU":
            if (
              this.selectedHeaders.includes("TOTALPOINTS") ||
              this.selectedHeaders.includes("QTY")
            ) {
              headerClass = "col-1-5";
            } else {
              headerClass = "col-2";
            }
            if (includedHeaders == 2) {
              headerClass = "col-2";
            }
            if (includedHeaders == 3) {
              headerClass = "col-1-5";
            }
            break;
          case "QTY":
            if (
              this.selectedHeaders.includes("TOTALPOINTS") ||
              this.selectedHeaders.includes("SKU")
            ) {
              headerClass = "col-1-5 tar";
            } else {
              headerClass = "col-2 tar";
            }
            if (includedHeaders == 2) {
              headerClass = "col-2 tar";
            }
            if (includedHeaders == 3) {
              headerClass = "col-1-5 tar";
            }
            break;
          case "TOTALPOINTS":
            if (
              this.selectedHeaders.includes("QTY") ||
              this.selectedHeaders.includes("SKU")
            ) {
              headerClass = "col-1-5";
            } else {
              headerClass = "col-2";
            }
            if (includedHeaders == 2) {
              headerClass = "col-2";
            }
            if (includedHeaders == 3) {
              headerClass = "col-1-5";
            }
            break;
          case "MSPIFF_ACCOUNTID":
            headerClass = "col-1";
            break;
          case "ASTATE":
            headerClass = "col-1";
            break;
          case "MSPIFF_ACCOUNTNAME":
            headerClass = "col-3";
            break;
          case "POINTSPER":
            headerClass = "col-1 tar";
            if (includedHeaders == 3) {
              headerClass = "col-1-5 tar";
            }
            break;
          case "PURCHASEID":
            headerClass = "col-1";
            if (includedHeaders == 1 || includedHeaders == 3 || includedHeaders === 2) {
              headerClass = "col-2";
            }
            break;
          default:
            headerClass = "col-1 tar";
            break;
        }
        break;
      case 8:
        switch (headerName) {
          case "ORDERSOURCE":
            headerClass = "col-1";
            break;
          case "SPIFFEFFECTIVEDATEFORMATTED":
            headerClass = "col-2-5";
            break;
          case "SKU":
            headerClass = "col-1-5";
            break;
          case "MSPIFF_ACCOUNTID":
            headerClass = "col-1";
            break;
          case "ASTATE":
            headerClass = "col-1";
            break;
          case "MSPIFF_ACCOUNTNAME":
            headerClass = "col-3";
            break;
          case "TOTALPOINTS":
            headerClass = "col-1";
            break;
          case "PURCHASEID":
            headerClass = "col-1";
            break;
          default:
            headerClass = "col-1 tar";
            break;
        }
        break;
      case 9:
        switch (headerName) {
          case "ORDERSOURCE":
            headerClass = "col-1";
            break;
          case "SPIFFEFFECTIVEDATEFORMATTED":
            headerClass = "col-1-5";
            break;
          case "SKU":
            headerClass = "col-1";
            break;
          case "MSPIFF_ACCOUNTID":
            headerClass = "col-1";
            break;
          case "ASTATE":
            headerClass = "col-1";
            break;
          case "MSPIFF_ACCOUNTNAME":
            headerClass = "col-3";
            break;
          case "TOTALPOINTS":
            headerClass = "col-1";
            break;
          case "PURCHASEID":
            headerClass = "col-1-5";
            break;
          default:
            headerClass = "col-1 tar";
            break;
        }
        break;
      default:
        switch (headerName) {
          case "ORDERSOURCE":
            headerClass = "col-1";
            break;
          case "SPIFFEFFECTIVEDATEFORMATTED":
            headerClass = "col-1";
            break;
          case "SKU":
            headerClass = "col-1";
            break;
          case "MSPIFF_ACCOUNTID":
            headerClass = "col-1";
            break;
          case "ASTATE":
            headerClass = "col-1";
            break;
          case "MSPIFF_ACCOUNTNAME":
            headerClass = "col-1";
            if(this.selectedHeaders.length === 11 || this.selectedHeaders.length === 10) {
              headerClass = "col-2";
            }
            break;
          case "SERIALNUMBER":
            headerClass = "col-1";
            break;
          case "PURCHASEID":
            headerClass = "col-1";
            if(this.selectedHeaders.length === 10) {
              headerClass = "col-2";
            }
            break;
          case "INVOICEDATEFORMATTED":
            headerClass = "col-1";
            break;
          default:
            headerClass = "col-1 tar";
            break;
        }
        break;
    }
    if (
      headerName != "INVOICEDATEFORMATTED" &&
      headerName != "ASTATE" &&
      headerName != "POINTSPER" &&
      headerName != "QTY" &&
      headerName != "TOTALPOINTS" &&
      headerName != "SERIALNUMBER"
    ) {
      headerClass += " textOverflow";
    }
    if (
      this.selectedHeaders[this.selectedHeaders.length - 1] == headerName ||
      this.selectedHeaders[this.selectedHeaders.length - 2] == headerName
    ) {
      headerClass += " lastSearchBox";
    }

    headerClass += ` col-${headerName.toLowerCase()}`;

    return headerClass;
  }
  sorting(spiffSort) {
    if (spiffSort == "SKU") {
      this.sortingBySKU();
    } else if (spiffSort == "SPIFFDATE") {
      this.sortingBySpiffDate();
    } else if (spiffSort == "PURCHASEID") {
      this.sortingByOrder();
    } else if (spiffSort == "INVOICEDATEFORMATTED") {
      this.sortingByInvoiceDate();
    } else if (spiffSort == "MSPIFF_ACCOUNTNAME") {
      this.sortingByAccount();
    } else if (spiffSort == "POINTSPER") {
      this.sortingByPerPoints();
    } else if (spiffSort == "QTY") {
      this.sortingByPerQty();
    } else if (spiffSort == "TOTALPOINTS") {
      this.sortingByTotalPoints();
    } else if (spiffSort == "ORDERID") {
      this.sortingByVarOrderId();
    } else {
      this.sortingByStatus();
    }
  }
  async addHeader() {
    var sortedHeaders: string[] = [];
    colsResizeableReset();
    for (var headerSpiff in this.headerSpiff) {
      var spiffIndex: string = this.headerSpiff[headerSpiff];
      const selectedIndex = this.selectedHeaders.includes(spiffIndex);
      if (selectedIndex) {
        sortedHeaders.push(spiffIndex);
      }
    }
    this.selectedHeaders = sortedHeaders;
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Users",
      FunctionName: "paramsUpdate",
      headers: this.selectedHeaders.join(","),
      from: "spiff",
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    colsResizeable();
  }

  removeColumn(header, x) {
    const sortedHeaders: string[] = [];
    for (const ts in this.headerSpiff) {
      const valIdx: string = this.headerSpiff[ts];
      const i = this.selectedHeaders.includes(valIdx);
      if (i && valIdx != header) {
        sortedHeaders.push(valIdx);
      }
    }
    this.selectedHeaders = sortedHeaders;
    this.addHeader();
  }

  getFilterValue(header) {
    let filter = '';
    let searchKey: string | undefined = '';
    switch (header) {
      case 'SKU':
        searchKey = this.filters.searchSku;
        break;
      case 'SPIFFDATE':
        searchKey = getDateRangeFilter(this.filters.spiffStartDate, this.filters.spiffEndDate);
        break;
      case 'ORDERSOURCE':
        if (this.filters.sourceName && !this.filters.sourceName.length)
          filter = "None";
        else if (
          this.filters.sourceName &&
          !(
            Object.keys(this.details.sourcesObj).length ==
            this.filters.sourceName.length
          )
        )
          filter = this.filters.sourceName.join(", ");
        break;
      case 'PURCHASEID':
        searchKey = this.filters.searchOrder;
        break;
      case 'MSPIFF_ACCOUNTNAME':
        {
          const arr: string[] = [];
          if(this.filters.searchAccountName) {
             arr.push(`Customer: ${this.filters.searchAccountName}`);
          }
          if(this.filters.gTagIDs.length || this.filters.AccountTagID.length) {
             arr.push(`Customer Tags: ${this.filters.accountTagName}`);
          }
          if(this.filters.selectedAccs.length) {
             arr.push(`Customer Accounts: ${this.filters.selectedAccsName}`);
          }
          if(this.filters.groupIDs.length) {
             arr.push(`Customer Groups: ${this.filters.groupName}`);
          }
          searchKey = arr.join('; ');
          break;
        }
      case 'INVOICEDATEFORMATTED':
        filter = getDateRangeFilter(this.filters.invoiceStartDate, this.filters.invoiceEndDate);
        searchKey = '';
        break;
      case 'MSPIFF_ACCOUNTID':
        searchKey = '';
        break;
      case 'POINTSPER':
        filter = this.getRangeFilter(this.filters.sRewardMin, this.filters.sRewardMax);
        searchKey = '';
        break;
      case 'QTY':
        filter = this.getRangeFilter(this.filters.sQuantityMin, this.filters.sQuantityMax);
        searchKey = '';
        break;
      case 'TOTALPOINTS':
        filter = this.getRangeFilter(this.filters.sTotalMin, this.filters.sTotalMax);
        searchKey = '';
        break;
      case 'STATUSTEXT':
        filter = this.getPreDefinedFilter(SpiffStatus, this.filters.statusName);
        searchKey = "";
        break;
      case 'ORDERID':
        searchKey = this.filters.searchVarOrder;
        break;
      case 'ASTATE':
        if (!this.filters.aState.length)
          filter = "None";
        else if (this.filters.aState.length != this.stateList.length) {
          filter = this.stateList.filter((state) => { return this.filters.aState.includes(state.ID); }).map((state) => state.TEXT).join(", ");
        }
        break;
      default:
        // nothing
        break;
    }
    return (filter ? `${filter}` : '') + (searchKey ? (filter ? ' - ' : '') + searchKey : '');
  }

  getRangeFilter(min, max) {
    const arr: string[] = [];
    if (min) {
      arr.push(`Min: ${min}`);
    }
    if (max) {
      arr.push(`Max: ${max}`);
    }
    return arr.join('; ');
  }

  getPreDefinedFilter(status, filter) {
    if (!filter.length) {
      return "None";
    } else if (!((Object.keys(status).length / 2) == filter.length)) {
      return filter.join(", ");
    }
  }

  async resetColumn(header) {
    this.loading = true;
    switch (header) {
      case 'SKU':
        this.resetSearchBySKU();
        break;
      case 'SPIFFDATE':
        this.resetSearchBySpiffDate();
        break;
      case 'ORDERSOURCE':
        this.resetSourceList();
        break;
      case 'PURCHASEID':
        this.resetSearchByOrder();
        break;
      case 'MSPIFF_ACCOUNTNAME':
        this.resetSearchByAccountName(false);
        this.resetTag(false);
        this.resetAcc(false);
        await this.fetchData();
        break;
      case 'INVOICEDATEFORMATTED':
        this.resetSearchByInvoiceDate();
        break;
      case 'MSPIFF_ACCOUNTID':
        // nothing
        break;
      case 'POINTSPER':
        this.resetSearchByReward();
        break;
      case 'QTY':
        this.resetSearchByQuantity();
        break;
      case 'TOTALPOINTS':
        this.resetSearchByTotal();
        break;
      case 'STATUSTEXT':
        this.resetStatusList();
        break;
      case 'ORDERID':
        this.resetSearchByVarOrder();
        break;
      case 'ASTATE':
        this.resetStateList();
        break;
      default:
        this.loading = false;
        break;
    }
  }
  async updateSpiff() {
    this.spiffImportModalVisible = false;
    await this.fetchData();
  }

  selectAll(selectedAll = true) {
    this.selectedAll = selectedAll;
    this.selectedID = [];
    this.showFilter = 0;
    if (this.selectedAll == true) {
      for (var val of this.Spiffs) {
        if (!this.excludedIDs.includes(val.MSPIFF_CLAIMID)) {
          this.selectedID.push(val.MSPIFF_CLAIMID);
        }
      }
    } else {
      //uncheck
      this.excludedIDs = [];
    }
  }

  getSelectedTotal(total) {
    var ret = 0;
    if (this.selectedAll) {
      ret = total - this.excludedIDs.length;
    } else {
      ret = this.selectedID.length;
    }

    return ret;
  }

  checkExcludedIDs(spiffId) {
    if (this.selectedAll) {
      if (!this.selectedID.includes(spiffId)) {
        if (!this.excludedIDs.includes(spiffId)) {
          this.excludedIDs.push(spiffId);
        }
      } else if (this.excludedIDs.includes(spiffId)) {
        this.$delete(
          this.excludedIDs,
          this.excludedIDs.findIndex(id => id == spiffId)
        );
      }

      if (this.getSelectedTotal(this.details.TOTALCOUNT) == 0) {
        this.selectedAll = false;
        this.excludedIDs = [];
      }
    }
  }

  spiffCheckAllChange() {
    this.spiffCheckAll = !this.spiffCheckAll;
    if (!this.spiffCheckAll) {
      this.selectInPage(false);
    } else {
      this.selectInPage(true);
    }
  }

  selectInPage(selectedAllPage = true) {
    this.selectPageAll = selectedAllPage;
    this.showFilter = 0;
    if (this.selectPageAll == true) {
      for (var val of this.Spiffs) {
        if(this.selectedID.indexOf(val.MSPIFF_CLAIMID) == -1)  {
          this.selectedID.push(val.MSPIFF_CLAIMID);
        }
        this.$delete(
          this.excludedIDs,
          this.excludedIDs.findIndex((id) => id == val.MSPIFF_CLAIMID)
        );
      }
    } else {
      for (var val of this.Spiffs) {
        let selectedIDIndex = this.selectedID.indexOf(val.MSPIFF_CLAIMID);
        this.selectedID.splice(selectedIDIndex, 1);
      }
    }
  }

  async performExport(formats: ExportFormat[]) {
    this.loading = true;
    this.exportModalVisible = false;
    await this.exportHeaders();
    const exportFns: Function[] = [];

    if (formats.includes(ExportFormat.csv)) {
      exportFns.push(await this.csvDownLoadFn());
    }

    exportFns.forEach(fn => fn());

    this.loading = false;
  }

  async csvDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await spiffRepo.exportListsCSVFileUrl(
        this.selectedID,
        this.selectedAll,
        this.excludedIDs,
        this.filters,
        this.sort,
        this.viewType,
        this.selectedView,
        this.exportHeadersArr
      );
    } catch (err) {
      notifier.alert("Export CSV error: " + err.message);
    }

    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async exportHeaders() {
    var widthSizes = {
      "col-1": 1,
      "col-1-5": 1.5,
      "col-2": 2,
      "col-2-5": 2.5,
      "col-3": 3,
      "col-3-5": 3.5,
      "col-4": 4,
      "col-5": 5,
      "col-6": 6
    };
    this.exportHeadersArr = [];
    for (var val of this.selectedHeaders) {
      var cls = this.getColumnWidth(val).replace(" tar", "");
      cls = cls.replace(" lastSearchBox", "");
      var width = (widthSizes[cls] * 100) / 12 + "%";
      var tempObj = {
        header: val,
        column: this.pageHeaders[val],
        widthPercent: width
      };
      this.exportHeadersArr.push(tempObj);
    }
  }

  validFilters(query: object, position: object) {
    var arr: any[] = [];
    query["statusName"] = query["statusName"] ? query["statusName"] : "None";
    query["sourceName"] = query["sourceName"] ? query["sourceName"] : "None";
    const queryFilters = Object.entries(query).reduce((a, [k, v]) => {
      if (k == "sStatus" && $.inArray(-1, v) != -1) {
        v.splice($.inArray(-1, v), 1);
      }
      if ((k == "sStatus" || k == "statusName") && this.getDefaultSpiffStatuses().length) {
        return (v.length == this.getDefaultSpiffStatuses().length) ? a : { ...a, [k]: v };
      }
      if ((k == "sSource" || k == "sourceName") && this.details.sourcesObj) {
        return (v.length == Object.keys(this.details.sourcesObj).length) ? a : { ...a, [k]: v };
      }

      return v == null || v === "" || k == "unassigned" ? a : { ...a, [k]: v };
    }, {});
    $.each(queryFilters, function(key,valueObj){
      if(position[key] != undefined) {
        let tempStruct: any = {};
        tempStruct[key] = valueObj;
        tempStruct["position"] = position[key];
        arr.push(tempStruct);
      }
    });
    var sortable = Object.entries(arr).sort(function(a, b) {
      return a[1].position - b[1].position;
    });

    var validFilters: any[] = [];
    for(var i in sortable){
      let val = sortable[i]
      let lastItem = val[sortable[i].length - 1];
      let key = Object.keys(lastItem)[0];
      var value = Object.values(lastItem)[0];
      if(Array.isArray(value)) {
        value = value.join(" ,");
      }
      let tempFilterStruct: any = {};
      tempFilterStruct[key] = value;
      validFilters.push(tempFilterStruct);
    }
    return validFilters;
  }

  async clearFilter() {
    this.resetSearchBySKU(false);
    this.resetSearchBySpiffDate(false);
    this.resetSourceList(false);
    this.resetSearchByOrder(false);
    this.resetSearchByInvoiceDate(false);
    this.resetSearchByAccountName(false);
    this.resetTag(false);
    this.resetAcc(false);
    this.resetSearchByReward(false);
    this.resetSearchByQuantity(false);
    this.resetSearchByTotal(false);
    this.resetStatusList(false);
    this.resetSearchByVarOrder(false);
    this.filterModalVisible = false;
    await this.fetchData();
  }
}
