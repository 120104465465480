






























































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import CustomerAutoSuggestInput from "@/components/CustomerAutoSuggestInput.vue";
import ConfirmRegenerateCommissionModal from "@/components/ConfirmRemoveItemModal.vue";
import DropdownControl from "@/components/DropdownControl.vue";
import { notifier } from "../models/common";
import { ApiHelper } from "@/helpers/all";
import SecurityAutoSuggestInput from "@/components/SecurityAutoSuggestInput.vue";

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    CustomerAutoSuggestInput,
    ConfirmRegenerateCommissionModal,
    DropdownControl,
    SecurityAutoSuggestInput
  }
})

export default class ModalEditCommTarget extends TSXComponent<void> {

  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: []})
  empPlanAccountData!: any;

  @Prop({ required: false, default: {}})
  data!: any;

  @Prop({ required: false, default: 0 })
  targetsTotal!: number;

  @Prop({ required: false, default: "$0.00" })
  realizedProfitTotal!: string;

  @Prop({ required: false, default: [
    {
      ID: 0,
      TEXT: "All"
    }
  ]})
  businessLineOptions!: any;

  oteBase: string = "$0.00";
  oteCommission: string = "$0.00";
  oteTotal: string = "$0.00";
  adjustedProfitTotal: string = "$0.00";
  saving:boolean = false;
  allTargetsTotal: string = '0.00';
  empPlanAccountDataBK: any = [];
  filters = {
    account: '',
  };
  oteYear: string = "";
  isEditAdjustedTarget: boolean = false;
  confirmCommModalVisible: boolean = false;
  allowEditComm: boolean = false;
  selectedGroups: any = [];
  selectedBusinessLine: number[] = [];
  lastThreeYearsMarginTotal = '0.00';
  prevSelectedBusinessLine: number = 0;
  twelveMonthsProfitTotal = '0.00';

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });

  }

  created() {
    this.empPlanAccountDataBK = JSON.parse(JSON.stringify(this.empPlanAccountData));
    if (this.data.OTEBASE_FORMATTED) {
      this.oteBase = this.data.OTEBASE_FORMATTED;
    }
    if (this.data.OTECOMM_FORMATTED) {
      this.oteCommission = this.data.OTECOMM_FORMATTED;
    }
    if (this.data.TOTALOTE_FORMATTED) {
      this.oteTotal = this.data.TOTALOTE_FORMATTED;
    }
    if (this.data.COMP_TARGET_FORMATTED) {
      this.adjustedProfitTotal = this.data.COMP_TARGET_FORMATTED;
    }
    if (this.data.OTEYEAR) {
      this.oteYear = this.data.OTEYEAR;
    }
    let adjustedProfitTotal = this.data.COMP_TARGET || 0;
    if (this.targetsTotal != adjustedProfitTotal) {
        this.isEditAdjustedTarget = true;
        this.adjustedProfitTotal = `$${this.formatter(adjustedProfitTotal)}`;
      }
    if (this.empPlanAccountDataBK.length) {
      this.calculateTotal();
    }
  }

  calculateTotal() {
    const total = this.empPlanAccountDataBK.reduce((sum, {TARGET_PROFIT}) => {
      const numericValue = parseFloat(TARGET_PROFIT.toString().replace(/[^\d.-]/g, '')) || 0;
      return sum + numericValue;
    }, 0);

    const lastThreeYearsMargin = this.empPlanAccountDataBK.reduce((sum, {LASTTHREEYEARSMARGIN}) => {
      const numericValue = parseFloat(LASTTHREEYEARSMARGIN.toString().replace(/[^\d.-]/g, '')) || 0;
      return sum + numericValue;
    }, 0);

    const customerMarginCnt = this.empPlanAccountDataBK.reduce((count, {LASTTHREEYEARSMARGIN}) => {
      const numericValue = (parseFloat(LASTTHREEYEARSMARGIN.toString().replace(/[^\d.-]/g, '')) || 0) > 0 ? 1 : 0;
      return count + numericValue;
    }, 0);

    const twelveMonthsProfitTotal = this.empPlanAccountDataBK.reduce((sum, {TWELVEMONTHSPROFIT}) => {
      const numericValue = parseFloat(TWELVEMONTHSPROFIT.toString().replace(/[^\d.-]/g, '')) || 0;
      return sum + numericValue;
    }, 0);

    let lastThreeYearsMarginTotal = 0;
    if (customerMarginCnt > 0 && lastThreeYearsMargin != 0) {
      lastThreeYearsMarginTotal = (lastThreeYearsMargin/customerMarginCnt);
    }

    this.allTargetsTotal = this.formatter(total);
    this.lastThreeYearsMarginTotal = this.formatter(lastThreeYearsMarginTotal);
    this.twelveMonthsProfitTotal = this.formatter(twelveMonthsProfitTotal);
    if (!this.isEditAdjustedTarget) {
      this.adjustedProfitTotal = `$${this.formatter(total)}`;
    }
    this.checkAccount();
  }

  formatter(amount) {
    // Use Intl.NumberFormat to format the total as a currency string
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(amount);
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async save() {
    await this.checkAccount();
    var duplicateAccountExist = this.empPlanAccountDataBK.find(o => o.ISDUPLICATE === 1);
    if (duplicateAccountExist) {
      notifier.alert("Duplicate Account exists");
      return;
    }
    let empAccountData = this.empPlanAccountDataBK.filter((item: any) => item.AID);
    // if (!empAccountData.length) {
    //   notifier.alert("Add at least one Account");
    //   return;
    // }
    if (this.formatAmount(this.oteCommission) != this.data.OTECOMM && !this.allowEditComm && this.formatAmount(this.realizedProfitTotal) != 0) {
      this.confirmCommModalVisible = true;
      return;
    }
    this.saving = true;
    const response = await ApiHelper.callApi("post", {
      controller: "Finances",
      FunctionName: "EmployeePlanAdd",
      user_account: this.data.EMPLOYEEID || 0,
      oteBase: this.formatAmount(this.oteBase),
      oteCommission: this.formatAmount(this.oteCommission),
      oteYear: this.oteYear,
      empPlanAccount: empAccountData,
      allTargetsTotal: this.formatAmount(this.allTargetsTotal),
      adjustedProfitTotal: this.formatAmount(this.adjustedProfitTotal),
      action: "update",
      compUUID: this.data.COMPUUID || ""
    });

    if (response.STATUS === 1) {
      notifier.success(response.STATUSMESSAGE);
      this.$emit("reload");
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
  }

  formatAmount(n) {
    return n.replace(/[^0-9.]/g, "")
  }

  formatNumber(n) {
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  formatCurrency(event, id) {
    // Ensure value is treated as a string
    let input_val = String(event.target.value);

    // Extract and remove the dollar sign to handle numeric operations
    input_val = input_val.replace(/^\$/, "");

    if (input_val === "") {
      return '';
    }

    // Split the input value into two parts: before and after the decimal point
    let parts = input_val.split(".");
    let left_side = parts[0];
    let right_side = parts.length > 1 ? '.' + parts[1] : '';

    left_side = this.formatNumber(left_side);

    // Only format the right side if there's something to format
    if (right_side) {
      right_side = right_side.substring(0, 3); // Keep the decimal point and the next two digits
    }

    this[id] = `$${left_side}${right_side}`;
  }

  handleBlur(event, id) {
    let input_val = String(event.target.value);

    // Remove any existing dollar sign to avoid confusion in formatting
    input_val = input_val.replace(/^\$/, "");

    if (input_val === "") {
      return '';
    }

    // Check if the value includes a decimal point but no cents or incomplete cents
    let parts = input_val.split(".");
    if (parts.length < 2 || parts[1].length < 2) {
      // Append missing parts or pad with zeros to ensure two decimal places
      input_val = parts[0] + (parts.length < 2 ? ".00" : "." + parts[1].padEnd(2, '0'));
    } else {
      // Ensure two decimal places if user entered partial cents
      input_val = parts[0] + "." + parts[1].substring(0, 2);
    }

    // Re-add the dollar sign for consistent currency formatting
    this[id] = `$${input_val}`;
  }

  addInputRow() {
    let BLid = 0;
    if (this.prevSelectedBusinessLine) {
      BLid = this.prevSelectedBusinessLine;
    } else if (this.selectedBusinessLine.length) {
      BLid = this.selectedBusinessLine[0];
    }
    var empAccountNew = {
      LINKID: 0,
      SECURITYGROUPID: 0,
      COMPWEIGHT: 100,
      AID: "",
      ANAME: "",
      TARGET_PROFIT: 0,
      TARGET_PROFIT_FORMATTED: '$0.00',
      ISDUPLICATE: 0,
      BUSINESSLINEID: BLid,
      LASTTHREEYEARSMARGIN: 0,
      LASTTHREEYEARSMARGIN_FORMATTED: '$0.00',
      TWELVEMONTHSPROFIT: 0,
      TWELVEMONTHSPROFIT_FORMATTED: '$0.00',
      TARGET_PROFIT_BK: 0,
      TARGET_PROFIT_BK_FORMATTED: '$0.00',
      COMPWEIGHT_BK: 100
    };
    this.empPlanAccountDataBK.push(empAccountNew);
    this.filters.account = '';
  }

  updateCustomer(data, item) {
    this.checkAccount();
    item.TARGET_PROFIT_BK = data.account.TARGET_PROFIT || 0;
    item.TARGET_PROFIT_BK_FORMATTED = `$${this.formatter(data.account.TARGET_PROFIT || 0)}`;

    let new_target_profit = ((item.TARGET_PROFIT_BK * item.COMPWEIGHT_BK)/100);
    item.TARGET_PROFIT = new_target_profit;
    item.TARGET_PROFIT_FORMATTED = `$${this.formatter(new_target_profit)}`;

    item.LASTTHREEYEARSMARGIN = data.account.LASTTHREEYEARSMARGIN || 0;
    item.LASTTHREEYEARSMARGIN_FORMATTED = `$${this.formatter(data.account.LASTTHREEYEARSMARGIN || 0)}`;
    item.TWELVEMONTHSPROFIT = data.account.TWELVEMONTHSPROFIT || 0;
    item.TWELVEMONTHSPROFIT_FORMATTED = data.account.TWELVEMONTHSPROFIT_FORMATTED || '$0.00';
    this.calculateTotal();
  }

  deleteInputRow(index) {
    if (index < this.empPlanAccountDataBK.length) {
      this.empPlanAccountDataBK.splice(index, 1);
      this.calculateTotal();
      this.checkAccount();
      this.filters.account = '';
    }
  }

  get getOteTotal() {
    let oteTotal = parseFloat(this.formatAmount(this.oteBase)) + parseFloat(this.formatAmount(this.oteCommission));
    return `$${this.formatter(oteTotal)}`
  }

  checkAccount() {
    this.empPlanAccountDataBK.map((item: any) => {
      var filteredAccount = this.empPlanAccountDataBK.filter(checkDupItem => item.AID != "" && checkDupItem.AID != "" && checkDupItem.AID == item.AID && checkDupItem.BUSINESSLINEID == item.BUSINESSLINEID);
      item.ISDUPLICATE = 0;
      if (filteredAccount.length > 1) {
        item.ISDUPLICATE = 1;
      }
    });
    this.$forceUpdate();
  }

  async regenerateCommission() {
    this.confirmCommModalVisible = false;
    this.allowEditComm = true;
    await this.save();
  }

  updateBusinessLine(id, item) {
    item.BUSINESSLINEID = id;
    this.prevSelectedBusinessLine = id;
    this.empPlanAccountDataBK.map((item:any) => {
      if (!item.AID) {
        item.BUSINESSLINEID = id;
      }
    });
    this.loadAccountData(item);
  }

  removeChar(data, key) {
    if (typeof data[key] != 'undefined') {
      data[key] = data[key].replace(/[^0-9.-]/g, "");
      if (!data[key].trim().length) {
        data[key] = 0;
      }
      if (data[key] > 100) {
        data[key] = 100;
      } else if (data[key] < 0) {
        data[key] = 0;
      }
    }

    data["COMPWEIGHT_BK"] = data["COMPWEIGHT"];
    let new_target_profit = ((data["TARGET_PROFIT_BK"] * data["COMPWEIGHT_BK"])/100);
    data["TARGET_PROFIT"] = new_target_profit;
    data["TARGET_PROFIT_FORMATTED"] = `$${this.formatter(new_target_profit)}`;
    this.calculateTotal();
  }

  updateSecurityGroup(data) {
    if (data.securityGroups) {
      let sgID = data.securityGroups.BOARDUUID || data.securityGroups.SECURITYGROUPID || 0;
      const index = this.selectedGroups.findIndex((item: any) => item.SECURITYGROUPID === sgID);
      if (index === -1) {
        this.selectedGroups.push({
          SECURITYGROUPNAME: data.securityGroups.SECURITYGROUPNAME || '',
          SECURITYGROUPID: sgID
        });
        if (data.securityGroups.ACCOUNTS) {
          data.securityGroups.ACCOUNTS.map((item: any) => {
            const acc_index = this.empPlanAccountDataBK.findIndex((accItem: any) => accItem.AID != "" && accItem.AID == item.AID && accItem.BUSINESSLINEID == data.securityGroups.BUSINESSLINEID);
            if (acc_index === -1) {
              var empAccountNew = {
                LINKID: 0,
                SECURITYGROUPID: sgID,
                COMPWEIGHT: 100,
                AID: item.AID || 0,
                ANAME: item.ANAME || '',
                TARGET_PROFIT: item.TARGET_PROFIT || 0,
                TARGET_PROFIT_FORMATTED: `$${this.formatter(item.TARGET_PROFIT || 0)}`,
                ISDUPLICATE: 0,
                BUSINESSLINEID: data.securityGroups.BUSINESSLINEID || this.prevSelectedBusinessLine,
                LASTTHREEYEARSMARGIN: item.LASTTHREEYEARSMARGIN || 0,
                LASTTHREEYEARSMARGIN_FORMATTED: `$${this.formatter(item.LASTTHREEYEARSMARGIN || 0)}`,
                TWELVEMONTHSPROFIT: item.TWELVEMONTHSPROFIT || 0,
                TWELVEMONTHSPROFIT_FORMATTED: item.TWELVEMONTHSPROFIT_FORMATTED || '$0.00',
                TARGET_PROFIT_BK: item.TARGET_PROFIT || 0,
                TARGET_PROFIT_BK_FORMATTED: `$${this.formatter(item.TARGET_PROFIT || 0)}`,
                COMPWEIGHT_BK: 100
              };
              this.empPlanAccountDataBK.push(empAccountNew);
            }
          });
          this.calculateTotal();
        }
      }
    }
  }

  updateSGList(index, id) {
    this.$delete(this.selectedGroups, index);
    this.empPlanAccountDataBK = this.empPlanAccountDataBK.filter(item => item.SECURITYGROUPID !== id);
    this.calculateTotal();
  }

  updateAllBusinessLine(id) {
    this.selectedBusinessLine = [id];
    this.prevSelectedBusinessLine = id;
    this.empPlanAccountDataBK.map((item:any) => {
      item.BUSINESSLINEID = id;
    });
    this.updateAccountTarget();
  }

  async updateAccountTarget() {
    let empAccountData = this.empPlanAccountDataBK.filter((item: any) => item.AID);
    if (empAccountData.length) {
      const response = await ApiHelper.callApi("post", {
        FunctionName            : "GetAccountTargets",
        controller              : "Accounts",
        empPlanAccount          : empAccountData,
        targetYear              : this.oteYear,
      });

      if (response.STATUS === 1) {
        response.ACCOUNTS.map((acc: any) => {
          let empData = this.empPlanAccountDataBK.find((empAcc: any) => acc.AID == empAcc.AID && acc.BUSINESSLINEID == empAcc.BUSINESSLINEID && acc.COMPWEIGHT == empAcc.COMPWEIGHT);
          if (empData) {
            if (acc.AID == empData.AID) {
              empData.TARGET_PROFIT_BK = acc.TARGET_PROFIT || 0;
              empData.TARGET_PROFIT_BK_FORMATTED = `$${this.formatter(acc.TARGET_PROFIT || 0)}`;
  
              let new_target_profit = ((acc.TARGET_PROFIT * empData.COMPWEIGHT_BK)/100);
              empData.TARGET_PROFIT = new_target_profit;
              empData.TARGET_PROFIT_FORMATTED = `$${this.formatter(new_target_profit)}`;
  
              empData.LASTTHREEYEARSMARGIN = acc.LASTTHREEYEARSMARGIN || 0;
              empData.LASTTHREEYEARSMARGIN_FORMATTED = `$${this.formatter(acc.LASTTHREEYEARSMARGIN || 0)}`;
              empData.TWELVEMONTHSPROFIT = acc.TWELVEMONTHSPROFIT || 0;
              empData.TWELVEMONTHSPROFIT_FORMATTED = acc.TWELVEMONTHSPROFIT_FORMATTED || '$0.00';
            }
          }
        });
        this.calculateTotal();
      }
    }
  }

  async loadAccountData(item) {
    if (item.AID) {
      const response = await ApiHelper.callApi("post", {
        FunctionName            : "List",
        controller              : "Accounts",
        targetYear              : this.oteYear,
        lastThreeYearsMargin    : true,
        businessLineId          : item.BUSINESSLINEID || 0,
        aID                     : item.AID
      });

      if (response.STATUS === 1) {
        response.ACCOUNTS.map((acc: any) => {
          if (acc.AID == item.AID) {
            item.TARGET_PROFIT_BK = acc.TARGET_PROFIT || 0;
            item.TARGET_PROFIT_BK_FORMATTED = `$${this.formatter(acc.TARGET_PROFIT || 0)}`;

            let new_target_profit = ((acc.TARGET_PROFIT * item.COMPWEIGHT_BK)/100);
            item.TARGET_PROFIT = new_target_profit;
            item.TARGET_PROFIT_FORMATTED = `$${this.formatter(new_target_profit)}`;

            item.LASTTHREEYEARSMARGIN = acc.LASTTHREEYEARSMARGIN || 0;
            item.LASTTHREEYEARSMARGIN_FORMATTED = `$${this.formatter(acc.LASTTHREEYEARSMARGIN || 0)}`;
            item.TWELVEMONTHSPROFIT = acc.TWELVEMONTHSPROFIT || 0;
            item.TWELVEMONTHSPROFIT_FORMATTED = acc.TWELVEMONTHSPROFIT_FORMATTED || '$0.00';
          }
        });
        this.calculateTotal();
      }
    }
  }

}
