























































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import SortIndicator from "../components/SortIndicator.vue";
import { Component, Prop } from "vue-property-decorator";
import axios from "axios";

import SecurityGroupStatus, {
  GroupStatusValue
} from "../components/SecurityGroupStatus.vue";
import SecurityGroupRowTooltip, {
  Item
} from "../components/SecurityGroupRowTooltip.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import Pagination from "../components/Pagination.vue";

import {
  SecurityGroupAPIRepo,
  FewDetailsJustGet
} from "../repos/SecurityGroupAPIRepo";
import { notifier } from "../models/common";
import { UserAPIRepo } from "../repos/UserAPIRepo";

const securityGroupRepo = new SecurityGroupAPIRepo();
const userRepo = new UserAPIRepo();
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    SecurityGroupRowTooltip,
    SortIndicator,
    SecurityGroupStatus,
    ConfirmRemoveItemModal
  }
})
export default class SecurityGroups extends TSXComponent<void> {
  selectedID = [];
  istooltipOpen = false;

  confirmRemoveModalVisible = false;
  currentRole = sessionStorage.getItem("userRole");
  usersTooltipVisibleIndex = -1;
  usersTooltipLoading = false;
  usersTooltipList: Item[] = [];

  accountsTooltipVisibleIndex = -1;
  accountsTooltipLoading = false;
  accountsTooltipList: Item[] = [];

  functionsTooltipVisibleIndex = -1;
  functionsTooltipLoading = false;
  functionsTooltipList: Item[] = [];
  tableColumns: Item[] = [];
  details: {
    CURRENTPAGE: number;
    TOTALALERTS: number;
    TOTALPAGES: number;
    sDate: string;
    eDate: string;
    searchName: string;
    searchId: string;
  } = {
    CURRENTPAGE: 1,
    TOTALPAGES: 1,
    TOTALALERTS: 1,
    sDate: "",
    eDate: "",
    searchName: '',
    searchId: ''
  };
  loading = false;
  statusCheckList:number[] = [0,1];
  items = [];
  dataObj = {
    controller: "SecurityGroups",
    FunctionName: "List",
    order: 2,
    direction: 2,
    pageNumber: sessionStorage.pageDetails && JSON.parse(sessionStorage.pageDetails)["securityGroups"] ? JSON.parse(sessionStorage.pageDetails)["securityGroups"] : 1,
    Default_totalRows: 20,
    selectedView: false
  };
  groupTypes: any = [];
  defaultGroupType = "Account Group";
  defaultGroupId:number = 1;
  isFunctionExists = 0;
  isAccountExists = 0;

  /*  Export  */
  exportObj = {
    "controller": "SecurityGroups",
    "FunctionName": "Export",
    "ColumnsHeader": "Order ID,Account Name,Purchase Name,Total Price,PO ID,Quote ID,Status",
    "ColumnsName": "Purchases.purchaseID,AccountName,Purchases.poDescription,Purchases.poCustomerTotalPrice,Purchases.poID,Purchases.quoteID,statusDetail",
    "ColumnsHeaderLine": "Purchase Date,Purchase Time,Total Price,SKU,Prod Price",
    "ColumnsNameItem": "Purchases.poDateTime,Purchases.estcompercent,Purchases.poCustomerTotalPrice,PurchaseSecurityGroups_lineitems.poProdSku,PurchaseSecurityGroups_lineitems.poPriceReg",
    "uploadPath": "Inbound/apiFiles/",
    "keyName": "Inbound/apiFiles/",
    "userRole":sessionStorage.getItem('userRole'),
  };

  async created() {
    this.dataObj["status"] = '0,1';
    this.dataObj["maxRows"] = sessionStorage.getItem("maxRows");
    this.getGrouplist(this.dataObj, this, "", "", "new", this.defaultGroupId, this.defaultGroupType);
  }

  async getGrouplist(dataObj, container, keyName, fieldname, option, groupId, groupName) {
    this.tableColumns = [];
    dataObj["maxRows"] = sessionStorage.getItem("maxRows");
    dataObj["groupId"] = groupId;
    dataObj["view"] = groupName;
    this.loading = true;
    var response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
    //now attach a done handler!
    try {
      if (!response.data.ERROR) {
        this.items = response.data.GROUPS || [];
        this.details = response.data || {};
        this.groupTypes = response.data.GROUPTYPEARRAY;
        this.loading = false;
        this.defaultGroupId = parseInt(response.data.RETURNEDGROUPTYPE);
        this.defaultGroupType = response.data.userSelectedCurrentView ? response.data.userSelectedCurrentView : this.defaultGroupType;
        this.isFunctionExists = response.data.ISFUNCTIONEXISTS;
        this.isAccountExists = response.data.ISACCOUNTEXISTS;

        if (option == "page") {
          if (dataObj["sDate"] != "" || dataObj["eDate"] != "") {
            this.details.sDate = dataObj["sDate"];
            this.details.eDate = dataObj["eDate"];
          }
        } else {
          if (option === "new") {
            this.details["searchName"] = "";
            this.details["searchId"] = "";
            // this.details['sortingId'] = 1;
            // this.details['sortingName'] = 1;
            // this.details['sortingReseller'] = 1;
            // this.details['sortingTotal'] = 1;
            // this.details['sortingDate'] = 1;
          } else {
            this.details[keyName] = dataObj[fieldname];
          }
        }
      } else if (response.data.ERROR) {
        console.log(response.data.ERROR);
      }
    } catch (e) {
      //handle error
      console.log("security-groups.js : this.getGrouplist error : ", e);
    } finally {
      this.loading = false;
    }
  }

  searchChange(searchData) {
    this.searchByAll(searchData);
  }

  showConfirmRemoveItemModal() {
    this.confirmRemoveModalVisible = true;
  }
  updateDate(id, value) {
    if (value != "") {
      $(".selectAll.left." + id).removeClass("disableCursor");
      $(".reset-list.right").removeClass("disableCursor");
    } else {
      $(".selectAll.left." + id).addClass("disableCursor");
      $(".reset-list.right").addClass("disableCursor");
    }
  }
  updateValue(id, value) {
    if (value != "") {
      $(".search-list.right#" + id).removeAttr("style");
      $(".reset-list.right").removeClass("disableCursor");
    } else {
      $(".search-list.right#" + id).attr("style", "cursor:not-allowed");
      $(".reset-list.right").addClass("disableCursor");
      this.resetSearchByName();
    }
  }
  async removeSelected() {
    this.loading = true;
    this.confirmRemoveModalVisible = false;
    if (this.selectedID.length > 0) {
      var selectedIDs = [];
      for (let i = 0; i < this.selectedID.length; i++) {
        selectedIDs.push(this.selectedID[i]);
      }
      const deleteStatus = 2;
      this.changeStatus(selectedIDs, deleteStatus);
      this.selectedID = [];
    }
    this.loading = false;
  }

  setTooltipPosition(tooltipRef) {
    var tooltipHeight = $(this.$refs[tooltipRef]).offset().top - $(window).scrollTop();
      var bottom = $(this.$refs[tooltipRef]).offset().top - $(window).scrollTop() + 180;
      var container = $("body").height();
      bottom = container - bottom;

      if (bottom <= tooltipHeight && $("#page-list .page-list-container tbody tr").length > 3) {
        if (!$(this.$refs[tooltipRef]).parent().hasClass("toTop")) {
          $(this.$refs[tooltipRef]).parent().addClass("toTop");
        }
      } else {
        $(this.$refs[tooltipRef]).parent().removeClass("toTop");
      }
  }

  async showTooltipUsers(item, index) {
    if(!this.istooltipOpen){
      if (!item.USERS) return;
  
      this.setTooltipPosition(`userTooltipRef${index}`);
      this.usersTooltipVisibleIndex = index;
  
      try {
        this.usersTooltipLoading = true;
        const details = await securityGroupRepo.findOneFewDetails(
          item.SECURITYGROUPID,
          FewDetailsJustGet.Users
        );
  
        this.usersTooltipList = details.securityUsersArr.map(item => ({
          id: item.USERID,
          text: item.UNAME
        }));
  
        this.usersTooltipLoading = false;
        this.istooltipOpen = true;
      } catch (err) {
        notifier.alert(err.message);
        this.usersTooltipVisibleIndex = -1;
      }
    }
  }
  hideTooltipUsers(item, index) {
    this.istooltipOpen = false;
    this.usersTooltipVisibleIndex = -1;
  }

  getListWithGrouptype(value) {
    this.dataObj["pageNumber"] = 1;
    this.dataObj["selectedView"] = true;
    var pageNumbers = JSON.parse(sessionStorage.pageDetails);
    pageNumbers.securityGroups = 1;
    sessionStorage.setItem("pageDetails", JSON.stringify(pageNumbers));
    if (value == 1) {
      this.defaultGroupType = "Account Group";
    } else if (value == 2) {
      this.defaultGroupType = "Function Group";
    }
    this.getGrouplist(this.dataObj, this, "", "", "new", value, this.defaultGroupType);
  }

  async showTooltipAccounts(item, index) {
    if(!this.istooltipOpen){
      if (!item.ACCOUNTS) return;
  
      this.setTooltipPosition(`accountTooltipRef${index}`);
      this.accountsTooltipVisibleIndex = index;
  
      try {
        this.accountsTooltipLoading = true;
        const details = await securityGroupRepo.findOneFewDetails(
          item.SECURITYGROUPID,
          FewDetailsJustGet.Accounts
        );
  
        this.accountsTooltipList = details.securityAccountsArr.map(item => ({
          id: item.AID,
          text: item.ANAME
        }));
  
        this.accountsTooltipLoading = false;
        this.istooltipOpen = true;
      } catch (err) {
        notifier.alert(err.message);
        this.accountsTooltipVisibleIndex = -1;
      }
    }
  }
  hideTooltipAccounts(item, index) {
    this.accountsTooltipVisibleIndex = -1;
    this.istooltipOpen = false;

  }

  async showTooltipFunctions(item, index) {
    if(!this.istooltipOpen){
      if (!item.FUNCTIONS) return;
  
      this.setTooltipPosition(`funcTooltipRef${index}`);
      this.functionsTooltipVisibleIndex = index;
  
      try {
        this.functionsTooltipLoading = true;
        const details = await securityGroupRepo.findOneFewDetails(
          item.SECURITYGROUPID,
          FewDetailsJustGet.Functions
        );
  
        this.functionsTooltipList = details.securityFunctionsArr.map(item => ({
          id: item.SECURITYFUNCTIONID,
          text: item.SECURITYFUCTIONNAME
        }));
  
        this.functionsTooltipLoading = false;
        this.istooltipOpen = true;

      } catch (err) {
        notifier.alert(err.message);
        this.functionsTooltipVisibleIndex = -1;
      }
    }
  }
  hideTooltipFunctions(item, index) {
    this.functionsTooltipVisibleIndex = -1;
    this.istooltipOpen = false;
  }

  async changeStatus(groupId, status: GroupStatusValue) {
    this.loading = true;
    const statusID =
      status == GroupStatusValue.Open
        ? "1"
        : status == GroupStatusValue.Close
        ? "0"
        : status;
    try {
      var dataObj = {
        controller: "SecurityGroups",
        FunctionName: "Edit",
        StatusID: statusID
      };
      if (Array.isArray(groupId)) {
        dataObj["selectedIDs"] = groupId;
        dataObj["pageNumber"] = sessionStorage.pageDetails && JSON.parse(sessionStorage.pageDetails)["securityGroups"] ? JSON.parse(sessionStorage.pageDetails)["securityGroups"] : 1;
      } else {
        dataObj["groupID"] = groupId;
      }
      this.loading = true;
      var result = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
      if (result.data.STATUS == 1) {
        notifier.success(result.data.MESSAGE);
        $(".actionsBtn")
          .addClass("gray")
          .removeClass("teal");
        $(".actionsBtn .total-selected").text("");
        $(".menu .total-selected span").text("");
        if(result.data.CURRENTPAGE) {
          if(sessionStorage.pageDetails != undefined && JSON.parse(sessionStorage.pageDetails)["securityGroups"]){
            var oldData = JSON.parse(sessionStorage.pageDetails);
            oldData["securityGroups"] = result.data.CURRENTPAGE;
            sessionStorage.setItem('pageDetails',JSON.stringify(oldData));
          }
          this.dataObj["pageNumber"] = result.data.CURRENTPAGE;
        }
      } else {
        notifier.alert(result.data.MESSAGE);
      }
      //reload data list
      this.reloadData();
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
  }
  // from Static.js
  sortingById(){
    this.dataObj["order"] = 1;
    this.dataObj["direction"] = parseInt(this.details['sortingId']) === 1 ? 2 : 1;
    this.getGrouplist(this.dataObj, this, 'sortingId', 'direction', '', this.defaultGroupId, this.defaultGroupType);
  }

  checkStatusList(value:number) {
    var index = this.statusCheckList.indexOf(value);
    if (index < 0) {
        this.statusCheckList.push(value);
    } else {
        this.statusCheckList.splice(index, 1);
    }
    this.dataObj["status"] = this.statusCheckList.toString();
    if(!this.statusCheckList.length){
      this.dataObj["status"] = '-1';
    }
    this.getGrouplist(this.dataObj, this, 'statusCheckListStr', 'status', '', this.defaultGroupId, this.defaultGroupType);
  }
  checkAllStatusList() {
    this.statusCheckList = [0,1];
    this.dataObj["status"] = this.statusCheckList.toString();
    var items:any = document.getElementsByName('statusCheckBox');
    for (var i = 0; i < items.length; i++) {
        if (items[i].type === 'checkbox')
            items[i].checked = true;
    }
    this.getGrouplist(this.dataObj, this, 'statusCheckListStr', 'status', '', this.defaultGroupId, this.defaultGroupType);
  }
  resetStatusList() {
    this.statusCheckList = []
    this.dataObj["status"] = this.statusCheckList.toString();
    var items:any = document.getElementsByName('statusCheckBox');
    for (var i = 0; i < items.length; i++) {
        if (items[i].type === 'checkbox')
            items[i].checked = false;
    }
    this.getGrouplist(this.dataObj, this, 'statusCheckListStr', 'status', '', this.defaultGroupId, this.defaultGroupType);
  }
  loadPage(pageNumber:number, type) {
    if(type == "prev"){
      pageNumber = pageNumber - 1;
    }
    else if(type == "next"){
      pageNumber = pageNumber + 1;
    }
    if(sessionStorage.pageDetails != undefined && JSON.parse(sessionStorage.pageDetails)["securityGroups"]){
      var oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["securityGroups"] = pageNumber;
      sessionStorage.setItem('pageDetails',JSON.stringify(oldData))
    }

    if(pageNumber <= this.details.TOTALPAGES && pageNumber >= 1){
      this.dataObj["pageNumber"] = pageNumber;
      this.getGrouplist(this.dataObj, this, '', '', 'page', this.defaultGroupId, this.defaultGroupType);
    }
  }

  pagintionDropdown() {
    if($(".paginationDropDown").hasClass('displayNone')){
      $(".paginationDropDown").removeClass('displayNone').animate({"top": "-" + $(".paginationDropDown").height(),}, 300);
      $("#currPage").css('opacity', 0);
    }
    else{
      $(".paginationDropDown").animate({"top": 0,}, 300, function(){$(".paginationDropDown").addClass('displayNone')});
      $("#currPage").css('opacity', 1);
    }
  }

  //go to search page
  gotoSearch(searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for/quotes"
    });
  }

  reloadData() {
    this.getGrouplist(this.dataObj, this, '', '', '', this.defaultGroupId, this.defaultGroupType);
  }

  showModal(show) {
    if(show == true){
      $(".modal").addClass('b');
    }
    else{
      $(".modal").removeClass('b');
    }
  }

  csvDownLoadFn(selectedID) {
    this.exportObj['ExportType'] = 'CSV';
    this.exportObj['selectedID'] = selectedID;
    this.downloadProcess(this.exportObj, this);
  }

  pdfDownLoadFn(selectedID) {
      this.exportObj['ExportType'] = 'PDF';
      this.exportObj['selectedID'] = selectedID;
      this.downloadProcess(this.exportObj, this);
  }
  htmlDownLoadFn(selectedID) {
      this.exportObj['ExportType'] = 'HTML';
      this.exportObj['selectedID'] = selectedID;
      this.downloadProcess(this.exportObj, this);
  }

  printFn(selectedID) {
      // exportObj["purchaseID"] = $route.params.id;
      this.exportObj['selectedID'] = selectedID;
      this.exportObj["ExportType"] = 'PDF';
      this.exportObj['Print'] = 'Yes';
      this.exportObj['ColumnsHeaderLine'] = 'Purchase Date,Purchase Time,Total Price,SKU,Prod Price';
      this.exportObj['ColumnsNameItem'] = 'Purchases.poDateTime,Purchases.estcompercent,Purchases.poCustomerTotalPrice,PurchaseSecurityGroups_lineitems.poProdSku,PurchaseSecurityGroups_lineitems.poPriceReg';
      printProcess(this.exportObj);
  }

  /* Search  */

  searchByName() {
      this.dataObj["sGroupsName"] = this.details.searchName;
      this.dataObj["pageNumber"] = 1;
      if(this.details.searchName == ''){
        // empty
      }else{
          this.getGrouplist(this.dataObj, this, 'searchName', 'sGroupsName', '', this.defaultGroupId, this.defaultGroupType);
      }
  }
  searchById() {
      this.dataObj["sGroupID"] = this.details.searchId;
      this.getGrouplist(this.dataObj, this, 'searchId', 'sGroupID', '', this.defaultGroupId, this.defaultGroupType);
  }
  searchByAll(searchData) {
      this.dataObj["sSearchAll"] = searchData;
      this.getGrouplist(this.dataObj, this, 'searchAll', 'sSearchAll', '', this.defaultGroupId, this.defaultGroupType);
  }
  searchByDate() {
      this.dataObj.pageNumber = 1
      this.dataObj["sDate"] = this.details.sDate;
      this.dataObj["eDate"] = this.details.eDate;
      if((this.details.sDate == undefined || this.details.sDate ==  '') && (this.details.eDate == '' || this.details.eDate == undefined)) {
          // notification('alert','Please Fill From or To Date');
      } else if(!document!.querySelector<HTMLInputElement>('#sDate')!.validity!.valid || !document!.querySelector<HTMLInputElement>('#eDate')!.validity!.valid ){
          var errDate = '';
          if(!document!.querySelector<HTMLInputElement>('#sDate')!.validity!.valid){
            console.log($('#sDate').attr('aria-invalid'));
            errDate += 'start'
          }
          if(!document!.querySelector<HTMLInputElement>('#eDate')!.validity!.valid ){
            if(errDate.length){
              errDate += ' & '
            }
            errDate += ' end '
          }
          notifier.alert('Please Fill Valid '+errDate+' Date')
      }
      else {
          this.getGrouplist(this.dataObj, this, '', '', 'page', this.defaultGroupId, this.defaultGroupType);
      }
  }
  resetSearchByName() {
      $('.search-list').attr('style','cursor:not-allowed');
      $('.reset-list.right').addClass('disableCursor');
      this.details.searchName = '';
      // document.getElementById('searchName').value = '';
      this.dataObj["sGroupsName"] = '';
      this.getGrouplist(this.dataObj, this, 'searchName', 'sGroupsName', '', this.defaultGroupId, this.defaultGroupType);
  }
  // resetSearchById() {
  //     document.getElementById('searchId').value = '';
  //     this.details.searchId = '';
  //     this.dataObj["sGroupID"] = '';
  //     this.getGrouplist(this.dataObj, this, 'searchId', 'sGroupID', '');
  // }
  resetSearchByDate() {
      $('.selectAll.left.eDate').addClass('disableCursor');
      $('.reset-list.right').addClass('disableCursor');
      this.dataObj.pageNumber = 1
      this.details.sDate = "";
      this.details.eDate = "";
      $('#sDate').val('');
      $('#eDate').val('');
      this.dataObj["sDate"] = this.details.sDate;
      this.dataObj["eDate"] = this.details.eDate;
      this.getGrouplist(this.dataObj, this, '', '', 'page', this.defaultGroupId, this.defaultGroupType);
  }

  sortingByName() {
      this.dataObj["order"] = 2;
      this.dataObj["direction"] = parseInt(this.details['sortingName']) === 1 ? 2 : 1;
      this.getGrouplist(this.dataObj, this, 'sortingName', 'direction', '', this.defaultGroupId, this.defaultGroupType);
  }
  sortingUsers() {
      this.dataObj["order"] = 3;
      this.dataObj["direction"] = parseInt(this.details['sortingUsers']) === 1 ? 2 : 1;
      this.getGrouplist(this.dataObj, this, 'sortingUsers', 'direction', '', this.defaultGroupId, this.defaultGroupType);
  }
  sortingAccounts() {
      this.dataObj["order"] = 4;
      this.dataObj["direction"] = parseInt(this.details['sortingAccounts']) === 1 ? 2 : 1;
      this.getGrouplist(this.dataObj, this, 'sortingAccounts', 'direction', '', this.defaultGroupId, this.defaultGroupType);
  }
  sortingFuncs(){
      this.dataObj["order"] = 5;
      this.dataObj["direction"] = parseInt(this.details['sortingFuncs']) === 1 ? 2 : 1;
      this.getGrouplist(this.dataObj, this, 'sortingFuncs', 'direction', '', this.defaultGroupId, this.defaultGroupType);
  }
  sortingByDate(){
      this.dataObj["order"] = 6;
      this.dataObj["direction"] = parseInt(this.details['sortingDate']) === 1 ? 2 : 1;
      this.getGrouplist(this.dataObj, this, 'sortingDate', 'direction', '', this.defaultGroupId, this.defaultGroupType);
  }
  async downloadProcess(data, container){
    var response = await axios.post(dataURL + "?ReturnType=JSON", data);
    try {
      if (response.data.STATUS) {
        var link = document.createElement("a");
        link.download = 'SecurityGroups';
        link.href = response.data.S3URL;
        link.click();
      } else if (response.data.ERROR) {
        console.log(response.data.ERROR);
      }
    }
    catch (e) {
      //handle error
      console.log('security-groups.js : downloadProcess error : ', e);
    }
  }
}
