





































































































































































import axios from "axios";
import SortIndicator from "../components/SortIndicator.vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import Pagination from "../components/Pagination.vue";
import { notifier } from "../models/common";
import SecurityGroupRowTooltip, {
  Item
} from "../components/SecurityGroupRowTooltip.vue";
import { colsResizeable } from "@/helpers/ApiHelper";

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    ConfirmRemoveItemModal,
    SecurityGroupRowTooltip,
    SortIndicator
  }
})
export default class AccountTags extends TSXComponent<void> {
  selectedID = [];
  loading = false;
  details: any = {};
  items = [];
  usersTooltipLoading = false;
  istooltipOpen = false;
  pageNumber = 1;
  sort = {
    field: null,
    direction: {
      tagName: 1,
      totalAccounts: 1
    }
  };
  directionField = "";
  usersTooltipList: Item[] = [];
  filters: any = {
    tagName: ""
  };
  usersTooltipVisibleIndex = -1;
  confirmRemoveModalVisible = false;
  searchFilters: any = {
    tagName: ""
  };

  async created() {
    this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Customers",
        FunctionName: "AccountTagsList",
        maxRows:
          sessionStorage.getItem("maxRows") != ""
            ? sessionStorage.getItem("maxRows")
            : getMaxRows(),
        pageNumber: this.pageNumber,
        tagName: this.filters.tagName,
        order: this.sort.field ? this.sort.field : 0,
        direction:
          this.directionField != ""
            ? this.sort.direction[this.directionField]
            : 0
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1 && response.data.STATUSMESSAGE) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      if (response.data.STATUS == 1) {
        this.details = response.data;
        this.items = this.details.ACCOUNTTAGS;
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }

    colsResizeable({ms: 500});
  }

  loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (this.pageNumber <= this.details.TOTALPAGES && this.pageNumber >= 1) {
      this.fetchData();
    }
  }

  async sortBy(field, directionField) {
    this.sort.field = field;
    this.sort.direction[directionField] =
      this.sort.direction[directionField] == 1 ? 2 : 1;
    this.directionField = directionField;

    //reset
    for (const dirField in this.sort.direction) {
      if (dirField === directionField) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  searchBy(field) {
    if (this.searchFilters[field] == "") {
      return;
    }
    this.pageNumber = 1;
    this.filters[field] = this.searchFilters[field];
    this.fetchData();
  }

  resetBy(field) {
    if (this.searchFilters[field] == "") {
      return;
    }
    this.pageNumber = 1;
    this.filters[field] = this.searchFilters[field] = "";
    this.fetchData();
  }

  updateValue(field) {
    if (this.searchFilters[field] == "") {
      this.pageNumber = 1;
      this.fetchData();
    }
  }

  async removeSelected() {
    var refModal: any = this.$refs.confirmRemoveModal;
    refModal.deleting = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Customers",
        FunctionName: "DeleteAccountTag",
        AccountTagIDs: this.selectedID.join(",")
      });

      if (response.data.ERROR) {
        refModal.deleting = "error";
        throw new Error(response.data.ERROR);
      }

      if (response.data.STATUS == 1) {
        refModal.deleting = false;
        this.selectedID = [];
        this.fetchData();
      }
    } catch (err) {
      refModal.deleting = "error";
    } finally {
      this.confirmRemoveModalVisible = false;
    }
  }

  async showTooltipUsers(item, index,cnt) {
    if (!this.istooltipOpen && cnt) {
      this.usersTooltipVisibleIndex = index;

      try {
        this.usersTooltipLoading = true;
        const details = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Customers",
          FunctionName: "tagAccounts",
          tagid: item.ACCOUNTTAGID
        });
        this.usersTooltipList = details.data.ACCOUNTS.map(item => ({
          id: item.ACCOUNTID,
          text: item.ANAME
        }));

        this.usersTooltipLoading = false;
        this.istooltipOpen = true;
      } catch (err) {
        notifier.alert(err.message);
        this.usersTooltipVisibleIndex = -1;
      }
    }
  }
  hideTooltipUsers(item, index) {
    this.istooltipOpen = false;
    this.usersTooltipVisibleIndex = -1;
  }
}
