












































































































































































































































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { roundCents } from "../models/common";
import directives from "../helpers/directives";

declare const $: any;

export interface Summary {
  profit: number;
  estPercent: number;
  totalShipping: number;
  qShip?: number;
  totalTax: number;
  qTax?: number;
  itemTotal: number;
  grandTotal: number;
  includeVarCost: boolean;
}

interface Props {
  summary: Summary;
}

interface Events {
  onSummaryChange: Summary;
}

@Component({
  inheritAttrs: false,
  directives
})
export default class OrderFormSummary extends TSXComponent<Props, Events> {
  $parent: any;
  //allowEst = false;
  @Prop({ required: true })
  summary!: Summary;
  totalvarShipping:number = 0;
  profitAmount:number = 0;
  ProfitVarCost:number = 0;

  //edit(type,allowhide = 0) {
  edit(type) {
    if (
      $("li." + type)
        .find("input")
        .hasClass("displayNone")
    ) {
      //if(!allowhide){
        $("li." + type)
          .find("strong")
          .addClass("displayNone");
        $("li." + type)
          .find("input")
          .removeClass("displayNone");
        
        //auto select
        $("li." + type).find("input").focus().select();

        if(type == "shipping")
          $("li." + type).find(".costInclude").removeClass("displayNone");

      //}
      // if(type == "estPercent"){
      //   var validItems = 0;
      //   var priceCnt = 0;
      //   var totalMargin = 0;
      //   $.each(
      //     this.$parent.$refs.orderFormLineItemList.getItemNames(),
      //     function(i, val) {
      //       totalMargin += parseInt(val.margin);
      //       if(val.customerPrice > 0 && val.price > 0){
      //         priceCnt += 1;
      //       }
      //       if((val.customerPrice > 0 || val.price > 0) && val.name != "" &&  val.sku != "" &&  val.quantity != 0 )
      //         validItems += 1;
      //     }
      //   );
      //   if(priceCnt == validItems && validItems && priceCnt && totalMargin){
      //     this.allowEst = true;
      //     this.summary.estPercent = 0;
      //   }else{
      //     this.allowEst = false;
      //   }
      // }
    } else {
      $("li." + type)
        .find("input")
        .addClass("displayNone");
      $("li." + type)
        .find("strong")
        .removeClass("displayNone");

      //recalculate in case re-click edit icon to hide textbox tax/ship
      if (type == "tax" || type == "shipping" || type == "estPercent") {
        this.updateTotalTaxShip(event, type, true);
      }
      if(type == "shipping")
        $("li." + type).find(".costInclude").addClass("displayNone");
    }
  }

  costInclude() {
    if($("li.shipping .costInclude .smartCheckBox").hasClass("checkbox_checked")) {
      this.summary.includeVarCost = false;
      $("li.shipping .costInclude .smartCheckBox").removeClass("checkbox_checked").addClass("checkbox_unchecked");
    }
    else {
      this.summary.includeVarCost = true;
      $("li.shipping .costInclude .smartCheckBox").removeClass("checkbox_unchecked").addClass("checkbox_checked");
    }
  }

  closeInput(type) {
    $("li." + type)
      .find("input")
      .addClass("displayNone");
    $("li." + type)
      .find("strong")
      .removeClass("displayNone");

    if (type == "tax" || type == "shipping" || type == "estPercent") {
      this.updateTotalTaxShip(event, type, true);
    }

    if(type == "globalMargin") {
      // auto confirm global margin
      this.$parent.confirmedGlobalMargin = (this.$parent.globalMargin != this.$parent.globalMarginBK ? true : this.$parent.confirmedGlobalMargin);
      this.$parent.$refs.orderFormLineItemList.globalMarginChanged(null, true);
    }
    if(type == "shipping")
      $("li." + type).find(".costInclude").addClass("displayNone");

    if (type == "tax" || type == "shipping") {
      this.$parent.$refs.orderFormLineItemList.reCalculateGlobalMargin();
    }
  }

  updateTotalTaxShip(event, type, forceUpdate = false) {
    var comInstance = this;
    if (event.key == "Enter" || event.key == "Tab" || forceUpdate) {
      var tax = $("li.tax input").val() != "" ? $("li.tax input").val() : 0;
      var shipping =
        $("li.shipping input").val() != "" ? $("li.shipping input").val() : 0;
      var estPercent =
        $("li.estPercent input").val() != ""
          ? $("li.estPercent input").val()
          : 0;
      this.summary.grandTotal = roundCents(
        this.summary.itemTotal + parseFloat(tax) + parseFloat(shipping)
      );
      this.summary.qTax = roundCents(tax);
      this.summary.qShip = roundCents(parseFloat(shipping));

      this.summary.totalTax = roundCents(tax);
      this.summary.totalShipping = roundCents(parseFloat(shipping));

      this.summary.estPercent = parseFloat(estPercent);
      //var profit = (this.summary.itemTotal * parseFloat(estPercent))/100;
      //this.summary.profit = parseFloat(profit);

      if (parseFloat(estPercent) > 0) {
        //if user inputted an EST %
        this.summary.profit =
          (this.summary.itemTotal * parseFloat(estPercent)) / 100;
      } else {
        var profit = 0;
        $.each(
          this.$parent.$refs.orderFormLineItemList.getItemNames(),
          function(i, val) {
            if (!val.included) {
              profit += val.margin * val.quantity;
            }
            if(val.isFee == true) {
              profit = profit - val.price;
            }
          }
        );
        this.summary.profit = profit;
      }

      $("li." + type)
        .find("input")
        .addClass("displayNone");
      $("li." + type)
        .find("strong")
        .removeClass("displayNone");

      this.$emit("summaryChange", this.summary);

      //case: user press TAB from custom tax box, go to custom shipping box so that user can input something there
      if (event.key == "Tab" && type == "tax") {
        $("li.shipping")
          .find("strong")
          .addClass("displayNone");
        $("li.shipping")
          .find("input")
          .removeClass("displayNone");
        setTimeout(() => {
          $("li.shipping")
            .find("input")
            .focus()
            .select();
        }, 100);
      }

      if (event.key == "Tab" && type == "estPercent") {
        event.preventDefault();
        $("li.tax").find("strong").addClass("displayNone");
        $("li.tax").find("input").removeClass("displayNone");
        setTimeout(() => {
          $("li.tax").find("input").focus().select();
        }, 100);
      }

      /**
       * case: press TAB or Enter on bottom textbox EST%
       * set margin to 0 on line items for some cases
       */
      if (type == "estPercent") {
        if (parseFloat(estPercent) > 0) {
          //if user inputted an EST %
          $.each(
            this.$parent.$refs.orderFormLineItemList.getItemNames(),
            function(i, val) {
              //save current margin/cost per/price per
              var currentMargin = val.customerPrice - val.price;
              if (currentMargin != 0) {
                //cost per and price per are different
                val.prevMargin = currentMargin;
                val.prevPrice = val.price;
                val.prevCustomerPrice = val.customerPrice;
              }

              //set cost per = price per
              //val.price = val.customerPrice;
              // comment out for not change any values on ext update
              // val.customerPrice = val.price;
              // comment out the line for not to update margin when update ESt
              // comInstance.$parent.$refs.orderFormLineItemList.marginCalc1(i);

              //reset margin
              // Comment out the lin for john comments
              // val.margin = 0;
            }
          );
        } else {
          //restore backup daata
          $.each(
            this.$parent.$refs.orderFormLineItemList.getItemNames(),
            function(i, val) {
              if (val.price == val.customerPrice) {
                //just restore in case cost per = price per
                //restore for previous saving
                val.margin =
                  typeof val.prevMargin != "undefined" && val.prevMargin != 0
                    ? val.prevMargin
                    : val.margin;
                val.price =
                  typeof val.prevPrice != "undefined" && val.prevPrice != 0
                    ? val.prevPrice
                    : val.price;
                val.customerPrice =
                  typeof val.prevCustomerPrice != "undefined" &&
                  val.prevCustomerPrice != 0
                    ? val.prevCustomerPrice
                    : val.customerPrice;
                comInstance.$parent.$refs.orderFormLineItemList.marginCalc1(i);

                //reset backup data
                val.prevMargin = 0;
                val.prevPrice = 0;
                val.prevCustomerPrice = 0;
              }else {
                let tmpCustomerPrice = (isNaN(val.customerPrice) ? 0 : val.customerPrice);
                let tmpPrice = (isNaN(val.price) ? 0 : val.price);
                val.margin = (val.customerPrice - val.price).toFixed(2);
              }
            }
          );

          //recalculate profit
          // var profit = 0;
          // $.each(this.$parent.$refs.orderFormLineItemList.getItemNames(), function(i, val) {
          //   if(!val.included) {
          //     profit += val.margin * val.quantity;
          //   }
          // });
          // this.summary.profit = profit;
        }
      }
    }
  }

  //run when user inputs a custom tax
  updateUseCustomTaxFlag() {
    this.$parent.USECUSTOMTAX = 0;
    if (this.summary.totalTax != 0) {
      this.$parent.USECUSTOMTAX = 1;
    }

    // set tax rate = 0 if user want to use a custom tax
    if(this.$parent.USECUSTOMTAX || this.summary.totalTax == 0) {
      this.$parent.currentTaxRate = 0;
    }
  }

  estPercentInput() {
    var estPercent = parseFloat($("li.estPercent input").val());
    if (estPercent > 100) {
      this.summary.estPercent = 100;
    }
  }

  //check if should enable/disable EST%
  disabledEST() {
    var totalMargin = 0;
    var itemNames = this.$parent.$refs.orderFormLineItemList.getItemNames();
    var doDisable = false;
    for(var i in itemNames) {
      let item = itemNames[i];
      // let tmpCost = (item.price != "" ? item.price : 0);
      // let tmpPricePer = (item.customerPrice != "" ? item.customerPrice : 0);
      let tmpMargin = (item.margin != "" ? item.margin : 0);
      if(item.included == 0 && tmpMargin != 0) {
        //totalMargin += parseFloat(item.margin != "" ? item.margin : 0);
        doDisable = true;
        break;
      }
    }

    if(doDisable) {
      //Force to use EST% DEFAULT from Global params
      // this.$parent.summary.estPercent = 0;
      return true;
    }

    return false;
  }

  selectAllText(id) {
    setTimeout(() => {
      $("#" + id).select();  
    }, 100);
  }

  titleCost(title,shipping) {
    if(shipping == '') {
      shipping = 0;
    }
    if(this.summary.includeVarCost == false)
      var titleText = title;
    else
      var titleText = title + parseFloat(shipping);
      this.ProfitVarCost = titleText;
    return titleText;
  }
  // titleProfit(profitTitle,shippingValue) {
  //   let profitText: any = 0;
  //   if(this.summary.includeVarCost == false) {
  //     profitText = profitTitle; 
  //   } else {
  //     if (isNaN(profitTitle)) {
  //       if(parseFloat(profitTitle.replace(/\$/g, '')) < 0) {
  //         profitText = -(parseFloat(shippingValue) + parseFloat(profitTitle.replace(/[^\d.]/g, '')));
  //       } else {
  //         profitText = parseFloat(profitTitle.replace(/[^\d.]/g, '')) - parseFloat(shippingValue);
  //       }
  //     } else {
  //       profitText = profitTitle - parseFloat(shippingValue);
  //     } 
  //   }
  //   return profitText;
  // }

  titleProfit(shippingValue) {
    if(shippingValue == '') {
      shippingValue = 0;
    }
    this.totalvarShipping = (this.summary.itemTotal + parseFloat(shippingValue));
    
    if(this.totalvarShipping < 0 && this.ProfitVarCost < 0) {
      this.profitAmount = this.totalvarShipping + this.ProfitVarCost;
    }
    else{
      this.profitAmount = this.totalvarShipping - this.ProfitVarCost;
    }
    return this.profitAmount;
  }
  
  hpTitleProfit() {
    if(this.summary.includeVarCost == false) {
      var profitAmount = this.summary.totalShipping + ((this.summary.estPercent * this.summary.itemTotal)/100);
    }
    else {
      var profitAmount = (this.summary.estPercent * this.summary.itemTotal)/100;
    }
    return profitAmount;
  }
}
