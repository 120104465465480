<template>
  <div :class="['sortContainer', sortState]" @click="handleClick"></div>
</template>

<script>
export default {
  props: {
    onClick: Function
  },
  data() {
    return {
      sortState: '', // Initial state
      isActive: false // New flag to track if this indicator is active
    };
  },
  created() {
    this.$root.$on('resetSortState', this.resetSortState);
  },
  beforeDestroy() {
    this.$root.$off('resetSortState', this.resetSortState);
  },
  methods: {
    handleClick() {
      if (!this.isActive) {
        // First click: activate and set to descending
        this.$root.$emit('resetSortState');
        this.isActive = true;
        this.sortState = 'descending';
      } else {
        // Subsequent clicks: toggle between ascending and descending
        this.sortState = this.sortState === 'ascending' ? 'descending' : 'ascending';
      }
      if (this.onClick) {
        this.onClick();
      }
    },
    resetSortState() {
      this.sortState = '';
      this.isActive = false;
    }
  }
};
</script>

