























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import SortIndicator from '@/components/SortIndicator.vue';
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

import OppStatusComponent, { OppStatusValue } from "../components/OppStatus.vue";
import Datepicker from '../components/Datepicker.vue'
import {downloadFile, downloadFileUrl, notifier, printHtml} from "../models/common";
import { Filters, OpportunityAPIRepo, Sort, SortField } from "../repos/OpportunityAPIRepo";
import Pagination from "../components/Pagination.vue";
import LaddaButton from "../components/LaddaButton.vue";
import FilterCurrencyBox from "../components/FilterCurrencyBox.vue";
import axios from "axios";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import { getRangeFilter, colsResizeable, colsResizeableReset, colsResizeableUpdate, getDateRangeFilter, getInitials } from "@/helpers/ApiHelper";
import { InvoiceReportStatus, InvoiceStatus, invoiceTypeName } from "@/repos/InvoiceAPIRepo";
import CustomerSearchTooltip from "../components/CustomerSearchTooltip.vue";
import { ApiHelper } from "@/helpers/all";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import ExportModal, {ExportFormat} from "@/components/ExportModal.vue";
import {onExport} from "@/repos/OrderAPIRepo";
const template = require("../templates/orders/orderList.handlebars");

declare const dataURL: string;
declare const $: any;
const opportunityRepo = new OpportunityAPIRepo();
declare const getRouteData: Function;
declare const htmlParse : Function;
declare const htmlCheck : Function;

@Component({
  inheritAttrs: false,
  components: {
    ExportModal,
    SortIndicator,
    ListHeaderActions,
    Pagination,
    OppStatusComponent,
    Datepicker,
    LaddaButton,
    FilterCurrencyBox,
    CustomerSearchTooltip,
    ConfirmRemoveItemModal
  },
  methods: {
    getRangeFilter: (min, max) => {
      return getRangeFilter(min, max);
    },
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    },
    getInitials
  }
})
export default class Opportunities extends TSXComponent<void> {
  $allowedFunctions: any;
  $userFunctions: any;
  $route: any;
  $router: any;
  selectedView = false;
  CurrentView = "My Opportunities";
  selectedTags = [];
  accountTags: any = [];
  tagIDs: number[] = [];
  hasChangedAccountFilters = false;
  customerTooltipVisibleIndex = -1;
  customerTooltipLoading = false;
  viewType = "CurrentUsers";
  loading = false;
  updatingHeaders = false;
  isSearch = 0;
  totalAll = 0;
  totalPrice = 0;
  totalPrice30 = 0;
  totalPrice60 = 0;
  totalPrice90 = 0;
  totalPriceFound = 0;
  totalProfitFound = 0;
  currentRole = sessionStorage.getItem("userRole");
  ret = {
    CURRENTPAGE: 1,
    TOTALPAGES: 1,
    businessLinesObj: []
  };
  totalopportunityItems = 0;
  pageNumber = sessionStorage.pageDetails && JSON.parse(sessionStorage.pageDetails)["paychecks"] ? JSON.parse(sessionStorage.pageDetails)["paychecks"] : 1;
  detail = [];
  Employee = 1;
  opportunityItems: any = [];
  showSelectedUsers = false;
  filters: any = {
    ms_opp_id: "",
    account_name: "",
    description: "",
    total_amt: "",
    opp_number: "",
    sales_stage: [1,2,3,4,5,6,7,8],
    tags: [],
    expected_close_date: "",
    follow_up: "",
    account_lead: "",
    sTotalMin: undefined,
    sTotalMax: undefined,
    expected_close_date_sDate: "",
    expected_close_date_eDate: "",
    follow_up_sDate: "",
    follow_up_eDate: "",
    tagsName: [],
    AccountTagID: [],
    gTagIDs: [],
    groupIDs: [],
    probability: [1,2,3,4],
    selectedAccs: [],
    businessLineId: [],
    businessLineName: [],
    selectedAccsName: [],
    estProfitMin: undefined,
    estProfitMax: undefined,
    oppLead: "",
    selectedUsers: [],
    selectedUsersName: [],
    nextSteps: "",
    estProfitPerMin: undefined,
    estProfitPerMax: undefined,
    est_payment_sDate: "",
    est_payment_eDate: "",
    opp_update_date_sDate: "",
    opp_update_date_eDate: "",
  };
  sort = {
    field: 0,
    direction: {
      [SortField.ms_opp_id]: 2,
      [SortField.account_name]: 1,
      [SortField.description]: 1,
      [SortField.total_amt]: 1,
      [SortField.opp_number]: 1,
      [SortField.sales_stage]: 1,
      [SortField.expected_close_date]: 1,
      [SortField.follow_up]: 1,
      [SortField.account_lead]: 1,
      [SortField.probability]: 1,
      [SortField.businessLineName]: 1,
      [SortField.EstProfit]: 1,
      [SortField.nextSteps]: 1,
      [SortField.EstProfitPer]: 1,
      [SortField.est_payment_date]: 1
    }
  };
  selectedID: string = "";
  selectedOpportunityTagsID: number[] = [];
  searchFilters = {
    ms_opp_id: "",
    account_name: "",
    description: "",
    total_amt: "",
    opp_number: "",
    sales_stage: [1,2,3,4,5,6,7,8],
    tags: [1,2,3,4,5,6],
    expected_close_date: "",
    follow_up: "",
    account_lead: "",
    sTotalMin: undefined,
    sTotalMax: undefined,
    expected_close_date_sDate: "",
    expected_close_date_eDate: "",
    follow_up_sDate: "",
    follow_up_eDate: "",
    selectedAccs: [],
    businessLineId: [],
    businessLineName: [],
    selectedAccsName: [],
    estProfitMin: undefined,
    estProfitMax: undefined,
    oppLead: "",
    selectedUsers: [],
    selectedUsersName: [],
    nextSteps: "",
    estProfitPerMin: undefined,
    estProfitPerMax: undefined,
    est_payment_sDate: "",
    est_payment_eDate: "",
    opp_update_date_sDate: "",
    opp_update_date_eDate: "",
  };
  opportunityTags: any[] = [];
  searchTagsTxt = "";
  isActive = "customerAccounts";
  varAccs: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  varAccsList: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  selectedAccs: number[] = [];
  searchAccTxt = "";
  hasChangedBusinessLineFilters: boolean = false;
  varUser: {
    USERID: number;
    USERFULLNAME: string;
  }[] = [];
  varUserList: {
    USERID: number;
    USERFULLNAME: string;
  }[] = [];
  hasChangedUserFilters:boolean = false;
  searchUserTxt:string = "";
  searchHeader:string = "";
  pageHeaders = {
    SALES_STAGE: "Status",
    MS_OPP_ID: "MS Opp ID",
    ACCOUNTNAME: "Account",
    DESCRIPTION: "Name",
    TOTAL_AMT: "Sales Amount",
    ESTPROFIT_FORMATTED: "Est. Profit $",
    ESTPROFITPERCENTAGE_FORMATTED: "Est. Profit %",
    OPP_NUMBER: "Opp/Reg #",
    // PROBABILITYASTEXT: "Probability",
    EXPECTED_CLOSE_DATE: "Est. Close",
    EST_PAYMENT_DATE_FORMATTED: "Est. Payment Date",
    OPP_ADD_DATE_FORMATTED: "Creation Date",
    OPP_UPDATE_DATE_FORMATTED: "Last Modified Date",
    FOLLOW_UP: "Follow Up",
    NEXTSTEPS: "Next Steps",
    UPDATES: "Updates", // count number of logs
    OPP_LEAD_NAME: "Opp Lead",
    BUSINESSLINENAME: "Business Line",
    TAGS: "Tags",
    QUOTES: "Quotes",
    ORDERS: "Orders",
  };
  headerEmpComp = [
    "SALES_STAGE",
    "MS_OPP_ID",
    "ACCOUNTNAME",
    "DESCRIPTION",
    "TOTAL_AMT",
    "ESTPROFIT_FORMATTED",
    "ESTPROFITPERCENTAGE_FORMATTED",
    "OPP_NUMBER",
    // "PROBABILITYASTEXT",
    "OPP_ADD_DATE_FORMATTED",
    "OPP_UPDATE_DATE_FORMATTED",
    "EXPECTED_CLOSE_DATE",
    "EST_PAYMENT_DATE_FORMATTED",
    "FOLLOW_UP",
    "NEXTSTEPS",
    "UPDATES",
    "OPP_LEAD_NAME",
    "BUSINESSLINENAME",
    "TAGS",
    "QUOTES",
    "ORDERS",
  ];
  selectedHeaders = [
    'SALES_STAGE',
    'MS_OPP_ID',
    'ACCOUNTNAME',
    'DESCRIPTION',
    'TOTAL_AMT',
    'EXPECTED_CLOSE_DATE',
    'OPP_LEAD_NAME'
  ];
  confirmRemoveModalVisible = false;
  CurrentOppTypeView:string = "Complete View";
  CurrentOppType:string = "";
  selectedGrandTotal: any = 0;
  selectedProfitTotal: any = 0;
  totalOppPrice: number = 0;
  totalOppProfit: number = 0;
  $isCustomer: any;

  async created() {
    //switch to all Opportunities if redirect from dashboard (case: selected a tag)
    if (typeof this.$route.query.viewFrom != "undefined" && this.$route.query.viewFrom == "dashboard") {
      this.filters["viewFrom"] = this.$route.query.viewFrom;
      if (
        typeof this.$route.query.gTagIDs != "undefined" &&
        Array.isArray(this.$route.query.gTagIDs) &&
        this.$route.query.gTagIDs.length
      ) {
        this.filters.gTagIDs = this.$route.query.gTagIDs.map(s =>
          parseInt(s || "0")
        );
      }
      if (
        typeof this.$route.query.groupIDs != "undefined" &&
        Array.isArray(this.$route.query.groupIDs) &&
        this.$route.query.groupIDs.length
      ) {
        this.filters.groupIDs = this.$route.query.groupIDs.map(s =>
          parseInt(s || "0")
        );
      }
      if (
        typeof this.$route.query.sDate === "string"
      ) {
        this.filters.expected_close_date_sDate = this.searchFilters.expected_close_date_sDate = this.$route.query.sDate;
      }
      if (
        typeof this.$route.query.eDate === "string"
      ) {
        this.filters.expected_close_date_eDate = this.searchFilters.expected_close_date_eDate = this.$route.query.eDate;
      }
      if (
        typeof this.$route.query.filterView === "string"
      ) {
        this.viewType = this.$route.query.filterView;
        this.selectedView = true;
      } else if (
        typeof this.$route.query.filterView === "number"
      ) {
        if (
          this.$route.query.tagIDs && Array.isArray(this.$route.query.tagIDs)
        ) {
          this.filters.AccountTagID = this.$route.query.tagIDs.map(s =>
            parseInt(s || "0")
          );
        }
      }
      if (typeof this.$route.query.businessLineId === "string") {
        this.filters.businessLineId = this.searchFilters.businessLineId = [this.$route.query.businessLineId];
      } else if (Array.isArray(this.$route.query.businessLineId)) {
        this.filters.businessLineId = this.$route.query.businessLineId;
      }
      if (typeof this.$route.query.businessLineName === "string") {
        this.filters.businessLineName = this.searchFilters.businessLineName = [this.$route.query.businessLineName];
      } else if (Array.isArray(this.$route.query.businessLineName)) {
        this.filters.businessLineName = this.$route.query.businessLineName;
      }
    }
    if (typeof this.$route.query.ms_opp_id === "string") {
      this.filters.ms_opp_id = this.searchFilters.ms_opp_id = this.$route.query.ms_opp_id;
    }

    this.CurrentView =
        this.viewType == "All" ? "Company Opportunities" : this.$isCustomer ? "Opportunities" : "My Opportunities";

    await this.fetchData(true);
    await this.accsLoad();
    await this.usersLoad();
    colsResizeable({ms: 500});
  }

  async changeStatus(oppID: string, status: OppStatusValue, confirmDelete: boolean) {
    this.selectedID = oppID;
    if(status === 0 && confirmDelete === false) { 
      this.confirmRemoveModalVisible = true;
    }
    else{
      try {
        this.loading = true;
        await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Opportunities",
          FunctionName: "UpdateOpportunityStatus",
          selectedID: oppID,
          sales_stage: status
        });
        this.selectedID = "";
  
        await this.fetchData();
      } catch (err) {
        notifier.alert(err.message);
      } finally {
        this.loading = false;
      }
    }
  }

  async searchByAccountName() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    if (this.searchFilters.account_name == "") {
      return;
    } else {
      this.filters.account_name = this.searchFilters.account_name;
      await this.fetchData();
    }
  }
  async resetSearchByAccountName(allowFetchData = true) {
    this.selectedIDs = [];
    this.pageNumber = 1;
    this.filters.account_name = this.searchFilters.account_name = "";
    if (allowFetchData) {
      await this.fetchData();
    }
  }
  async searchById() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    if (this.searchFilters.ms_opp_id == "") {
      return;
    } else {
      this.filters.ms_opp_id = this.searchFilters.ms_opp_id;
      await this.fetchData();
    }
  }
  async resetSearchById() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    this.selectedID = "";
    this.filters.ms_opp_id = this.searchFilters.ms_opp_id = "";
    await this.fetchData();
  }

  async resetSearchByDescription() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    this.selectedID = "";
    this.filters.description = this.searchFilters.description = "";
    await this.fetchData();
  }
  async searchByDescription() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    this.selectedID = "";
    if (this.searchFilters.description == "") {
      return;
    } else {
      this.filters.description = this.searchFilters.description;
      await this.fetchData();
    }
  }
  async resetSearchByNextSteps() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    this.selectedID = "";
    this.filters.nextSteps = this.searchFilters.nextSteps = "";
    await this.fetchData();
  }
  async searchByNextSteps() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    this.selectedID = "";
    if (this.searchFilters.nextSteps == "") {
      return;
    } else {
      this.filters.nextSteps = this.searchFilters.nextSteps;
      await this.fetchData();
    }
  }
  async resetSearchByOppLead(allowFetchData = true) {
    this.selectedIDs = [];
    this.pageNumber = 1;
    this.selectedID = "";
    this.filters.oppLead = this.searchFilters.oppLead = "";
    if (allowFetchData) {
      await this.fetchData();
    }
  }
  async searchByOppLead() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    this.selectedID = "";
    if (this.searchFilters.oppLead == "") {
      return;
    } else {
      this.filters.oppLead = this.searchFilters.oppLead;
      await this.fetchData();
    }
  }
  async checkStatusList(status: number) {
    this.selectedIDs = [];
    this.pageNumber = 1;
    const i = this.filters.sales_stage.findIndex(s => s === status);
    if (i === -1) {
      this.filters.sales_stage.push(status);
    } else {
      this.filters.sales_stage.splice(i, 1);
    }
    
    await this.fetchData();
  }
  async checkAllStatusList() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    this.filters.sales_stage = [1, 2, 3, 4, 5, 6, 7, 8];
    await this.fetchData();
  }
  async uncheckAllStatusList() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    this.filters.sales_stage = [];
    await this.fetchData();
  }
  async resetStatusList() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    this.filters.sales_stage = [];
    this.checkAllStatusList();
  }
  async checkProbabilityList(probability: number) {
    this.selectedIDs = [];
    this.pageNumber = 1;
    const i = this.filters.probability.findIndex(s => s === probability);
    if (i === -1) {
      this.filters.probability.push(probability);
    } else {
      this.filters.probability.splice(i, 1);
    }
    await this.fetchData();
  }
  async checkAllProbabilityList() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    this.filters.probability = [1, 2, 3, 4];
    await this.fetchData();
  }
  async uncheckAllProbabilityList() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    this.filters.probability = [];
    await this.fetchData();
  }
  async resetProbabilityList() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    this.filters.probability = [];
    await this.checkAllProbabilityList();
  }
  async updateOpportunityTagList(tag,id,oppID) {
    var tagList = tag;
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Opportunities",
      FunctionName: "UpdateOpportunity",
      oppID: oppID,
      oppTagID: id,
      field: "tags",
      fieldVal: tagList
    });

    if (response.data.STATUS == 1) {
      const getOppIDIndex = this.opportunityItems.findIndex( item => item.MS_OPP_ID === oppID );
      if (!isNaN(getOppIDIndex)) {
        const removeIndex = this.opportunityItems[getOppIDIndex].TAGS.findIndex( item => item.tagID === id );
        if (!isNaN(removeIndex)) {
          this.opportunityItems[getOppIDIndex].TAGS.splice(removeIndex, 1);
        }
      }
    }
  }
  async checkTagList(status: number, name: string) {
    this.selectedIDs = [];
    this.pageNumber = 1;
    const i = this.filters.tags.findIndex(s => s === status);
    const tagNameIndex = this.filters.tagsName.findIndex(
      tagNameValue => tagNameValue === name
    );
    if (i === -1) {
      this.filters.tags.push(status);
    } else {
      this.filters.tags.splice(i, 1);
    }
    if (tagNameIndex === -1) {
      this.filters.tagsName.push(name);
    } else {
      this.filters.tagsName.splice(tagNameIndex, 1);
    }
    await this.fetchData();
  }
  async checkAllTagList() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    this.filters.tags = this.opportunityTags.map(item => item.OPPTAGID);
    this.filters.tagsName = this.opportunityTags.map(item => item.OPPTAGNAME);
    await this.fetchData();
  }
  async uncheckAllTagList() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    this.filters.tags = [];
    this.filters.tagsName = [];
    await this.fetchData();
  }
  async resetTagList() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    this.filters.tags = [];
    this.filters.tagsName = [];
    this.checkAllTagList();
    // await this.fetchData();
  }
  async sortBy(field: SortField) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }
  async sortingByOppID() {
    await this.sortBy(SortField.ms_opp_id);
  }
  async sortingByAccountName() {
    await this.sortBy(SortField.account_name);
  }
  async sortingByDescription() {
    await this.sortBy(SortField.description);
  }
  async sortingByTotalAmt() {
    await this.sortBy(SortField.total_amt);
  }
  async sortingByEstProfit() {
    await this.sortBy(SortField.EstProfit);
  }
  async sortingByEstProfitPer() {
    await this.sortBy(SortField.EstProfitPer);
  }
  async sortingByOppNumber() {
    await this.sortBy(SortField.opp_number);
  }
  async sortingBySalesStage() {
    await this.sortBy(SortField.sales_stage);
  }
  async sortingByCloseDate() {
    await this.sortBy(SortField.expected_close_date);
  }
  async sortingByEstPaymentDate() {
    await this.sortBy(SortField.est_payment_date);
  }
  async sortingByAddDate() {
    await this.sortBy(SortField.add_date);
  }
  async sortingByFollowUp() {
    await this.sortBy(SortField.follow_up);
  }
  async sortingByOppLead() {
    await this.sortBy(SortField.account_lead);
  }
  async sortingByProbability() {
    await this.sortBy(SortField.probability);
  }
  async sortingByBusinessLine() {
    await this.sortBy(SortField.businessLineName);
  }
  async sortingByNextSteps() {
    await this.sortBy(SortField.nextSteps);
  }
  async searchByTotal() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    this.filters.sTotalMin = this.searchFilters.sTotalMin;
    this.filters.sTotalMax = this.searchFilters.sTotalMax;
    await this.fetchData();
  }
  async searchByEstProfit() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    this.filters.estProfitMin = this.searchFilters.estProfitMin;
    this.filters.estProfitMax = this.searchFilters.estProfitMax;
    await this.fetchData();
  }
  async searchByEstProfitPer() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    this.filters.estProfitPerMin = this.searchFilters.estProfitPerMin;
    this.filters.estProfitPerMax = this.searchFilters.estProfitPerMax;
    await this.fetchData();
  }
  async searchByCloseDate() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    if (
      (this.searchFilters.expected_close_date_sDate == undefined ||
        this.searchFilters.expected_close_date_sDate == "") &&
      (this.searchFilters.expected_close_date_eDate== "" ||
        this.searchFilters.expected_close_date_eDate== undefined)
    ) {
      notifier.alert("Please fill From or To Date");
    } else {
      this.filters.expected_close_date_sDate = this.searchFilters.expected_close_date_sDate;
      this.filters.expected_close_date_eDate = this.searchFilters.expected_close_date_eDate;
      await this.fetchData();
    }
  }
  async searchByEstPaymentDate() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    if (
      (this.searchFilters.est_payment_sDate == undefined ||
        this.searchFilters.est_payment_sDate == "") &&
      (this.searchFilters.est_payment_eDate== "" ||
        this.searchFilters.est_payment_eDate== undefined)
    ) {
      notifier.alert("Please fill From or To Date");
    } else {
      this.filters.est_payment_sDate = this.searchFilters.est_payment_sDate;
      this.filters.est_payment_eDate = this.searchFilters.est_payment_eDate;
      await this.fetchData();
    }
  }
  async resetSearchByCloseDate() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    if (
      (this.searchFilters.expected_close_date_sDate == undefined ||
        this.searchFilters.expected_close_date_sDate == "") &&
      (this.searchFilters.expected_close_date_eDate== "" ||
        this.searchFilters.expected_close_date_eDate== undefined)
    ) {
      return;
    } else {
      this.filters.expected_close_date_sDate = this.searchFilters.expected_close_date_sDate = "";
      this.filters.expected_close_date_eDate = this.searchFilters.expected_close_date_eDate = "";
      await this.fetchData();
      const currentQueryParam = { ...this.$route.query };
      this.$delete(currentQueryParam, "sDate");
      this.$delete(currentQueryParam, "eDate");
      this.$router.replace({ query: currentQueryParam });
    }
  }
  async resetEstPaymentDate() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    if (
      (this.searchFilters.est_payment_sDate == undefined ||
        this.searchFilters.est_payment_sDate == "") &&
      (this.searchFilters.est_payment_eDate== "" ||
        this.searchFilters.est_payment_eDate== undefined)
    ) {
      return;
    } else {
      this.filters.est_payment_sDate = this.searchFilters.est_payment_sDate = "";
      this.filters.est_payment_eDate = this.searchFilters.est_payment_eDate = "";
      await this.fetchData();
    }
  }
  async searchByFollowUp() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    if (
      (this.searchFilters.follow_up_sDate == undefined ||
        this.searchFilters.follow_up_sDate == "") &&
      (this.searchFilters.follow_up_eDate== "" ||
        this.searchFilters.follow_up_eDate== undefined)
    ) {
      notifier.alert("Please fill From or To Date");
    } else {
      this.filters.follow_up_sDate = this.searchFilters.follow_up_sDate;
      this.filters.follow_up_eDate = this.searchFilters.follow_up_eDate;
      await this.fetchData();
    }
  }
  async resetSearchByFollowUp() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    if (
      (this.searchFilters.follow_up_sDate == undefined ||
        this.searchFilters.follow_up_sDate == "") &&
      (this.searchFilters.follow_up_eDate== "" ||
        this.searchFilters.follow_up_eDate== undefined)
    ) {
      return;
    } else {
      this.filters.follow_up_sDate = this.searchFilters.follow_up_sDate = "";
      this.filters.follow_up_eDate = this.searchFilters.follow_up_eDate = "";
      await this.fetchData();
    }
  }
  // opp_update_date filter
  async searchByOppUpdateDate() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    if (
        (this.searchFilters.opp_update_date_sDate == undefined ||
            this.searchFilters.opp_update_date_sDate == "") &&
        (this.searchFilters.opp_update_date_eDate== "" ||
            this.searchFilters.opp_update_date_eDate== undefined)
    ) {
      notifier.alert("Please fill From or To Date");
    } else {
      this.filters.opp_update_date_sDate = this.searchFilters.opp_update_date_sDate;
      this.filters.opp_update_date_eDate = this.searchFilters.opp_update_date_eDate;
      await this.fetchData();
    }
  }
  async resetOppUpdateDate() {
    this.selectedIDs = [];
    this.pageNumber = 1;
    if (
        (this.searchFilters.opp_update_date_sDate == undefined ||
            this.searchFilters.opp_update_date_sDate == "") &&
        (this.searchFilters.opp_update_date_eDate== "" ||
            this.searchFilters.opp_update_date_eDate== undefined)
    ) {
      return;
    } else {
      this.filters.opp_update_date_sDate = this.searchFilters.opp_update_date_sDate = "";
      this.filters.opp_update_date_eDate = this.searchFilters.opp_update_date_eDate = "";
      await this.fetchData();
    }
  }

  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    if(sessionStorage.pageDetails != undefined && JSON.parse(sessionStorage.pageDetails)["paychecks"]){
      var oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["paychecks"] = this.pageNumber;
      sessionStorage.setItem('pageDetails',JSON.stringify(oldData))
    }

    if (this.pageNumber <= this.ret.TOTALPAGES && this.pageNumber >= 1) {
      await this.fetchData();
    }
  }
  async fetchData(initialLoad = false) {
    //this.$router.replace({ path: this.$route.name, query: this.query});
    this.loading = true;
    this.hasChangedAccountFilters = false;
    this.hasChangedBusinessLineFilters = false;
    this.hasChangedUserFilters = false;

    if (!initialLoad && JSON.parse(sessionStorage.filterDetails)["Opportunities"]) {
      var exisitngFilterDetails = JSON.parse(sessionStorage.filterDetails);
      exisitngFilterDetails["Opportunities"] = JSON.parse(JSON.stringify(this.filters));
      exisitngFilterDetails["Orders"] = {};
      exisitngFilterDetails["Quotes"] = {};
      exisitngFilterDetails["Invoices"] = {};
      if (this.query != "undefined" && Object.keys(this.query).length != 0){
        sessionStorage.setItem("filterDetails", JSON.stringify(exisitngFilterDetails));
      }
    }
    if (Object.keys(JSON.parse(sessionStorage.filterDetails).Opportunities).length) {
      this.filters = this.searchFilters = JSON.parse(sessionStorage.filterDetails).Opportunities;
    }

    try {    
    const ret = await opportunityRepo.find(
      this.filters,
      this.sort,
      this.pageNumber,
      initialLoad,
      this.viewType,
      this.selectedView,
      this.CurrentOppType
    );
    this.ret = ret;
    const allOrders = ret.allOrders || [];
    const allQuotes = ret.allQuotes || [];
    this.opportunityItems = ret.OPPORTUNITIES.map((item) => {
      const PURCHASEIDS = item.PURCHASEIDS || '';
      if (PURCHASEIDS) {
        const arr = PURCHASEIDS.split(',') || [];
        // item['ORDERS'] = allOrders.filter((order) => arr.includes(`${order.PURCHASEID}`));
        item['ORDERS'] = arr.map((id) => {
          return {
            PURCHASEID: id,
            PODESCRIPTION: '',
          };
        });
      } else {
        item['ORDERS'] = [];
      }
      const QUOTEIDS = item.QUOTEIDS || '';
      if (QUOTEIDS) {
        const arr = QUOTEIDS.split(',') || [];
        // item['QUOTES'] = allQuotes.filter((order) => arr.includes(`${order.QUOTEID}`));
        item['QUOTES'] = arr.map((id) => {
          return {
            QUOTEID: id,
            QDESCRIPTION: '',
          };
        });
      } else {
        item['QUOTES'] = [];
      }
      return item;
    });
    this.isSearch = ret.ISSEARCH;
    this.totalAll = ret.TOTALALLOPPORTUNITIES;
    this.TOTAL = ret.TOTALALLOPPORTUNITIES || 0;
    this.totalPrice = ret.TOTALPRICE;
    this.totalPrice30 = ret.TOTALPRICE30;
    this.totalPrice60 = ret.TOTALPRICE60;
    this.totalPrice90 = ret.TOTALPRICE90;
    this.totalopportunityItems = ret.TOTALFOUNDOPPORTUNITIES;
    this.totalPriceFound = ret.TOTALFOUNDPRICE;
    this.totalProfitFound = ret.TOTALFOUNDPROFIT;
    this.totalOppPrice = ret.TOTALOPPPRICE;
    this.totalOppProfit = ret.TOTALOPPPROFIT;
    const opportunityTags = ret.opportunityTags;

    this.opportunityTags = opportunityTags.map((tag) => ({
      OPPTAGID: tag.GTAGID,
      OPPTAGNAME: tag.TAGNAME
    })) || [];
    this.opportunityTags.push({
      OPPTAGID: 0,
      OPPTAGNAME: 'No Tag',
    })
    this.customerTooltipVisibleIndex = -1;
    // this.accountTags = ret.accountTags || [];
      this.accountTags = [];
    for (let i = 0; i < ret.accountTags.length; i++) {
      if(ret.accountTags[i].ISGLOBALTAG) {
        if(ret.accountTags[i].FOROPPORTUNITIES) {
          this.accountTags.push(ret.accountTags[i]);
        }
      } else {
        // Show Opportunities only on Customer Portal
        if (this.$isCustomer && ret.accountTags[i].ACCOUNTTAGID === 'CurrentUsers') {
          const accTag = ret.accountTags[i];
          accTag.TAGNAME = 'Opportunities';
          this.accountTags.push(accTag);
        } else {
          this.accountTags.push(ret.accountTags[i]);
        }
      }
    }

    if (initialLoad && this.opportunityTags.length && !this.filters.tags.length) {
      this.filters.tags = this.opportunityTags.map(item => item.OPPTAGID);
      this.filters.tagsName = this.opportunityTags.map(item => item.OPPTAGNAME);
    }
    if (
      !this.selectedView &&
      typeof ret["userSelectedCurrentView"] != "undefined"
    ) {
      this.selectedView = true;
      this.viewType = ret["userSelectedCurrentView"];
      this.CurrentView =
        this.viewType == "All" ? "Company Opportunities" : this.$isCustomer ? "Opportunities" : "My Opportunities";
    }
    if (
      typeof this.ret.businessLinesObj != "undefined" &&
      this.filters.businessLineId.length == 0 &&
      initialLoad
    ) {
      this.filters.businessLineId = Object.keys(
        this.ret.businessLinesObj
      );
      this.filters.businessLineName = Object.values(
        this.ret.businessLinesObj
      );
    }

    if (ret.userOpportunitiesHeaders && ret.userOpportunitiesHeaders.length) {
      this.selectedHeaders = ret.userOpportunitiesHeaders.filter((oph) => oph !== 'PROBABILITYASTEXT');
    }

      } catch (err) {
        notifier.alert(err.message);
      } finally {
        this.loading = false;
      }
    if (this.selectedAll === true) {
      this.selectAll(true);
    }
    this.totalOrders = [];
    for (var val of this.opportunityItems) {
      if (this.selectedIDs.includes(val.MS_OPP_ID)) {
        this.totalOrders.push(val.MS_OPP_ID);
      }
    }
    if (this.opportunityItems.length == this.totalOrders.length) {
      this.ordersCheckAll = true;
      this.selectPageAll = true;
    } else {
      this.ordersCheckAll = false;
      this.selectPageAll = false;
    }
  }

  saveField(field,id,index, date = '') {
    var fieldOriginalVal = $("."+field+"_COPY_"+index).attr("data-copy").trim();
    var fieldVal = date.length ? date : $("."+field+"_COPY_"+index).text().trim();

    if ($("."+field+"_COPY_"+index).attr("maxlength")) {
      $("."+field+"_COPY_"+index).text(fieldVal.substring(0, $("."+field+"_COPY_"+index).attr("maxlength")));
      fieldVal = $("."+field+"_COPY_"+index).text();
    }

    if (fieldVal != "") {
      if (htmlCheck(fieldVal)) {
        fieldVal = htmlParse(fieldVal);
      }
    }
    if(field == 'TOTAL_AMT'){
      fieldVal = fieldVal.replace(/[^\d.]/g, '');
      fieldOriginalVal = fieldOriginalVal.replace(/[^\d.]/g, '');
      if (!$.trim(fieldVal)) {
        fieldVal = "0";
      }
    }
    if (field == "DESCRIPTION" && !$.trim(fieldVal).length) {
      notifier.alert("The Opportunity Description field is required");
      return;
    }
    if (fieldVal != fieldOriginalVal) {
        getRouteData({
          controller: "Opportunities",
          FunctionName: "UpdateOpportunity",
          oppID: id,
          field: field,
          fieldVal: fieldVal,
        }).then(function(response, statusText, jqXHR) {
            if(field == 'TOTAL_AMT'){
              let tempFieldVal = Vue.filter('formatCurrency')(fieldVal);
              $("."+field+"_COPY_"+index).html(tempFieldVal).attr("data-copy", tempFieldVal);
            }else if(field != 'EXPECTED_CLOSE_DATE' && field != 'FOLLOW_UP' && field != 'EST_PAYMENT_DATE_FORMATTED') {
              $("."+field+"_COPY_"+index).html(fieldVal).attr("data-copy", fieldVal);

              if (field == "OPP_NUMBER") {
                $("."+field+"_COPY_"+index).removeClass("grayedOut");
                if (fieldVal == "N/A" || !$.trim(fieldVal).length) {
                  $("."+field+"_COPY_"+index).addClass("grayedOut").html("N/A").attr("data-copy", "N/A");
                }
              } else if (field == "DESCRIPTION") {
                if (!$.trim(fieldVal).length) {
                  $("."+field+"_COPY_"+index).html("(No description)").attr("data-copy", "(No description)");
                }
              }
            }
        });
    }
  }



  async resetSearchByTotal() {
    this.pageNumber = 1;
    this.selectedID = "";
    this.filters.sTotalMin = this.searchFilters.sTotalMin = undefined;
    this.filters.sTotalMax = this.searchFilters.sTotalMax = undefined;
    await this.fetchData();
  }

  async resetSearchByEstProfit() {
    this.pageNumber = 1;
    this.selectedID = "";
    this.filters.estProfitMin = this.searchFilters.estProfitMin = undefined;
    this.filters.estProfitMax = this.searchFilters.estProfitMax = undefined;
    await this.fetchData();
  }

  async resetSearchByEstProfitPer() {
    this.pageNumber = 1;
    this.selectedID = "";
    this.filters.estProfitPerMin = this.searchFilters.estProfitPerMin = undefined;
    this.filters.estProfitPerMax = this.searchFilters.estProfitPerMax = undefined;
    await this.fetchData();
  }

  getFilterValue(header) {
    let filter = '';
    let searchKey: string | undefined = '';
    let salesStageFilters = [1, 2, 3, 4, 5, 6, 7, 8];
    switch (header) {
      case 'SALES_STAGE':
        if ( this.filters.sales_stage && !this.filters.sales_stage.length) {
          filter = "None";
        } else if (this.filters.sales_stage && this.filters.sales_stage.length == salesStageFilters.length && salesStageFilters.every(value => this.filters.sales_stage.includes(value))) {
          filter = '';
        } else if (this.filters.sales_stage && this.filters.sales_stage.length) {
          const arrStatusNames: string[] = [];
          if (this.filters.sales_stage.includes(1)) {
            arrStatusNames.push('Active');
          }
          if (this.filters.sales_stage.includes(2)) {
            arrStatusNames.push('Won');
          }
          if (this.filters.sales_stage.includes(3)) {
            arrStatusNames.push('Lost');
          }
          if (this.filters.sales_stage.includes(4)) {
            arrStatusNames.push('Proposed');
          }
          if (this.filters.sales_stage.includes(5)) {
            arrStatusNames.push('Delayed');
          }
          if (this.filters.sales_stage.includes(6)) {
            arrStatusNames.push('Prospect');
          }
          if (this.filters.sales_stage.includes(7)) {
            arrStatusNames.push('Scope');
          }
          if (this.filters.sales_stage.includes(8)) {
            arrStatusNames.push('Closed');
          }
          if (this.filters.sales_stage.includes(0)) {
            arrStatusNames.push('Deleted');
          }
          filter = arrStatusNames.join(', ');
        }
        break;
      case 'MS_OPP_ID':
        searchKey = this.filters.ms_opp_id;
        break;
      case 'DESCRIPTION':
        searchKey = this.filters.description;
        break;
      case 'TOTAL_AMT':
        searchKey = getRangeFilter(this.filters.sTotalMin, this.filters.sTotalMax);
        break;
      case 'ESTPROFIT_FORMATTED':
        searchKey = getRangeFilter(this.filters.estProfitMin, this.filters.estProfitMax);
        break;
      case 'ESTPROFITPERCENTAGE_FORMATTED':
        searchKey = getRangeFilter(this.filters.estProfitPerMin, this.filters.estProfitPerMax);
        break;
      case 'EXPECTED_CLOSE_DATE':
        searchKey = getDateRangeFilter(this.filters.expected_close_date_sDate, this.filters.expected_close_date_eDate);
        break;
      case 'OPP_UPDATE_DATE_FORMATTED':
        searchKey = getDateRangeFilter(this.filters.opp_update_date_sDate, this.filters.opp_update_date_eDate);
        break;
      case 'EST_PAYMENT_DATE_FORMATTED':
        searchKey = getDateRangeFilter(this.filters.est_payment_sDate, this.filters.est_payment_eDate);
        break;
      case 'FOLLOW_UP':
        searchKey = getDateRangeFilter(this.filters.follow_up_sDate, this.filters.follow_up_eDate);
        break;
      case 'NEXTSTEPS':
        searchKey = this.filters.nextSteps;
        break;
      case 'TAGS':
        {
          let tagsNameArr = this.opportunityTags.map(item => item.OPPTAGNAME);
          if ( this.filters.tags && !this.filters.tags.length) {
            filter = "None";
          } else if (this.filters.tagsName && tagsNameArr.length != this.filters.tagsName.length) {
            filter = this.filters.tagsName.join(', ');
            // console.log('arrTagNames', this.filters.tags, filter);
          }
          break;
        }
      case 'PROBABILITYASTEXT':
        if ( this.filters.probability && !this.filters.probability.length) {
          filter = "None";
        } else if (this.filters.probability && this.filters.probability.length != 4) {
          const arrProbabilityNames: string[] = [];
          if (this.filters.probability.includes(1)) {
            arrProbabilityNames.push('100%'); // Imminent
          }
          if (this.filters.probability.includes(2)) {
            arrProbabilityNames.push('75%'); // High
          }
          if (this.filters.probability.includes(3)) {
            arrProbabilityNames.push('50%'); // Medium
          }
          if (this.filters.probability.includes(4)) {
            arrProbabilityNames.push('25%'); // Investigatory
          }
          filter = arrProbabilityNames.join(', ');
        }
        break;
      case "BUSINESSLINENAME":
        if (
          this.filters.businessLineName &&
          !this.filters.businessLineName.length
        )
          filter = "None";
        else if (
          this.filters.businessLineName &&
          Object.keys(this.ret.businessLinesObj).length !=
            this.filters.businessLineName.length
        ) {
          filter = this.filters.businessLineName.join(", ");
        }
        searchKey = "";
        break;
      case "ACCOUNTNAME": {
        const arr: string[] = [];
        if (this.filters.account_name) {
          arr.push(`Customer: ${this.filters.account_name}`);
        }
        if (this.filters.gTagIDs.length || this.filters.AccountTagID.length) {
          const selectedTags = this.accountTags
            .filter(
              t =>
                !(t.ISGLOBALTAG || false) &&
                !(t.ISGROUP || false) &&
                t.ACCOUNTTAGID > 0 &&
                this.filters.AccountTagID.includes(t.ACCOUNTTAGID)
            )
            .map(t => t.TAGNAME);
          const selectedGtags = this.accountTags
            .filter(
              t =>
                (t.ISGLOBALTAG || false) &&
                this.filters.gTagIDs.includes(t.GTAGID)
            )
            .map(t => t.TAGNAME);
          const selectedTagNames = [...selectedTags, ...selectedGtags];
          arr.push(`Customer Tags: ${selectedTagNames.join(", ")}`);
        }
        if (this.filters.selectedAccs.length) {
          arr.push(`Customer Accounts: ${this.filters.selectedAccsName}`);
        }
        if (this.filters.groupIDs.length) {
          const selectedGroups = this.accountTags
            .filter(
              t =>
                (t.ISGROUP || false) &&
                this.filters.groupIDs.includes(t.ACCOUNTTAGID)
            )
            .map(t => t.TAGNAME);
          arr.push(`Customer Groups: ${selectedGroups.join(", ")}`);
        }
        searchKey = arr.join("; ");
        break;
      }
      case "OPP_LEAD_NAME": {
        const arr: string[] = [];
        if (this.filters.oppLead) {
          arr.push(`${this.filters.oppLead}`);
        }
        if (this.filters.selectedUsers.length) {
          arr.push(`${this.filters.selectedUsersName}`);
        }
        if (arr.length) {
          searchKey = `Users: ${arr.join(",")}`;
        }
        break;
      }
      default:
        // nothing
        break;
    }
    return (filter ? `${filter}` : '') + (searchKey ? (filter ? ' - ' : '') + searchKey : '');
  }

  isBeforeToday(dateString) {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset today's date to midnight for accurate comparison
    
    const compareDate = new Date(dateString);
    compareDate.setHours(0, 0, 0, 0); // Ensure time components don't affect the comparison
    
    return compareDate < today;
  }

  async hideTooltipCustomers() {
    this.customerTooltipVisibleIndex = -1;
  }

  async showTooltipCustomers(item, index) {
    if (!item.length) return;
    this.customerTooltipVisibleIndex = index;
    try {
      this.customerTooltipLoading = true;
      // const subAccounts = await CustomerRepo.findOneFewDetails(item.SECURITYGROUPID, FewDetailsJustGet.Users)
      this.customerTooltipLoading = false;
    } catch (err) {
      notifier.alert(err.message);
      this.customerTooltipVisibleIndex = -1;
    }
  }

  getCurrentViewTitle() {
    if (this.filters.AccountTagID.length || this.filters.gTagIDs.length || this.filters.groupIDs.length) {
      var fulltitle:any = [];
      var $this = this;
      this.accountTags.forEach(function (val: any) {
      if (!isNaN(val.ACCOUNTTAGID)) {
        if (typeof val.ISGLOBALTAG == 'undefined' && typeof val.ISGROUP == 'undefined' && $this.filters.AccountTagID && $this.filters.AccountTagID.includes(val.ACCOUNTTAGID)) {
          fulltitle.push(val.TAGNAME);
        }
        if (typeof val.ISGLOBALTAG != 'undefined' && $this.filters.gTagIDs && $this.filters.gTagIDs.includes(val.ACCOUNTTAGID)) {
          fulltitle.push(val.TAGNAME);
        }
        if (typeof val.ISGROUP != 'undefined' && $this.filters.groupIDs && $this.filters.groupIDs.includes(val.ACCOUNTTAGID)) {
          fulltitle.push(val.TAGNAME);
        }
      }
    });
    return fulltitle.join(", ");

    }

    return "";
  }
  async selectView(item, e) {
    if (isNaN(item.ACCOUNTTAGID)) {
      // selected my customers / company customers
      this.tagIDs = [];
      this.filters.gTagIDs = [];
      this.filters.groupIDs = [];

      this.filters.AccountTagID = []; // reset
      this.selectedTags = []; // reset
      this.CurrentView = item.TAGNAME;
      this.viewType = item.ACCOUNTTAGID;
      this.selectedView = true;
      await this.fetchData();
      await this.accsLoad();
    } else {
      e.stopPropagation();
    }
  }

  async filterByTag(tagName = '', groupName = '') {
    this.selectedIDs = [];
    this.hasChangedAccountFilters = true;
    if (this.filters.AccountTagID.length || this.filters.gTagIDs.length || this.filters.groupIDs.length) {
      this.selectedView = true;
      this.CurrentView = this.getCurrentViewTitle();
    } else {
      this.selectedView = false;
      if(!this.$allowedFunctions.includes(this.$userFunctions.All_Milestone_Accounts)) {
        this.CurrentView = this.$isCustomer ? "Opportunities": "My Opportunities";
        this.viewType = "CurrentUsers";
			}
    }
  }

  toggleActive(div) {
    this.isActive = div;
  }

  filterAccsInBox() {
    let options = this.varAccsList;

    if (this.searchAccTxt) {
      options = options.filter(
        option =>
          `${option.ANAME}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTALIAS}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTID}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1
      );
    }

    this.varAccs = options;
  }
  async filterByAcc(accountsName: string) {
    this.selectedIDs = [];
    this.hasChangedAccountFilters = true;
    if (
      accountsName != undefined &&
      this.filters.selectedAccsName != undefined
    ) {
      const accountsIndex = this.filters.selectedAccsName.findIndex(
        accountsValue => accountsValue === accountsName
      );
      if (accountsIndex === -1) {
        this.filters.selectedAccsName.push(accountsName);
      } else {
        this.filters.selectedAccsName.splice(accountsIndex, 1);
      }
    }
    // this.resetSelected();
    // this.pageNumber = 1;
    // await this.fetchData();
  }
  async checkAllAcc() {
    this.selectedIDs = [];
    this.filters.selectedAccs = this.varAccs.map(val => val.AID);
    this.filters.selectedAccsName = this.varAccs.map(val => val.ANAME);
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetAcc(allowFetchData = true) {
    this.selectedIDs = [];
    this.filters.selectedAccs = [];
    this.filters.selectedAccsName = [];
    this.pageNumber = 1;
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  filterTagsInBox() {
    let options = this.ret["accountTags"] || [];

    if (this.searchTagsTxt) {
      options = options.filter(
        option =>
          `${option.TAGNAME}`
            .toLowerCase()
            .indexOf(this.searchTagsTxt.toLowerCase()) !== -1
      );
    }

    this.accountTags = options;
  }

  async checkAllTag() {
    var $this = this;
    this.selectedIDs = [];
    this.filters.AccountTagID = [];
    // this.filters.accountTagName = [];
    this.accountTags.forEach(function(val: any) {
      if (!isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP == "undefined") {
        if (typeof val.ISGLOBALTAG == "undefined") {
          if (
            $this.filters.AccountTagID != undefined 
            // && $this.filters.accountTagName != undefined
          ) {
            $this.filters.AccountTagID.push(val.ACCOUNTTAGID);
            // $this.filters.accountTagName.push(val.TAGNAME);
          }
        } else {
          $this.filters.gTagIDs.push(val.ACCOUNTTAGID);
          // if ($this.filters.accountTagName != undefined) {
          //   $this.filters.accountTagName.push(val.TAGNAME);
          // }
        }
      }
    });
    this.filterByTag();
    // this.filters.AccountTagID = this.accountTags.map(val => val.ACCOUNTTAGID);
    this.pageNumber = 1;
    await this.fetchData();
  }

  async checkAllGroupTag() {
    var $this = this;
    this.selectedIDs = [];
    this.filters.groupIDs = [];
    // this.filters.groupName = [];
    this.accountTags.forEach(function(val: any) {
      if (
        $this.filters.groupIDs &&
        !isNaN(val.ACCOUNTTAGID) &&
        typeof val.ISGROUP != "undefined"
      ) {
        if (
          $this.filters.groupIDs != undefined 
          // && $this.filters.groupName != undefined
        ) {
          $this.filters.groupIDs.push(val.ACCOUNTTAGID);
          // $this.filters.groupName.push(val.TAGNAME);
        }
      }
    });
    this.filterByTag();
    // this.filters.groupIDs = this.accountTags.map(val => val.ACCOUNTTAGID);
    this.pageNumber = 1;
    await this.fetchData();
  }

  async resetTag(allowFetchData = true) {
    this.selectedIDs = [];
    this.filters.AccountTagID = [];
    // this.filters.accountTagName = [];
    // this.filters.groupIDs = [];
    this.filters.gTagIDs = [];
    // this.filters.groupName = [];
    this.pageNumber = 1;
    this.filterByTag();
    if (allowFetchData) {
      await this.fetchData();
    }
    const currentQueryParam = { ...this.$route.query };
    this.$delete(currentQueryParam, "tagIDs");
    this.$delete(currentQueryParam, "gTagIDs");
    this.$delete(currentQueryParam, "groupIDs");
    this.$router.replace({ query: currentQueryParam });
  }

  async resetGroupTag(allowFetchData = true) {
    this.selectedIDs = [];
    this.filters.AccountTagID = [];
    this.filters.groupIDs = [];
    this.pageNumber = 1;
    this.filterByTag();
    if (allowFetchData) {
      await this.fetchData();
    }
    const currentQueryParam = { ...this.$route.query };
    this.$delete(currentQueryParam, "tagIDs");
    this.$delete(currentQueryParam, "gTagIDs");
    this.$delete(currentQueryParam, "groupIDs");
    this.$router.replace({ query: currentQueryParam });
  }

  async accsLoad() {
    // if (this.searchAccTxt == "") {
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        FunctionName: "ListExt",
        controller: "Accounts",
        queryType: "top5UsedAcct",
        view: this.viewType,
        isOrder: 1,
        accSearch: 1
      });
      if (response.data.STATUS) {
        this.varAccs = response.data.ACCOUNTS;
        this.selectedAccs = response.data.VARACCOUNTSIDSRR;
        this.varAccsList = response.data.ACCOUNTS;
        if (this.searchAccTxt != "") {
          await this.filterAccsInBox();
        }
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
    // }
  }

  // Business Line filter
  async checkBusinessLineList(bLine: string, name: string) {
    this.selectedIDs = [];
    this.pageNumber = 1;
    if (
      this.filters.businessLineId != undefined &&
      this.filters.businessLineName != undefined
    ) {
      const i = this.filters.businessLineId.findIndex(s => s === bLine);
      const bLineIndex = this.filters.businessLineName.findIndex(
        bLineValue => bLineValue === name
      );
      if (i === -1) {
        this.filters.businessLineId.push(bLine);
      } else {
        this.filters.businessLineId.splice(i, 1);
      }
      if (bLineIndex === -1) {
        this.filters.businessLineName.push(name);
      } else {
        this.filters.businessLineName.splice(bLineIndex, 1);
      }
    }
    this.hasChangedBusinessLineFilters = true;
  }

  async uncheckBusinessLineList() {
    this.selectedIDs = [];
    this.filters.businessLineId = [];
    this.filters.businessLineName = [];
    await this.fetchData();
  }

  async resetBusinessLineList(allowFetchData = true) {
    this.selectedIDs = [];
    this.pageNumber = 1;
    this.filters.businessLineId = [];
    this.filters.businessLineName = [];
    this.checkAllBusinessLineList(allowFetchData);
  }

  async checkAllBusinessLineList(allowFetchData = true) {
    this.selectedID = "";
    this.filters.businessLineId = Object.keys(this.ret.businessLinesObj);
    this.filters.businessLineName = Object.values(
      this.ret.businessLinesObj
    );
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async resetColumn(header) {
    switch (header) {
      case 'SALES_STAGE':
        this.resetStatusList();
        break;
      case 'MS_OPP_ID':
        this.resetSearchById();
        break;
      case "ACCOUNTNAME":
        this.resetSearchByAccountName(false);
        this.resetAcc(false);
        this.resetTag(false);
        this.resetGroupTag(false);
        await this.fetchData();
        break;
      case 'DESCRIPTION':
        this.resetSearchByDescription();
        break;
      case 'TOTAL_AMT':
        this.resetSearchByTotal();
        break;
      case 'ESTPROFIT_FORMATTED':
        this.resetSearchByEstProfit();
        break;
      case 'ESTPROFITPERCENTAGE_FORMATTED':
        this.resetSearchByEstProfitPer();
        break;
      case 'PROBABILITYASTEXT':
        this.resetProbabilityList();
        break;
      case 'EXPECTED_CLOSE_DATE':
        this.resetSearchByCloseDate();
        break;
      case 'EST_PAYMENT_DATE_FORMATTED':
        this.resetEstPaymentDate();
        break;
      case 'OPP_UPDATE_DATE_FORMATTED':
        this.resetOppUpdateDate();
        break;
      case 'FOLLOW_UP':
        this.resetSearchByFollowUp();
        break;
      case 'NEXTSTEPS':
        this.resetSearchByNextSteps();
        break;
      case "OPP_LEAD_NAME":
        this.resetSearchByOppLead(false);
        this.resetUser(false);
        await this.fetchData();
        break;
      case 'OPP_LEAD_NAME':
        this.resetBusinessLineList();
        break;
      case 'BUSINESSLINENAME':
        this.resetBusinessLineList();
        break;
      case 'TAGS':
        this.resetTagList();
        break;
      default:
        break;
    }
  }

  getMoreTagNames(tags) {
    const arr: any[] = [];
    let index = 1;
    for(const item of tags) {
      if (index > 2) {
        arr.push(item.tag || '');
      }
      index = index + 1;
    }

    return arr.join(',');
  }

  getMoreNames(values, key) {
    const arr: any[] = [];
    let index = 1;
    for(const item of values) {
      if (index > 1) {
        arr.push(item[key] || '');
      }
      index = index + 1;
    }

    return arr.join(',');
  }

  async usersLoad() {
    try {
      const response = await ApiHelper.callApi("post", {
        controller: "Users",
        FunctionName: "List"
      });

      if (response.STATUS === 1) {
        this.varUser = response.USERS.map((item: any) => ({
          USERID: item.USERID,
          USERFULLNAME: item.UFNAME + ' ' + item.ULNAME
        }));
        this.varUserList = [...this.varUser];
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
  }

  filterUserInBox() {
    let options = this.varUserList;

    if (this.searchUserTxt) {
      options = options.filter(
        option =>
          `${option.USERFULLNAME}`
            .toLowerCase()
            .indexOf(this.searchUserTxt.toLowerCase()) !== -1
      );
    }

    this.varUser = options;
  }

  async filterByUser(userName: string) {
    this.selectedIDs = [];
    this.hasChangedUserFilters = true;
    if (
      userName != undefined &&
      this.filters.selectedUsersName != undefined
    ) {
      const userIndex = this.filters.selectedUsersName.findIndex(
        userValue => userValue === userName
      );
      if (userIndex === -1) {
        this.filters.selectedUsersName.push(userName);
      } else {
        this.filters.selectedUsersName.splice(userIndex, 1);
      }
    }
  }

  async checkAllUser() {
    this.selectedIDs = [];
    this.filters.selectedUsers = this.varUser.map(val => val.USERID);
    this.filters.selectedUsersName = this.varUser.map(val => val.USERFULLNAME);
    this.pageNumber = 1;
    await this.fetchData();
  }

  async resetUser(allowFetchData = true) {
    this.selectedIDs = [];
    this.searchUserTxt = '';
    this.filterUserInBox();
    this.showSelectedUsers = false;
    this.filters.selectedUsers = [];
    this.filters.selectedUsersName = [];
    this.pageNumber = 1;
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  searchByHeader() {
    const filteredHeader = Object.entries(this.pageHeaders).reduce((header, [key, value]) => {
      if (value.toLowerCase().search(this.searchHeader.toLowerCase()) != -1) {
        header[key] = value;
      }
      return header;
    }, {});
    return filteredHeader;
  }

  clickOutsideHeader() {
    this.searchHeader = "";
  }

  async addHeader(x = '') {
    colsResizeableReset();
    var sortedHeaders: string[] = [];
    for (var headerEmpComp in this.headerEmpComp) {
      var invoiceIndex: string = this.headerEmpComp[headerEmpComp];
      const selectedIndex = this.selectedHeaders.includes(invoiceIndex);
      if (selectedIndex) {
        sortedHeaders.push(invoiceIndex);
      }
    }
    this.selectedHeaders = sortedHeaders;
    const response = await ApiHelper.callApi("post", {
      controller: "Users",
      FunctionName: "paramsUpdate",
      headers: this.selectedHeaders.join(","),
      from: "opportunities",
    });

    if (response.ERROR) {
      throw new Error(response.ERROR);
    }
    if (response.STATUS !== 1) {
      throw new Error(response.STATUSMESSAGE);
    }
    this.updatingHeaders = true;
    setTimeout(() => {
      this.updatingHeaders = false;
      colsResizeable();
    }, 100);

  }

  sorting(c) {
    switch (c) {
      case "SALES_STAGE":
        this.sortingBySalesStage();
        break;
      case "MS_OPP_ID":
        this.sortingByOppID();
        break;
      case "ACCOUNTNAME":
        this.sortingByAccountName();
        break;
      case "DESCRIPTION":
        this.sortingByDescription();
        break;
      case "TOTAL_AMT":
        this.sortingByTotalAmt();
        break;
      case "ESTPROFIT_FORMATTED":
        this.sortingByEstProfit();
        break;
      case "ESTPROFITPERCENTAGE_FORMATTED":
        this.sortingByEstProfitPer();
        break;
      case "OPP_NUMBER":
        this.sortingByOppNumber();
        break;
      case "PROBABILITYASTEXT":
        this.sortingByProbability();
        break;
      case "EXPECTED_CLOSE_DATE":
        this.sortingByCloseDate();
        break;
      case "EST_PAYMENT_DATE_FORMATTED":
        this.sortingByEstPaymentDate();
        break;
      case "OPP_ADD_DATE_FORMATTED":
        this.sortingByAddDate();
        break;
      case "FOLLOW_UP":
        this.sortingByFollowUp();
        break;
      case "NEXTSTEPS":
        this.sortingByNextSteps();
        break;
      case "OPP_LEAD_NAME":
        this.sortingByOppLead();
        break;
      case "BUSINESSLINENAME":
        this.sortingByBusinessLine();
        break;
      default:
        // nothing
        break;
    }
  }

  async removeSelected() {
    this.loading = true;
    this.confirmRemoveModalVisible = false;
    this.changeStatus(this.selectedID, 0, true);
  }

  removeColumn(header, x) {
    const sortedHeaders: string[] = [];
    for (const ts in this.headerEmpComp) {
      const valIdx: string = this.headerEmpComp[ts];
      const i = this.selectedHeaders.includes(valIdx);
      if (i && valIdx != header) {
        sortedHeaders.push(valIdx);
      }
    }
    this.selectedHeaders = sortedHeaders;
    this.addHeader(x);
  }

  getListHeader(key: string) {
    let ret = "";
    if(this.currentRole != 'Customer') {
      ret = this.pageHeaders[key];
    }
    return ret;
  }

  async selectOppTypeView(type: string, view: string) {
    if (this.CurrentOppType != type) {
      this.CurrentOppType = type;
      this.CurrentOppTypeView = `${view} view`;
      this.pageNumber = 1;
      await this.fetchData();
    }
  }

  getStatusName(status) {
    switch (status) {
      case 1:
        return 'Active';
      case 2:
        return 'Won';
      case 3:
        return 'Lost';
      case 4:
        return 'Proposed';
      case 5:
        return 'Delayed';
      case 6:
        return 'Prospect';
      case 7:
        return 'Scope';
      case 8:
        return 'Closed';
      case 0:
        return 'Deleted';
      default:
        return 'Unknown';
    }








  }

  getProbabilityPercent(status) {
    switch (status) {
      case 1: return 100; // Imminent
      case 2: return 75; // High
      case 3: return 50; // Medium
      case 4: return 25; // Investigatory
      default: return 0;
    }
  }
  modalConfirmRemoveItem = {
    show: false,
    title: 'Delete Item',
    message: 'Are you sure you want to delete this?',
    callback: () => {}
  };

  async updateOpportunityItem(item) {
    await ApiHelper.callApi(
        'post',
        {
          controller: "Opportunities",
          FunctionName: "UpdateOpportunity",
          oppID: item.MS_OPP_ID,
          orderIDs: item.ORDERS.map((order) => order.PURCHASEID).join(','),
          quoteIDs: item.QUOTES.map((quote) => quote.QUOTEID).join(','),
          field: "linkOrderAndQuote"
        }
    );
  }

  async deleteOrder(item, id) {
    this.modalConfirmRemoveItem.title = 'Delete Order';
    this.modalConfirmRemoveItem.callback = async () => {
      item.ORDERS = item.ORDERS.filter((order) => order.PURCHASEID != id);
      this.modalConfirmRemoveItem.show = false;
      await this.updateOpportunityItem(item);
    };
    this.modalConfirmRemoveItem.show = true;
  }

  deleteQuote(item, id) {
    this.modalConfirmRemoveItem.title = 'Delete Quote';
    this.modalConfirmRemoveItem.callback = async () => {
      item.QUOTES = item.QUOTES.filter((quote) => quote.QUOTEID != id);
      this.modalConfirmRemoveItem.show = false;
      await this.updateOpportunityItem(item);
    };
    this.modalConfirmRemoveItem.show = true;
  }

  TOTAL = 0;
  ordersCheckAll = false;
  selectedAll = false;
  selectPageAll = false;
  excludedIDs: number[] = [];
  selectedIDs: (number | undefined)[] = [];
  totalOrders: (number | undefined)[] = [];
  showFilter = 0;
  selectedPage = 0;
  newPageOrder: (number | undefined)[] = [];
  selectInPage(selectedAllPage = true) {
    this.selectPageAll = selectedAllPage;
    this.showFilter = 0;
    if (this.selectPageAll == true) {
      this.selectedPage = this.pageNumber;
      this.newPageOrder.push(this.selectedPage);
      for (const val of this.opportunityItems) {
        if (this.selectedIDs.indexOf(val.MS_OPP_ID) == -1) {
          this.selectedIDs.push(val.MS_OPP_ID);
        }
        if (this.totalOrders.indexOf(val.MS_OPP_ID) == -1) {
          this.totalOrders.push(val.MS_OPP_ID);
        }
        this.$delete(
            this.excludedIDs,
            this.excludedIDs.findIndex(id => id == val.MS_OPP_ID)
        );
        this.getTotalPrice(val);
      }
    } else {
      for (const val of this.opportunityItems) {
        let selectedIDIndex = this.selectedIDs.indexOf(val.MS_OPP_ID);
        let totalOrdersIndex = this.totalOrders.indexOf(val.MS_OPP_ID);
        this.selectedIDs.splice(selectedIDIndex, 1);
        this.totalOrders.splice(totalOrdersIndex, 1);
        this.getTotalPrice(val);
      }
      let newPageOrderindex = this.newPageOrder.indexOf(this.pageNumber);
      this.newPageOrder.splice(newPageOrderindex, 1);
    }
  }

  getSelectedTotal(total) {
    var ret = 0;
    if (this.selectedAll) {
      ret = total - this.excludedIDs.length;
    } else {
      ret = this.selectedIDs.length;
    }

    return ret;
  }

  selectAll(selectedAll = true) {
    this.ordersCheckAll = selectedAll;
    this.excludedIDs = [];
    this.selectedAll = selectedAll;
    this.selectedIDs = [];
    this.showFilter = 0;
    if (this.selectedAll == true) {
      for (const val of this.opportunityItems) {
        if (!this.excludedIDs.includes(val.MS_OPP_ID)) {
          this.selectedIDs.push(val.MS_OPP_ID);
        }
      }
      this.selectedGrandTotal = this.totalOppPrice;
      this.selectedProfitTotal = this.totalOppProfit;
    } else {
      //uncheck
      this.excludedIDs = [];
      this.selectedGrandTotal = 0.0;
      this.selectedProfitTotal = 0.0;
    }
  }

  checkExcludedIDs(items, key, purchaseID = 0) {
    if (this.selectedAll || this.selectPageAll) {
      for (var val of items) {
        if (!this.selectedIDs.includes(val[key])) {
          if (!this.excludedIDs.includes(val[key])) {
            this.excludedIDs.push(val[key]);
            this.ordersCheckAll = false;
          }
        } else if (this.excludedIDs.includes(val[key])) {
          this.$delete(
              this.excludedIDs,
              this.excludedIDs.findIndex(id => id == val[key])
          );
        }
      }

      if (this.getSelectedTotal(this.totalopportunityItems) == 0) {
        this.selectedAll = false;
        this.excludedIDs = [];
      }
    }
    if (purchaseID) {
      if (this.selectedIDs.includes(purchaseID)) {
        this.totalOrders.push(purchaseID);
      } else {
        this.$delete(
            this.totalOrders,
            this.totalOrders.findIndex(id => id == purchaseID)
        );
      }
    }
    if (
        this.opportunityItems.length == this.totalOrders.length &&
        this.opportunityItems.length != 0
    ) {
      this.ordersCheckAll = true;
      this.selectPageAll = true;
    } else {
      this.ordersCheckAll = false;
      this.selectPageAll = false;
    }
  }

  exportModalVisible = false;
  exportHeadersArr: {
    header: string;
    column: string;
    widthPercent: string;
  }[] = [];
  async csvDownLoadFn() {
    let fileUrl;
    try {

      fileUrl = await opportunityRepo.exportListsCSVFileUrl(
          this.selectedIDs,
          this.selectedAll,
          this.excludedIDs,
          this.filters,
          this.sort,
          this.viewType,
          this.selectedView,
          this.exportHeadersArr
      );
    } catch (err) {
      notifier.alert("Export CSV error: " + err.message);
    }

    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async selectedOpportunitiesHtml(from: string): Promise<string | false> {
    try {

      const orderList = await opportunityRepo.exportOpportunityBySelect(
          this.selectedIDs,
          this.selectedAll,
          this.excludedIDs,
          this.filters,
          this.sort,
          this.viewType,
          this.selectedView,
          this.exportHeadersArr
      );
      if (from == "print") {
        orderList["type"] = "print";
      }
      return template(orderList);
    } catch (err) {
      notifier.alert(err.message);
      return false;
    }
  }

  async print() {
    await this.exportHeaders();
    this.loading = true;
    const html = await this.selectedOpportunitiesHtml("print");
    this.loading = false;
    if (!html) return;

    printHtml(html);
  }

  async htmlDownLoadFn() {
    const html = await this.selectedOpportunitiesHtml("html");

    return () => {
      if (!html) return;
      var fileNameExport =
          "OrderList_" + Math.round(+new Date() / 1000) + ".html";
      downloadFile(fileNameExport, html);
    };
  }

  async pdfDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await opportunityRepo.exportListsPDFFileUrl(
          this.selectedIDs,
          this.selectedAll,
          this.excludedIDs,
          this.filters,
          this.sort,
          this.viewType,
          this.selectedView,
          this.exportHeadersArr
      );
    } catch (err) {
      notifier.alert("Export PDF error: " + err.message);
    }

    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async detailDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await opportunityRepo.exportOpportunityDetailFileUrl(
          this.selectedIDs,
          this.selectedAll,
          this.excludedIDs,
          this.filters,
          this.sort,
          this.viewType,
          this.selectedView
      );
    } catch (err) {
      notifier.alert("Export CSV error: " + err.message);
    }

    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async reportDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await opportunityRepo.reportOpportunityDetailFileUrl(
          this.selectedIDs,
          this.selectedAll,
          this.excludedIDs,
          this.filters,
          this.sort,
          this.viewType,
          this.selectedView
      );
    } catch (err) {
      notifier.alert("Export CSV error: " + err.message);
    }

    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async exportETS() {
    let fileUrl;
    try {
      fileUrl = await opportunityRepo.exportETS(
          this.selectedIDs,
          this.selectedAll,
          this.excludedIDs,
          this.filters,
          this.sort,
          this.viewType,
          this.selectedView
      );
    } catch (err) {
      notifier.alert("Export CSV error: " + err.message);
    }

    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async performExport(selected) {
    const formats: ExportFormat[] = selected || [];

    this.loading = true;
    this.exportModalVisible = false;
    await this.exportHeaders();
    const exportFns: Function[] = [];

    if (formats.includes(ExportFormat.csv)) {
      exportFns.push(await this.csvDownLoadFn());
    }

    if (formats.includes(ExportFormat.html)) {
      exportFns.push(await this.htmlDownLoadFn());
    }

    if (formats.includes(ExportFormat.pdf)) {
      exportFns.push(await this.pdfDownLoadFn());
    }

    exportFns.forEach(fn => fn());

    this.loading = false;
  }

  getColumnWidth(e, frm = 0) {
    var headerCls = "";
    switch (this.selectedHeaders.length) {
      case 3:
        switch (e) {
          case "PROFIT":
            headerCls = "col-3";
            break;
          case "ACCOUNTNAME":
            headerCls = "col-6";
            break;
          default:
            headerCls = "col-3 tar";
            break;
        }
        break;
      case 4:
        switch (e) {
          case "PROFIT":
            headerCls = "col-2 tar";
            break;
          case "ACCOUNTNAME":
            if (
                this.selectedHeaders.includes("PODESCRIPTION") ||
                this.selectedHeaders.includes("POID") ||
                this.selectedHeaders.includes("IndirectQuoteID")
            ) {
              headerCls = "col-4";
            } else {
              headerCls = "col-6";
            }
            break;
          case "PODESCRIPTION":
            headerCls = "col-4";
            break;
          case "POID":
            headerCls = "col-4";
            break;
          case "NEWDATEFORMATTED":
            headerCls = "col-2";
            break;
          case "PURCHASEID":
            headerCls = "col-2";
            break;
          case "STATUSTEXT":
            headerCls = "col-2";
            break;
          case "CustomerPO":
            headerCls = "col-2";
            break;
          case "IndirectQuoteID":
            headerCls = "col-4";
            break;
          case "SOURCE":
            headerCls = "col-2";
            break;
          default:
            headerCls = "col-2 tar";
            break;
        }
        break;
      case 5:
        switch (e) {
          case "PROFIT":
            headerCls = "col-1-5 tar";
            break;
          case "ACCOUNTNAME":
            if (
                this.selectedHeaders.includes("PODESCRIPTION") ||
                this.selectedHeaders.includes("POID") ||
                this.selectedHeaders.includes("IndirectQuoteID") ||
                this.selectedHeaders.includes("CustomerPO")
            ) {
              headerCls = "col-4";
            } else {
              headerCls = "col-5";
            }
            break;
          case "PODESCRIPTION":
            if (
                this.selectedHeaders.includes("POID") ||
                this.selectedHeaders.includes("IndirectQuoteID") ||
                this.selectedHeaders.includes("CustomerPO")
            ) {
              headerCls = "col-2-5";
            } else {
              headerCls = "col-3";
            }
            break;
          case "POID":
            if (
                this.selectedHeaders.includes("PODESCRIPTION") ||
                this.selectedHeaders.includes("IndirectQuoteID") ||
                this.selectedHeaders.includes("CustomerPO")
            ) {
              headerCls = "col-2-5";
            } else {
              headerCls = "col-3";
            }
            break;
          case "NEWDATEFORMATTED":
            headerCls = "col-2";
            break;
          case "PURCHASEID":
            headerCls = "col-2";
            break;
          case "STATUSTEXT":
            headerCls = "col-2";
            break;
          case "CustomerPO":
            if (
                this.selectedHeaders.includes("PODESCRIPTION") ||
                this.selectedHeaders.includes("IndirectQuoteID") ||
                this.selectedHeaders.includes("POID")
            ) {
              headerCls = "col-2-5";
            } else {
              headerCls = "col-3";
            }
            break;
          case "IndirectQuoteID":
            if (
                this.selectedHeaders.includes("PODESCRIPTION") ||
                this.selectedHeaders.includes("POID") ||
                this.selectedHeaders.includes("CustomerPO")
            ) {
              headerCls = "col-2-5";
            } else {
              headerCls = "col-3";
            }
            break;
          case "TOTALPOTAX":
            headerCls = "col-2";
            break;
          case "SOURCE":
            headerCls = "col-2";
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-2";
            break;
          default:
            headerCls = "col-1-5 tar";
            break;
        }
        break;
      case 6:
        switch (e) {
          case "PROFIT":
            headerCls = "col-1-5 tar";
            break;
          case "ACCOUNTNAME":
            headerCls = "col-2-5";
            break;
          case "PODESCRIPTION":
            headerCls = "col-2";
            break;
          case "POID":
            headerCls = "col-2";
            break;
          case "NEWDATEFORMATTED":
            headerCls = "col-1-5";
            break;
          case "PURCHASEID":
            headerCls = "col-2";
            break;
          case "STATUSTEXT":
            if (this.selectedHeaders.includes("PROFIT")) {
              headerCls = "col-3";
            } else {
              headerCls = "col-1-5";
            }
            break;
          case "CustomerPO":
            headerCls = "col-2";
            break;
          case "IndirectQuoteID":
            headerCls = "col-2";
            break;
          case "SOURCE":
            headerCls = "col-2";
            break;
          case "TOTALPOTAX":
            headerCls = "col-2";
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-2-5";
            break;
          default:
            headerCls = "col-2 tar";
            break;
        }
        break;
      case 7:
        switch (e) {
          case "PROFIT":
            headerCls = "col-1-5 tar";
            break;
          case "ACCOUNTNAME":
            headerCls = "col-3";
            break;
          case "PODESCRIPTION":
            headerCls = "col-1-5";
            break;
          case "POID":
            headerCls = "col-1-5";
            break;
          case "NEWDATEFORMATTED":
            headerCls = "col-1-5";
            break;
          case "PURCHASEID":
            headerCls = "col-1-5";
            break;
          case "STATUSTEXT":
            headerCls = "col-1-5";
            break;
          case "CustomerPO":
            headerCls = "col-1-5";
            break;
          case "IndirectQuoteID":
            headerCls = "col-1-5";
            break;
          case "SOURCE":
            headerCls = "col-1-5";
            break;
          case "TOTALPOTAX":
            headerCls = "col-1-5";
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-1-5";
            break;
          default:
            headerCls = "col-1-5 tar";
            break;
        }
        break;
      case 8:
        switch (e) {
          case "PROFIT":
            headerCls = "col-1 tar";
            break;
          case "ACCOUNTNAME":
            headerCls = "col-2-5";
            break;
          case "PODESCRIPTION":
            headerCls = "col-1-5";
            break;
          case "POID":
            headerCls = "col-1-5";
            break;
          case "NEWDATEFORMATTED":
            headerCls = "col-1-5";
            break;
          case "PURCHASEID":
            headerCls = "col-1-5";
            break;
          case "STATUSTEXT":
            headerCls = "col-1-5";
            break;
          case "CustomerPO":
            headerCls = "col-1-5";
            break;
          case "IndirectQuoteID":
            headerCls = "col-1-5";
            break;
          case "SOURCE":
            headerCls = "col-1-5";
            break;
          case "TOTALPOTAX":
            headerCls = "col-1-5";
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-2";
            break;
          default:
            headerCls = "col-1 tar";
            break;
        }
        break;
      case 9:
        var totalAdd = 0;
        if (!this.selectedHeaders.includes("POID")) {
          totalAdd += 1;
        }
        if (!this.selectedHeaders.includes("PODESCRIPTION")) {
          totalAdd += 1;
        }
        if (!this.selectedHeaders.includes("CustomerPO")) {
          totalAdd += 1;
        }
        if (!this.selectedHeaders.includes("IndirectQuoteID")) {
          totalAdd += 1;
        }
        var includedHeaders = 4 - totalAdd;
        switch (e) {
          case "PROFIT":
            headerCls = "col-1 tar";
            break;
          case "ACCOUNTNAME":
            if (
                this.selectedHeaders.includes("PODESCRIPTION") ||
                this.selectedHeaders.includes("POID") ||
                this.selectedHeaders.includes("IndirectQuoteID") ||
                this.selectedHeaders.includes("CustomerPO")
            ) {
              headerCls = "col-2";
            } else {
              headerCls = "col-3";
            }
            if (includedHeaders == 3) {
              headerCls = "col-1-5";
            }
            break;
          case "PODESCRIPTION":
            if (
                this.selectedHeaders.includes("POID") ||
                this.selectedHeaders.includes("IndirectQuoteID") ||
                this.selectedHeaders.includes("CustomerPO")
            ) {
              headerCls = "col-1-5";
            } else {
              headerCls = "col-2";
            }
            if (includedHeaders == 2) {
              headerCls = "col-2";
            }
            if (includedHeaders == 3) {
              headerCls = "col-1-5";
            }
            break;
          case "POID":
            if (
                this.selectedHeaders.includes("PODESCRIPTION") ||
                this.selectedHeaders.includes("IndirectQuoteID") ||
                this.selectedHeaders.includes("CustomerPO")
            ) {
              headerCls = "col-1-5";
            } else {
              headerCls = "col-2";
            }
            if (includedHeaders == 2) {
              headerCls = "col-2";
            }
            if (includedHeaders == 3) {
              headerCls = "col-1-5";
            }
            break;
          case "CustomerPO":
            if (
                this.selectedHeaders.includes("PODESCRIPTION") ||
                this.selectedHeaders.includes("IndirectQuoteID") ||
                this.selectedHeaders.includes("POID")
            ) {
              headerCls = "col-1-5";
            } else {
              headerCls = "col-2";
            }
            if (includedHeaders == 2) {
              headerCls = "col-2";
            }
            if (includedHeaders == 3) {
              headerCls = "col-1-5";
            }
            break;
          case "IndirectQuoteID":
            if (
                this.selectedHeaders.includes("PODESCRIPTION") ||
                this.selectedHeaders.includes("CustomerPO") ||
                this.selectedHeaders.includes("POID")
            ) {
              headerCls = "col-1-5";
            } else {
              headerCls = "col-2";
            }
            if (includedHeaders == 2) {
              headerCls = "col-2";
            }
            if (includedHeaders == 3) {
              headerCls = "col-1-5";
            }
            break;
          case "NEWDATEFORMATTED":
            headerCls = "col-1";
            break;
          case "PURCHASEID":
            headerCls = "col-1";
            if (includedHeaders == 1 || includedHeaders == 3) {
              headerCls = "col-1-5";
            }

            break;
          case "STATUSTEXT":
            headerCls = "col-1";
            if (includedHeaders == 3) {
              headerCls = "col-1-5";
            }
            break;
          case "SOURCE":
            headerCls = "col-1";
            if (includedHeaders == 3) {
              headerCls = "col-1-5";
            }
            break;
          case "TOTALPOTAX":
            headerCls = "col-1";
            if (includedHeaders == 1 || includedHeaders == 3) {
              headerCls = "col-1-5";
            }
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-1";
            if (includedHeaders == 1 || includedHeaders == 3) {
              headerCls = "col-1-5";
            }
            break;
          default:
            headerCls = "col-1 tar";
            break;
        }
        break;
      case 10:
        switch (e) {
          case "PROFIT":
            headerCls = "col-1 tar";
            break;
          case "ACCOUNTNAME":
            headerCls = "col-2-5";
            break;
          case "PODESCRIPTION":
            headerCls = "col-1-5";
            break;
          case "POID":
            headerCls = "col-1";
            if (this.selectedHeaders.includes("PODESCRIPTION")) {
              headerCls = "col-1";
            } else {
              headerCls = "col-1-5";
            }
            break;
          case "NEWDATEFORMATTED":
            headerCls = "col-1";
            break;
          case "PURCHASEID":
            headerCls = "col-1";
            break;
          case "STATUSTEXT":
            headerCls = "col-1";
            break;
          case "CustomerPO":
            headerCls = "col-1";
            break;
          case "IndirectQuoteID":
            headerCls = "col-1";
            break;
          case "SOURCE":
            headerCls = "col-1";
            break;
          case "TOTALPOTAX":
            headerCls = "col-1";
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-1";
            break;
          default:
            headerCls = "col-1 tar";
            break;
        }
        break;
      case 11:
        switch (e) {
          case "PROFIT":
            headerCls = "col-1 tar";
            break;
          case "ACCOUNTNAME":
            headerCls = "col-1-5";
            break;
          case "PODESCRIPTION":
            headerCls = "col-1-5";
            break;
          case "POID":
            headerCls = "col-1";
            break;
          case "NEWDATEFORMATTED":
            headerCls = "col-1";
            break;
          case "PURCHASEID":
            headerCls = "col-1";
            break;
          case "STATUSTEXT":
            headerCls = "col-1";
            break;
          case "CustomerPO":
            headerCls = "col-1";
            break;
          case "IndirectQuoteID":
            headerCls = "col-1";
            break;
          case "SOURCE":
            headerCls = "col-1";
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-1";
            break;
          default:
            headerCls = "col-1 tar";
            break;
        }
        break;
      default:
        switch (e) {
          case "PROFIT":
            headerCls = "col-1 tar";
            break;
          case "ACCOUNTNAME":
            headerCls = "col-1";
            break;
          case "PODESCRIPTION":
            headerCls = "col-1";
            break;
          case "POID":
            headerCls = "col-1";
            break;
          case "NEWDATEFORMATTED":
            headerCls = "col-1";
            break;
          case "PURCHASEID":
            headerCls = "col-1";
            break;
          case "STATUSTEXT":
            headerCls = "col-1";
            break;
          case "CustomerPO":
            headerCls = "col-1";
            break;
          case "IndirectQuoteID":
            headerCls = "col-1";
            break;
          case "SOURCE":
            headerCls = "col-1";
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-1";
            break;
          default:
            headerCls = "col-1 tar";
            break;
        }
        break;
    }
    if (
        e != "ACCOUNTNAME" &&
        e != "PROFIT" &&
        frm != 1 &&
        this.selectedHeaders[0] != e
    ) {
      // headerCls += " textOverflow";
    }

    if (e.indexOf("customData") != -1) {
      headerCls = "col-1";
    }

    if (
        this.selectedHeaders[this.selectedHeaders.length - 1] == e ||
        this.selectedHeaders[this.selectedHeaders.length - 2] == e
    ) {
      headerCls += " lastSearchBox";
    }

    headerCls += ` col-${e.toLowerCase()}`;

    return headerCls;
  }

  async exportHeaders() {
    var widthSizes = {
      "col-1": 1,
      "col-1-5": 1.5,
      "col-2": 2,
      "col-2-5": 2.5,
      "col-3": 3,
      "col-3-5": 3.5,
      "col-4": 4,
      "col-5": 5,
      "col-6": 6
    };
    this.exportHeadersArr = [];
    const totalCols = this.selectedHeaders.length;
    let widthPercent = totalCols > 10 ? `${(100/totalCols).toFixed(4)}%` : "";
    for (const val of this.selectedHeaders) {
      let cls = this.getColumnWidth(val).replace(" tar", "");
      cls = cls.replace(" lastSearchBox", "");
      cls = cls.replace(" textOverflow", "");
      // const width = (widthSizes[cls] * 100) / 12 + "%";
      const tempObj = {
        header: val,
        column: this.pageHeaders[val] || '',
        widthPercent: widthPercent
      };
      this.exportHeadersArr.push(tempObj);
    }
  }

  get query() {
    const query = Object.entries(this.filters).reduce((a, [k, v]) => {
      if (Array.isArray(v)) {
        return v.length === 0 ? a : { ...a, [k]: v };
      }

      return v == null || v === "" ? a : { ...a, [k]: v };
    }, {});
    return query;
  }
  
  getTotalPrice(item) {
    if(this.selectedIDs.includes(item.MS_OPP_ID)) {
      this.selectedGrandTotal += parseFloat(item.TOTALAMOUNT);
      this.selectedProfitTotal += parseFloat(item.ESTPROFIT);
    } else {
      this.selectedGrandTotal -= parseFloat(item.TOTALAMOUNT);
      this.selectedProfitTotal -= parseFloat(item.ESTPROFIT);
    }
  }

}
