



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import SortIndicator from "../components/SortIndicator.vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

import Pagination from "../components/Pagination.vue";

import { notifier } from "../models/common";
import {
  IndirectOrderAPIRepo,
  Filters,
  Sort,
  SortField
} from "../repos/IndirectOrderAPIRepo";
import { IndirectOrderListResp } from "../models/IndirectOrderListResp";
import FilterCurrencyBox from "../components/FilterCurrencyBox.vue";
import CustomerSearchTooltip from "../components/CustomerSearchTooltip.vue";
import { colsResizeable } from "@/helpers/ApiHelper";

const indirectOrderRepo = new IndirectOrderAPIRepo();

declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    SortIndicator,
    FilterCurrencyBox,
    CustomerSearchTooltip
  }
})
export default class Finances extends TSXComponent<void> {
  loading = false;
  pageNumber = sessionStorage.pageDetails && JSON.parse(sessionStorage.pageDetails)["indirectorders"] ? JSON.parse(sessionStorage.pageDetails)["indirectorders"] : 1;
  customerTooltipVisibleIndex = -1;

  pageData: Partial<IndirectOrderListResp.Root> = {
    ORDERS: []
  };
  filters: Filters = {
    searchId: "",
    searchName: "",
    searchAccountName: "",
    sDate: undefined,
    eDate: undefined,
    sPdate: undefined,
    ePdate: undefined,
    sPaymentMin: undefined,
    sPaymentMax: undefined,
    sTotalMin: undefined,
    sTotalMax: undefined,
    AccountTagID: [],
    groupIDs: [],
    selectedAccs: [],
    sMarginMin: undefined,
    sMarginMax: undefined,
    sInvoiceTaxMin: undefined,
    sInvoiceTaxMax: undefined,
    sInvoiceTotalMin: undefined,
    sInvoiceTotalMax: undefined,
    gTagIDs: []
  };
  sort: Sort = {
    field: null,
    direction: {
      [SortField.Id]: 1,
      [SortField.Name]: 1,
      [SortField.Customer]: 1,
      [SortField.Margin]: 1,
      [SortField.InvoiceTax]: 1,
      [SortField.InvoiceTotal]: 1,
      [SortField.PaidDate]: 1
    }
  };
  customerTooltipLoading = false;
  selectedID: number[] = [];
  accountTags: any = [];
  accountTagsList: any = [];
  searchTagsTxt = "";
  isActive = "customerAccounts";
  varAccs: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  varAccsList: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  selectedAccs: (number | undefined)[] = [];
  searchAccTxt = "";
  searchFilters: Filters = {
    searchId: "",
    searchName: "",
    searchAccountName: "",
    sDate: undefined,
    eDate: undefined,
    sPdate: undefined,
    ePdate: undefined,
    sPaymentMin: undefined,
    sPaymentMax: undefined,
    sTotalMin: undefined,
    sTotalMax: undefined,
    AccountTagID: [],
    groupIDs: [],
    selectedAccs: [],
    sMarginMin: undefined,
    sMarginMax: undefined,
    sInvoiceTaxMin: undefined,
    sInvoiceTaxMax: undefined,
    sInvoiceTotalMin: undefined,
    sInvoiceTotalMax: undefined,
    gTagIDs: []
  };

  async created() {
    await this.fetchData();
    await this.accsLoad();
  }

  async fetchData() {
    this.loading = true;
    try {
      const data = await indirectOrderRepo.find(
        this.pageNumber,
        this.filters,
        this.sort
      );
      this.customerTooltipVisibleIndex = -1;
      this.pageData = data;
      if (this.searchTagsTxt == "") {
        this.accountTags = data.accountTags;
        this.accountTagsList = data.accountTags;
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }

    colsResizeable({ms: 500});
  }
  async hideTooltipCustomers() {
    this.customerTooltipVisibleIndex = -1;
  }
  async showTooltipCustomers(item, index) {
    if (!item.length) return;
    this.customerTooltipVisibleIndex = index;
    try {
      this.customerTooltipLoading = true;
      this.customerTooltipLoading = false;
    } catch (err) {
      notifier.alert(err.message);
      this.customerTooltipVisibleIndex = -1;
    }
  }
  async sortBy(field: SortField) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  async sortingByInvoiceId() {
    this.sortBy(SortField.Id);
  }
  async sortingByorderName() {
    this.sortBy(SortField.Name);
  }
  async sortingByReseller() {
    this.sortBy(SortField.Customer);
  }   
  async sortingByMargin() {
    this.sortBy(SortField.Margin);
  }
  async sortingByInvoiceTax() {
    this.sortBy(SortField.InvoiceTax);
  }
  async sortingByInvoiceTotal() {
    this.sortBy(SortField.InvoiceTotal);
  }
  async sortingByPaidDate() {
    this.sortBy(SortField.PaidDate);
  }

  async resetSearchByInvoiceId() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchId = this.searchFilters.searchId = "";
    await this.fetchData();
  }

  async resetSearchByName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchName = this.searchFilters.searchName = "";
    await this.fetchData();
  }

  async resetSearchByCustomerName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchAccountName = this.searchFilters.searchAccountName = "";
    await this.fetchData();
  }

  async searchByProfit() {
    this.pageNumber = 1;
    this.selectedID = [];
    await this.fetchData();
  }

  async searchByTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    await this.fetchData();
  }

  // async resetSearchByProfit() {
  //   this.pageNumber = 1;
  //   this.selectedID = [];
  //   this.filters.sPaymentMin = undefined;
  //   this.filters.sPaymentMax = undefined;
  //   await this.fetchData();
  // }

  async resetSearchByTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sTotalMin = undefined;
    this.filters.sTotalMax = undefined;
    await this.fetchData();
  } 

  async resetSearchByDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.filters.sDate == undefined || this.filters.sDate == "") &&
      (this.filters.eDate == "" || this.filters.eDate == undefined)
    ) {
      return;
    } else {
      this.filters.sDate = undefined;
      this.filters.eDate = undefined;
      await this.fetchData();
    }
  }
  async resetSearchByPaidDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.searchFilters.sPdate == undefined || this.searchFilters.sPdate == "") &&
      (this.searchFilters.ePdate == "" || this.searchFilters.ePdate == undefined)
    ) {
      return;
    } else {
      this.filters.sPdate = this.searchFilters.sPdate = undefined;
      this.filters.ePdate = this.searchFilters.ePdate = undefined;
      await this.fetchData();
    }
  }

  async searchByInvoiceId() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.searchFilters.searchId == "") {
      return;
    } else {
      this.filters.searchId = this.searchFilters.searchId;
      await this.fetchData();
    }
  }

  async searchByName() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.searchFilters.searchName == "") {
      return;
    } else {
      this.filters.searchName = this.searchFilters.searchName;
      await this.fetchData();
    }
  }

  async searchByCustomerName() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.searchFilters.searchAccountName == "") {
      return;
    } else {
      this.filters.searchAccountName = this.searchFilters.searchAccountName;
      await this.fetchData();
    }
  }

  async searchByMargin() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sMarginMin = this.searchFilters.sMarginMin;
    this.filters.sMarginMax = this.searchFilters.sMarginMax;
    await this.fetchData();
  }

  async searchByInvoiceTax() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sInvoiceTaxMin = this.searchFilters.sInvoiceTaxMin;
    this.filters.sInvoiceTaxMax = this.searchFilters.sInvoiceTaxMax;
    await this.fetchData();
  }

  async searchByInvoiceTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sInvoiceTotalMin = this.searchFilters.sInvoiceTotalMin;
    this.filters.sInvoiceTotalMax = this.searchFilters.sInvoiceTotalMax;
    await this.fetchData();
  }

  async searchByDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.filters.sDate == undefined || this.filters.sDate == "") &&
      (this.filters.eDate == "" || this.filters.eDate == undefined)
    ) {
      return;
    } else {
      await this.fetchData();
    }
  }
  async searchByPaidDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.searchFilters.sPdate == undefined || this.searchFilters.sPdate == "") &&
      (this.searchFilters.ePdate == "" || this.searchFilters.ePdate == undefined)
    ) {
      return;
    } else {
      this.filters.sPdate = this.searchFilters.sPdate;
      this.filters.ePdate = this.searchFilters.ePdate;
      await this.fetchData();
    }
  }
  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    if(sessionStorage.pageDetails != undefined && JSON.parse(sessionStorage.pageDetails)["indirectorders"]){
      var oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["indirectorders"] = this.pageNumber;
      sessionStorage.setItem('pageDetails',JSON.stringify(oldData))
    }

    if (
      this.pageNumber <= (this.pageData.TOTALPAGES || 0) &&
      this.pageNumber >= 1
    ) {
      await this.fetchData();
    }
  }
  gotoSearch = function(this: any, searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for" + this.$route.path.toLowerCase()
    });
  };
  resetSelected() {
    this.selectedID = [];
    // this.selectedAll = false;
    // this.excludedIDs = [];
  }

  //filter by Tags
  async filterByTag() {
    this.resetSelected();
    this.pageNumber = 1;
    await this.fetchData();
  }
  async checkAllTag() {
    this.resetSelected();
    // this.filters.AccountTagID = this.accountTags.map(val => val.ACCOUNTTAGID);
    var $this = this;
		this.filters.AccountTagID = [];
		this.accountTags.forEach(function (val: any) {
			if (!isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP == 'undefined') {
        if (typeof val.ISGLOBALTAG == 'undefined') {
					if($this.filters.AccountTagID != undefined) {
						$this.filters.AccountTagID.push(val.ACCOUNTTAGID);
					}
				}
				else {
					$this.filters.gTagIDs.push(val.ACCOUNTTAGID);
				}
			}
		});
    this.pageNumber = 1;
    await this.fetchData();
  }
  async checkAllGroupTag() {
    this.resetSelected();
    var $this = this;
    this.filters.groupIDs = [];
		this.accountTags.forEach(function (val: any) {
			if (!isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP != 'undefined') {
				$this.filters.groupIDs.push(val.ACCOUNTTAGID);
			}
		});
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetTag() {
    this.resetSelected();
    this.filters.AccountTagID = [];
    this.filters.groupIDs = [];
    this.filters.gTagIDs = [];
    this.pageNumber = 1;
    await this.fetchData();
  }
  filterTagsInBox() {
    let options = this.accountTagsList;

    if (this.searchTagsTxt) {
      options = options.filter(
        option =>
          `${option.TAGNAME}`
            .toLowerCase()
            .indexOf(this.searchTagsTxt.toLowerCase()) !== -1
      );
    }

    this.accountTags = options;
  }

  toggleActive(div) {
    this.isActive = div;
  }
  filterAccsInBox() {
    let options = this.varAccsList;

    if (this.searchAccTxt) {
      options = options.filter(
        option =>
          `${option.ANAME}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTALIAS}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTID}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1
      );
    }

    this.varAccs = options;
  }
  async filterByAcc() {
    this.resetSelected();
    this.pageNumber = 1;
    await this.fetchData();
  }
  async checkAllAcc() {
    this.resetSelected();
    this.filters.selectedAccs = this.varAccs.map(val => val.AID);
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetAcc() {
    this.resetSelected();
    this.filters.selectedAccs = [];
    this.pageNumber = 1;
    await this.fetchData();
  }
  async accsLoad() {
    if (this.searchAccTxt == "") {
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          FunctionName: "ListExt",
          controller: "Accounts",
          queryType: "top5UsedAcct",
          view: "all",
          accSearch: 1
        });
        if (response.data.STATUS) {
          this.varAccs = response.data.ACCOUNTS;
          this.selectedAccs = response.data.VARACCOUNTSIDSRR;
          this.varAccsList = response.data.ACCOUNTS;
        }
        if (response.data.ERROR || !response.data.ACCOUNTS) {
          console.log("error", response.data.ERROR);
        }
      } catch (err) {
        notifier.alert(err.message);
      } finally {
        this.loading = false;
      }
    }
  }
}
