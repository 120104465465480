

























































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

interface Props {
  status: DeployStatusValue;
  }

  interface Events {
  onChangeStatus: DeployStatusValue
  }

export enum DeployStatusValue {
  Planning = 1,
  Building = 2,
  Delivering = 3,
  Complete = 4,
  OnHold = 5,
  Closed = 6
}

@Component({
  inheritAttrs: false,
  components: {}
})
export default class DeployStatusComponent extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  status!: DeployStatusValue;

  @Prop({ required: false, default: true })
  showStatus?: boolean;

  currentRole = sessionStorage.getItem("userRole");
  optionsVisible = false

  get iconClass() {
      if(this.status == DeployStatusValue.Planning){
          return 'gray'
      }
      else if(this.status == DeployStatusValue.Building){
          return 'purple'
      }
      else if(this.status == DeployStatusValue.Delivering){
          return 'green'
      }
      else if(this.status == DeployStatusValue.Complete){
          return 'blue'
      }
      else if(this.status == DeployStatusValue.OnHold){
          return 'red'
      }
      else if(this.status == DeployStatusValue.Closed){
          return 'red'
      }
      else{
          return 'gray'
      }
  }

  get statusText() {
      if(this.status == DeployStatusValue.Planning){
          return 'Planning'
      }
      else if(this.status == DeployStatusValue.Building){
          return 'Building'
      }
      else if(this.status == DeployStatusValue.Delivering){
          return 'Delivering'
      }
      else if(this.status == DeployStatusValue.Complete){
          return 'Complete'
      }
      else if(this.status == DeployStatusValue.OnHold){
          return 'On Hold'
      }
      else if(this.status == DeployStatusValue.Closed){
          return 'Closed'
      }
      else{
          return 'Planning'
      }
  }

  hideTooltip() {
    this.optionsVisible = false;
  }

  click() {
    this.optionsVisible = !this.optionsVisible
  }

  clickOutsideOptionsBox() {
    if (this.optionsVisible) {
      this.optionsVisible = false
    }
  }

  itemClick(status) {
    this.$emit('changeStatus', status)
    this.optionsVisible = false
  }
}
