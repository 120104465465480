




































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { htmlParse } from '../../helpers/htmlHelpers'

interface Props {
  options: [];
  selectedIDs: [];
  placeholderText: string;
  create_newTag: boolean;
}

interface Events {
}

declare const $: any;
declare const htmlCheck: Function;

@Component({
  inheritAttrs: false,
  components: {}
})
export default class FormFunctions extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  options!: any[];

  @Prop({ required: true })
  selectedIDs!: (number | string)[];

  @Prop({ required: false, default: "" })
  label!: string;

  @Prop({ required: false, default: "Search" })
  placeholderText!: string;

  @Prop({ required: false, default: "No Items Found" })
  noDataText!: string;

  @Prop({ required: false, default: false })
  create_newTag!: boolean;

  @Prop({ required: false, default: true })
  isActive!: boolean;

  @Prop({ required: false, default: false })
  hideToggle!: boolean;

  @Prop({ required: false, default: false })
  disabled!: boolean;

  @Prop({ required: false, default: false })
  alwaysShow!: boolean;

  dropdownVisible = false
  search = ''
  showOnlySelected: boolean = false
  creating: any = false;
  inputWidth : number = 0;

  created() {
  }

  autoExpand() {
    this.inputWidth = (this.search.length + 1) * 9.2;
  }

  get filteredOptions() {
    let options = this.options

    if (this.search) {
      options = options.map((option) => {
        const filteredFunctions = option.OPTIONS.filter((securityFunction) => securityFunction.SECURITYFUCTIONNAME.toLowerCase().indexOf(this.search.toLowerCase()) !== -1);
        if (option.SECURITYFUNCTIONCATEGORY.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || filteredFunctions.length ) {
          return { ...option, OPTIONS: filteredFunctions };
        }
        return null;
      }).filter(Boolean);
    }

    if (this.showOnlySelected) {
      options = options.map((option) => {
        const filteredFunctions = option.OPTIONS.filter((securityFunction) => securityFunction.checked);
        if (option.checked || filteredFunctions.length) {
          return { ...option, OPTIONS: filteredFunctions };
        }
        return null;
      }).filter(Boolean);
    }

    options = options.filter((option) => option.OPTIONS.length > 0);
    return options
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible
    if (this.dropdownVisible == true) {
      setTimeout(() => {
        $(".account-tags #autoExpand").focus();
      }, 300);
    }
  }

  clickOutside() {
    this.dropdownVisible = false
  }

  isChecked(id: any): boolean {
    const checked = !!this.selectedIDs.find((val) => val === id);
    return checked;
  }

  selectAll() {
    this.$emit('selectAll')
  }

  reset() {
    this.$emit('reset')
  }

  createNewTag() {
    if (!this.create_newTag) {
      return;
    }

    //remove HTML
    this.search = this.removeHTML(this.search);

    if (this.search != "" && this.search.length >= 2) {
      //make sure tag name is unique
      const check = this.options.filter((val: any) => val.text.toLowerCase() == this.search.toLowerCase());
      if (check.length === 0) {
        this.$emit("createNewTag", this.search);
      }
    }
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }
}
