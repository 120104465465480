
































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { notifier } from "@/models/common";
import { getEUAddress, loginCheck } from "@/helpers/ApiHelper";
import { VBPopover } from 'bootstrap-vue';
import Datepicker from "../Datepicker.vue";
import moment from "moment";
import DropdownControl from "@/components/DropdownControl.vue";
import AssignToEndUserModal from "@/components/AssignToEndUserModal.vue";
import ConfirmRemoveItemModal from "@/components/ConfirmRemoveItemModal.vue";

interface Props {
  data: object;
  showPopover: false;
}

interface Events {
  onClose: void;
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: {
    ConfirmRemoveItemModal,
    Datepicker,
    DropdownControl,
    AssignToEndUserModal
  },
  methods: {
    loginCheck,
    getEUAddress
  },
  mounted() {
    $('body').addClass('stop-scrolling');
    this.$nextTick(() => {
      const datepickerInputs = document.querySelectorAll('input[placeholder="Warranty Start Date"], input[placeholder="Warranty End Date"]');
      
      datepickerInputs.forEach(input => {
        input.addEventListener('focus', () => {
          const scrollContainer = document.querySelector('#alertLog-container .ps--active-y');
          if (scrollContainer) {
            scrollContainer.classList.add('overflowHidden');
            scrollContainer.classList.remove('ps', 'ps--active-y');
          }
        });

        input.addEventListener('blur', () => {
          const hiddenContainer = document.querySelector('#alertLog-container .overflowHidden');
          if (hiddenContainer) {
            hiddenContainer.classList.add('ps', 'ps--active-y');
            hiddenContainer.classList.remove('overflowHidden');
          }
        });
      });
    });
  },
  beforeDestroy() {
    $('body').removeClass('stop-scrolling');
  }
})
export default class HardwareDetails extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  data!: any;

  @Prop({ required: false, default: false })
  fromEndUser!: boolean;

  isStatusDropdownOpen = false;
  isCategoryDropdownOpen = false;
  popoverVisible = false;
  loadingLogs = false;
  logins: any = [];
  endUsers: any = [];
  aID: any = this.data.customer.aId;
  customerAddresses: any = [];
  customerAddressesBilling: any = [];
  onetimeAddress: any = {};
  onetimeAddressBilling: any = {};
  selectedEndUsers: number[] = [];
  euID = 0;
  euName = "";
  euPhone = "";
  euPhoneText = "";
  warningMessage = "";
  inputField = "";
  euEmail = "";
  defaultEndUser = 0;
  invoiceMail: any = [];
  defaultInvoiceMail = 0;
  contactEmail: number[] = [];
  addNewType = "";
  endUserVisible = false;
  accountEUsList: any = [];
  selectedEUIds: any = [];
  // assign to end user
  assignEndUsersModalVisible = false;
  selectedID: any = [];
  selectedItems: any = [];
  isAddingNewEU = false;
  newEUData: any = {};
  loadingItem: any = null;
  itemFound: boolean = true;
  showCheck: boolean = false;
  showError: boolean = false;
  confirmRemoveSerialNumber: boolean = false;
  editedField: string = "";
  editedItem: any = {};

  mouseover() {
    this.popoverVisible = true
  }

  mouseout() {
    this.popoverVisible = false
  }

  async created() {
    let setDefault = true;
    if (this.data.endUser.contactEuId && this.data.endUser.contactEuId != 0) {
      this.selectedEndUsers = [parseInt(this.data.endUser.contactEuId)];
      setDefault = false;
    }
    await this.getHardwareLogs();
    // await this.getCustomerAddresses(this.aID, setDefault);

    // get end users list of this account
    // trying to use same api with hardware > assign to end user
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      Controller: "Hardware",
      FunctionName: "GetAccountEUsList",
      hardwareIds: "",
      aId: this.aID,
      selectedEuId: this.data.endUser.euID || 0
    });
    if (response.data.STATUS == 1) {
      this.accountEUsList = [];
      for(const item of (response.data.accountEUsList || [])) {
        const addr = getEUAddress(
          item.ADDR1,
          item.ADDR2,
          item.CITY,
          item.EUSTATE,
          item.ZIP,
          item.COUNTRY || ''
        );
        this.accountEUsList.push({
          ID: item.EUID,
          TEXT: `${item.EUNAME}${addr.trim() != "" ? ` - ${addr.trim()}` : ""}`,
          data: item
        });
      }
      this.accountEUsList.push({
        ID: 'onetime',
        TEXT: 'Create New',
        data: {
          EUNAME: 'Create New'
        }
      });
      // this.hardwareInfo = response.data.hardwareInfo || [];
    }
    if((this.data.endUser.euID || 0) > 0) {
      this.selectedEUIds = [this.data.endUser.euID];
    }
    this.data.identifiers.forEach(item => {
      item.valueBK = item.value;
    });
  }

  toggleDropdown(dropdownName) {
    this[dropdownName] = !this[dropdownName];
  }

  setStatus(status) {
    this.data.statusName = status;

    let hStatus = 1;

    if(status == "Deleted"){
      hStatus = 0;
    }
    else if(status == "Retired"){
      hStatus = 2;
    }

    // Determine if 'products' or 'items' exists in the parent and use that for finding the item
    let parentData = this.$parent.$parent.$parent;
    let collection = parentData.products || parentData.items;

    if (collection) {
      let product = collection.find(item => item.HARDWAREID === this.data.id);
      if (product) {
        product.STATUS = product.ISACTIVE = this.data.statusName;
      }
    }

    axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Deployments",
      FunctionName: "UpdateHardware",
      hardwareId: this.data.id,
      aId: this.aID,
      hardwareStatus: hStatus,
      action: "updateStatus"
    });

    this.isStatusDropdownOpen = false;
    if (this.fromEndUser) {
      parentData.fetchData();
    }
  }
  
  setCategory(categoryID, category) {
    let parentData = this.$parent.$parent.$parent;
    let collection = parentData.products || parentData.items;

    if (collection) {
      let product = collection.find(item => item.HARDWAREID === this.data.id);
      if (product) {
        product.CATEGORYNAME = category;
      }
    }

    this.data.categoryName = category;
    axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Deployments",
      FunctionName: "UpdateHardware",
      hardwareId: this.data.id,
      aId: this.aID,
      action: "updateCategory",
      hardwareCategory: categoryID
    });

    this.isCategoryDropdownOpen = false;
    if (this.fromEndUser) {
      parentData.fetchData();
    }
    
  }

  focusIdentifier(e) {
    $(e.target).select();
  }

  async updateIdentifer(item: any) {
    if (item.value == item.valueBK) {
      return false;
    }

    item.error = "";
    let action = "";
    const requestObj: any = {
      controller: "Deployments",
      FunctionName: "UpdateHardware",
      hardwareId: this.data.id,
      hardwareUUID: this.data.hardwareUUID,
      aId: this.aID
    };
    switch (item.type) {
      case "asset_number":
        action = "updateAssetNumber";
        requestObj.assetNumber = item.value;
        break;
      case "asset_tag":
        action = "updateAssetTag";
        requestObj.assetTag = item.value;
        break;
    }
    if (action == "") return;
    requestObj.action = action;

    const response = await axios.post(dataURL + "?ReturnType=JSON", requestObj);
    if (response.data.STATUS == 1) {
      this.confirmRemoveSerialNumber = false;
      this.data.identifiers.forEach(element => {
        if (element.type == item.type) {
          element.valueBK = element.value;
        }
      });
      this.$emit("updateList", { hardwareId: this.data.id, ...item });
      notifier.success("Updated successfully");
      await this.getHardwareLogs();
    } else {
      // eslint-disable-next-line require-atomic-updates
      item.error = response.data.STATUSMESSAGE || "";
      if (item.error != "") {
        notifier.alert(item.error);
      }
    }
  }

  checkWarrantyDate(item) {
    // const element:any = event.target;
    // if ((typeof $(element).attr("data-view") != "undefined" && $(element).attr("data-view").includes("day")) || (element.tagName == "INPUT" && moment(item.value, "MM/DD/YYYY", true).isValid())) {
    //   this.updateWarranty(item, true);
    // }

    if (item.value && moment(item.value, "MM/DD/YYYY", true).isValid()) {
      // this.updateWarranty(item, true);
      this.checkDate(item);
    }
  }

  async isHP(item,sku) {
    this.loadingItem = item.value;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "ExternalSends",
        FunctionName: "carepacks",
        serialNumber: item.value,
        ProductSku: sku
      });

      console.dir(response);

      if (response.data.return !== undefined && response.data.return["Coverage type"] !== undefined && response.data.return["Coverage type"] !== '') {
        const startDate = moment(response.data.return["Start date"]);
        const endDate = moment(response.data.return["End date"]);
        this.data.warranty[0] = { value: response.data.return["Coverage type"], type: "warranty_name" };
        this.data.warranty[1] = { value: startDate.isValid() ? startDate.format('MM/DD/YYYY') : '', type: "start_date" };
        this.data.warranty[2] = { value: endDate.isValid() ? endDate.format('MM/DD/YYYY') : '', type: "end_date" };

        this.updateWarranty(this.data.warranty, false);

        await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Deployments",
          FunctionName: "UpdateHardware",
          hardwareId: this.data.id,
          aId: this.aID
        });
      } else {
        this.itemFound = false;

        const warrantyCheckErrorElement = document.getElementById("warrantyCheckError");
        if (warrantyCheckErrorElement) {
          warrantyCheckErrorElement.innerHTML = response.data.return["Error"];
          warrantyCheckErrorElement.classList.remove("d-none");
          warrantyCheckErrorElement.classList.add("d-flex");
        }
        
        // Automatically reset itemFound after 5 seconds
        await new Promise(resolve => setTimeout(resolve, 5000)); 
        this.itemFound = true;
        
        if (warrantyCheckErrorElement) {
          warrantyCheckErrorElement.classList.add("d-none");
          warrantyCheckErrorElement.classList.remove("d-flex");
          warrantyCheckErrorElement.innerHTML = '';
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingItem = null;
    }
  }

  async updateWarranty(item: any | any[], alerts) {
    // Check if item is an array
    const items = Array.isArray(item) ? item : [item];

    for (const singleItem of items) {
      if (singleItem.value == "") {
        if (singleItem.type == "warranty_name") {
          singleItem.error = "Warranty Name is required";
        } else if (singleItem.type == "start_date") {
          singleItem.error = "Start Date is required";
        } else if (singleItem.type == "end_date") {
          singleItem.error = "End Date is required";
        }
        continue; // Skip to the next item
      }

      singleItem.error = "";
      let action = "";
      const requestObj: any = {
        controller: "Deployments",
        FunctionName: "UpdateHardware",
        hardwareId: this.data.id,
        hardwareUUID: this.data.hardwareUUID,
        aId: this.aID
      };

      switch (singleItem.type) {
        case "warranty_name":
          action = "warrantyName";
          requestObj.warrantyName = singleItem.value;
          break;
        case "start_date":
          action = "startDate";
          requestObj.startDate = singleItem.value;
          break;
        case "end_date":
          action = "endDate";
          requestObj.endDate = singleItem.value;
          break;
      }
      if (action == "") continue; // Skip to the next item if no action is set
      requestObj.action = action;

      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", requestObj);
        if (response.data.STATUS == 1) {
          let lastChecked = "";
          if (response.data.LASTCHECKEDFORMATTED) {
            this.data.warranty.map((warrantyItem: any) => {
              if (warrantyItem.type == 'last_checked') {
                warrantyItem.value = response.data.LASTCHECKEDFORMATTED;
              }
            });
            lastChecked = moment(response.data.LASTCHECKEDFORMATTED).format("MM/DD/YYYY");
          }
          this.$emit("updateList", { hardwareId: this.data.id, ...singleItem, lastChecked: lastChecked });
          if(alerts){
            notifier.success("Updated successfully");
          }
          await this.getHardwareLogs();
        } else {
          singleItem.error = response.data.STATUSMESSAGE || "";
          if (singleItem.error != "") {
            notifier.alert(singleItem.error);
          }
        }
      } catch (error) {
        singleItem.error = "An error occurred while updating the warranty.";
        console.error(error);
      }
    }
  }


  async getHardwareLogs() {
    this.loadingLogs = true;
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Hardware",
      FunctionName: "GetHardwareLogs",
      hardwareId: this.data.id,
      aId: this.aID
    });
    this.loadingLogs = false;
    if (response.data.STATUS == 1) {
      const hardwareLogs = response.data.hardwareLogs || [];
      const loginLogs = response.data.loginLogs || [];
      for (const item of hardwareLogs) {
        if (item.LOGTYPE == 1) {
          item.desc = "Product was added";
        } else {
          item.desc = item.LOGDETAILS || "";
        }
      }
      
      this.logins = loginLogs;
      if (this.logins != "") {
        this.logins[0].last10 = this.data.logs.last10;
      }
      this.data.logs = hardwareLogs;
    }
  }

  identifierBlur(e) {
    e.target.setSelectionRange(0, 0);
  }

  async getCustomerAddresses(aID, setDefault = false) {
    // console.log("aID", aID);
    // var ret: object[] = [];
    if(aID > 0) {
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Queries",
          subsystem: "Helpers",
          FunctionName: "getAccountAddresses",
          aID: aID,
          addressType: "1,2"
        });
        if(response.data.STATUS) {
          const shippingAddr = response.data.addressArr.filter(tmp => tmp.ACCOUNTSADDRESS_TYPE == 1);
          const billingAddr = response.data.addressArr.filter(tmp => tmp.ACCOUNTSADDRESS_TYPE == 2);
          this.customerAddresses = shippingAddr.map(tmp => ({
            ID: tmp.ACCOUNTSADDRESS_ID,
            TEXT: this.getAddressText(tmp)
          }));
          this.customerAddressesBilling = billingAddr.map(tmp => ({
            ID: tmp.ACCOUNTSADDRESS_ID,
            TEXT: this.getAddressText(tmp)
          }));
        }
        // option to add a permanent address, and one time using
        // ret.push({
        //   ID: "permanent",
        //   TEXT: "Add new shipping address"
        // });
        this.customerAddresses.push({
          ID: "onetime",
          TEXT: "Enter a shipping address"
        });
        this.customerAddressesBilling.push({
          ID: "onetime",
          TEXT: "Enter a billing address"
        });
        
        if(Object.keys(this.onetimeAddress).length) {
          this.customerAddresses.unshift({
            ID: 0,
            TEXT: this.getAddressText(this.onetimeAddress)
          });
        }
        if (Object.keys(this.onetimeAddressBilling).length) {
          this.customerAddressesBilling.unshift({
            ID: 0,
            TEXT: this.getAddressText(this.onetimeAddressBilling)
          });
        }
        const endUserArr = response.data.contactListArr.filter(tmp => tmp.ACONTACTTYPE == 1 || tmp.ACONTACTTYPE == 3 || tmp.UTYPE);
        const invoiceMailArr = response.data.contactListArr.filter(tmp => tmp.ACONTACTTYPE == 2 || tmp.ACONTACTTYPE == 3);
        const defaultEndUserItem = response.data.contactListArr.find(tmp => (tmp.ACONTACTTYPE == 1 || tmp.ACONTACTTYPE == 3) && tmp.ISDEFAULT);
        const defaultInvoiceMailItem = response.data.contactListArr.find(tmp => (tmp.ACONTACTTYPE == 2 || tmp.ACONTACTTYPE == 3) && tmp.ISDEFAULT);
        this.endUsers = endUserArr.map(tmp => ({
          ID: tmp.ACCOUNTCONTACTID,
          TEXT: tmp.ENDUSERDETAILS,
          NAME: tmp.ACONTACTNAME,
          PHONE: tmp.ACONTACTPHONE,
          EMAIL: tmp.ACONTACTEMAIL,
          PHONEEXT: tmp.ACONTACTPHONEEXT,
          ACCOUNTUSERID: tmp.ACCOUNTUSERID
        }));
        this.endUsers.push({
          ID: "onetime",
          TEXT: "Enter New End User"
        });
        if (this.selectedEndUsers.length) {
          const selectedEndUserItem = this.endUsers.find(tmp => (tmp.ID == this.selectedEndUsers));
          this.euName = selectedEndUserItem.NAME;
          this.euPhone = selectedEndUserItem.PHONE;
          this.euEmail = selectedEndUserItem.EMAIL;
          this.euPhoneText = selectedEndUserItem.PHONEEXT;
        }
        if(defaultEndUserItem) {
          this.defaultEndUser = defaultEndUserItem.ACCOUNTCONTACTID;
          if(setDefault) {
            this.selectedEndUsers = [defaultEndUserItem.ACCOUNTCONTACTID];
            this.euName = defaultEndUserItem.ACONTACTNAME;
            this.euPhone = defaultEndUserItem.ACONTACTPHONE;
            this.euEmail = defaultEndUserItem.ACONTACTEMAIL;
            this.euPhoneText = defaultEndUserItem.ACONTACTPHONEEXT;
          }
        }
        this.invoiceMail = invoiceMailArr.map(tmp => ({
          ID: tmp.ACCOUNTCONTACTID,
          TEXT: tmp.ACONTACTEMAIL
        }));
        this.invoiceMail.push({
          ID: "onetime",
          TEXT: "Enter E-mail"
        });
        if(defaultInvoiceMailItem) {
          this.defaultInvoiceMail = defaultInvoiceMailItem.ACCOUNTCONTACTID;
          if(setDefault) {
            this.contactEmail.push(defaultInvoiceMailItem.ACCOUNTCONTACTID);
          }
        }
      } catch (error) {
        // console.log(error);
      }
    }
    // return ret;
  }

  checkDuplicateExists(header, item) {
    const response = axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Deployments",
      FunctionName: "UpdateHardware",
      header: header,
      value: item.value,
      action: "checkDuplicate"
    });

    response.then(
      response => {
        if (response.data.STATUS == 1) {
          if ((item.value != item.valueBK) && item.value == "") {
            this.editedField = item.type;
            this.editedItem = item;
            this.confirmRemoveSerialNumber = true;
            return false;
          } else {
            this.updateIdentifer(item);
          }
        } else {
          this.warningMessage = response.data.STATUSMESSAGE;
          this.inputField = response.data.header;
        }
      }
    )
  }

  getAddressText(address) {
    var ret = "";
    const name = address.ADDRESSNAME || "";
    const addr1 = address.ACCOUNTSADDRESS_ADDRESS1 || "";
    const city = address.ACCOUNTSADDRESS_CITY || "";
    const state = address.ACCOUNTSADDRESS_STATE || "";
    const zip = address.ACCOUNTSADDRESS_ZIP || "";
    if(name != "") {
      ret += name + " -";
    }
    if(addr1 != "") {
      ret += " " + addr1;
    }
    if(city != "") {
      ret += " " + city;
    }
    if(state != "" || zip != "") {
      ret += ",";
      if(state != "") {
        ret += " " + state;
      }
      if(zip != "") {
        ret += " " + zip;
      }
    }

    return ret;
  }

  async selectEndUser(selectedID) {
    // if (selectedID == "onetime") {
    //   this.addNewType = "endUser";
    //   this.endUserVisible = true;
    // } else {
    //   var euDetails = this.endUsers.filter(user => user.ID == selectedID);
    //   this.selectedEndUsers = [selectedID];
    //   if (euDetails.length) {
    //     this.euName = euDetails[0].NAME;
    //     this.euPhone = euDetails[0].PHONE;
    //     this.euEmail = euDetails[0].EMAIL;
    //     this.euPhoneText = euDetails[0].PHONEEXT;
    //   } else {
    //     this.euName = "";
    //     this.euPhone = "";
    //     this.euEmail = "";
    //   }
    //   // To list the selected contacts on the top
    //   /* if(this.endUsers.length) {
    //     this.sortingAddresses(this.endUsers, this.selectedEndUsers);
    //   } */
    // }

    this.isAddingNewEU = false;
    if(selectedID == "onetime") {
      // create new
      this.isAddingNewEU = true;
      this.showEUModal(true);
    }else {
      const inList = this.accountEUsList.find(item => item.ID == selectedID);
      if(inList) {
        this.selectedEUIds = [inList.ID];
      }
    }
  }

  showEUModal(createNew = false) {
    // reset
    this.newEUData = {};

    if(this.selectedEUIds.length && `${this.selectedEUIds[0]}`.indexOf("0-") != -1) {
      // create new for case has no euId
      const inList = this.accountEUsList.find(item => item.ID == this.selectedEUIds[0]);
      if(inList) {
        this.newEUData = inList;
        this.isAddingNewEU = true;
      }
    }
    this.assignEndUsersModalVisible = true;
  }

  reloadHardwareData() {
    if(this.$route.name == "Hardware") {
      const pageComponent: any = this.$parent.$parent.$parent;
      if(pageComponent && pageComponent.fetchData) {
        pageComponent.fetchData();
        pageComponent.modalHardwareDetails.show = false;
      }
    }else if(this.$route.name == "ViewOrder") {
      const pageComponent: any = this.$parent.$parent.$parent;
      if(pageComponent && pageComponent.getOrdersdetails) {
        pageComponent.getOrdersdetails();
        pageComponent.modalHardwareDetails.show = false;
      }
    }
  }

  getStartDate(item) {
    if (item.type === "end_date") {
      let startDate = this.data.warranty.find(item => item.type === "start_date");
      if (startDate) {
        return startDate.value;
      }
    }
    return "";
  }

  checkDate(item) {
    if( new Date((this.data.warranty.find(item => item.type === "start_date")).value) > 
        new Date((this.data.warranty.find(item => item.type === "end_date")).value) ) {
      this.showError = true;
    }
    else {
      this.showError = false;
      this.updateWarranty(item, true);
    }
  }
}
